import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Switch, Route } from "react-router-dom";

import { default as Contacts } from "containers/Contacts/List";
import { ContactContainer as Contact } from "containers/Contacts/Single";
import { default as Opportunities } from "containers/Opportunities/List";
import { default as Opportunity } from "containers/Opportunities/Single";
import { default as PastOpportunities } from "containers/Opportunities/Past";
import { default as Projects } from "containers/Projects/List";
import { default as Project } from "containers/Projects/Single";
import { default as ProjectsDashboard } from "containers/Projects/Dashboard";
import { ProjectsBestPracticesList } from "containers/Projects/BestPractices/List";
import { default as SubscriptionsContainer } from "containers/Billing/Subscriptions";
import InvoiceSingle from "containers/Billing/Single";
import InvoiceList from "containers/Billing/List";
import BillingSettings from "containers/Billing/Settings";
import Dashboard from "containers/Dashboard";
import clsx from "clsx";
import { ProjectsBestPracticesSingle } from "containers/Projects/BestPractices/Single";
import AcademyCoursesListComponent from "containers/Academy/List";
import AcademyCoursesSingleComponent from "containers/Academy/Single";
import { ManagerWebCampusList } from "modules/web/Campus/List";
import { ManagerWebCampusSingle } from "modules/web/Campus/Single";
import { ManagerWebCampusGroupSingle } from "modules/web/Campus/Group/Single";
import { WebManagerInbox } from "modules/web/Inbox";

import { ManagerWebStaffList } from "modules/web/Staff/List";
import { ManagerWebStaffSingle } from "modules/web/Staff/Single";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.default,
    height: "100vh",
  },
  contentShift: {
    // paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}));

const Content = ({ open }) => {
  const classes = useStyles();
  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
    >
      <div className={classes.toolbar} />
      <Switch>
        <Route path="/" exact component={Dashboard} />

        {/** PROJECTS */}
        <Route path="/projects" exact component={Projects} />
        <Route path="/projects/dashboard" exact component={ProjectsDashboard} />
        <Route
          path="/projects/best-practices"
          exact
          component={ProjectsBestPracticesList}
        />
        <Route
          path="/projects/best-practices/:id"
          exact
          component={ProjectsBestPracticesSingle}
        />
        <Route
          path="/projects/:id"
          component={Project}
          // key={Math.random() * 100}
        />
        {/** OPPORTUNITIES */}
        <Route path="/pipeline" exact component={Opportunities} />
        <Route path="/pipeline/past" exact component={PastOpportunities} />
        <Route path="/pipeline/:id" component={Opportunity} />
        {/** CRM */}
        <Route path="/contacts" exact component={Contacts} />
        <Route path="/contacts/:id" component={Contact} />
        {/** BILLING */}
        <Route path="/subscriptions" component={SubscriptionsContainer} />
        <Route
          path="/add-invoice"
          exact
          render={() => <InvoiceSingle variant="add" drawerOpen={open} />}
        />
        <Route path="/billing-settings" exact component={BillingSettings} />
        <Route path="/invoices" exact component={InvoiceList} />

        <Route
          path="/invoices/:id/:action"
          exact
          render={(params) => (
            <InvoiceSingle
              variant={params.match.params.action}
              id={params.match.params.id}
              drawerOpen={open}
            />
          )}
        />
        {/** ACADEMY */}

        <Route path="/academy" exact component={AcademyCoursesListComponent} />
        <Route
          path="/academy/courses/:id"
          exact
          component={AcademyCoursesSingleComponent}
        />
        {/** CAMPUS */}
        <Route path="/campus" exact component={ManagerWebCampusList} />
        <Route path="/campus/:id" exact component={ManagerWebCampusSingle} />
        <Route
          path="/campus/:campusId/groups/:groupId"
          exact
          component={ManagerWebCampusGroupSingle}
        />
        {/** STAFF */}
        <Route path="/staff" exact component={ManagerWebStaffList} />
        <Route path="/staff/:staffId" exact component={ManagerWebStaffSingle} />
        {/** EXTERNAL LINKS */}
        <Route
          path="/support"
          exact
          component={() => {
            window.location.href = "https://support.sport-madness.com/";
            return null;
          }}
        />
        <Route
          path="/knowhow"
          exact
          component={() => {
            window.location.href = "https://knowhow.sport-madness.com/";
            return null;
          }}
        />
        <Route path="/inbox" exact component={WebManagerInbox} />
      </Switch>
    </main>
  );
};

export default Content;
