import React, { Component } from "react";
import userContext from "providers/UserProvider";
import { FranchiseProjectDashboard as DasboardComponent } from "react-library-sm";
import { Grid } from "@material-ui/core";
import DashboardServices from "services/franchise-projects/dashboard.services";
class ProjectsDashboard extends Component {
  static contextType = userContext;
  constructor(props) {
    super(props);
    this.dashboardServices = new DashboardServices();
    this.state = {
      numProjects: null,
      totalBudget: null,
      tasks: [],
    };
  }

  componentDidMount() {
    this.dashboardServices
      .getDashboard(this.context.franchise)
      .then((response) => {
        // console.log("response get dashboard", response);

        this.setState({
          numProjects: response.data.projects.count,
          totalBudget: response.data.projects.total,
          tasks: response.data.tasks,
        });
      })
      .catch((err) => {
        // console.log("err get dashboard", err.response);
      });
  }

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <DasboardComponent
          numProjects={this.state.numProjects}
          totalBudget={this.state.totalBudget}
          tasks={this.state.tasks}
          translation={this.context.translation.templates.projectsDashboard}
          locale={this.context.locale}
        />
      </Grid>
    );
  }
}

export default ProjectsDashboard;
