import React from "react";

import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import { Link } from "react-router-dom";
import moment from "moment";
import { numeral, Chip } from "react-library-sm";

import { options } from "assets/mui-datatables/options-default";

const PastOpportunitiesTemplate = translation => {
  // console.log('translation en OpportunitiesTemplate', translation);
  return {
    title: translation.opportunities.past.title,

    buttons: {
      view_open_opportunities:
        translation.opportunities.past.buttons.view_open_opportunities
    },
    tabs: [
      {
        value: "won",
        label: translation.opportunities.past.tabs.won
      },
      {
        value: "lost",
        label: translation.opportunities.past.tabs.lost
      }
    ],
    datatables: {
      won: {
        title: translation.opportunities.past.datatables.won.title,
        textLabels: translation.opportunities.past.datatables.won.textLabels,
        columns: [
          {
            label: translation.opportunities.past.datatables.won.columns.id,
            name: "_id",
            options: { display: false }
          },
          {
            label: translation.opportunities.past.datatables.won.columns.name,
            name: "name"
          },
          {
            label: translation.opportunities.past.datatables.won.columns.date,
            name: "date",
            options: {
              filterType: "checkbox",
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[2]).format("DD/MM/YYYY")
            }
          },
          {
            label:
              translation.opportunities.past.datatables.won.columns
                .service_type,
            name: "type",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation.opportunities.past.datatables.won.type[
                        tableMeta.rowData[3]
                      ]
                    }
                  />
                );
              }
            }
          },

          {
            label: translation.opportunities.past.datatables.won.columns.client,
            name: "client"
          },
          {
            label: translation.opportunities.past.datatables.won.columns.budget,
            name: "budget",
            options: {
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[5]).format("0,0[.]00 $")
            }
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                // console.log('tableMeta.rowData[0]', tableMeta.rowData);
                return (
                  <Link
                    to={`/pipeline/${tableMeta.rowData[0]}/view`}
                    style={{ color: "#fff" }}
                  >
                    <RemoveRedEye />
                  </Link>
                );
              }
            }
          }
        ],
        options: options
      },
      lost: {
        title: translation.opportunities.past.datatables.lost.title,
        textLabels: translation.opportunities.past.datatables.lost.textLabels,
        columns: [
          {
            label: translation.opportunities.past.datatables.lost.columns.id,
            name: "_id",
            options: { display: false }
          },
          {
            label: translation.opportunities.past.datatables.lost.columns.name,
            name: "name"
          },
          {
            label: translation.opportunities.past.datatables.lost.columns.date,
            name: "date",
            options: {
              filterType: "checkbox",
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[2]).format("DD/MM/YYYY")
            }
          },
          {
            label:
              translation.opportunities.past.datatables.won.columns
                .service_type,
            name: "type",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation.opportunities.past.datatables.lost.type[
                        tableMeta.rowData[3]
                      ]
                    }
                  />
                );
              }
            }
          },
          {
            label:
              translation.opportunities.past.datatables.lost.columns.client,
            name: "client"
          },
          {
            label:
              translation.opportunities.past.datatables.lost.columns.budget,
            name: "budget",
            options: {
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[5]).format("0,0[.]00 $")
            }
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Link
                    to={`/pipeline/${tableMeta.rowData[0]}/view`}
                    style={{ color: "#fff" }}
                  >
                    <RemoveRedEye />
                  </Link>
                );
              }
            }
          }
        ],
        options: options
      }
    }
  };
};

export { PastOpportunitiesTemplate };
