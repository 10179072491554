import React, { Component } from "react";
import languages from "assets/languages/languages";
import UserContext from "providers/UserProvider";
import Login from "containers/Login/Login";
import ResetPassword from "containers/ResetPassword";
import theme from "core/MUITheme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Switch, Route, withRouter } from "react-router-dom";

import LogoSM from "assets/img/white-logo-lg.png";

import Home from "./components/Home";
import { Auth } from "aws-amplify";
import { Language } from "assets/languages/i18n";

import CognitoServices from "services/auth/cognito.services";
import { default as NotificationOpportunitiesServices } from "services/franchise-crm/notifications.services";
import { default as NotificationProjectsServices } from "services/franchise-projects/notifications.services";
import { CssBaseline } from "@material-ui/core";

class App extends Component {
  constructor(props) {
    super(props);
    Auth.currentSession()
      .then((result) => {
        // console.log("result", result);
        this.cognitoService
          .getFranchise(result.accessToken.payload.username)
          .then((response) => {
            // console.log("response login franchise", response);
            // Por defecto seleccion posición 0 franchise
            if (response.data && response.data.franchises) {
              // localStorage.setItem(
              //   "sportmadness-manager-franchise",
              //   response.data.franchises[0].id._id
              // );
              this.initialLoginSuccess(
                response.data.franchises[0].id._id,
                response.data.franchises[0].id.language,
                response.data.franchises[0].id.country,
                response.data.franchises[0].id.currency,
                response.data.franchises[0].id.name
              );
              //Identify user to track analytics for HEAP
              window.heap.identify(result.idToken.payload.email);
              window.heap.addUserProperties({
                role: result.idToken.payload["custom:role"],
                franchise_name: response.data.franchises[0].id.name,
                franchise_country: response.data.franchises[0].id.country,
              });
              // console.log("HEAP", window.heap);
            } else {
              Auth.signOut();
            }
          })
          .catch((err) => {
            // console.log("err get franchise", err);
          });
      })
      .catch((err) => {
        // ONLY IN TEST
        this.setState({ statusUser: "notLoggedIn" });
        // console.log("err auth sesion", err);
      });
    this.languageDefault = languages.find((lang) => {
      return lang.value === "es";
    });
    this.cognitoService = new CognitoServices();
    this.notificationsOpportunitiesServices =
      new NotificationOpportunitiesServices();
    this.notificationsProjectsServices = new NotificationProjectsServices();
  }

  state = {
    languageItem: languages.find((lang) => {
      return lang.value === navigator.language.substring(0, 2);
    }),
    userCognito: null,
    franchise: null,
    menuOpen: true,
    notificationsOpportunities: [],
    notificationsProjects: [],
    statusUser: "loading",
  };

  languageChangeHandler = (value) => {
    // console.log("language", value);
    let language = languages.find((lang) => {
      return lang.value === value;
    });

    this.setState({
      languageItem: language ? language : this.languageDefault,
    });
  };

  notificationOpportunitiesClicked = (notification) => {
    // console.log("notification app", notification);
    let notificationsOpportunities = [...this.state.notificationsOpportunities];
    notificationsOpportunities = notificationsOpportunities.filter(
      (_notification) => _notification._id !== notification
    );
    this.setState({ notificationsOpportunities });
  };

  notificationProjectsClicked = (notification) => {
    let notificationsProjects = [...this.state.notificationsProjects];
    notificationsProjects = notificationsProjects.filter(
      (_notification) => _notification._id !== notification
    );
    this.setState({ notificationsProjects });
  };

  menuHandler = (value) => {
    this.setState({ menuOpen: value });
  };

  userLoginSuccessHandler = (userCognito) => {
    // console.log("login data", userCognito);
    this.cognitoService
      .getFranchise(userCognito.username)
      .then((response) => {
        // console.log("response getFranchise", response);
        // Por defecto seleccion posición 0 franchise
        if (response.data && response.data.franchises) {
          this.initialLoginSuccess(
            response.data.franchises[0].id._id,
            response.data.franchises[0].id.language,
            response.data.franchises[0].id.country,
            response.data.franchises[0].id.currency
          );

          //Identify user to track analytics for HEAP
          window.heap.identify(userCognito.attributes.email);
          window.heap.addUserProperties({
            role: userCognito.attributes["custom:role"],
            franchise_name: response.data.franchises[0].id.name,
            franchise_country: response.data.franchises[0].id.country,
          });
          // console.log("HEAP", window.heap);
        } else {
          Auth.signOut();
        }
      })
      .catch((err) => {
        // console.log("err get franchise", err);
      });
  };

  resetPassworSuccessdHandler = (userCognito) => {
    this.cognitoService
      .getFranchise(userCognito.username)
      .then((response) => {
        // Por defecto seleccion posición 0 franchise
        if (response.data && response.data.franchises) {
          this.initialResetPasswordSuccess(
            response.data.franchises[0].id._id,
            response.data.franchises[0].id.language,
            response.data.franchises[0].id.country,
            response.data.franchises[0].id.currency
          );
        } else {
          Auth.signOut();
        }
      })
      .catch((err) => {
        // console.log("err get franchise", err);
      });
  };

  initialLoginSuccess = (franchise, languageFranchise, country, currency) => {
    this.setState({
      statusUser: "loggedIn",
      franchise,
      languageFranchise,
      country,
      currency,
    });

    this.notificationsOpportunitiesServices
      .getNotifications(franchise)
      .then((response) => {
        // console.log("response notifications CRM", response);
        if (Object.keys(response.data).length) {
          const notificationsOpportunities = [
            ...response.data[franchise].tasks,
          ];
          this.setState({
            notificationsOpportunities,
          });
        }
      })
      .catch((err) => {
        // console.log("err get notifications", err);
      });
    this.notificationsProjectsServices
      .getNotifications(franchise)
      .then((response) => {
        // console.log("response notifications Projects", response);
        if (Object.keys(response.data).length) {
          const notificationsProjects = [...response.data[franchise].tasks];
          this.setState({
            notificationsProjects,
          });
        }
      })
      .catch((err) => {
        // console.log("err get notifications", err);
      });
  };

  initialResetPasswordSuccess = (
    franchise,
    languageFranchise,
    country,
    currency
  ) => {
    this.notificationsOpportunitiesServices
      .getNotifications(franchise)
      .then((response) => {
        if (Object.keys(response.data).length) {
          const notificationsOpportunities = [
            ...response.data[franchise].tasks,
          ];
          this.setState(
            {
              statusUser: "loading",
              franchise,
              notificationsOpportunities,
              languageFranchise,
              country,
              currency,
            },
            () => {
              this.props.history.push("/");
              this.setState({ statusUser: "loggedIn" });
            }
          );
        } else {
          this.setState(
            {
              statusUser: "passwordChanged",
              franchise,
              languageFranchise,
            },
            () => {
              this.props.history.push("/");
              this.setState({ statusUser: "loggedIn" });
            }
          );
        }
      })
      .catch((err) => {
        // console.log("err get notifications", err);
      });
  };

  render() {
    let languageView = this.state.languageItem
      ? this.state.languageItem
      : this.languageDefault;

    let content;
    switch (this.state.statusUser) {
      case "loading":
        content = (
          <div
            style={{
              height: "100vh",
              background: "#000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={LogoSM} style={{ width: "200px" }} alt="sm-logo" />
          </div>
        );
        break;
      case "notLoggedIn":
        content = (
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Login
                  login={this.login}
                  loginSuccess={this.userLoginSuccessHandler}
                />
              )}
            />
            <Route
              path="/password-reset"
              exact
              render={() => (
                <ResetPassword
                  resetPasswordSuccess={this.resetPassworSuccessdHandler}
                />
              )}
            />
          </Switch>
        );
        break;
      case "loggedIn":
        content = (
          <Home
            languageChanged={this.languageChangeHandler}
            menuHandler={this.menuHandler}
            notificationsOpportunities={this.state.notificationsOpportunities}
            notificationsProjects={this.state.notificationsProjects}
            notificationOpportunitiesClicked={
              this.notificationOpportunitiesClicked
            }
            notificationProjectsClicked={this.notificationProjectsClicked}
          />
        );
        break;
      default:
        alert("Error, estado no definido");
        this.setState({ statusUser: "notLoggedIn" });
        break;
    }

    localStorage.setItem("sportmadness-manager-lang", languageView.value);
    localStorage.setItem("this.state.country", this.state.country);
    return (
      <MuiThemeProvider theme={theme}>
        <UserContext.Provider
          value={{
            language: languageView,
            translation: new Language(this.state.country).getLanguage(),
            userCognito: this.state.userCognito,
            franchise: this.state.franchise,
            menuOpen: this.state.menuOpen,
            languageFranchise: this.state.languageFranchise,
            locale: this.state.country,
            currency: this.state.currency,
          }}
        >
          <CssBaseline />
          {content}
        </UserContext.Provider>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);
