import React, { Component } from "react";
import {
  FranchiseContactsSingle as ContactsSingle,
  Alert,
} from "react-library-sm";
import { Grid } from "@material-ui/core";
import UserContext from "providers/UserProvider";
import ContactsServices from "services/franchise-crm/contacts.services";
import { Redirect } from "react-router-dom";
import OpportunitiesServices from "services/franchise-crm/opportunities.services";
import BoardsServices from "services/franchise-crm/boards.services";
import TasksServices from "services/franchise-crm/tasks.services";
import InvoiceServices from "services/franchise-billing/invoices.services";
class ContactContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opportunities: [],
      projects: [],
      tasks: [],
      contact: {},
      deletedContact: false,
      invoices: [],
    };
    this.opportunityService = new OpportunitiesServices();
    this.contactService = new ContactsServices();
    this.boardService = new BoardsServices();
    this.taskService = new TasksServices();
    this.invoiceService = new InvoiceServices();
  }

  componentDidMount() {
    this.contactService
      .getContact(this.props.match.params.id, this.context.franchise)
      .then((response) => {
        // console.log("response get contact", response);
        let contact = response.data;
        let dataTasks = [];
        contact.projects.forEach((project) => {
          if (project.tasks.length > 0) {
            if (project.tasks.length === 1) {
              dataTasks.push({
                ...project.tasks[0],
                project: project._id,
                person_of_contact: project.tasks[0].contact.name
                  ? project.tasks[0].contact.name
                  : "",
              });
            } else {
              dataTasks = dataTasks.concat(
                project.tasks.map((task) => {
                  return {
                    ...task,
                    project: project._id,
                    person_of_contact: task.contact.name
                      ? task.contact.name
                      : "",
                  };
                })
              );
            }
          }
        });
        const dataOpportunities = contact.projects
          .filter((project) => project.statusExecution === undefined)
          .map((opportunity) => {
            return {
              ...opportunity,
              client: contact.name,
              // date: moment(opportunity.date).format('DD/MM/YYYY')
            };
          });
        const dataProjects = contact.projects
          .filter((project) => project.statusExecution !== undefined)
          .map((project) => {
            return { ...project, client: contact.name };
          });

        contact.people = contact.people
          .filter((person) => person.visible)
          .map((person) => {
            return { ...person, label: person.name, value: person._id };
          });

        this.setState({
          contact,
          invoices: response.data.invoices,
          opportunities: dataOpportunities,
          tasks: dataTasks,
          projects: dataProjects,
        });
      })
      .catch((err) => {
        // console.log("err get contact", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            text: this.context.translation.templates.contact.alerts
              .download_contact.error.text,
            linkText:
              this.context.translation.templates.contact.alerts.download_contact
                .error.submit,
            link: "/contacts",
          },
        });
      });
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };

  static contextType = UserContext;

  createOpportunity = (formData) => {
    // console.log("data en createOpp", formData);
    const contact = { ...this.state.contact };
    const contactState = { ...this.state.contact };
    const opportunity = { ...formData };
    opportunity.client = [contact._id];
    opportunity.franchise = this.context.franchise;
    // Creamos oportunidad
    this.opportunityService
      .addOpportunity(opportunity)
      .then((response) => {
        const finalOpportunity = { ...response.data };
        // console.log("finalOpportunity", finalOpportunity);
        // Añadir al array de projects del contacto visual
        contactState.projects.push(finalOpportunity);
        // Preparación cliente para actualizar
        contact.projects.push(finalOpportunity._id);
        // Buscar persona de contacto
        //Actualizamos visual
        const opportunities = [...this.state.opportunities];
        opportunities.push({
          ...finalOpportunity,
          client: this.state.contact.name,
        });
        //Actualizamos board opportunity franchise
        this.boardService
          .getBoards(this.context.franchise)
          .then((response) => {
            const board = { ...response.data[0].boards };
            // console.log("response get board", board);
            board.opportunity.lanes = board.opportunity.lanes.map((lane) => {
              if (lane.id === finalOpportunity.statusSells) {
                let cards;
                if (lane.cards) {
                  cards = [...lane.cards];
                } else {
                  cards = [];
                }
                cards.push(finalOpportunity._id);
                return { ...lane, cards: cards };
              } else {
                return { ...lane };
              }
            });
            this.setState({
              contact: contactState,
              opportunities,
              alertAPI: true,
              alert: {
                open: true,
                title: this.context.translation.alerts.titles.success,
                status: "success",
                text: this.context.translation.templates.contact.alerts
                  .create_opportunity.success.text,
                submit:
                  this.context.translation.templates.contact.alerts
                    .create_opportunity.success.submit,
                onSubmit: this.handleOnCloseAlert,
              },
            });
          })
          .catch((err) => {
            // console.log("error get boards", err.response);
            this.setState({
              alertAPI: true,
              alert: {
                open: true,
                title: this.context.translation.alerts.titles.error,
                status: "error",
                text: this.context.translation.templates.contact.alerts
                  .download_boards.error.text,
                linkText:
                  this.context.translation.templates.contact.alerts
                    .download_boards.error.submit,
                link: "/contacts",
              },
            });
          });
      })
      .catch((err) => {
        // console.log("error add opportunity", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.contact.alerts
              .create_opportunity.warning.text,
            submit:
              this.context.translation.templates.contact.alerts
                .create_opportunity.warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  editContact = (data) => {
    // console.log("change contact", data);
    const contact = { ...data };
    const contactState = { ...data };
    contact.people = contact.people.map((aux) => {
      return aux._id;
    });
    contact.projects = contact.projects.map((aux) => {
      return aux._id;
    });

    this.contactService
      .updateContact(contact)
      .then((response) => {
        // console.log("responses update contact", response);
        this.setState({
          contact: contactState,
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.contact.alerts.edit_contact
              .success.text,
            submit:
              this.context.translation.templates.contact.alerts.edit_contact
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err update contacts", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.contact.alerts.edit_contact
              .warning.text,
            submit:
              this.context.translation.templates.contact.alerts.edit_contact
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  deleteContact = () => {
    const contact = { ...this.state.contact };
    this.contactService
      .deleteContact(contact._id)
      .then((response) => {
        this.setState({ deletedContact: true });
      })
      .catch((err) => {
        // console.log("err delete contact", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.contact.alerts
              .delete_contact.warning.text,
            submit:
              this.context.translation.templates.contact.alerts.delete_contact
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  createPeople = (data) => {
    const client = JSON.parse(JSON.stringify(this.state.contact));
    this.contactService
      .createPeople(data.people, this.context.franchise, client)
      .then((response) => {
        // console.log('response people1', response);
        const people = [...this.state.contact.people];
        response.length > 1
          ? response.map((res) => people.push(res.data))
          : people.push(response.data);
        this.setState({
          contact: { ...this.state.contact, people },
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.contact.alerts
              .create_contact.success.text,
            submit:
              this.context.translation.templates.contact.alerts.create_contact
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err create people", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.contact.alerts
              .create_contact.warning.text,
            submit:
              this.context.translation.templates.contact.alerts.create_contact
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  editPeople = (data) => {
    // console.log('data en edit people franchise delivery', data);

    this.contactService
      .updateContact(data.people[0])
      .then((response) => {
        let people = [...this.state.contact.people];
        people.some((person, index) => {
          if (person._id === data.people[0]._id) {
            people[index] = data.people[0];
            return true;
          } else return false;
        });
        this.setState({
          contact: { ...this.state.contact, people },
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.contact.alerts.edit_contact
              .success.text,
            submit:
              this.context.translation.templates.contact.alerts.edit_contact
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err update contact", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.contact.alerts.edit_contact
              .warning.text,
            submit:
              this.context.translation.templates.contact.alerts.edit_contact
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  deletePeople = (id) => {
    const contact = { ...this.state.contact };
    this.contactService
      .deleteContact(id)
      .then((response) => {
        // console.log("response en deletepeople", response);
        let people = [...this.state.contact.people];
        //if returned true, stop the loop
        people.some((person, index) => {
          if (person._id === id) {
            people.splice(index, 1);
            return true;
          } else return false;
        });

        this.setState({
          contact: { ...contact, people },
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.contact.alerts
              .delete_contact.success.text,
            submit:
              this.context.translation.templates.contact.alerts.delete_contact
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err delete contact", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.contact.alerts
              .delete_contact.warning.text,
            submit:
              this.context.translation.templates.contact.alerts.delete_contact
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  editTask = (data) => {
    const task = {
      ...data,
      contact: data.person_of_contact,
    };

    // console.log("task to update", task);
    this.taskService
      .editTask(data.project, this.context.franchise, task)
      .then((response) => {
        // console.log("response edit task", response.data.tasks);

        // OPTIMIZACION LETI
        let tasks = [...this.state.tasks];

        tasks.some((task, index) => {
          if (task._id === data._id) {
            // console.log(response.data.tasks.find(res => res._id === task._id));
            const updatedTask = response.data.tasks.find(
              (res) => res._id === task._id
            );
            tasks[index] = {
              ...updatedTask,
              person_of_contact: this.state.contact.people.find(
                (person) => person._id === data.person_of_contact
              ).name,
            };
            return true;
          } else return false;
        });

        this.setState({
          tasks,
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.contact.alerts.edit_task
              .success.text,
            submit:
              this.context.translation.templates.contact.alerts.edit_task
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("error response edit task", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.contact.alerts.edit_task
              .warning.text,
            submit:
              this.context.translation.templates.contact.alerts.edit_task
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  deleteTask = (data) => {
    this.taskService
      .deleteTask(data.project, this.context.franchise, data._id)
      .then((response) => {
        if (response.status === 201) {
          let tasks = [...this.state.tasks];
          tasks = tasks.filter((task) => task._id !== data._id);
          this.setState({
            tasks,
            alertAPI: true,
            alert: {
              open: true,
              title: this.context.translation.alerts.titles.success,
              status: "success",
              text: this.context.translation.templates.contact.alerts
                .delete_task.success.text,
              submit:
                this.context.translation.templates.contact.alerts.delete_task
                  .success.submit,
              onSubmit: this.handleOnCloseAlert,
            },
          });
        }
      })
      .catch((err) => {
        // console.log("err delete task", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.contact.alerts.delete_task
              .warning.text,
            submit:
              this.context.translation.templates.contact.alerts.delete_task
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  getInvoice = (id) => {
    return this.invoiceService.getInvoice(id);
  };
  editInvoiceStatus = (values) => {
    this.invoiceService
      .updateStatus(values, this.context.franchise)
      .then((response) => {
        // console.log("editStatus response", response);
        if (response.status === 200) {
          // console.log("todo bien");
          let invoices = [...this.state.invoices];
          invoices.some((invoice, index) => {
            if (invoice._id === response.data.invoice._id) {
              invoices[index] = {
                ...response.data.invoice,
                client:
                  response.data.invoice.franchise ||
                  response.data.invoice.client,
              };
              return true;
            } else return false;
          });

          // console.log("invoic", invoices);
          this.setState({ invoices /*  dashboard: response.data.dashboard */ });
        }
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            linkText:
              this.context.translation.templates.invoices.alerts.edit_status
                .error.submit,
            link: "/invoices",
            onSubmit: this.handleOnCloseAlert,
          },
        })
      );
  };

  render() {
    let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    let content = this.state.deletedContact ? (
      <Redirect to="/contacts" />
    ) : (
      <Grid
        container
        style={{
          position: "fixed",
          width: this.context.menuOpen ? "80%" : "100%",
          overflowY: "scroll",
        }}
      >
        {alert}
        <ContactsSingle
          opportunities={this.state.opportunities}
          projects={this.state.projects}
          tasks={this.state.tasks}
          invoices={this.state.invoices}
          contact={this.state.contact}
          createOpportunity={this.createOpportunity}
          createPeople={this.createPeople}
          editPeople={this.editPeople}
          deletePeople={this.deletePeople}
          translation={
            this.context.translation.templates
              ? this.context.translation.templates.contact
              : null
          }
          changeBoard={this.changeBoard}
          editContact={this.editContact}
          deleteContact={this.deleteContact}
          language={this.context.language.value}
          editTask={this.editTask}
          deleteTask={this.deleteTask}
          getInvoice={this.getInvoice}
          editStatus={this.editInvoiceStatus}
        />
      </Grid>
    );
    return content;
  }
}

export { ContactContainer };
