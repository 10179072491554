import Axios from "core/axios";

class ProjectsServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_PROJECTS;
    this.service = new Axios(this.api_url);
  }

  getProjects = franchise => {
    // return this.service.get('/projects?franchise=' + franchise);
    return this.service.get("/projects?franchise=" + franchise);
  };

  addProject = (data, templateId, language, franchise) => {
    return this.service.post(
      "/projects?template=" +
        templateId +
        "&language=" +
        language +
        "&franchise=" +
        franchise,
      data
    );
  };

  updateProject = (data, franchise) => {
    return this.service.put(
      "/projects/" + data._id + "?franchise=" + franchise,
      data
    );
  };

  deleteProject = (id, franchise) => {
    return this.service.delete("/projects/" + id + "?franchise=" + franchise);
  };

  getProject = (id, franchise) => {
    return this.service.get("/projects/" + id + "?franchise=" + franchise);
  };

  updateTask = (projectId, taskId, task, franchise) => {
    return this.service.put(
      "/projects/" + projectId + "/tasks/" + taskId + "?franchise=" + franchise,
      task
    );
  };

  addInsideTask = (data, projectId, franchise) => {
    return this.service.post(
      "/projects/" + projectId + "/tasks/inside?franchise=" + franchise,
      data
    );
  };

  addTask = (data, projectId, franchise) => {
    return this.service.post(
      "/projects/" + projectId + "/tasks?franchise=" + franchise,
      data
    );
  };
  deleteTask = (projectId, taskId) =>
    this.service.delete(`/projects/${projectId}/tasks/${taskId}`);

  getBestPractices = language =>
    this.service.get(`/practices?lang=${language}`);

  getBestPractice = (templateId, language) =>
    this.service.get(`/practices/${templateId}?lang=${language}`);

  getTemplates = language => this.service.get(`/templates?lang=${language}`);
}

export default ProjectsServices;
