import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { WebCampusSingle } from "react-library-sm";
import moment from "moment";
import WebCampusServices from "services/franchise-campus/web.campus.services";
import ProjectsServices from "services/franchise-projects/projects.services";
import UserContext from "providers/UserProvider";
import StaffServices from "services/franchise-staff/staff.services";
import { Redirect } from "react-router-dom";
import CoreServices from "services/services-core/core.services";
import { s3GetObject } from "core/S3";

class ManagerWebCampusSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "downloading",
      campusData: {},
      activitiesPerVenue: { venues: [], activities: [] },
      activitiesPerGroup: { groups: [], activities: [] },
      groups: [],
      users: [],
      staff: [],
      sm_staff: [],
      projects: [],
      coordinatorsCampus: [],
      deleted: false,
    };
    this.campusService = new WebCampusServices();
    this.projectService = new ProjectsServices();
    this.coreService = new CoreServices();
    this.staffService = new StaffServices();
  }

  static contextType = UserContext;

  // dependencies
  // venues -> groups.activities
  // users.groups -> groups.staff
  // staff -> groups
  // groups -> users

  componentDidMount() {
    this.campusService
      .getCampusSingle(this.props.match.params.id)
      .then((response) => {
        const campus = response.data;
        // console.log("campus download", campus);
        this.coreService
          .getSatisfactionService(this.props.match.params.id)
          .then((responseSatisfaction) => {
            this.setState({
              franchise: campus.franchise,
              satisfaction: this.createLogicSatisfactionView(
                responseSatisfaction.data
              ),
              status: "success",
            });
            this.createCampus(campus);
          })
          .catch((err) => {
            // console.log(err);
            this.setState({
              franchise: campus.franchise,
              satisfaction: null,
              status: "error",
            });
            this.createCampus(campus);
          });
      })
      .catch((err) => {
        // console.log(err.response);
        this.setState({ status: "error" });
      });
  }

  // ----------------- SHORCUTS ----------------- //

  /*
    Lógica para crear SATISFACTION
  */
  createLogicSatisfactionView = (data) => {
    return {
      ...data,
      satisfactions: data.satisfactions.map((satisfaction) => ({
        ...satisfaction,
        _id: satisfaction.answers[0]._id,
        parent: satisfaction.answers[0].parent
          ? satisfaction.answers[0].parent.userId
            ? satisfaction.answers[0].parent.userId.name || ""
            : ""
          : "",
        recommends: satisfaction.answers[0].recommends,
        comments: satisfaction.answers[0].comments,
        metrics: satisfaction.answers[0].metrics,
      })),
    };
  };

  /*
    Logica para crear CAMPUS
  */
  createCampus = (campus) => {
    const campusData = {
      name: campus.name,
      date: {
        start: moment(campus.date.start, "DD/MM/YYYY"),
        end: moment(campus.date.end, "DD/MM/YYYY"),
      },
      targetUsers: campus.targetUsers,
      project: campus.project,
    };
    // console.log("campus Data created", campusData);
    const coordinatorsCampus = campus.coordinator.map((coordinator) => {
      return { ...coordinator, role: "coordinator", groups: [] };
    });
    // console.log("coordinators campus created", coordinatorsCampus);
    const groups = this.createGroups(campus.groups, campus.users);
    // console.log("groups created", groups);
    const users = this.createUsers(campus.users, campus.groups);
    // console.log("users created", users);
    const staff = this.createStaff(campus.groups, coordinatorsCampus);
    // console.log("staff created", staff);
    const activitiesPerVenue = this.createActivitiesPerVenue(
      campus.groups,
      campus.venues
    );
    // console.log("activities per venue", activitiesPerVenue);
    const activitiesPerGroup = this.createActivitiesPerGroup(
      campus.groups,
      campus.venues
    );
    // console.log("activities per group", activitiesPerGroup);

    this.setState({
      campusData,
      activitiesPerVenue,
      activitiesPerGroup,
      groups,
      users,
      staff,
      coordinatorsCampus,
    });
  };

  /**
   * Logica para crear coordinadores de campus
   */
  createLogicGromCoordinatorsCampusDownload = (auxCoordinators) => {
    const coordinatorsCampus = auxCoordinators.map((coordinator) => {
      return { ...coordinator, role: "coordinator", groups: [] };
    });

    const staff = this.createStaff(this.state.groups, coordinatorsCampus);
    this.setState({ coordinatorsCampus, staff });
  };

  createLogicFromGroupsDownload = (auxGroups) => {
    const prevGroups = this.state.groups.map((group) => {
      if (auxGroups.find((_group) => _group._id === group._id)) {
        return { ...auxGroups.find((_group) => _group._id === group._id) };
      } else {
        return group;
      }
    });

    const groups = this.createGroups(prevGroups, this.state.users);
    const users = this.createUsers(this.state.users, prevGroups);
    const staff = this.createStaff(prevGroups, this.state.coordinatorsCampus);
    this.setState({ groups, staff, users });
  };

  // ----------------- END SHORCUTS ----------------- //

  /*
    Logica para crear grupos
    - Dependencias:
      Grupos
      Usuarios
    */
  createGroups = (groups, users) => {
    const auxGroups = groups.map((group) => {
      return {
        ...group,
        //filtramos usuarios que pertenezcan a ese grupo
        users_size: users.filter((user) =>
          user.groups.find((_group) => _group.key === group._id) ? true : false
        ).length,
        //filtrar trainers que pertenezcan a ese grupo
        trainers: group.staff
          .filter((staffWorker) => staffWorker.role === "trainer")
          .map((staffWorker) => {
            return { name: staffWorker.name, _id: staffWorker.id };
          }),
        //filtar coordinadores que pertenezcan a ese grupo
        coordinators: group.staff
          .filter((staffWorker) => staffWorker.role === "coordinator")
          .map((staffWorker) => {
            return { name: staffWorker.name, _id: staffWorker.id };
          }),
      };
    });

    return auxGroups;
  };

  /*
    Logica para crear usuarios
    - Dependencias:
      Usuarios
      Grupos
    */
  createUsers = (users, groups) => {
    const auxUsers = users.map((user) => {
      return {
        ...user,
        //crear grupos listos para datatables
        groups: user.groups.map((group) => {
          return { ...group };
        }),
        emergency_contact: user.parents
          ? user.parents[0]
            ? `${user.parents[0].name} / ${user.parents[0].phone}`
            : "Sin contactos asignados"
          : "Sin contactos asignados",
        //filtrar grupos en los que el usuario aparece
        coordinators: groups
          .filter((group) =>
            user.groups.find((_group) => _group.key === group._id)
              ? true
              : false
          )
          //recorrer grupos para
          .map((group) =>
            group.staff
              //filtrar en los que está el rol de coordinador
              .filter((staffWorker) => staffWorker.role === "coordinator")
              //devolver array de coordinadores
              .map((staffWorker) => {
                return { _id: staffWorker.id, name: staffWorker.name };
              })
          )
          //pasar a un unico array
          .flat()
          //eliminar coordinadores repetidos
          .reduce(
            (unique, item) =>
              unique.find((_item) => item._id === _item._id)
                ? unique
                : [...unique, item],
            []
          ),
      };
    });

    return auxUsers;
  };

  /*
    Logica para crear staff
    - Dependencias:
      Grupos
    */
  //TODO implementar staff de campus
  createStaff = (groups, coordinatorsCampus) => {
    const auxStaff = groups
      // recorrer grupos y sacar staff
      .map((group) =>
        group.staff.map((staffWorker) => {
          return {
            ...staffWorker,
            groups: [{ _id: group._id, name: group.name }],
          };
        })
      )
      // pasar a un unico array
      .flat()
      // concatenamos array coordinadores campus
      .concat(coordinatorsCampus)
      // eliminar repetidos uniendo los grupos en los que aparezcan
      .reduce(
        (unique, item) =>
          unique.find((_item) => item.id === _item.id)
            ? unique.map((uniqueItem) => {
                if (uniqueItem.id === item.id) {
                  let auxGroups = [...uniqueItem.groups];
                  item.groups.forEach((group) => {
                    if (!auxGroups.includes(group)) {
                      auxGroups.push(group);
                    }
                  });
                  return { ...uniqueItem, groups: auxGroups };
                }
                return { ...uniqueItem };
              })
            : [...unique, item],
        []
      )
      // preparar para vista
      .map((staffWorker) => {
        // console.log("staffWorker", staffWorker);
        return { ...staffWorker, groups: staffWorker.groups };
      });

    return auxStaff;
  };

  /*
    Logica para crear actividades por instalacion
    - Dependencias:
      Grupos
      Instalaciones
    */
  createActivitiesPerVenue = (groups, venues) => {
    //Recorremos venues para crear los componentes de agrupacion de react-big-sheduler
    const auxVenues = venues.map((venue) => {
      return { id: venue._id, name: venue.name };
    });
    const auxActivities = venues
      //recorremos venues
      .map((venue) => {
        return (
          groups
            //recorremos grupos
            .map((group) => {
              return (
                group.activities
                  //recorremos actividades
                  .map((activity) => {
                    return (
                      activity.repeats
                        //filtramos repeticiones de actividad por venue
                        .filter((repeat) => repeat.venue === venue._id)
                        .map((repeat, index) => {
                          //TODO comprobar si repeats tiene _id el objeto y cambiar
                          return {
                            id: activity._id + "" + index + "" + venue._id,
                            title: activity.name + " - " + group.name,
                            resourceId: venue._id,
                            start: repeat.start,
                            end: repeat.end,
                          };
                        })
                    );
                  })
                  .flat()
              );
            })
            .flat()
        );
      })
      .flat();

    return { venues: auxVenues, activities: auxActivities };
  };

  /*
    Logica para crear actividades por grupo
    - Dependencias:
      Grupos
      Instalaciones
    */
  createActivitiesPerGroup = (groups, venues) => {
    const auxGroups = groups.map((group) => {
      return { id: group._id, name: group.name };
    });

    const auxActivities = groups
      //recorremos grupos
      .map((group) => {
        return (
          group.activities
            //recorremos actividades
            .map((activity) => {
              return (
                activity.repeats
                  //recorremos repeticiones de actividad
                  .map((repeat, index) => {
                    //TODO comprobar si repeats tiene _id el objeto y cambiar
                    return {
                      id: activity._id + "" + index,
                      title:
                        activity.name +
                        " - " +
                        (venues.find((venue) => venue._id === repeat.venue)
                          ? venues.find((venue) => venue._id === repeat.venue)
                              .name
                          : "Error venue"),
                      // : "",
                      resourceId: group._id,
                      start: repeat.start,
                      end: repeat.end,
                    };
                  })
                  .flat()
              );
            })
            .flat()
        );
      })
      .flat();
    return { groups: auxGroups, activities: auxActivities };
  };

  /* -------------     HANDLE FUNCTIONS     ------------- */

  // Funciones campus

  handleEditCampus = (data) => {
    const dataCampus = {
      ...data,
      date: {
        start: moment(data.date.start).format("DD/MM/YYYY"),
        end: moment(data.date.end).format("DD/MM/YYYY"),
      },
    };
    this.setState({ status: "loading" }, () => {
      this.campusService
        .putCampusSingle(this.props.match.params.id, dataCampus)
        .then((response) => {
          // console.log("response edit campus", response.data);
          const campus = response.data;
          const campusData = {
            name: campus.name,
            date: {
              start: moment(campus.date.start).format("DD/MM/YYYY"),
              end: moment(campus.date.end).format("DD/MM/YYYY"),
            },
            targetUsers: campus.targetUsers,
            project: campus.project,
          };

          this.setState({ campusData, status: "success" });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  handleDeleteCampus = () => {
    this.setState({ status: "loading" }, () => {
      this.campusService
        .deleteCampusSingle(this.props.match.params.id)
        .then((response) => {
          // console.log("response delete campus single", response);
          this.setState({ deleted: true });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  handleDownloadProjects = () => {
    return this.projectService
      .getProjects(this.context.franchise)
      .then((response) => {
        this.setState({
          projects: response.data.map((project) => {
            return { _id: project._id, name: project.infoProject.name };
          }),
        });
        return Promise.resolve();
      });
  };

  // Funciones popover
  handleAddGroup = (data) => {
    this.setState({ status: "loading" }, () => {
      this.campusService
        .postCampusSingleGroups(this.props.match.params.id, data)
        .then((response) => {
          // console.log("response", response);
          this.setState({ status: "success" }, () => {
            this.createCampus(response.data);
          });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  handleAddUser = (data) => {
    const dataUser = { ...data, dob: moment(data.dob).format("DD/MM/YYYY") };
    this.setState({ status: "loading" }, () => {
      this.campusService
        .postCampusSingleUsers(this.props.match.params.id, [dataUser])
        .then((response) => {
          const stateUsers = this.state.users;
          const newUsers = this.createUsers(
            response.data.map((user) => {
              return { ...user, groups: [], extraResponsibles: [] };
            }),
            this.state.groups
          );

          const users = stateUsers.concat(newUsers);
          const groups = this.createGroups(this.state.groups, users);

          this.setState({ users, groups, status: "success" });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  handleAddStaffTrainer = (data) => {
    const staff = { ...data };
    const option = data.option;
    // console.log("staff", staff);
    // console.log("option", option);
    this.setState({ status: "loading" }, () => {
      //OPTION SELECT => PUT
      switch (option) {
        case "select":
          const dataEmployeeToGroup = this.state.sm_staff.find(
            (staffWorker) => staffWorker._id === staff.staffId
          );
          const selectData = {
            groups: { addTo: [staff.groupId], removeFrom: [] },
            employee: {
              name: dataEmployeeToGroup.name,
              role: staff.role,
              rocketChatId: dataEmployeeToGroup.rocket_chat.id,
            },
          };
          this.staffService
            .putCampusSingleStaff(
              this.context.franchise,
              this.props.match.params.id,
              staff.staffId,
              "edit",
              selectData
            )
            .then((response) => {
              // console.log("added staff by select", response);
              this.setState({ status: "success" }, () => {
                this.createLogicFromGroupsDownload(response.data.groups);
              });
            })
            .catch((err) => {
              // console.log(err.response);
              this.setState({ status: "error" });
            });
          break;
        case "create":
          const createData = {
            name: staff.name,
            email: staff.email,
            phone: staff.phone,
            identificationNumber: staff.identificationNumber,
            ssn: staff.ssn,
            bankAccountNumber: staff.bankAccountNumber,
            sports: staff.sports,
            role: staff.role,
          };
          this.staffService
            .postStaffAndAssignCampusGroup(
              this.context.franchise,
              this.props.match.params.id,
              { action: "group", groupId: staff.groupId },
              [createData]
            )
            .then((response) => {
              // console.log("response add trainer and assign group", response);
              this.setState({ status: "success" }, () => {
                this.createLogicFromGroupsDownload([response.data]);
              }).catch((err) => {
                // console.log(err.response);
                this.setState({ status: "error" });
              });
            });
          break;
        default:
          break;
      }
    });
    //OPTION CREATE => POST
  };

  handleAddStaffCoordinator = (data) => {
    const staff = { ...data };
    const option = data.option;
    // console.log("staff", staff);
    // console.log("option", option);
    this.setState({ status: "loading" }, () => {
      //OPTION SELECT => PUT
      switch (option) {
        case "select_campus":
          const dataEmployeeToCampus = this.state.sm_staff.find(
            (staffWorker) => staffWorker._id === staff.staffId
          );
          const selectCampusData = {
            employee: {
              name: dataEmployeeToCampus.name,
              role: staff.role,
              rocketChatId: dataEmployeeToCampus.rocket_chat.id,
            },
          };
          this.staffService
            .putCampusSingleStaff(
              this.context.franchise,
              this.props.match.params.id,
              staff.staffId,
              "add",
              selectCampusData
            )
            .then((response) => {
              // console.log("added staff by select", response);
              this.setState({ status: "success" }, () => {
                this.createLogicGromCoordinatorsCampusDownload(
                  response.data.coordinator
                );
              });
              // this.createLogicFromGroupsDownload(response.data.groups);
            })
            .catch((err) => {
              // console.log(err.response);
              this.setState({ status: "error" });
            });
          break;
        case "create_campus":
          const createCampusData = {
            name: staff.name,
            email: staff.email,
            phone: staff.phone,
            identificationNumber: staff.identificationNumber,
            ssn: staff.ssn,
            bankAccountNumber: staff.bankAccountNumber,
            sports: staff.sports,
            role: staff.role,
          };

          this.staffService
            .postStaffAndAssignCampusGroup(
              this.context.franchise,
              this.props.match.params.id,
              { action: "campus" },
              [createCampusData]
            )
            .then((response) => {
              // console.log(
              //   "response add coordinator and assign campus",
              //   response
              // );
              // this.createLogicFromGroupsDownload([response.data]);
              this.setState({ status: "success" }, () => {
                this.createCampus(response.data);
              });
            })
            .catch((err) => {
              // console.log(err.response);
              this.setState({ status: "error" });
            });
          break;
        case "select_group":
          const dataEmployeeToGroup = this.state.sm_staff.find(
            (staffWorker) => staffWorker._id === staff.staffId
          );
          const selectData = {
            groups: { addTo: [staff.groupId], removeFrom: [] },
            employee: {
              name: dataEmployeeToGroup.name,
              role: staff.role,
              rocketChatId: dataEmployeeToGroup.rocket_chat.id,
            },
          };
          // console.log("options data", selectData);
          this.staffService
            .putCampusSingleStaff(
              this.context.franchise,
              this.props.match.params.id,
              staff.staffId,
              "edit",
              selectData
            )
            .then((response) => {
              // console.log("added staff by select", response);
              this.setState({ status: "success" }, () => {
                this.createLogicFromGroupsDownload(response.data.groups);
              });
            })
            .catch((err) => {
              // console.log(err.response);
              this.setState({ status: "error" });
            });
          break;
        case "create_group":
          const createGroupData = {
            name: staff.name,
            email: staff.email,
            phone: staff.phone,
            identificationNumber: staff.identificationNumber,
            ssn: staff.ssn,
            bankAccountNumber: staff.bankAccountNumber,
            sports: staff.sports,
            role: staff.role,
          };

          this.staffService
            .postStaffAndAssignCampusGroup(
              this.context.franchise,
              this.props.match.params.id,
              { action: "group", groupId: staff.groupId },
              [createGroupData]
            )
            .then((response) => {
              // console.log("response add trainer and assign group", response);
              this.setState({ status: "success" }, () => {
                this.createLogicFromGroupsDownload([response.data]);
              });
            })
            .catch((err) => {
              // console.log(err.response);
              this.setState({ status: "error" });
            });
          break;
        default:
          break;
      }
    });
    //OPTION CREATE => POST
  };

  handleDownloadSMStaff = () => {
    return this.staffService
      .getStaffList(this.context.franchise)
      .then((response) => {
        // console.log("response download staff", response.data);
        const sm_staff = response.data;
        // console.log(sm_staff);
        this.setState({ sm_staff: sm_staff });
        return Promise.resolve();
      })
      .catch((err) => {
        // console.log(err.response);
        this.setState({ status: "error" });
      });
  };

  handleImportUsers = (users) => {
    const sendUsers = users.map((user) => {
      return { ...user, dob: moment(user.dob).format("DD/MM/YYYY") };
    });
    this.setState({ status: "loading" }, () => {
      this.campusService
        .postCampusSingleUsers(this.props.match.params.id, sendUsers)
        .then((response) => {
          const stateUsers = this.state.users;
          const newUsers = this.createUsers(
            response.data.map((user) => {
              return { ...user, groups: [] };
            }),
            this.state.groups
          );

          const users = stateUsers.concat(newUsers);

          this.setState({ users, status: "success" });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  // Funciones Venues
  handleSubmitModalAddResource = (data) => {
    return this.campusService
      .postCampusSingleVenues(this.props.match.params.id, data)
      .then((response) => {
        // console.log("response add venue", response.data);
        const venues = this.state.activitiesPerVenue.venues.map((venue) => {
          return { _id: venue.id, name: venue.name };
        });
        venues.push(response.data);
        const activitiesPerVenue = this.createActivitiesPerVenue(
          this.state.groups,
          venues
        );
        const activitiesPerGroup = this.createActivitiesPerGroup(
          this.state.groups,
          venues
        );
        this.setState({ activitiesPerVenue, activitiesPerGroup });
        return Promise.resolve({
          id: response.data._id,
          name: response.data.name,
        });
      })
      .catch((err) => {
        // console.log(err.response);
        this.setState({ status: "error" });
      });
  };

  handleEditVenue = (data) => {
    // console.log("data edit venue", data);
    this.setState({ status: "loading" }, () => {
      this.campusService
        .putCampusSingleVenue(this.props.match.params.id, data.id, {
          name: data.name,
        })
        .then((response) => {
          const venues = this.state.activitiesPerVenue.venues.map((venue) => {
            if (venue.id === data.id) {
              return response.data;
            } else {
              return { _id: venue.id, name: venue.name };
            }
          });
          const activitiesPerVenue = this.createActivitiesPerVenue(
            this.state.groups,
            venues
          );
          const activitiesPerGroup = this.createActivitiesPerGroup(
            this.state.groups,
            venues
          );
          this.setState({
            activitiesPerVenue,
            activitiesPerGroup,
            status: "success",
          });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  handleDeleteVenue = (data) => {
    // console.log("data delete venue", data);
    this.setState({ status: "loading" }, () => {
      this.campusService
        .deleteCampusSingleVenue(this.props.match.params.id, data.id)
        .then((response) => {
          // console.log("response delete venue", response);
          const venues = this.state.activitiesPerVenue.venues
            .filter((venue) => venue.id !== data.id)
            .map((venue) => {
              return { _id: venue.id, name: venue.name };
            });
          // console.log("venues final", venues);
          const activitiesPerVenue = this.createActivitiesPerVenue(
            this.state.groups,
            venues
          );
          const activitiesPerGroup = this.createActivitiesPerGroup(
            this.state.groups,
            venues
          );
          this.setState({
            activitiesPerVenue,
            activitiesPerGroup,
            status: "success",
          });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  // Funciones Users
  handleEditUser = (data) => {
    //TODO LOGIC CHECK ARRAY GROUPS & NAME AND SEND TO API
    // console.log("data edit user", data);
    const user = {
      child: {
        name: data.name,
        comments: data.comments,
        allergies: data.allergies,
        status: data.status,
        parents: data.parents,
      },
      childChanged: data.childChanged,
      groups: data.groups,
      extraResponsiblesChanged: data.extraResponsiblesChanged,
      extraResponsibles: data.extraResponsibles || null,
    };
    this.setState({ status: "loading" }, () => {
      this.campusService
        .putCampusSingleUser(this.props.match.params.id, data.key, "edit", user)
        .then((response) => {
          // console.log("response", response);
          let users = [...this.state.users];
          users = users.map((_user) => {
            if (_user._id === response.data._id) {
              return { ...response.data };
            } else {
              return _user;
            }
          });
          users = this.createUsers(users, this.state.groups);
          const groups = this.createGroups(this.state.groups, users);
          this.setState({ groups, users, status: "success" });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  handleDeleteUser = (key) => {
    const user = this.state.users.find((_user) => _user.key === key);
    this.setState({ status: "loading" }, () => {
      this.campusService
        .putCampusSingleUser(this.props.match.params.id, key, "delete", {
          child: { parents: user.parents },
        })
        .then((response) => {
          // console.log("responde delete user", response);

          const users = this.state.users.filter((user) => user.key !== key);
          const groups = this.createGroups(this.state.groups, users);

          this.setState({ groups, users, status: "success" });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  handleAssignUsersToGroup = (data) => {
    const usersData = { name: data.name, users: data.users };
    this.setState({ status: "loading" }, () => {
      this.campusService
        .putCampusSingleAssignUsersGroup(
          this.props.match.params.id,
          data.groupId,
          usersData
        )
        .then((response) => {
          // console.log("response assign group", response.data);
          const users = this.createUsers(response.data, this.state.groups);
          const groups = this.createGroups(this.state.groups, users);
          // console.log("groups", groups);
          this.setState({ users, groups, status: "success" });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  // Funciones staff

  downloadStaffInfo = (id) => {
    // console.log(id);
    return this.staffService
      .getStaffSingle(this.context.franchise, id)
      .then((response) => {
        let staff = this.state.staff;
        let staffToSend;

        staff = staff.map((staffWorker) => {
          if (staffWorker.id === id) {
            //MERGE 2 objetos para mantener staff logica visual
            staffToSend = {
              ...staffWorker,
              ...response.data,
              sports: response.data.sports.canTeach,
              downloaded: true,
            };
            return staffToSend;
          } else {
            return staffWorker;
          }
        });
        // console.log("value staff to send drawer", staffToSend);

        this.setState({ staff });
        return Promise.resolve(staffToSend);
      })
      .catch((err) => {
        this.setState({ status: "error" });
        return Promise.reject();
      });
  };

  handleEditStaff = (data) => {
    const staff = {
      groups: data.groups,
      employee: {
        name: data.name,
        role: data.role,
        rocketChatId: data.rocket_chat.id,
      },
    };
    this.setState({ status: "loading" }, () => {
      this.staffService
        .putCampusSingleStaff(
          this.context.franchise,
          this.props.match.params.id,
          data.id,
          "edit",
          staff
        )
        .then((response) => {
          this.setState({ status: "success" }, () => {
            this.createLogicFromGroupsDownload(response.data.groups);
          });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  handleViewCertificate = (key) => {
    return s3GetObject(key).then((response) => {
      // console.log("id ", response);
      return response;
    });
  };

  handleDeleteStaff = (id) => {
    this.setState({ status: "loading" }, () => {
      this.staffService
        .putCampusSingleStaff(
          this.context.franchise,
          this.props.match.params.id,
          id,
          "delete",
          {}
        )
        .then((response) => {
          // console.log("responde delete staff", response);
          const groups = this.state.groups.map((group) => {
            return {
              ...group,
              staff: group.staff.filter((staffWorker) => staffWorker.id !== id),
              trainers: group.trainers.filter((trainer) => trainer._id !== id),
            };
          });

          const staff = this.state.staff.filter(
            (staffWorker) => staffWorker.id !== id
          );

          const coordinatorsCampus = this.state.coordinatorsCampus.filter(
            (coordinator) => coordinator.id
          );

          this.setState({
            groups,
            staff,
            coordinatorsCampus,
            status: "success",
          });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  render() {
    if (this.state.deleted) {
      return <Redirect to="/campus" />;
    }
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <WebCampusSingle
          role="manager"
          //STATUS
          status={this.state.status}
          //CAMPUS
          campusId={this.props.match.params.id}
          campusData={this.state.campusData}
          handleEditCampus={this.handleEditCampus}
          handleDeleteCampus={this.handleDeleteCampus}
          handleDownloadProjects={this.handleDownloadProjects}
          projects={this.state.projects}
          //SM STAFF
          sm_staff={this.state.sm_staff}
          //POPOVER
          handleAddGroup={this.handleAddGroup}
          handleAddUser={this.handleAddUser}
          handleAddStaffTrainer={this.handleAddStaffTrainer}
          handleAddStaffCoordinator={this.handleAddStaffCoordinator}
          handleDownloadSMStaff={this.handleDownloadSMStaff}
          handleImportUsers={this.handleImportUsers}
          //VENUES
          activitiesPerVenue={this.state.activitiesPerVenue}
          activitiesPerGroup={this.state.activitiesPerGroup}
          handleSubmitModalAddResource={this.handleSubmitModalAddResource}
          handleEditVenue={this.handleEditVenue}
          handleDeleteVenue={this.handleDeleteVenue}
          //GROUPS
          groups={this.state.groups}
          //USERS
          users={this.state.users}
          handleEditUser={this.handleEditUser}
          handleDeleteUser={this.handleDeleteUser}
          handleAssignUsersToGroup={this.handleAssignUsersToGroup}
          //STAFF
          staff={this.state.staff}
          downloadStaffInfo={this.downloadStaffInfo}
          handleEditStaff={this.handleEditStaff}
          handleDeleteStaff={this.handleDeleteStaff}
          handleViewCertificate={this.handleViewCertificate}
          //SATISFACTION
          satisfaction={this.state.satisfaction}
          //TRANSLATION
          translation={this.context.translation.templates.web.campus.single}
        />
      </Grid>
    );
  }
}

export { ManagerWebCampusSingle };
