import { ContactTemplate } from "./Contacts/Single";
import { ContactsTemplate } from "./Contacts/List";
import { OpportunitiesTemplate } from "./Opportunities/List";
import { OpportunityTemplate } from "./Opportunities/Single";
import { DashboardCRMTemplate } from "./Dashboard/CRM";
import { DashboardProjectTemplate } from "./Dashboard/Projects";
import { PastOpportunitiesTemplate } from "./Opportunities/PastOpportunities";

import { ProjectTemplate } from "./Projects/Single";
import { ProjectsTemplate } from "./Projects/List";
import { InvoiceTemplate } from "./Billing/Single";
import { InvoicesTemplate } from "./Billing/List";
import { BillingSettingsTemplate } from "./Billing/Settings";
import { SubscriptionsTemplate } from "./Billing/Subscriptions";
import { ProjectBestPracticesTemplate } from "./Projects/BestPractices/Single";
import { ProjectBestPracticesListTemplate } from "./Projects/BestPractices/List";
import { AcademyListTemplate } from "./Academy/List";
import { WebCampusListTemplate } from "./Web/Campus/List";
import { WebCampusSingleTemplate } from "./Web/Campus/Single";
import { WebCampusGroupSingleTemplate } from "./Web/Campus/Group/Single";
import { WebInboxTemplate } from "./Web/Inbox";
import { WebStaffListTemplate } from "./Web/Staff/List";
import { WebStaffSingleTemplate } from "./Web/Staff/Single";

const templates = (translation) => {
  return {
    contact: ContactTemplate(translation),
    contacts: ContactsTemplate(translation),
    opportunities: OpportunitiesTemplate(translation),
    opportunity: OpportunityTemplate(translation),
    crmdashboard: DashboardCRMTemplate(translation),
    projectsDashboard: DashboardProjectTemplate(translation),
    pastOpportunities: PastOpportunitiesTemplate(translation),
    projects: ProjectsTemplate(translation),
    projectBestPractices: ProjectBestPracticesTemplate(translation),
    projectBestPracticesList: ProjectBestPracticesListTemplate(translation),
    // courses: AcademyListTemplate(translation),
    project: ProjectTemplate(translation),
    invoices: InvoicesTemplate(translation),
    invoice: InvoiceTemplate(translation),
    settings: BillingSettingsTemplate(translation),
    subscriptions: SubscriptionsTemplate(translation),
    web: {
      campus: {
        list: WebCampusListTemplate(translation.web.campus.list),
        single: WebCampusSingleTemplate(translation.web.campus.single),
        group: {
          single: WebCampusGroupSingleTemplate(
            translation.web.campus.group.single
          ),
        },
      },
      staff: {
        list: WebStaffListTemplate(translation.web.staff.list),
        single: WebStaffSingleTemplate(translation.web.staff.single),
      },
      inbox: WebInboxTemplate(translation.web.inbox),
    },
  };
};

export { templates };
