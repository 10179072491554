import React, { Component } from "react";
import { ResetPassword } from "react-library-sm";
import { Auth } from "aws-amplify";

class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { status: "forgotPassword", email: null };
  }
  forgotPasswordHandler = values => {
    Auth.forgotPassword(values.email).then(data => {
      // console.log(data);
      this.setState({ status: "resetPassword", email: values.email });
    });
  };

  resetPasswordHandler = values => {
    Auth.forgotPasswordSubmit(values.email, values.code, values.password).then(
      data => {
        // console.log("data forgot Password submit", data);
        Auth.signIn(values.email, values.password).then(user => {
          this.props.resetPasswordSuccess(user);
        });
      }
    );
  };
  render() {
    let content;
    switch (this.state.status) {
      case "forgotPassword":
        content = (
          <ResetPassword
            resetPassword={this.resetPasswordHandler}
            forgotPassword={this.forgotPasswordHandler}
          />
        );
        break;
      case "resetPassword":
        content = (
          <ResetPassword
            resetPassword={this.resetPasswordHandler}
            forgotPassword={this.forgotPasswordHandler}
            variant="RESET_PASSWORD"
            email={this.state.email}
          />
        );
        break;
      default:
        content = (
          <ResetPassword
            resetPassword={this.resetPasswordHandler}
            forgotPassword={this.forgotPasswordHandler}
          />
        );
        break;
    }
    return content;
  }
}

export default ResetPasswordComponent;
