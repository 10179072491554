import React from "react";
import { Typography } from "react-library-sm";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import { Link } from "react-router-dom";
import { options } from "assets/mui-datatables/options-default";

const ContactsTemplate = (translation) => {
  return {
    alerts: {
      create_contact: {
        success: {
          text: translation.alerts.create_contact.success.text,
          submit: translation.alerts.create_contact.success.submit,
        },
        warning: {
          text: translation.alerts.create_contact.warning.text,
          submit: translation.alerts.create_contact.warning.submit,
        },
      },
      download_contacts: {
        error: {
          text: translation.alerts.download_contacts.error.text,
          submit: translation.alerts.download_contacts.error.submit,
        },
      },
    },
    title: translation.contacts.list.title,
    buttons: {
      add_contact: translation.contacts.list.buttons.add_contact,
      import: translation.contacts.list.buttons.import_contacts,
    },
    datatables: {
      contact_list: {
        title: translation.contacts.list.datatables.contact_list.title,
        textLabels:
          translation.contacts.list.datatables.contact_list.textLabels,
        columns: [
          {
            label: translation.contacts.list.datatables.contact_list.columns.id,
            name: "_id",
            options: { display: false, filter: false },
          },
          {
            label:
              translation.contacts.list.datatables.contact_list.columns.name,
            name: "name",
            options: { filter: false },
          },
          {
            label:
              translation.contacts.list.datatables.contact_list.columns.type,
            name: "type",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListOptions: {
                render: (v) =>
                  `${translation.contacts.list.datatables.contact_list.columns.type} ${v}}`,
              },
              filterOptions: {
                names: Object.values(
                  translation.contacts.list.datatables.contact_list.type
                ),
                logic(type, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.contacts.list.datatables.contact_list.type
                        .client
                    ) >= 0 &&
                      type === "client") ||
                    (filterVal.indexOf(
                      translation.contacts.list.datatables.contact_list.type
                        .person
                    ) >= 0 &&
                      type === "person");

                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Typography variant="body2" gutterBottom>
                    {translation.contacts.list.contact_type[
                      tableMeta.rowData[2]
                    ] || "no definido"}
                  </Typography>
                );
              },
            },
          },
          {
            label:
              translation.contacts.list.datatables.contact_list.columns.email,
            name: "email",
            options: { filter: false },
          },
          {
            label:
              translation.contacts.list.datatables.contact_list.columns.phone,
            name: "telephone",
            options: { filter: false },
          },
          {
            label:
              translation.contacts.list.datatables.contact_list.columns.city,
            name: "city",
            options: { filter: false },
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (tableMeta.rowData[2] === "client") {
                  return (
                    <Link
                      to={"/contacts/" + tableMeta.rowData[0] + "/view"}
                      style={{ color: "#fff" }}
                    >
                      <RemoveRedEye />
                    </Link>
                  );
                }
              },
            },
          },
        ],
        options: options,
      },
    },
    contact_type: [
      { value: "person", label: translation.contacts.list.contact_type.person },
      { value: "client", label: translation.contacts.list.contact_type.client },
      { value: "user", label: translation.contacts.list.contact_type.user },
    ],
    dialogs: {
      add_contact: {
        titles: {
          add_contact:
            translation.contacts.list.dialogs.add_contact.titles.add_contact,
          edit_contact:
            translation.contacts.list.dialogs.add_contact.titles.edit_contact,
          view_contact:
            translation.contacts.list.dialogs.add_contact.titles.view_contact,
        },
        buttons: {
          cancel: translation.contacts.list.dialogs.add_contact.buttons.cancel,
          save: translation.contacts.list.dialogs.add_contact.buttons.save,
          add: translation.contacts.list.dialogs.add_contact.buttons.add,
        },
        inputs: {
          contact_name:
            translation.contacts.list.dialogs.add_contact.inputs.contact_name,
          billing_info_name:
            translation.contacts.list.dialogs.add_contact.inputs
              .billing_info_name,
        },
        forms: {
          client: {
            inputs: {
              contact_city:
                translation.contacts.list.dialogs.add_contact.forms.client
                  .inputs.contact_city,
              contact_email:
                translation.contacts.list.dialogs.add_contact.forms.client
                  .inputs.contact_email,
              contact_phone:
                translation.contacts.list.dialogs.add_contact.forms.client
                  .inputs.contact_phone,
              contact_web:
                translation.contacts.list.dialogs.add_contact.forms.client
                  .inputs.contact_web,
            },
            selects: {
              client_type: {
                placeholder:
                  translation.contacts.list.dialogs.add_contact.forms.client
                    .selects.client_type.placeholder,
                items: [
                  {
                    value: "school",
                    label:
                      translation.contacts.list.dialogs.add_contact.forms.client
                        .selects.client_type.school,
                  },
                  {
                    value: "publicAdmin",
                    label:
                      translation.contacts.list.dialogs.add_contact.forms.client
                        .selects.client_type.public_admin,
                  },
                  {
                    value: "company",
                    label:
                      translation.contacts.list.dialogs.add_contact.forms.client
                        .selects.client_type.company,
                  },
                  {
                    value: "individual",
                    label:
                      translation.contacts.list.dialogs.add_contact.forms.client
                        .selects.client_type.individual,
                  },
                  {
                    value: "club",
                    label:
                      translation.contacts.list.dialogs.add_contact.forms.client
                        .selects.client_type.club,
                  },
                ],
              },
            },
          },
          person_of_contact: {
            inputs: {
              person_name:
                translation.contacts.list.dialogs.add_contact.forms
                  .person_of_contact.inputs.person_name,
              person_job:
                translation.contacts.list.dialogs.add_contact.forms
                  .person_of_contact.inputs.person_job,
              person_email:
                translation.contacts.list.dialogs.add_contact.forms
                  .person_of_contact.inputs.person_email,
              person_phone:
                translation.contacts.list.dialogs.add_contact.forms
                  .person_of_contact.inputs.person_phone,
            },
            buttons: {
              add_person_of_contact:
                translation.contacts.list.dialogs.add_contact.forms
                  .person_of_contact.buttons.add_person_of_contact,
            },
          },
          billing_info: {
            inputs: {
              name: translation.contacts.list.dialogs.add_contact.forms
                .billing_info.inputs.name,
              address:
                translation.contacts.list.dialogs.add_contact.forms.billing_info
                  .inputs.address,
              zipcode:
                translation.contacts.list.dialogs.add_contact.forms.billing_info
                  .inputs.zipcode,
              town: translation.contacts.list.dialogs.add_contact.forms
                .billing_info.inputs.town,
              province:
                translation.contacts.list.dialogs.add_contact.forms.billing_info
                  .inputs.province,
              vat_number:
                translation.contacts.list.dialogs.add_contact.forms.billing_info
                  .inputs.vat_number,
            },
          },
        },
        tabs: [
          {
            value: "client",
            label: translation.contacts.list.dialogs.add_contact.tabs.client,
          },
          {
            value: "person_of_contact",
            label:
              translation.contacts.list.dialogs.add_contact.tabs
                .person_of_contact,
          },
          {
            value: "billing",
            label: translation.contacts.list.dialogs.add_contact.tabs.billing,
          },
          {
            value: "face",
            label: "Datos FACE",
          },
        ],
        tabs_edit: [
          {
            value: "client",
            label: translation.contacts.list.dialogs.add_contact.tabs.client,
          },
          {
            value: "billing",
            label: translation.contacts.list.dialogs.add_contact.tabs.billing,
          },
          {
            value: "face",
            label: "Datos FACE",
          },
        ],
      },
      import_contacts: {
        titles: {
          loading:
            translation.contacts.list.dialogs.import_contacts.titles.loading,
        },
        buttons: {
          download_template:
            translation.contacts.list.dialogs.import_contacts.buttons
              .download_template,
          select:
            translation.contacts.list.dialogs.import_contacts.buttons.select,
          cancel:
            translation.contacts.list.dialogs.import_contacts.buttons.cancel,
          import:
            translation.contacts.list.dialogs.import_contacts.buttons.import,
        },
        datatables: {
          import_contacts: {
            title:
              translation.contacts.list.dialogs.import_contacts.datatables
                .import_contacts.title,
            textLabels:
              translation.contacts.list.dialogs.import_contacts.datatables
                .import_contacts.textLabels,
            columns: [
              {
                name: "name",
                label:
                  translation.contacts.list.dialogs.import_contacts.datatables
                    .import_contacts.columns.name,
              },
              {
                name: "billingInfo_name",
                label:
                  translation.contacts.list.dialogs.import_contacts.datatables
                    .import_contacts.columns.billingInfo_name,
              },
              {
                name: "city",
                label:
                  translation.contacts.list.dialogs.import_contacts.datatables
                    .import_contacts.columns.city,
              },
              {
                name: "email",
                label:
                  translation.contacts.list.dialogs.import_contacts.datatables
                    .import_contacts.columns.email,
              },
              {
                name: "web",
                label:
                  translation.contacts.list.dialogs.import_contacts.datatables
                    .import_contacts.columns.web,
              },
              {
                name: "telephone",
                label:
                  translation.contacts.list.dialogs.import_contacts.datatables
                    .import_contacts.columns.telephone,
              },
              {
                name: "clientTypeLabel",
                label:
                  translation.contacts.list.dialogs.import_contacts.datatables
                    .import_contacts.columns.clientTypeLabel,
              },
              {
                name: "people_0_name",
                label:
                  translation.contacts.list.dialogs.import_contacts.datatables
                    .import_contacts.columns.person_of_contact.name,
              },
              {
                name: "people_0_email",
                label:
                  translation.contacts.list.dialogs.import_contacts.datatables
                    .import_contacts.columns.person_of_contact.email,
              },
              {
                name: "people_0_telephone",
                label:
                  translation.contacts.list.dialogs.import_contacts.datatables
                    .import_contacts.columns.person_of_contact.telephone,
              },
              {
                name: "people_0_job",
                label:
                  translation.contacts.list.dialogs.import_contacts.datatables
                    .import_contacts.columns.person_of_contact.job,
              },
            ],
          },
        },
      },
    },
  };
};
export { ContactsTemplate };
