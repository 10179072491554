import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";

import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import Logo from "components/UI/Logo/Logo";
import {
  ListItemText,
  ListItemIcon,
  Grid,
  Typography,
} from "@material-ui/core";
import languages from "assets/languages/languages";
import UserContext from "providers/UserProvider";
import MenuIcon from "@material-ui/icons/Menu";
import { Auth } from "aws-amplify";

import { Link } from "react-router-dom";

import clsx from "clsx";
import moment from "moment";

import PeopleIcon from "@material-ui/icons/People";
import CallIcon from "@material-ui/icons/Call";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import EmailIcon from "@material-ui/icons/Email";

import { Tabs } from "react-library-sm";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    flexDirection: "row",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    flexGrow: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    //	width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  languageImg: {
    width: "50px",
    height: "50px",
    padding: "12px",
  },
}));

const TopBar = ({
  toggleDrawer,
  open,
  notificationsOpportunities,
  notificationsProjects,
  ...props
}) => {
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const [anchorNotifications, setAnchorNotifications] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [notificationsTab, setNotificationsTab] =
    React.useState("opportunities");
  const isMenuProfileOpen = Boolean(anchorProfile);
  const isMenuNotificationsOpen = Boolean(anchorNotifications);
  const isMenuLanguageOpen = Boolean(anchorLanguage);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleTabs = (event, newValue) => {
    setNotificationsTab(newValue);
  };

  const contentTabHandler = (type) => {
    return getNotifications(type);
  };
  function handleMenuOpen(event) {
    if (event.currentTarget.id === "profileButton") {
      setAnchorProfile(event.currentTarget);
    } else if (event.currentTarget.id === "languageButton") {
      setAnchorLanguage(event.currentTarget);
    } else if (event.currentTarget.id === "notificationsButton") {
      setAnchorNotifications(event.currentTarget);
    }
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorProfile(null);
    setAnchorLanguage(null);
    setAnchorNotifications(null);
    handleMobileMenuClose();
  }

  function logout() {
    Auth.signOut()
      .then((data) => {
        // console.log('d', data);
        localStorage.removeItem("sportmadness-manager-franchise");
        localStorage.removeItem("email");
        localStorage.removeItem("rocket-chat-auth_token");
        localStorage.removeItem("rocket-chat-user_id");
        window.location.href = "/";
      })
      .catch((err) => console.log("error logout sesion", err));
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const handleLanguageChanged = (language) => {
    handleMenuClose();
    props.languageChanged(language);
  };

  const handleNotificationProjectsClicked = (task) => {
    handleMenuClose();
    props.notificationProjectsClicked(task);
  };

  const handleNotificationOpportunitiesClicked = (task) => {
    handleMenuClose();
    props.notificationOpportunitiesClicked(task);
  };

  const getLanguages = () => {
    const items = [];
    for (const language of languages) {
      if (userContext.language.value !== language.value) {
        items.push(
          <MenuItem
            onClick={() => handleLanguageChanged(language.value)}
            key={language.value}
          >
            <ListItemIcon>
              <img
                style={{
                  width: "50px",
                  height: "50px",
                  padding: "12px",
                }}
                src={language.valueImage}
                alt={language.value}
              />
            </ListItemIcon>
            <ListItemText primary={language.text} />
          </MenuItem>
        );
      }
    }

    return items;
  };

  const getNotifications = () => {
    let items = [];
    if (notificationsTab === "opportunities") {
      return notificationsOpportunities.length > 0 ? (
        (items = notificationsOpportunities.map((notification) => {
          return (
            <MenuItem
              key={notification._id}
              component={Link}
              to={"/pipeline/" + notification.projectId}
              onClick={() => {
                handleNotificationOpportunitiesClicked(notification._id);
              }}
            >
              <ListItemText key={notification._id}>
                <Grid container spacing={4} style={{ alignItems: "center" }}>
                  <Grid item xs={2}>
                    {getIconNotification(notification.type)}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {notification.subject}
                    </Typography>
                    <Typography variant="body2">
                      {notification.projectName}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="caption"
                      style={{ wordWrap: "break-word" }}
                    >
                      {moment(notification.date).fromNow()}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </ListItemText>
            </MenuItem>
          );
        }))
      ) : (
        <MenuItem key="0">
          <ListItemText key="no_notifications">
            <Grid container spacing={4} style={{ alignItems: "center" }}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  No hay notificaciones pendientes
                </Typography>
              </Grid>
            </Grid>
          </ListItemText>
        </MenuItem>
      );
    } else if (notificationsTab === "projects") {
      return notificationsProjects.length > 0 ? (
        (items = notificationsProjects.map((notification) => {
          return (
            <MenuItem
              key={notification._id}
              component={Link}
              to={{
                pathname: "/projects/" + notification.projectId,
                state: { notification: notification.key },
              }}
              onClick={() => {
                handleNotificationProjectsClicked(notification._id);
              }}
            >
              <ListItemText>
                <Grid container style={{ alignItems: "center" }}>
                  <Grid item xs={8}>
                    <Typography
                      variant="body2"
                      style={{ wordWrap: "break-word" }}
                    >
                      {notification.name.length > 40
                        ? notification.name.substring(0, 40) + "..."
                        : notification.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ wordWrap: "break-word" }}
                    >
                      {notification.projectName}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="caption"
                      style={{ wordWrap: "break-word" }}
                    >
                      {moment(notification.date).fromNow()}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </ListItemText>
            </MenuItem>
          );
        }))
      ) : (
        <MenuItem key="0">
          <ListItemText>
            <Grid container spacing={4} style={{ alignItems: "center" }}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  No hay notificaciones pendientes
                </Typography>
              </Grid>
            </Grid>
          </ListItemText>
        </MenuItem>
      );
    }
    // return items;
  };

  const getIconNotification = (type) => {
    let icon;
    switch (type) {
      case "call":
        icon = <CallIcon />;
        break;
      case "visit":
        icon = <DriveEtaIcon />;
        break;
      case "meeting":
        icon = <PeopleIcon />;
        break;
      case "email":
        icon = <EmailIcon />;
        break;
      default:
        break;
    }

    return icon;
  };

  const renderMenuProfile = (
    <Menu
      anchorEl={anchorProfile}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      style={{ marginTop: "28px" }}
      open={isMenuProfileOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>
        <ListItemText primary="Sent mail" />
      </MenuItem> */}
      <MenuItem component={Link} to="/subscriptions">
        Mis facturas
      </MenuItem>
      <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
    </Menu>
  );

  const renderMenuLanguages = (
    <Menu
      anchorEl={anchorLanguage}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      style={{ marginTop: "28px" }}
      open={isMenuLanguageOpen}
      onClose={handleMenuClose}
    >
      {getLanguages()}
    </Menu>
  );

  const renderMenuNotifications = (
    <Menu
      anchorEl={anchorNotifications}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      style={{ marginTop: "28px", marginRight: "16px" }}
      open={isMenuNotificationsOpen}
      onClose={handleMenuClose}
    >
      <Tabs
        variant="fullWidth"
        value={notificationsTab}
        onChange={handleTabs}
        contentTabs={[
          {
            label: (
              <Badge
                badgeContent={notificationsOpportunities.length}
                color="secondary"
              >
                Oportunidades
              </Badge>
            ),
            value: "opportunities",
          },
          {
            label: (
              <Badge
                badgeContent={notificationsProjects.length}
                color="secondary"
              >
                Proyectos
              </Badge>
            ),
            value: "projects",
          },
        ]}
      />
      {contentTabHandler(notificationsTab)}
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMenuOpen}>
        <IconButton color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleMenuOpen}>
        <IconButton color="inherit">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.Toolbar}>
          <IconButton color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Logo />
          <div className={classes.sectionDesktop}>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton> */}
            {/* <IconButton
              color="inherit"
              onClick={handleMenuOpen}
              id="notificationsButton"
            >
              <Badge
                badgeContent={
                  notificationsOpportunities.length +
                  notificationsProjects.length
                }
                color="secondary"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <img
              style={{
                width: "50px",
                height: "50px",
                padding: "12px",
              }}
              src={userContext.language.valueImage}
              onClick={handleMenuOpen}
              id="languageButton"
              alt={userContext.language.value}
            />
            <IconButton
              edge="end"
              aria-owns={isMenuProfileOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              color="inherit"
              id="profileButton"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenuProfile}
      {renderMenuLanguages}
      {renderMobileMenu}
      {renderMenuNotifications}
    </div>
  );
};

export default TopBar;
