import React, { Component } from "react";
import {
  FranchiseOpportunityList as OpportunityList,
  Alert,
} from "react-library-sm";
import UserContext from "providers/UserProvider";
import OpportunitiesServices from "services/franchise-crm/opportunities.services";
import ContactsServices from "services/franchise-crm/contacts.services";
import BoardsServices from "services/franchise-crm/boards.services";
import { Paper } from "@material-ui/core";

class OpportunitiesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opportunities: [],
      boardDidMount: false,
      contacts: [],
      alertAPI: false,
      alert: {},
    };

    this.opportunityService = new OpportunitiesServices();
    this.contactService = new ContactsServices();
    this.boardService = new BoardsServices();
    this.language = null;
    this.opportunitySourceLane = null;
    this.opportunityTargetLane = null;
    this.opportunitySameLane = false;
    this.opportunityId = null;
  }

  static contextType = UserContext;

  componentDidMount() {
    this.boardService
      .getBoards(this.context.franchise)
      .then((response) => {
        // console.log("response get board", response);

        const boards = { ...response.data[0].boards };
        boards.opportunity.lanes = boards.opportunity.lanes.map((lane) => {
          lane.cards = lane.cards.map((card) => {
            return {
              ...card,
              id: card._id,
              status: card.statusSells,
              laneId: lane.id,
              link: "/pipeline/" + card._id + "/view",
              client: card.client[0] ? card.client[0].name : "",
              // card.client[0].name
            };
          });

          return {
            ...lane,
            title: this.context.translation.templates.opportunities
              ? this.context.translation.templates.opportunities.views.board
                  .pipeline[lane.id].title
              : lane.id + " Sin traduccion",
          };
        });
        this.contactService
          .getContacts(this.context.franchise)
          .then((response) => {
            let contacts = [...response.data];
            contacts = contacts
              .filter((contact) => contact.type === "client")
              .map((contact) => {
                return {
                  ...contact,
                  value: contact._id,
                  label: contact.name,
                };
              });
            this.opportunityService
              .getOpportunities(this.context.franchise)
              .then((response) => {
                this.setState({
                  contacts,
                  boards,
                  opportunities: response.data,
                });
              });
          })
          .catch((err) => {
            // console.log("err get contacts", err);
            this.setState({
              alertAPI: true,
              alert: {
                open: true,
                title: this.context.translation.alerts.titles.error,
                status: "error",
                text: this.context.translation.templates.opportunities.alerts
                  .download_contacts.error.text,
                linkText:
                  this.context.translation.templates.opportunities.alerts
                    .download_contacts.error.submit,
                link: "/",
              },
            });
          });
      })
      .catch((err) => {
        // console.log("err get board", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            text: this.context.translation.templates.opportunities.alerts
              .download_boards.error.text,
            linkText:
              this.context.translation.templates.opportunities.alerts
                .download_boards.error.submit,
            link: "/",
          },
        });
      });
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };

  createOpportunity = (data) => {
    const opportunity = { ...data };
    opportunity.client = opportunity.contact;
    opportunity.franchise = this.context.franchise;

    this.opportunityService
      .addOpportunity(opportunity)
      .then((opportunity) => {
        // console.log("response addOpportunity", opportunity);
        // console.log("response addOpportunity data", opportunity.data);
        const _opportunities = [...this.state.opportunities];

        _opportunities.push({
          ...opportunity.data,
          contact: opportunity.data.client[0].name,
        });

        this.boardService
          .getBoards(this.context.franchise)
          .then((response) => {
            // console.log("response get board", response);

            const boards = { ...response.data[0].boards };
            boards.opportunity.lanes = boards.opportunity.lanes.map((lane) => {
              lane.cards = lane.cards.map((card) => {
                return {
                  ...card,
                  id: card._id,
                  status: card.statusSells,
                  laneId: lane.id,
                  link: "/pipeline/" + card._id + "/view",
                  client: card.client[0] ? card.client[0].name : "",
                  // card.client[0].name
                };
              });

              return {
                ...lane,
                title: this.context.translation.templates.opportunities
                  ? this.context.translation.templates.opportunities.views.board
                      .pipeline[lane.id].title
                  : lane.id + " Sin traduccion",
              };
            });

            this.setState({
              ...this.state,
              boards,
              opportunities: _opportunities,
            });
          })
          .catch((err) => {
            // console.log("err get boards", err);
            this.setState({
              alertAPI: true,
              alert: {
                open: true,
                title: this.context.translation.alerts.titles.error,
                status: "error",
                text: this.context.translation.templates.opportunities.alerts
                  .download_boards.error.text,
                linkText:
                  this.context.translation.templates.opportunities.alerts
                    .download_boards.error.submit,
                link: "/",
              },
            });
          });
      })
      .catch((err) => {
        // console.log("err add opportunity", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.opportunities.alerts
              .create_opportunity.warning.text,
            submit:
              this.context.translation.templates.opportunities.alerts
                .create_opportunity.warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  handleCardDragBoard = (data) => {
    // console.log("handle card drag board", data);

    this.opportunitySourceLane = data.sourceLaneId;
    this.opportunityTargetLane = data.targetLaneId;
    this.opportunityId = data.cardId;

    this.opportunityService
      .getOpportunity(data.cardId, this.context.franchise)
      .then((response) => {
        // console.log("response card", response.data);
        const projectUpdate = {
          ...response.data[0],
          statusSells: this.opportunityTargetLane,
        };
        // console.log("project", projectUpdate);
        this.opportunityService
          .updateOpportunity(
            projectUpdate,
            this.context.franchise,
            data.position
          )
          .then((response) => {
            // console.log("updated project", response);
            // console.log("opportunity updated");
            this.setState({
              alertAPI: true,
              alert: {
                open: true,
                title: this.context.translation.alerts.titles.success,
                status: "success",
                text: this.context.translation.templates.opportunities.alerts
                  .edit_opportunity.success.text,
                submit:
                  this.context.translation.templates.opportunities.alerts
                    .edit_opportunity.success.submit,
                onSubmit: this.handleOnCloseAlert,
              },
            });
          })
          .catch((err) => {
            // console.log("err update project", err.data);
            this.setState({
              alertAPI: true,
              alert: {
                open: true,
                title: this.context.translation.alerts.titles.warning,
                status: "warning",
                text: this.context.translation.templates.opportunities.alerts
                  .edit_opportunity.warning.text,
                submit:
                  this.context.translation.templates.opportunities.alerts
                    .edit_opportunity.warning.submit,
                onSubmit: this.handleOnCloseAlert,
              },
            });
          });
      })
      .catch((err) => {
        // console.log("err get project", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            text: this.context.translation.templates.opportunities.alerts
              .download_opportunity.error.text,
            linkText:
              this.context.translation.templates.opportunities.alerts
                .download_opportunity.error.submit,
            link: "/",
          },
        });
      });
  };

  handleClick = (props) => {
    // console.log("HANDLE ", props);
  };

  changeTitles = async () => {
    const boards = { ...this.state.boards };
    boards.opportunity.lanes = boards.opportunity.lanes.map((lane) => {
      // console.log("change", lane);
      return {
        ...lane,
        title: this.context.translation.templates.opportunities
          ? this.context.translation.templates.opportunities.views.board
              .pipeline[lane.id].title
          : lane.id + " Sin traduccion",
      };
    });
    this.setState({ boards });
  };

  handleChangeBoard = (data) => {
    // console.log("new board received", data);
    if (this.state.boardDidMount) {
      // console.log("sin montar");
    } else {
      this.setState({ boardDidMount: true }, () => {
        // console.log("montado");
      });
    }
  };

  render() {
    // console.log(
    //   "this.context.translation en opp list",
    //   this.context.translation
    // );
    if (this.language === null) this.language = this.context.language.value;
    if (this.language !== this.context.language.value) {
      this.language = this.context.language.value;
      this.changeTitles();
    }

    let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    // console.log("locale Opplist", this.context.locale);
    return (
      <Paper elevation={0} style={{ background: "transparent" }}>
        {alert}
        <OpportunityList
          comercial={this.state.boards ? this.state.boards.opportunity : null}
          onCardClick={this.handleClick}
          createOpportunity={this.createOpportunity}
          contacts={this.state.contacts}
          translation={
            this.context.translation.templates
              ? this.context.translation.templates.opportunities
              : null
          }
          opportunities={this.state.opportunities}
          changeBoard={this.handleChangeBoard}
          handleCardDragBoard={this.handleCardDragBoard}
          locale={this.context.locale}
          currency={this.context.currency}
        />
      </Paper>
    );
  }
}

export default OpportunitiesContainer;
