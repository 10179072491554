const alerts = {
  create_contact: {
    success: {
      text: "Contact created succesfully",
      submit: "Accept"
    },
    warning: {
      text: "There's been a problem. Please try again",
      submit: "Accept"
    }
  },
  create_opportunity: {
    success: {
      text: "Opportunity created succesfully",
      submit: "Accept"
    },
    warning: {
      text: "There's been a problem. Please try again",
      submit: "Accept"
    }
  },
  create_project: {
    success: {
      text: "Project created succesfully",
      submit: "Accept"
    },
    warning: {
      text: "There's been a problem. Please try again",
      submit: "Accept"
    }
  },
  create_task: {
    success: {
      text: "Task  created succesfully",
      submit: "Accept"
    },
    warning: {
      text: "There's been a problem. Please try again",
      submit: "Accept"
    }
  },
  delete_contact: {
    success: {
      text: "Contact deleted successfully",
      submit: "Accept"
    },
    warning: {
      text: "There's been a problem. Please try again",
      submit: "Accept"
    }
  },
  delete_opportunity: {
    success: {
      text: "Opportunity deleted successfully",
      submit: "Accept"
    },
    warning: {
      text: "There's been a problem. Please try again",
      submit: "Accept"
    }
  },
  delete_project: {
    success: {
      text: "Project deleted successfully",
      submit: "Accept"
    },
    warning: {
      text: "There's been a problem. Please try again",
      submit: "Accept"
    }
  },
  delete_task: {
    success: {
      text: "Task  deleted successfully",
      submit: "Accept"
    },
    warning: {
      text: "There's been a problem. Please try again",
      submit: "Accept"
    }
  },
  download_boards: {
    error: {
      text:
        "There was an error downloading your pipeline. Try refresh the page. If the problem persists, please submit a ticket",
      submit: "Cerrar"
    }
  },
  download_contact: {
    error: {
      text:
        "There was an error downloading the contact. Try refresh the page. If the problem persists, please submit a ticket",
      submit: "Cerrar"
    }
  },
  download_contacts: {
    error: {
      text:
        "There was an error downloading your contacts. Try refresh the page. If the problem persists, please submit a ticket",
      submit: "Cerrar"
    }
  },
  download_template: {
    error: {
      text:
        "There was an error downloading your template. Try refresh the page. If the problem persists, please submit a ticket",
      submit: "Cerrar"
    }
  },
  download_templates: {
    error: {
      text:
        "There was an error downloading your templates. Try refresh the page. If the problem persists, please submit a ticket",
      submit: "Cerrar"
    }
  },
  download_opportunity: {
    error: {
      text:
        "There was an error downloading this opportunity. Try refresh the page. If the problem persists, please submit a ticket",
      submit: "Cerrar"
    }
  },
  download_opportunities: {
    error: {
      text:
        "There was an error downloading your opportunities. Try refresh the page. If the problem persists, please submit a ticket",
      submit: "Cerrar"
    }
  },
  download_project: {
    error: {
      text:
        "There was an error downloading this project. Try refresh the page. If the problem persists, please submit a ticket",
      submit: "Cerrar"
    }
  },
  download_projects: {
    error: {
      text:
        "There was an error downloading your projects. Try refresh the page. If the problem persists, please submit a ticket",
      submit: "Cerrar"
    }
  },
  edit_contact: {
    success: {
      text: "Contact edit correctly",
      submit: "Accept"
    },
    warning: {
      text: "There's been a problem. Please try again",
      submit: "Accept"
    }
  },
  edit_opportunity: {
    success: {
      text: "Opportunity edited correctly",
      submit: "Accept"
    },
    warning: {
      text: "There's been a problem. Please try again",
      submit: "Accept"
    }
  },
  edit_task: {
    success: {
      text: "Task edited correctly",
      submit: "Accept"
    },
    warning: {
      text: "There's been a problem. Please try again",
      submit: "Accept"
    }
  }
};

export { alerts };
