import React from "react";
import { Chip } from "react-library-sm";

const BillingSettingsTemplate = translation => {
  return {
    title: translation.settings.title,
    tabs: [
      {
        value: "series",
        label: translation.settings.tabs.series
      },
      {
        value: "payment_methods",
        label: translation.settings.tabs.payment_methods
      },
      {
        value: "billing_info",
        label: translation.settings.tabs.billing_info
      },
      {
        value: "certificate",
        label: translation.settings.tabs.certificate
      }
    ],
    series: {
      buttons: {
        add: translation.settings.series.buttons.add
      },
      dialogs: {
        add: {
          titles: {
            edit_serie:
              translation.settings.series.dialogs.add.titles.edit_serie,
            add_serie: translation.settings.series.dialogs.add.titles.add_serie
          },
          buttons: {
            save: translation.settings.series.dialogs.add.buttons.save,
            add: translation.settings.series.dialogs.add.buttons.add,
            cancel: translation.settings.series.dialogs.add.buttons.cancel
          },
          inputs: {
            name: translation.settings.series.dialogs.add.inputs.name,
            prefix: translation.settings.series.dialogs.add.inputs.prefix,
            sufix: translation.settings.series.dialogs.add.inputs.sufix,
            last_invoice:
              translation.settings.series.dialogs.add.inputs.last_invoice
          },
          selects: {
            type: {
              placeholder:
                translation.settings.series.dialogs.add.selects.type
                  .placeholder,
              items: [
                {
                  value: "regular",
                  label:
                    translation.settings.series.dialogs.add.selects.type.regular
                },
                {
                  value: "amending",
                  label:
                    translation.settings.series.dialogs.add.selects.type
                      .amending
                }
              ]
            }
          }
        },
        delete: {
          title: translation.settings.series.dialogs.delete.title,
          info: translation.settings.series.dialogs.delete.info,
          buttons: {
            delete: translation.settings.series.dialogs.delete.buttons.delete,
            cancel: translation.settings.series.dialogs.delete.buttons.cancel
          }
        }
      },
      datatable: {
        title: translation.settings.series.datatable.title,
        textLabels: translation.settings.series.datatable.textLabels,
        columns: [
          {
            label: "id",
            name: "_id",
            options: { display: false, filter: false }
          },
          {
            label: translation.settings.series.datatable.columns.name,
            name: "name"
          },
          {
            label: translation.settings.series.datatable.columns.type,
            name: "type",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation.settings.series.datatable.type[
                        tableMeta.rowData[2]
                      ]
                    }
                  />
                );
              }
            }
          },
          {
            label: translation.settings.series.datatable.columns.prefix,
            name: "prefix"
          },
          {
            label: translation.settings.series.datatable.columns.sufix,
            name: "sufix",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            label: translation.settings.series.datatable.columns.lastInvoice,
            name: "lastInvoice",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: true
            }
          }
        ]
      }
    },
    payment_methods: {
      datatable: {
        title: translation.settings.payment_methods.datatable.title,
        textLabels: translation.settings.payment_methods.datatable.textLabels,
        columns: [
          {
            label: translation.settings.payment_methods.datatable.columns._id,
            name: "_id",
            options: { display: false, filter: false }
          },
          {
            label: translation.settings.payment_methods.datatable.columns.name,
            name: "name"
          },
          {
            label: translation.settings.payment_methods.datatable.columns.type,
            name: "type",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) => (
                <Chip
                  label={
                    translation.settings.payment_methods.datatable.type[
                      tableMeta.rowData[2]
                    ]
                  }
                />
              )
            }
          },
          {
            label:
              translation.settings.payment_methods.datatable.columns
                .account_number,
            name: "account_number"
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: true
            }
          }
        ]
      },
      buttons: {
        add: translation.settings.payment_methods.buttons.add
      },
      dialogs: {
        add: {
          titles: {
            edit_payment_method:
              translation.settings.payment_methods.dialogs.add.titles
                .edit_payment_method,
            add_payment_method:
              translation.settings.payment_methods.dialogs.add.titles
                .add_payment_method
          },
          buttons: {
            save: translation.settings.payment_methods.dialogs.add.buttons.save,
            add: translation.settings.payment_methods.dialogs.add.buttons.add,
            cancel:
              translation.settings.payment_methods.dialogs.add.buttons.cancel
          },
          inputs: {
            name: translation.settings.payment_methods.dialogs.add.inputs.name,
            account_number:
              translation.settings.payment_methods.dialogs.add.inputs
                .account_number,
            message:
              translation.settings.payment_methods.dialogs.add.inputs.message
          },
          selects: {
            type: {
              placeholder:
                translation.settings.payment_methods.dialogs.add.selects.type
                  .placeholder,
              items: [
                {
                  value: "bank",
                  label:
                    translation.settings.payment_methods.dialogs.add.selects
                      .type.bank
                },
                {
                  value: "cash",
                  label:
                    translation.settings.payment_methods.dialogs.add.selects
                      .type.cash
                },
                {
                  value: "stripe",
                  label:
                    translation.settings.payment_methods.dialogs.add.selects
                      .type.stripe
                }
              ]
            }
          }
        },
        delete: {
          title: translation.settings.payment_methods.dialogs.delete.title,
          info: translation.settings.payment_methods.dialogs.delete.info,
          buttons: {
            delete:
              translation.settings.payment_methods.dialogs.delete.buttons
                .delete,
            cancel:
              translation.settings.payment_methods.dialogs.delete.buttons.cancel
          }
        }
      }
    },

    billing_info: {
      inputs: {
        legal_entity: translation.settings.billing_info.inputs.legal_entity,
        name: translation.settings.billing_info.inputs.name,
        vat_number: translation.settings.billing_info.inputs.vat_number,
        address: translation.settings.billing_info.inputs.address,
        town: translation.settings.billing_info.inputs.town,
        zipcode: translation.settings.billing_info.inputs.zipcode,
        province: translation.settings.billing_info.inputs.province
      },
      buttons: {
        save: translation.settings.billing_info.buttons.save,
        add: translation.settings.billing_info.buttons.add,
        cancel: translation.settings.billing_info.buttons.cancel
      }
    }
  };
};

export { BillingSettingsTemplate };
