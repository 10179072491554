import React, { Component } from "react";
import InvoicesServices from "services/franchise-billing/invoices.services";
import {
  FranchiseInvoicesCentral as InvoicesCentral,
  Grid
} from "react-library-sm";
import UserContext from "providers/UserProvider";

class SubscriptionsContainer extends Component {
  constructor(props) {
    super(props);
    this.service = new InvoicesServices();
    this.state = {
      invoices: [],
      invoice: null
    };
  }

  componentDidMount() {
    /* ---------- LLAMADA API BBDD ---------- */
    this.service
      .getSubscriptionInvoices(this.context.franchise)
      .then(response => {
        // console.log("getSubscriptionInvoices response", response);
        this.setState({ invoices: response.data });
      })
      .catch(err => {
        console.error("err get invoices", err.response);
      });
  }

  getInvoice = id => {
    // console.log("getInvoice id", id);

    return this.service.getSubscriptionInvoice(id);
  };

  static contextType = UserContext;

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px"
        }}
      >
        <Grid item xs={12} key={this.state.invoices.length}>
          <InvoicesCentral
            invoices={this.state.invoices}
            // invoice_pdf={this.state.invoice}
            getInvoice={this.getInvoice}
            language={this.context.language.value}
            translation={
              this.context.translation.templates
                ? this.context.translation.templates.subscriptions
                : null
            }
          />
        </Grid>
      </Grid>
    );
  }
}

export default SubscriptionsContainer;
