import React from "react";
import { Chip, numeral } from "react-library-sm";
import moment from "moment";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const InvoicesTemplate = (translation) => {
  return {
    title: translation.invoices.list.title,
    buttons: {
      add_invoice: translation.invoices.list.buttons.add_invoice,
    },
    compare: translation.invoices.list.compare,
    dashboard: {
      total: translation.invoices.list.dashboard.total,
      month: translation.invoices.list.dashboard.month,
      last_month: translation.invoices.list.dashboard.last_month,
      events: translation.invoices.list.dashboard.events,
      academies: translation.invoices.list.dashboard.academies,
      campus: translation.invoices.list.dashboard.campus,
      other: translation.invoices.list.dashboard.other,
      paid: translation.invoices.list.dashboard.paid,
      pending: translation.invoices.list.dashboard.pending,
      overdue: translation.invoices.list.dashboard.overdue,
    },
    chart: {
      events: translation.invoices.list.chart.events,
      academies: translation.invoices.list.chart.academies,
      campus: translation.invoices.list.chart.campus,
      others: translation.invoices.list.chart.others,
    },
    datatable: {
      invoices: {
        title: translation.invoices.list.datatables.invoices.title,
        textLabels: translation.invoices.list.datatables.invoices.textLabels,
        columns: [
          {
            label: translation.invoices.list.datatables.invoices.columns._id,
            name: "_id",
            options: { display: false, filter: false },
          },
          {
            label: "Date",
            name: "date",
            options: {
              filter: true,
              filterType: "custom",
              customFilterListRender: (v) => {
                // console.log(v);
                const values = v.map((_v) => moment(_v).format("DD-MM-YYYY"));
                if (v[0] && v[1]) {
                  return `${translation.contacts.single.datatables.opportunities.date.from} ${values[0]}, ${translation.contacts.single.datatables.opportunities.date.to} ${values[1]}`;
                } else if (values[0]) {
                  return `${translation.contacts.single.datatables.opportunities.date.from} ${values[0]}`;
                } else if (values[1]) {
                  return `${translation.contacts.single.datatables.opportunities.date.to} ${values[1]}`;
                }
                return false;
              },
              filterOptions: {
                names: [],
                logic(date, filters) {
                  const momFilt = filters.map((filt) =>
                    moment(filt, "DD-MM-YYYY")
                  );
                  const momDate = moment(date, "DD-MM-YYYY");
                  let show = true;

                  if (filters[0] && filters[1]) {
                    show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                  } else if (filters[0]) {
                    show = momDate.isSameOrAfter(momFilt[0]);
                  } else if (filters[1]) {
                    show = momDate.isSameOrBefore(momFilt[1]);
                  }
                  return !show;
                },
                display: (filterList, onChange, index, column) => {
                  return [
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                      <KeyboardDatePicker
                        ampm={false}
                        clearable
                        label={
                          translation.opportunities.list.views.datatable.date
                            .from
                        }
                        format="DD/MM/YYYY"
                        value={filterList[index][0] || null}
                        onChange={(event) => {
                          filterList[index][0] = moment(event, "DD/MM/YYYY");
                          onChange(filterList[index], index, column);
                        }}
                        style={{ marginRight: "5%" }}
                      />
                      <KeyboardDatePicker
                        ampm={false}
                        clearable
                        label={
                          translation.opportunities.list.views.datatable.date.to
                        }
                        format="DD/MM/YYYYY"
                        value={filterList[index][1] || null}
                        onChange={(event) => {
                          filterList[index][1] = moment(event, "DD/MM/YYYY");
                          onChange(filterList[index], index, column);
                        }}
                      />
                    </MuiPickersUtilsProvider>,
                  ];
                },
              },
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[1]).format("DD/MM/YYYY"),
            },
          },
          {
            label: "Num",
            name: "docnumber",
            options: { filter: false },
          },
          {
            label: "Due date",
            name: "due_date",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[3]).format("DD/MM/YYYY"),
            },
          },
          {
            label: "Client",
            name: "client.billingInfo.name",
            options: { filter: false },
          },
          {
            label: "Status",
            name: "status",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) => `Status ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.invoices.list.datatables.invoices.status
                ),
                logic(status, filterVal) {
                  const show =
                    filterVal.indexOf(
                      translation.invoices.list.datatables.invoices.status[
                        status
                      ]
                    ) >= 0;
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation.invoices.list.datatables.invoices.status[
                        tableMeta.rowData[5]
                      ]
                    }
                    style={
                      tableMeta.rowData[5] === "pending"
                        ? {
                            borderRadius: "5px",
                            background: "transparent",
                            border: "1px solid #ffe082",
                            color: "#ffe082",
                          }
                        : tableMeta.rowData[5] === "uploaded_to_face"
                        ? {
                            borderRadius: "5px",
                            border: "1px solid #77ffb5",
                            color: "#77ffb5",
                            background: "transparent",
                          }
                        : tableMeta.rowData[5] === "paid"
                        ? {
                            borderRadius: "5px",
                            border: "1px solid #77ffb5",
                            color: "#77ffb5",
                            background: "transparent",
                          }
                        : tableMeta.rowData[5] === "payment_failed" ||
                          tableMeta.rowData[5] === "overdue"
                        ? {
                            borderRadius: "5px",
                            border: "1px solid #E95A58",
                            color: "#fff",
                            background: "transparent",
                          }
                        : tableMeta.rowData[5] === "draft"
                        ? {
                            borderRadius: "5px",
                            border: "1px solid #999",
                            color: "#fff",
                            background: "transparent",
                          }
                        : tableMeta.rowData[5] === "amended"
                        ? {
                            borderRadius: "5px",
                            border: "1px solid #28e2ab",
                            color: "#28e2ab",
                            background: "transparent",
                          }
                        : {
                            borderRadius: "5px",
                            border: "1px solid #80b9d6",
                            color: "#80b9d6",
                            background: "transparent",
                          }
                    }
                  />
                );
              },
            },
          },
          {
            label: "Subtotal",
            name: "subtotal",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[6]).format("0,0[.]00 $"),
            },
          },
          {
            label: "Tax",
            name: "tax",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[7]).format("0,0[.]00 $"),
            },
          },
          {
            label: "Total",
            name: "total",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[8]).format("0,0[.]00 $"),
            },
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: true,
            },
          },
        ],
        popovers: {
          edit: translation.invoices.list.datatables.invoices.popovers.edit,
          view: translation.invoices.list.datatables.invoices.popovers.view,
          delete: translation.invoices.list.datatables.invoices.popovers.delete,
          duplicate:
            translation.invoices.list.datatables.invoices.popovers.duplicate,
          amend: translation.invoices.list.datatables.invoices.popovers.amend,
          download:
            translation.invoices.list.datatables.invoices.popovers.download,
          download_face:
            translation.invoices.list.datatables.invoices.popovers
              .download_face,
        },
      },
    },
    dialogs: {
      view_invoice: {
        title: translation.invoices.list.dialogs.view_invoice.title,
        docnumber: translation.invoices.list.dialogs.view_invoice.docnumber,
        date: translation.invoices.list.dialogs.view_invoice.date,
        due_date: translation.invoices.list.dialogs.view_invoice.due_date,
        payment_date:
          translation.invoices.list.dialogs.view_invoice.payment_date,
        client: translation.invoices.list.dialogs.view_invoice.client,
        status: translation.invoices.list.dialogs.view_invoice.status,
        subtotal: translation.invoices.list.dialogs.view_invoice.subtotal,
        tax: translation.invoices.list.dialogs.view_invoice.tax,
        total: translation.invoices.list.dialogs.view_invoice.total,
        selects: {
          status: {
            placeholder:
              translation.invoices.list.dialogs.view_invoice.selects.status
                .placeholder,
            items: [
              {
                value: "pending",
                label:
                  translation.invoices.list.dialogs.view_invoice.selects.status
                    .pending,
              },
              {
                value: "uploaded_to_face",
                label:
                  translation.invoices.list.dialogs.view_invoice.selects.status
                    .uploaded_to_face,
              },
              {
                value: "overdue",
                label:
                  translation.invoices.list.dialogs.view_invoice.selects.status
                    .overdue,
              },
              {
                value: "paid",
                label:
                  translation.invoices.list.dialogs.view_invoice.selects.status
                    .paid,
              },
              {
                value: "amended",
                label:
                  translation.invoices.list.dialogs.view_invoice.selects.status
                    .amended,
              },
              {
                value: "amending",
                label:
                  translation.invoices.list.dialogs.view_invoice.selects.status
                    .amending,
              },
            ],
          },
        },
        inputs: {
          date: translation.invoices.list.dialogs.view_invoice.inputs.date,
        },
        buttons: {
          save: translation.invoices.list.dialogs.view_invoice.buttons.save,
        },
        pdf: {
          message: translation.invoices.list.dialogs.view_invoice.pdf.message,
          document: {
            docnumber:
              translation.invoices.list.dialogs.view_invoice.pdf.document
                .docnumber,
            date: translation.invoices.list.dialogs.view_invoice.pdf.document
              .date,
            due_date:
              translation.invoices.list.dialogs.view_invoice.pdf.document
                .due_date,
            issuer:
              translation.invoices.list.dialogs.view_invoice.pdf.document
                .issuer,
            receiver:
              translation.invoices.list.dialogs.view_invoice.pdf.document
                .receiver,
            subtotal:
              translation.invoices.list.dialogs.view_invoice.pdf.document
                .subtotal,
            tax: translation.invoices.list.dialogs.view_invoice.pdf.document
              .tax,
            total:
              translation.invoices.list.dialogs.view_invoice.pdf.document.total,
            method:
              translation.invoices.list.dialogs.view_invoice.pdf.document
                .method,
            items: {
              name: translation.invoices.list.dialogs.view_invoice.pdf.document
                .items.name,

              quantity:
                translation.invoices.list.dialogs.view_invoice.pdf.document
                  .items.quantity,
              price:
                translation.invoices.list.dialogs.view_invoice.pdf.document
                  .items.price,
              subtotal:
                translation.invoices.list.dialogs.view_invoice.pdf.document
                  .items.subtotal,
              tax: {
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .items.tax.label,
                amount:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .items.tax.amount,
              },
            },
            taxes: [
              {
                percentage: 0.077,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.iva_7_7,
                value: "iva_7_7",
              },
              {
                percentage: 0.081,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.iva_8_1,
                value: "iva_8_1",
              },
              {
                percentage: 0.16,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.iva_16,
                value: "iva_16",
              },
              {
                percentage: 0.17,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.iva_17,
                value: "iva_17",
              },
              {
                percentage: 0.18,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.iva_18,
                value: "iva_18",
              },
              {
                percentage: 0.19,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.iva_19,
                value: "iva_19",
              },
              {
                percentage: 0.2,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.iva_20,
                value: "iva_20",
              },
              {
                percentage: 0.21,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.iva_21,
                value: "iva_21",
              },
              {
                percentage: 0.1,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.iva_10,
                value: "iva_10",
              },
              {
                percentage: 0,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.no_iva,
                value: "no_iva",
              },
              {
                percentage: 0,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.no_igic,
                value: "no_igic",
              },
              {
                percentage: 0.07,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.igic_7,
                value: "igic_7",
              },
              {
                percentage: 0.22,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.iva_22,
                value: "iva_22",
              },
              {
                percentage: 0.23,
                label:
                  translation.invoices.list.dialogs.view_invoice.pdf.document
                    .taxes.iva_23,
                value: "iva_23",
              },
            ],
          },
        },
      },
      delete_invoice: {
        title: translation.invoices.list.dialogs.delete_invoice.title,
        info: translation.invoices.list.dialogs.delete_invoice.info,
        buttons: {
          delete:
            translation.invoices.list.dialogs.delete_invoice.buttons.delete,
          cancel:
            translation.invoices.list.dialogs.delete_invoice.buttons.cancel,
        },
      },
    },
    alerts: {
      download_invoices: {
        error: {
          text: translation.invoices.list.alerts.download_invoices.error.text,
          submit:
            translation.invoices.list.alerts.download_invoices.error.submit,
        },
      },
      get_pdf: {
        error: {
          text: translation.invoices.list.alerts.get_pdf.error.text,
          submit: translation.invoices.list.alerts.get_pdf.error.submit,
        },
      },
      edit_status: {
        error: {
          text: translation.invoices.list.alerts.edit_status.error.text,
          submit: translation.invoices.list.alerts.edit_status.error.submit,
        },
      },
      duplicate: {
        error: {
          text: translation.invoices.list.alerts.duplicate.error.text,
          submit: translation.invoices.list.alerts.duplicate.error.submit,
        },
      },
      amend: {
        success: {
          text: translation.invoices.list.alerts.amend.success.text,
          submit: translation.invoices.list.alerts.amend.success.submit,
        },
        error: {
          text: translation.invoices.list.alerts.amend.error.text,
          submit: translation.invoices.list.alerts.amend.error.submit,
        },
      },
      delete: {
        success: {
          text: translation.invoices.list.alerts.delete.success.text,
          submit: translation.invoices.list.alerts.delete.success.submit,
        },
        error: {
          text: translation.invoices.list.alerts.delete.error.text,
          submit: translation.invoices.list.alerts.delete.error.submit,
        },
      },
      face: {
        warning: {
          text: translation.invoices.list.alerts.face.error.text,
          submit: translation.invoices.list.alerts.face.error.submit,
        },
      },
    },
  };
};
export { InvoicesTemplate };
