import Axios from "core/axios";

class SettingsServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_BILLING;
    // console.log("this.api_url ", this.api_url);
    this.service = new Axios(this.api_url);
  }

  getSettings = (franchiseId) =>
    this.service.get(`/settings?franchiseId=${franchiseId}`);

  /* Payment methods */
  postPaymentMethod = (data, franchiseId) => {
    // console.log(data);
    return this.service.post(
      `/settings/payment?franchiseId=${franchiseId}`,
      data
    );
  };
  putPaymentMethod = (data, franchiseId) =>
    this.service.put(`/settings/payment/?franchiseId=${franchiseId}`, data);
  deletePaymentMethod = (id, franchiseId) =>
    this.service.delete(`/settings/payment/${id}?franchiseId=${franchiseId}`);

  /* Series  */
  postSerie = (data, franchiseId) =>
    this.service.post(`/settings/series?franchiseId=${franchiseId}`, data);
  editSerie = (data, franchiseId) =>
    this.service.put(`/settings/series?franchiseId=${franchiseId}`, data);
  deleteSerie = (id, franchiseId) =>
    this.service.delete(`/settings/series/${id}?franchiseId=${franchiseId}`);

  /* Info  */
  editInfo = (data, franchiseId) =>
    this.service.put(`/settings/info?franchiseId=${franchiseId}`, data);
  addCertificateURL = (certificate, franchiseId) =>
    this.service.put(
      `/settings/certificate?franchiseId=${franchiseId}`,
      certificate
    );
}

export default SettingsServices;
