import React from "react";
import { Chip, Typography, numeral } from "react-library-sm";
import moment from "moment";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import { Link } from "react-router-dom";
import { options } from "assets/mui-datatables/options-default";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const ContactTemplate = (translation) => {
  let popovers;
  const getPopovers = (lang) => {
    popovers =
      require(`assets/languages/i18n/translations/${lang}/popovers`).popovers;
  };

  getPopovers(localStorage.getItem("sportmadness-manager-lang"));
  return {
    alerts: {
      create_contact: {
        success: {
          text: translation.alerts.create_contact.success.text,
          submit: translation.alerts.create_contact.success.submit,
        },
        warning: {
          text: translation.alerts.create_contact.warning.text,
          submit: translation.alerts.create_contact.warning.submit,
        },
      },
      create_opportunity: {
        success: {
          text: translation.alerts.create_opportunity.success.text,
          submit: translation.alerts.create_opportunity.success.submit,
        },
        warning: {
          text: translation.alerts.create_opportunity.warning.text,
          submit: translation.alerts.create_opportunity.warning.submit,
        },
      },
      delete_contact: {
        success: {
          text: translation.alerts.delete_contact.success.text,
          submit: translation.alerts.delete_contact.success.submit,
        },
        warning: {
          text: translation.alerts.delete_contact.warning.text,
          submit: translation.alerts.delete_contact.warning.submit,
        },
      },
      delete_opportunity: {
        success: {
          text: translation.alerts.delete_opportunity.success.text,
          submit: translation.alerts.delete_opportunity.success.submit,
        },
        warning: {
          text: translation.alerts.delete_opportunity.warning.text,
          submit: translation.alerts.delete_opportunity.warning.submit,
        },
      },
      delete_task: {
        success: {
          text: translation.alerts.delete_task.success.text,
          submit: translation.alerts.delete_task.success.submit,
        },
        warning: {
          text: translation.alerts.delete_task.warning.text,
          submit: translation.alerts.delete_task.warning.submit,
        },
      },
      download_contact: {
        error: {
          text: translation.alerts.download_contact.error.text,
          submit: translation.alerts.download_contact.error.submit,
        },
      },
      download_boards: {
        error: {
          text: translation.alerts.download_boards.error.text,
          submit: translation.alerts.download_boards.error.submit,
        },
      },
      edit_contact: {
        success: {
          text: translation.alerts.edit_contact.success.text,
          submit: translation.alerts.edit_contact.success.submit,
        },
        warning: {
          text: translation.alerts.edit_contact.warning.text,
          submit: translation.alerts.edit_contact.warning.submit,
        },
      },
    },
    buttons: {
      add_opportunity: translation.contacts.single.buttons.add_opportunity,
      edit_contact: translation.contacts.single.buttons.edit_contact,
      delete_contact: translation.contacts.single.buttons.delete_contact,
    },
    contact_type: [
      { value: "person", label: translation.contacts.list.contact_type.person },
      { value: "client", label: translation.contacts.list.contact_type.client },
      { value: "user", label: translation.contacts.list.contact_type.user },
    ],
    client_type: [
      {
        value: "school",
        label: translation.contacts.single.client_type.school,
      },
      {
        value: "publicAdmin",
        label: translation.contacts.single.client_type.public_admin,
      },
      {
        value: "company",
        label: translation.contacts.single.client_type.company,
      },
      {
        value: "individual",
        label: translation.contacts.single.client_type.individual,
      },
      {
        value: "club",
        label: translation.contacts.single.client_type.club,
      },
    ],
    contact_info: {
      titles: {
        contact_city:
          translation.contacts.single.contact_info.titles.contact_city,
        contact_email:
          translation.contacts.single.contact_info.titles.contact_email,
        contact_phone:
          translation.contacts.single.contact_info.titles.contact_phone,
        contact_web:
          translation.contacts.single.contact_info.titles.contact_web,
        contact_type:
          translation.contacts.single.contact_info.titles.contact_type,
        contact_billing_info_name:
          translation.contacts.single.contact_info.titles
            .contact_billing_info_name,
      },
      people_of_contact: {
        titles: {
          people_of_contact:
            translation.contacts.single.contact_info.people_of_contact.titles
              .people_of_contact,
        },
        tooltips: {
          add_person_of_contact:
            translation.contacts.single.contact_info.people_of_contact.tooltips
              .add_person_of_contact,
          edit_person_of_contact:
            translation.contacts.single.contact_info.people_of_contact.tooltips
              .edit_person_of_contact,
          view_person_of_contact:
            translation.contacts.single.contact_info.people_of_contact.tooltips
              .view_person_of_contact,
          delete_person_of_contact:
            translation.contacts.single.contact_info.people_of_contact.tooltips
              .delete_person_of_contact,
        },
      },
    },
    boards: {
      opportunities_lane: translation.contacts.single.boards.opportunities_lane,
      projects_lane: translation.contacts.single.boards.projects_lane,
    },
    tabs: [
      {
        value: "invoices",
        label: translation.contacts.single.tabs.invoices,
      },
    ],
    datatables: {
      projects: {
        title: translation.contacts.single.datatables.projects.title,
        textLabels: translation.contacts.single.datatables.projects.textLabels,
        columns: [
          {
            label: translation.contacts.single.datatables.projects.columns.id,
            name: "_id",
            options: { display: false, filter: false },
          },
          {
            label: translation.contacts.single.datatables.projects.columns.name,
            name: "name",
            options: { filter: false },
          },
          {
            label: translation.contacts.single.datatables.projects.columns.date,
            name: "date",
            options: {
              filter: true,
              filterType: "custom",
              customFilterListRender: (v) => {
                const values = v.map((_v) =>
                  moment(_v).format("DD-MM-YYYY HH:mm")
                );
                if (v[0] && v[1]) {
                  return `${translation.contacts.single.datatables.projects.date.from} ${values[0]}, ${translation.contacts.single.datatables.projects.date.to} ${values[1]}`;
                } else if (values[0]) {
                  return `${translation.contacts.single.datatables.projects.date.from} ${values[0]}`;
                } else if (values[1]) {
                  return `${translation.contacts.single.datatables.projects.date.to} ${values[1]}`;
                }
                return false;
              },
              filterOptions: {
                names: [],
                logic(date, filters) {
                  const momFilt = filters.map((filt) =>
                    moment(filt, "DD-MM-YYYY HH:mm")
                  );
                  const momDate = moment(date, "DD-MM-YYYY HH:mm");
                  let show = true;

                  if (filters[0] && filters[1]) {
                    show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                  } else if (filters[0]) {
                    show = momDate.isSameOrAfter(momFilt[0]);
                  } else if (filters[1]) {
                    show = momDate.isSameOrBefore(momFilt[1]);
                  }
                  return !show;
                },
                display: (filterList, onChange, index, column) => {
                  // return <RangeDatePicker
                  //   time
                  //   translation={translation.opportunities.single.datatables.tasks.date ? translation.opportunities.single.datatables.tasks.date: null}
                  //   values={filterList}
                  //   onChange={onChange}
                  //   index={index}
                  //   column={column}
                  // />
                  return [
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.contacts.single.datatables.projects.date
                            .from
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][0] || null}
                        onChange={(event) => {
                          filterList[index][0] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                        style={{ marginRight: "5%" }}
                      />
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.contacts.single.datatables.projects.date
                            .to
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][1] || null}
                        onChange={(event) => {
                          filterList[index][1] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                      />
                    </MuiPickersUtilsProvider>,
                  ];
                },
              },
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[2]).format("DD/MM/YYYY"),
            },
          },
          {
            label:
              translation.contacts.single.datatables.projects.columns
                .service_type,
            name: "infoProject.type",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) =>
                `${translation.contacts.single.datatables.projects.columns.service_type} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.contacts.single.datatables.projects.service_type
                ),
                logic(service_type, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.projects
                        .service_type.event
                    ) >= 0 &&
                      service_type === "event") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.projects
                        .service_type.academy
                    ) >= 0 &&
                      service_type === "academy") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.projects
                        .service_type.campus
                    ) >= 0 &&
                      service_type === "campus");
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => (
                <Chip
                  label={
                    translation.contacts.single.datatables.projects
                      .service_type[tableMeta.rowData[3]]
                  }
                />
              ),
            },
          },
          {
            label:
              translation.contacts.single.datatables.projects.columns.client,
            name: "client",
            options: { filter: false },
          },
          {
            label:
              translation.contacts.single.datatables.projects.columns.status,
            name: "statusExecution",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) =>
                `${translation.contacts.single.datatables.projects.columns.status} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.contacts.single.datatables.projects.status
                ),
                logic(status, filterVal) {
                  const show =
                    filterVal.indexOf(
                      translation.contacts.single.datatables.projects.status[
                        status
                      ]
                    ) >= 0;
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation && tableMeta.rowData[5]
                        ? translation.contacts.single.datatables.projects
                            .status[tableMeta.rowData[5]]
                        : "plan"
                    }
                    // style={
                    // 	tableMeta.rowData[5] === 4 ? tableMeta.rowData[6] === 5 ? (
                    // 		{
                    // 			background: '#E95A58',
                    // 			color: '#616161'
                    // 		}
                    // 	) : (
                    // 			{
                    // 				background: '#77ffb5',
                    // 				color: '#616161'
                    // 			}
                    // 		) : (
                    // 			{
                    // 				background: '#ffe082',
                    // 				color: '#616161'
                    // 			}
                    // 		)
                    // }
                  />
                );
              },
            },
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Link
                    to={"/projects/" + tableMeta.rowData[0] + "/view"}
                    style={{ color: "#fff" }}
                  >
                    <RemoveRedEye />
                  </Link>
                );
              },
            },
          },
        ],
        options: options,
      },
      opportunities: {
        title: translation.contacts.single.datatables.opportunities.title,
        textLabels:
          translation.contacts.single.datatables.opportunities.textLabels,
        columns: [
          {
            label:
              translation.contacts.single.datatables.opportunities.columns.id,
            name: "_id",
            options: { display: false, filter: false },
          },
          {
            label:
              translation.contacts.single.datatables.opportunities.columns.name,
            name: "name",
            options: { filter: false },
          },
          {
            label:
              translation.contacts.single.datatables.opportunities.columns.date,
            name: "date",
            options: {
              filter: true,
              filterType: "custom",
              customFilterListRender: (v) => {
                const values = v.map((_v) =>
                  moment(_v).format("DD-MM-YYYY HH:mm")
                );
                if (v[0] && v[1]) {
                  return `${translation.contacts.single.datatables.opportunities.date.from} ${values[0]}, ${translation.contacts.single.datatables.opportunities.date.to} ${values[1]}`;
                } else if (values[0]) {
                  return `${translation.contacts.single.datatables.opportunities.date.from} ${values[0]}`;
                } else if (values[1]) {
                  return `${translation.contacts.single.datatables.opportunities.date.to} ${values[1]}`;
                }
                return false;
              },
              filterOptions: {
                names: [],
                logic(date, filters) {
                  const momFilt = filters.map((filt) =>
                    moment(filt, "DD-MM-YYYY HH:mm")
                  );
                  const momDate = moment(date, "DD-MM-YYYY HH:mm");
                  let show = true;

                  if (filters[0] && filters[1]) {
                    show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                  } else if (filters[0]) {
                    show = momDate.isSameOrAfter(momFilt[0]);
                  } else if (filters[1]) {
                    show = momDate.isSameOrBefore(momFilt[1]);
                  }
                  return !show;
                },
                display: (filterList, onChange, index, column) => {
                  // return <RangeDatePicker
                  //   time
                  //   translation={translation.opportunities.single.datatables.tasks.date ? translation.opportunities.single.datatables.tasks.date: null}
                  //   values={filterList}
                  //   onChange={onChange}
                  //   index={index}
                  //   column={column}
                  // />
                  return [
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.contacts.single.datatables.opportunities
                            .date.from
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][0] || null}
                        onChange={(event) => {
                          filterList[index][0] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                        style={{ marginRight: "5%" }}
                      />
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.contacts.single.datatables.opportunities
                            .date.to
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][1] || null}
                        onChange={(event) => {
                          filterList[index][1] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                      />
                    </MuiPickersUtilsProvider>,
                  ];
                },
              },
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[2]).format("DD/MM/YYYY"),
            },
          },
          {
            label:
              translation.contacts.single.datatables.opportunities.columns.type,
            name: "type",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) =>
                `${translation.contacts.single.datatables.opportunities.columns.type} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.contacts.single.datatables.opportunities.type
                ),
                logic(type, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.opportunities.type
                        .event
                    ) >= 0 &&
                      type === "event") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.opportunities.type
                        .academy
                    ) >= 0 &&
                      type === "academy") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.opportunities.type
                        .campus
                    ) >= 0 &&
                      type === "campus");
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => (
                <Chip
                  label={
                    translation.contacts.single.datatables.opportunities.type[
                      tableMeta.rowData[3]
                    ]
                  }
                />
              ),
            },
          },
          {
            label:
              translation.contacts.single.datatables.opportunities.columns
                .client,
            name: "client",
            options: {
              filter: false,
            },
          },
          {
            label:
              translation.contacts.single.datatables.opportunities.columns
                .budget,
            name: "budget",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[5]).format("0,0[.]00 $"),
            },
          },
          {
            label:
              translation.contacts.single.datatables.opportunities.columns
                .status,
            name: "statusSells",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) =>
                `${translation.contacts.single.datatables.opportunities.columns.status} ${v}`,
              filterOptions: {
                names:
                  translation.contacts.single.datatables.opportunities.status.map(
                    (lab) => lab.label
                  ),
                logic(status, filterVal) {
                  const show =
                    filterVal.indexOf(
                      translation.contacts.single.datatables.opportunities
                        .status[status].label
                    ) >= 0;
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation.contacts.single.datatables.opportunities
                        .status[tableMeta.rowData[6]].label
                    }
                    style={
                      tableMeta.rowData[6] === 4
                        ? tableMeta.rowData[6] === 5
                          ? {
                              background: "#E95A58",
                              color: "#616161",
                            }
                          : {
                              background: "#77ffb5",
                              color: "#616161",
                            }
                        : {
                            background: "#ffe082",
                            color: "#616161",
                          }
                    }
                  />
                );
              },
            },
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Link
                    to={"/pipeline/" + tableMeta.rowData[0] + "/view"}
                    style={{ color: "#fff" }}
                  >
                    <RemoveRedEye />
                  </Link>
                );
              },
            },
          },
        ],
        options: options,
      },
      tasks: {
        title: translation.contacts.single.datatables.tasks.title,
        textLabels: translation.contacts.single.datatables.tasks.textLabels,
        columns: [
          {
            label: translation.contacts.single.datatables.tasks.columns.id,
            name: "_id",
            options: { display: false, filter: false },
          },
          {
            label: translation.contacts.single.datatables.tasks.columns.subject,
            name: "subject",
            options: { filter: false },
          },
          {
            label: translation.contacts.single.datatables.tasks.columns.date,
            name: "date",
            options: {
              filter: true,
              filterType: "custom",
              customFilterListRender: (v) => {
                const values = v.map((_v) =>
                  moment(_v).format("DD-MM-YYYY HH:mm")
                );
                if (v[0] && v[1]) {
                  return `${translation.contacts.single.datatables.tasks.date.from} ${values[0]}, ${translation.contacts.single.datatables.tasks.date.to} ${values[1]}`;
                } else if (values[0]) {
                  return `${translation.contacts.single.datatables.tasks.date.from} ${values[0]}`;
                } else if (values[1]) {
                  return `${translation.contacts.single.datatables.tasks.date.to} ${values[1]}`;
                }
                return false;
              },
              filterOptions: {
                names: [],
                logic(date, filters) {
                  const momFilt = filters.map((filt) =>
                    moment(filt, "DD-MM-YYYY HH:mm")
                  );
                  const momDate = moment(date, "DD-MM-YYYY HH:mm");
                  let show = true;

                  if (filters[0] && filters[1]) {
                    show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                  } else if (filters[0]) {
                    show = momDate.isSameOrAfter(momFilt[0]);
                  } else if (filters[1]) {
                    show = momDate.isSameOrBefore(momFilt[1]);
                  }
                  return !show;
                },
                display: (filterList, onChange, index, column) => {
                  // return <RangeDatePicker
                  //   time
                  //   translation={translation.contacts.single.datatables.tasks.date ? translation.contacts.single.datatables.tasks.date: null}
                  //   values={filterList}
                  //   onChange={onChange}
                  //   index={index}
                  //   column={column}
                  // />
                  return [
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.contacts.single.datatables.tasks.date.from
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][0] || null}
                        onChange={(event) => {
                          filterList[index][0] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                        style={{ marginRight: "5%" }}
                      />
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.contacts.single.datatables.tasks.date.to
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][1] || null}
                        onChange={(event) => {
                          filterList[index][1] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                      />
                    </MuiPickersUtilsProvider>,
                  ];
                },
              },
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[2]).format("DD/MM/YYYY"),
            },
          },
          {
            label:
              translation.contacts.single.datatables.tasks.columns
                .person_of_contact,
            name: "person_of_contact",
            options: {
              filter: false,
            },
          },

          {
            label: translation.contacts.single.datatables.tasks.columns.type,
            name: "type",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) =>
                `${translation.contacts.single.datatables.tasks.columns.type} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.contacts.single.datatables.tasks.type
                ),
                logic(type, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks.type.call
                    ) >= 0 &&
                      type === "call") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks.type.email
                    ) >= 0 &&
                      type === "email") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks.type.meeting
                    ) >= 0 &&
                      type === "meeting") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks.type.visit
                    ) >= 0 &&
                      type === "visit");
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => (
                <Typography variant="body2" gutterBottom>
                  {
                    translation.contacts.single.datatables.tasks.type[
                      tableMeta.rowData[4]
                    ]
                  }
                </Typography>
              ),
            },
          },
          {
            label: translation.contacts.single.datatables.tasks.columns.status,
            name: "status",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) =>
                `${translation.contacts.single.datatables.tasks.columns.status} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.contacts.single.datatables.tasks.status
                ),
                logic(status, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks.status
                        .pending
                    ) >= 0 &&
                      status === "pending") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks.status.done
                    ) >= 0 &&
                      status === "done");
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => (
                <Chip
                  label={
                    translation.contacts.single.datatables.tasks.status[
                      tableMeta.rowData[5]
                    ]
                  }
                  style={
                    tableMeta.rowData[5] === "done"
                      ? {
                          background: "#77ffb5",
                          color: "#616161",
                        }
                      : {
                          background: "#ffe082",
                          color: "#616161",
                        }
                  }
                />
              ),
            },
          },
          {
            label:
              translation.contacts.single.datatables.tasks.columns
                .opportunity_status,
            name: "opportunity_status",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) =>
                `${translation.contacts.single.datatables.tasks.columns.opportunity_status} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.contacts.single.datatables.tasks
                    .opportunity_status
                ),
                logic(opportunity_status, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks
                        .opportunity_status.lead
                    ) >= 0 &&
                      opportunity_status === "lead") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks
                        .opportunity_status.offer
                    ) >= 0 &&
                      opportunity_status === "offer") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks
                        .opportunity_status.proposal
                    ) >= 0 &&
                      opportunity_status === "proposal") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks
                        .opportunity_status.hibernate
                    ) >= 0 &&
                      opportunity_status === "hibernate") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks
                        .opportunity_status.won
                    ) >= 0 &&
                      opportunity_status === "won") ||
                    (filterVal.indexOf(
                      translation.contacts.single.datatables.tasks
                        .opportunity_status.lost
                    ) >= 0 &&
                      opportunity_status === "lost");
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => (
                <Chip
                  label={
                    translation.contacts.single.datatables.tasks
                      .opportunity_status[tableMeta.rowData[6]]
                  }
                />
              ),
            },
          },
          {
            label: "",
            name: "options",
          },
        ],
        options: options,
      },
      invoices: {
        title: "Invoices",
        columns: [
          {
            label: "id",
            name: "_id",
            options: { display: false, filter: false },
          },
          {
            label:
              translation.invoices.subscriptions.datatables.invoices.columns
                .date,
            name: "date",
            options: {
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[1]).format("DD/MM/YYYY"),
            },
          },
          {
            label:
              translation.invoices.subscriptions.datatables.invoices.columns
                .docnumber,
            name: "docnumber",
          },
          {
            label:
              translation.invoices.subscriptions.datatables.invoices.columns
                .due_date,
            name: "due_date",
            options: {
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[3]).format("DD/MM/YYYY"),
            },
          },
          {
            label:
              translation.invoices.subscriptions.datatables.invoices.columns
                .status,
            name: "status",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) => `Status ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.invoices.subscriptions.datatables.invoices.status
                ),
                logic(status, filterVal) {
                  const show =
                    filterVal.indexOf(
                      translation.invoices.subscriptions.datatables.invoices
                        .status[status]
                    ) >= 0;
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation.invoices.subscriptions.datatables.invoices
                        .status[tableMeta.rowData[4]]
                    }
                    style={
                      tableMeta.rowData[4] === "pending"
                        ? {
                            borderRadius: "5px",
                            background: "transparent",
                            border: "1px solid #ffe082",
                            color: "#ffe082",
                          }
                        : tableMeta.rowData[4] === "paid"
                        ? {
                            borderRadius: "5px",
                            border: "1px solid #77ffb5",
                            color: "#77ffb5",
                            background: "transparent",
                          }
                        : tableMeta.rowData[4] === "payment_failed" ||
                          tableMeta.rowData[4] === "overdue"
                        ? {
                            borderRadius: "5px",
                            border: "1px solid #E95A58",
                            color: "#fff",
                            background: "transparent",
                          }
                        : {
                            borderRadius: "5px",
                            border: "1px solid #80b9d6",
                            color: "#80b9d6",
                            background: "transparent",
                          }
                    }
                  />
                );
              },
            },
          },
          {
            label:
              translation.contacts.single.datatables.invoices.columns.category,
            name: "category",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) => `Category ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.contacts.single.datatables.invoices.category
                ),
                logic(category, filterVal) {
                  // TODO
                  const show =
                    filterVal.indexOf(
                      translation.contacts.single.datatables.invoices.category[
                        category
                      ]
                    ) >= 0;
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation.contacts.single.datatables.invoices.category[
                        tableMeta.rowData[5]
                      ]
                    }
                  />
                );
              },
            },
          },
          {
            label: "Subtotal",
            name: "subtotal",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[6]).format("0,0[.]00 $"),
            },
          },
          {
            label: "Tax",
            name: "tax",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[7]).format("0,0[.]00 $"),
            },
          },
          {
            label: "Total",
            name: "total",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[8]).format("0,0[.]00 $"),
            },
          },
          {
            label: "",
            name: "options",
          },
        ],
      },
    },
    popovers: { tasks: popovers.contact.single.datatables.tasks },
    dialogs: {
      add_person_of_contact: {
        buttons: {
          add: translation.contacts.single.dialogs.add_person_of_contact.buttons
            .add,
          cancel:
            translation.contacts.single.dialogs.add_person_of_contact.buttons
              .cancel,
          save: translation.contacts.single.dialogs.add_person_of_contact
            .buttons.save,
        },
        titles: {
          add_person_of_contact:
            translation.contacts.single.dialogs.add_person_of_contact.titles
              .add_person_of_contact,
          edit_person_of_contact:
            translation.contacts.single.dialogs.add_person_of_contact.titles
              .edit_person_of_contact,
        },
        inputs: {
          person_name:
            translation.contacts.single.dialogs.add_person_of_contact.inputs
              .person_name,
          person_job:
            translation.contacts.single.dialogs.add_person_of_contact.inputs
              .person_job,
          person_email:
            translation.contacts.single.dialogs.add_person_of_contact.inputs
              .person_email,
          person_phone:
            translation.contacts.single.dialogs.add_person_of_contact.inputs
              .person_phone,
        },
      },
      add_opportunity: {
        titles: {
          add_opportunity:
            translation.contacts.single.dialogs.add_opportunity.titles
              .add_opportunity,
          edit_opportunity:
            translation.contacts.single.dialogs.add_opportunity.titles
              .edit_opportunity,
        },
        buttons: {
          add: translation.contacts.single.dialogs.add_opportunity.buttons.add,
          cancel:
            translation.contacts.single.dialogs.add_opportunity.buttons.cancel,
          save: translation.contacts.single.dialogs.add_opportunity.buttons
            .save,
        },
        inputs: {
          opportunity_name:
            translation.contacts.single.dialogs.add_opportunity.inputs
              .opportunity_name,
          opportunity_date:
            translation.contacts.single.dialogs.add_opportunity.inputs
              .opportunity_date,
          opportunity_estimated_value:
            translation.contacts.single.dialogs.add_opportunity.inputs
              .opportunity_estimated_value,
        },
        selects: {
          currency: {
            placeholder:
              translation.contacts.single.dialogs.add_opportunity.selects
                .currency.placeholder,
            items: [
              {
                value: "euro",
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .currency.eur,
              },
              {
                value: "dolar",
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .currency.usd,
              },
              {
                value: "sol",
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .currency.sol,
              },
              {
                value: "pound",
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .currency.gbp,
              },
            ],
          },
          opportunity_contact: {
            placeholder: "Contacto asociado",
          },
          opportunity_source: {
            placeholder:
              translation.contacts.single.dialogs.add_opportunity.selects
                .opportunity_source.placeholder,
            items: [
              {
                value: "own",
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_source.own,
              },
              {
                value: "product",
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_source.product,
              },
            ],
          },
          opportunity_status: {
            placeholder:
              translation.contacts.single.dialogs.add_opportunity.selects
                .opportunity_status.placeholder,
            items: [
              {
                value: 0,
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_status.lead.label,
                tooltip:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_status.lead.tooltip,
              },
              {
                value: 1,
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_status.proposal.label,
                tooltip:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_status.proposal.tooltip,
              },
              {
                value: 2,
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_status.offer.label,
                tooltip:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_status.offer.tooltip,
              },
              {
                value: 3,
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_status.hibernate.label,
                tooltip:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_status.hibernate.tooltip,
              },
            ],
          },
          opportunity_service_type: {
            placeholder:
              translation.contacts.single.dialogs.add_opportunity.selects
                .opportunity_service_type.placeholder,
            items: [
              {
                value: "event",
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_service_type.event,
              },
              {
                value: "campus",
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_service_type.campus,
              },
              {
                value: "academy",
                label:
                  translation.contacts.single.dialogs.add_opportunity.selects
                    .opportunity_service_type.academy,
              },
            ],
          },
        },
      },
      edit_contact: {
        titles: {
          edit_contact:
            translation.contacts.single.dialogs.edit_contact.titles
              .edit_contact,
        },
        buttons: {
          cancel:
            translation.contacts.single.dialogs.edit_contact.buttons.cancel,
          save: translation.contacts.single.dialogs.edit_contact.buttons.save,
        },
        inputs: {
          contact_name:
            translation.contacts.single.dialogs.edit_contact.inputs
              .contact_name,
        },
        forms: {
          client: {
            inputs: {
              contact_city:
                translation.contacts.single.dialogs.edit_contact.forms.client
                  .inputs.contact_city,
              contact_email:
                translation.contacts.single.dialogs.edit_contact.forms.client
                  .inputs.contact_email,
              contact_phone:
                translation.contacts.single.dialogs.edit_contact.forms.client
                  .inputs.contact_phone,
              contact_web:
                translation.contacts.single.dialogs.edit_contact.forms.client
                  .inputs.contact_web,
              contact_name:
                translation.contacts.single.dialogs.edit_contact.forms.client
                  .inputs.contact_name,
            },
            selects: {
              client_type: {
                placeholder:
                  translation.contacts.single.dialogs.edit_contact.forms.client
                    .selects.client_type.placeholder,
                items: [
                  {
                    value: "school",
                    label:
                      translation.contacts.single.dialogs.edit_contact.forms
                        .client.selects.client_type.school,
                  },
                  {
                    value: "publicAdmin",
                    label:
                      translation.contacts.single.dialogs.edit_contact.forms
                        .client.selects.client_type.public_admin,
                  },
                  {
                    value: "company",
                    label:
                      translation.contacts.single.dialogs.edit_contact.forms
                        .client.selects.client_type.company,
                  },
                ],
              },
            },
          },
          person_of_contact: {
            inputs: {
              person_name:
                translation.contacts.single.dialogs.edit_contact.forms
                  .person_of_contact.inputs.person_name,
              person_job:
                translation.contacts.single.dialogs.edit_contact.forms
                  .person_of_contact.inputs.person_job,
              person_email:
                translation.contacts.single.dialogs.edit_contact.forms
                  .person_of_contact.inputs.person_email,
              person_phone:
                translation.contacts.single.dialogs.edit_contact.forms
                  .person_of_contact.inputs.person_phone,
            },
            buttons: {
              add_person_of_contact:
                translation.contacts.single.dialogs.edit_contact.forms
                  .person_of_contact.buttons.add_person_of_contact,
            },
          },
          billing_info: {
            inputs: {
              name: translation.contacts.single.dialogs.edit_contact.forms
                .billing_info.inputs.name,
              address:
                translation.contacts.single.dialogs.edit_contact.forms
                  .billing_info.inputs.address,

              zipcode:
                translation.contacts.single.dialogs.edit_contact.forms
                  .billing_info.inputs.zipcode,
              town: translation.contacts.single.dialogs.edit_contact.forms
                .billing_info.inputs.town,
              province:
                translation.contacts.single.dialogs.edit_contact.forms
                  .billing_info.inputs.province,
              vat_number:
                translation.contacts.single.dialogs.edit_contact.forms
                  .billing_info.inputs.vat_number,
            },
          },
        },
        tabs_edit: [
          {
            value: "client",
            label: translation.contacts.single.dialogs.edit_contact.tabs.client,
          },
          {
            value: "billing",
            label:
              translation.contacts.single.dialogs.edit_contact.tabs.billing,
          },
          {
            value: "face",
            label: "Datos FACE",
          },
        ],
      },
      delete_contact: {
        titles: {
          delete_contact:
            translation.contacts.single.dialogs.delete_contact.titles
              .delete_contact,
        },
        info: translation.contacts.single.dialogs.delete_contact.info,
        buttons: {
          cancel:
            translation.contacts.single.dialogs.delete_contact.buttons.cancel,
          delete:
            translation.contacts.single.dialogs.delete_contact.buttons.delete,
        },
      },
      delete_task: {
        title:
          translation.contacts.single.dialogs.delete_task.titles.delete_task,
        info: translation.contacts.single.dialogs.delete_task.info,
        buttons: {
          delete:
            translation.contacts.single.dialogs.delete_task.buttons.delete,
          cancel:
            translation.contacts.single.dialogs.delete_task.buttons.cancel,
        },
      },
      delete_person_of_contact: {
        titles: {
          delete_person_of_contact:
            translation.contacts.single.dialogs.delete_person_of_contact.titles
              .delete_person_of_contact,
        },
        info: translation.contacts.single.dialogs.delete_person_of_contact.info,
        buttons: {
          cancel:
            translation.contacts.single.dialogs.delete_person_of_contact.buttons
              .cancel,
          delete:
            translation.contacts.single.dialogs.delete_person_of_contact.buttons
              .delete,
        },
      },
      add_task: {
        buttons: {
          add: translation.contacts.single.dialogs.add_task.buttons.add,
          save: translation.contacts.single.dialogs.add_task.buttons.save,
          cancel: translation.contacts.single.dialogs.add_task.buttons.cancel,
        },
        inputs: {
          task_date:
            translation.contacts.single.dialogs.add_task.inputs.task_date,
          task_details:
            translation.contacts.single.dialogs.add_task.inputs.task_details,
          task_subject:
            translation.contacts.single.dialogs.add_task.inputs.task_bubject,
        },
        selects: {
          task_type: {
            placeholder:
              translation.contacts.single.dialogs.add_task.selects.task_type
                .placeholder,
            items: [
              {
                value: "call",
                label:
                  translation.contacts.single.dialogs.add_task.selects.task_type
                    .call,
              },
              {
                value: "email",
                label:
                  translation.contacts.single.dialogs.add_task.selects.task_type
                    .email,
              },
              {
                value: "meeting",
                label:
                  translation.contacts.single.dialogs.add_task.selects.task_type
                    .meeting,
              },
              {
                value: "visit",
                label:
                  translation.contacts.single.dialogs.add_task.selects.task_type
                    .visit,
              },
            ],
          },
          task_status: {
            placeholder:
              translation.contacts.single.dialogs.add_task.selects.task_status
                .placeholder,
            items: [
              {
                value: "pending",
                label:
                  translation.contacts.single.dialogs.add_task.selects
                    .task_status.pending,
              },
              {
                value: "done",
                label:
                  translation.contacts.single.dialogs.add_task.selects
                    .task_status.done,
              },
            ],
          },
          task_opportunity_status: {
            placeholder:
              translation.contacts.single.dialogs.add_task.selects
                .task_opportunity_status.placeholder,
            items: [
              {
                value: "lead",
                label:
                  translation.contacts.single.dialogs.add_task.selects
                    .task_opportunity_status.lead.label,
                tooltip:
                  translation.contacts.single.dialogs.add_task.selects
                    .task_opportunity_status.lead.tooltip,
              },
              {
                value: "proposal",
                label:
                  translation.contacts.single.dialogs.add_task.selects
                    .task_opportunity_status.proposal.label,
                tooltip:
                  translation.contacts.single.dialogs.add_task.selects
                    .task_opportunity_status.proposal.tooltip,
              },
              {
                value: "offer",
                label:
                  translation.contacts.single.dialogs.add_task.selects
                    .task_opportunity_status.offer.label,
                tooltip:
                  translation.contacts.single.dialogs.add_task.selects
                    .task_opportunity_status.offer.tooltip,
              },
              {
                value: "hibernate",
                label:
                  translation.contacts.single.dialogs.add_task.selects
                    .task_opportunity_status.hibernate.label,
                tooltip:
                  translation.contacts.single.dialogs.add_task.selects
                    .task_opportunity_status.hibernate.tooltip,
              },
            ],
          },
          task_contact: {
            placeholder:
              translation.contacts.single.dialogs.add_task.selects.task_contact
                .placeholder,
          },
        },
      },
      view_invoice: {
        title: translation.contacts.single.dialogs.view_invoice.title,
        docnumber: translation.contacts.single.dialogs.view_invoice.docnumber,
        date: translation.contacts.single.dialogs.view_invoice.date,
        due_date: translation.contacts.single.dialogs.view_invoice.due_date,
        payment_date:
          translation.contacts.single.dialogs.view_invoice.payment_date,
        client: translation.contacts.single.dialogs.view_invoice.client,
        status: translation.contacts.single.dialogs.view_invoice.status,
        subtotal: translation.contacts.single.dialogs.view_invoice.subtotal,
        tax: translation.contacts.single.dialogs.view_invoice.tax,
        total: translation.contacts.single.dialogs.view_invoice.total,
        selects: {
          status: {
            placeholder:
              translation.contacts.single.dialogs.view_invoice.selects.status
                .placeholder,
            items: [
              {
                value: "pending",
                label:
                  translation.contacts.single.dialogs.view_invoice.selects
                    .status.pending,
              },
              {
                value: "overdue",
                label:
                  translation.contacts.single.dialogs.view_invoice.selects
                    .status.overdue,
              },
              {
                value: "paid",
                label:
                  translation.contacts.single.dialogs.view_invoice.selects
                    .status.paid,
              },
              {
                value: "amended",
                label:
                  translation.contacts.single.dialogs.view_invoice.selects
                    .status.amended,
              },
              {
                value: "amending",
                label:
                  translation.contacts.single.dialogs.view_invoice.selects
                    .status.amending,
              },
            ],
          },
        },
        inputs: {
          date: translation.contacts.single.dialogs.view_invoice.date,
        },
        buttons: {
          save: translation.contacts.single.dialogs.view_invoice.buttons.save,
        },
        pdf: {
          message: translation.contacts.single.dialogs.view_invoice.pdf.message,
          document: {
            docnumber:
              translation.contacts.single.dialogs.view_invoice.pdf.document
                .docnumber,
            date: translation.contacts.single.dialogs.view_invoice.pdf.document
              .date,
            due_date:
              translation.contacts.single.dialogs.view_invoice.pdf.document
                .due_date,
            issuer:
              translation.contacts.single.dialogs.view_invoice.pdf.document
                .issuer,
            receiver:
              translation.contacts.single.dialogs.view_invoice.pdf.document
                .receiver,
            subtotal:
              translation.contacts.single.dialogs.view_invoice.pdf.document
                .subtotal,
            tax: translation.contacts.single.dialogs.view_invoice.pdf.document
              .tax,
            total:
              translation.contacts.single.dialogs.view_invoice.pdf.document
                .total,
            method:
              translation.contacts.single.dialogs.view_invoice.pdf.document
                .method,
            items: {
              name: translation.contacts.single.dialogs.view_invoice.pdf
                .document.items.name,

              quantity:
                translation.contacts.single.dialogs.view_invoice.pdf.document
                  .items.quantity,
              price:
                translation.contacts.single.dialogs.view_invoice.pdf.document
                  .items.price,
              subtotal:
                translation.contacts.single.dialogs.view_invoice.pdf.document
                  .items.subtotal,
              tax: {
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .items.tax.label,
                amount:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .items.tax.amount,
              },
            },
            taxes: [
              {
                percentage: 0.077,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.iva_7_7,
                value: "iva_7_7",
              },
              {
                percentage: 0.081,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.iva_8_1,
                value: "iva_8_1",
              },
              {
                percentage: 0.16,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.iva_16,
                value: "iva_16",
              },
              {
                percentage: 0.17,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.iva_17,
                value: "iva_17",
              },
              {
                percentage: 0.18,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.iva_18,
                value: "iva_18",
              },
              {
                percentage: 0.19,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.iva_19,
                value: "iva_19",
              },
              {
                percentage: 0.2,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.iva_20,
                value: "iva_20",
              },
              {
                percentage: 0.21,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.iva_21,
                value: "iva_21",
              },
              {
                percentage: 0.23,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.iva_23,
                value: "iva_23",
              },
              {
                percentage: 0.1,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.iva_10,
                value: "iva_10",
              },
              {
                percentage: 0,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.no_iva,
                value: "no_iva",
              },
              {
                percentage: 0,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.no_igic,
                value: "no_igic",
              },
              {
                percentage: 0.07,
                label:
                  translation.contacts.single.dialogs.view_invoice.pdf.document
                    .taxes.igic_7,
                value: "igic_7",
              },
            ],
          },
        },
      },
    },
  };
};

export { ContactTemplate };
