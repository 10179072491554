//step 3 - creamos el archivo .js del que se podrán leer las traducciones para cada idioma

const flatten = require("flat");
const fs = require("fs");

const Airtable = require("airtable");
const base = new Airtable({
  apiKey:
    "patujgtVH6a7ORHa0.c308ae691dd88d49371cdb418645e963e53b3da9ec22f2b851e58597b2da9c9c",
}).base("appQ3abDRfi9wpJnw");

const createTranslationFile = (table, lang) => {
  console.log(lang);
  const newobj = {};
  base(table)
    .select({
      view: "All",
    })
    .eachPage(
      function page(records, fetchNextPage) {
        //leemos los registros de airtable para crear el archivo con
        //TODAS las cadenas de texto (sustituye al archivo anterior, no lo complementa con las nuevas traducciones)
        records.forEach((record) => {
          Object.keys(record.fields)
            .filter((field) => field.includes(lang))
            .map((a) => (newobj[record.fields.key] = record.fields[a]));
        });
        // console.log('newobj', newobj);
        fetchNextPage();
      },
      function done(err) {
        if (err) {
          console.error(err);
          return;
        } else {
          const obj = flatten.unflatten(newobj);
          console.log(obj);
          const buffer = Buffer.from(
            `const translation =${JSON.stringify(obj)}\n export{translation}`
          );

          fs.writeFile(
            `/Users/adriannaranjosanchez/Development/sportmadness/manager-franquicia/src/assets/languages/i18n/translations/${lang}/index.js`,
            buffer,
            "binary",
            (err) => {
              if (err) console.log(err);
              else console.log("File saved");
            }
          );
        }
      }
    );
};

const languages = ["de", "es", "it", "fr", "ca", "en", "eu", "pt"];
// const languages = [ 'zh-CN' ];
languages.forEach((lang) => {
  createTranslationFile("translation", lang);
});
