import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { WebCampusList } from "react-library-sm";
import WebCampusServices from "services/franchise-campus/web.campus.services";
import UserContext from "providers/UserProvider";

import ProjectsServices from "services/franchise-projects/projects.services";

class ManagerWebCampusList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campus: [],
      projects: [],
    };
    this.campusService = new WebCampusServices();
    this.projectService = new ProjectsServices();
  }

  static contextType = UserContext;

  componentDidMount() {
    this.campusService
      .getCampusList()
      .then((response) => {
        this.setState({ campus: response.data });
      })
      .catch((err) => {
        // console.log(err.response);
      });
  }

  handleSubmitAddCampus = (data) => {
    //TO DO CALL TO API AND ADD

    this.campusService
      .postCampusSingle({
        ...data,
        date: {
          ...data.date,
          start: data.date.start.format("DD/MM/YYYY"),
          end: data.date.end.format("DD/MM/YYYY"),
        },
      })
      .then((response) => {
        const campus = [...this.state.campus];
        campus.push(response.data);
        this.setState({ campus });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  handleDownloadProjects = () => {
    // return this.projectService
    //   .getProjects(this.context.franchise)
    //   .then((response) => {
    // this.setState({
    //   projects: [
    //     { _id: "5e728cfdc0d3f191d99efd56", name: "Campus Verano Madrid" },
    //   ],
    // });
    // return Promise.resolve();
    // });

    return this.projectService
      .getProjects(this.context.franchise)
      .then((response) => {
        this.setState({
          projects: response.data.map((project) => {
            return { _id: project._id, name: project.infoProject.name };
          }),
        });
        return Promise.resolve();
      });
  };

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <WebCampusList
          role="manager"
          handleSubmitAddCampus={this.handleSubmitAddCampus}
          handleDownloadProjects={this.handleDownloadProjects}
          projects={this.state.projects}
          campus={this.state.campus}
          translation={this.context.translation.templates.web.campus.list}
        />
      </Grid>
    );
  }
}

export { ManagerWebCampusList };
