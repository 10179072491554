import React, { Component } from "react";
import UserContext from "providers/UserProvider";
import { FranchiseAcademyCoursesList, Grid } from "react-library-sm";
import AcademyServices from "services/franchise-academy/academy.services";

class AcademyCoursesListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: null,
      alertAPI: false,
      alert: null,
    };
    this.academyService = new AcademyServices();
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };
  static contextType = UserContext;

  componentDidMount() {
    this.academyService
      .getCourses(this.context.language.value)
      .then((response) => {
        // console.log("response", response.data);
        let courses = response.data.map((course) => {
          let languages = [];

          let dataLanguage = { ...course.languages };
          return {
            ...course,
            name: dataLanguage.name,
            description: dataLanguage.description,
            languages: languages,
          };
        });
        // console.log("final courses", courses);
        this.setState({ courses });
      })
      .catch((err) => console.log(err));
  }

  render() {
    // let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {alert}
        <FranchiseAcademyCoursesList
          courses={this.state.courses}
          translation={this.context.translation.templates.courses}
        />
      </Grid>
    );
  }
}

export default AcademyCoursesListComponent;
