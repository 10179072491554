import { Storage, Auth } from "aws-amplify";

export async function s3Upload(file) {
  return Auth.currentSession().then(async (response) => {
    // console.log("dentro llamada s3", response);
    let info = await Auth.currentUserInfo();
    // console.log("info user", info);
    const filename = `${Date.now()}-${file.name}`;

    Storage.configure({
      bucket: process.env.REACT_APP_BUCKET,
      level: "public",
      region: process.env.REACT_APP_REGION,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    });

    const stored = await Storage.put(info.id + "/" + filename, file, {
      contentType: file.type,
    });

    return `${stored.key}`;
  });
}

export async function s3GetObject(key) {
  return Auth.currentSession().then(async (response) => {
    Storage.configure({
      bucket: process.env.REACT_APP_BUCKET,
      // level: "protected",
      region: process.env.REACT_APP_REGION,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    });

    // console.log("key", key.split("/")[key.split("/").length - 1]);

    try {
      let info = await Auth.currentCredentials();
      console.log("info user", info);
      const stored = await Storage.get(
        // key.split("/")[key.split("/").length - 1]
        key
        // { level: "protected", identityId: info.id }
      );
      return stored;
    } catch (error) {
      // console.log("Error", error);
    }
  });
}
