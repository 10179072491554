const flatten = require("flat");
const _ = require("lodash");
// const fs = require('fs');
// 1. Leer es/index.js
/* Antes de lanzar este archivo, copiar traducción es/index.js en es/es.js con module.exports */
const translation = require("../es/es");

// 2. flat object
const flatObj = flatten(translation);
// console.log(Object.keys(flatObj));

// patujgtVH6a7ORHa0.c308ae691dd88d49371cdb418645e963e53b3da9ec22f2b851e58597b2da9c9c;
// 3. Set up airtable config. ApiKey and baseId can be found in Airtable (Help>Api Documentation)
const Airtable = require("airtable");
const base = new Airtable({
  apiKey:
    "patujgtVH6a7ORHa0.c308ae691dd88d49371cdb418645e963e53b3da9ec22f2b851e58597b2da9c9c",
}).base("appQ3abDRfi9wpJnw");

//useful if we want to know how many characters are we translating to estimate Google API consumption.
// const countCharacters = () => {
// 	let count = 0;
// 	Object.values(flatObj).forEach((record) => (count += record.length));
// 	// console.log(count);
// };
// countCharacters();

const getRecords = (table, query, view) => {
  // Consultamos todos los registros de Airtable para poder traducir solo las cadenas de texto que hayamos incluido nuevas
  let airtableRecords = [];

  return new Promise((resolve, reject) => {
    base(table)
      .select({
        filterByFormula: query || "",
        view: view || "All",
      })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            airtableRecords.push(record.get("key"));
          });
          fetchNextPage();
        },
        (err) => {
          if (err) {
            reject(err);
            console.error(err);
            return;
          }
          resolve(airtableRecords);
        }
      );
  });
};

const postRecords = (table, obj) => {
  // getRecords(table, "", "All").then(data => console.log(data));
  const data = [];

  for (let [key, value] of Object.entries(obj)) {
    data.push({
      fields: {
        key,
        es: value,
        L1: key.split(".")[0],
        L2: key.split(".")[1],
        L3: key.split(".")[2],
      },
    });
  }

  let createdRecords = 0;
  let slicedData = [];
  //Necesitamos dividir la información para no superar el número máximo de llamadas que permite la API de Airtable
  const interval = setInterval(() => {
    slicedData = data.slice(createdRecords, createdRecords + 10);
    if (createdRecords <= data.length) {
      base(table).create(slicedData, (err, records) => {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach((record) => console.log(record.get("key")));
      });
      createdRecords += 10;
    } else clearInterval(interval);
  }, 2000);
};

// postRecords("translation", flatObj);
getRecords("translation").then((data) => {
  console.log("project", Object.keys(flatObj).length);
  console.log("airtable", data.length);
  const newStrings = _.difference(Object.keys(flatObj), data); //nuevas cadenas de texto
  const newObj = {};
  newStrings.forEach((elm) => {
    // console.log("elm", elm);
    newObj[elm] = flatObj[elm];
  });

  console.log(newStrings);
  console.log(newObj);
  postRecords("translation", newObj); //incluimos en Airtable solo los textos nuevos
});
