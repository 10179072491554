import React, { Component } from "react";
import SettingsServices from "services/franchise-billing/settings.services";
import UserContext from "providers/UserProvider";

import { BillingSettings, Grid } from "react-library-sm";

class BillingSettingsComponent extends Component {
  constructor(props) {
    super(props);
    this.settingsService = new SettingsServices();
    this.state = {
      series: [],
      payment_methods: [],
      billing_info: {},
      certificate: null
    };
  }
  static contextType = UserContext;

  componentDidMount() {
    this.settingsService
      .getSettings(this.context.franchise)
      .then(response => {
        // console.log("getSettings response", response);
        this.setState({
          series: response.data.billing.series,
          payment_methods: response.data.billing.payment_methods,
          billing_info: response.data.billingInfo,
          certificate: response.data.billingInfo.certificate
        });
      })
      .catch(err => console.log(err));
  }

  /* Payment Methods */
  createPaymentMethod = values => {
    // console.log("values create pm", values);
    this.settingsService
      .postPaymentMethod(values, this.context.franchise)
      .then(
        response =>
          response.status === 200 &&
          this.setState({ payment_methods: response.data })
      )
      .catch(err => console.log(err));
  };

  editPaymentMethod = values => {
    this.settingsService
      .putPaymentMethod(values, this.context.franchise)
      .then(response => {
        let payment_methods = [...response.data];
        this.setState({ payment_methods });
      });
  };

  deletePaymentMethod = selected => {
    // console.log("deletePaymentMethod selected", selected);
    this.settingsService
      .deletePaymentMethod(selected._id, this.context.franchise)
      .then(response => {
        // console.log("response delete payment method", response.data);
        if (response.status === 200) {
          let payment_methods = [...this.state.payment_methods];
          payment_methods.some((method, index) => {
            if (method._id === selected._id) {
              payment_methods.splice(index, 1);
              return true;
            } else return false;
          });
          this.setState({ payment_methods });
        }
      });
  };

  createSerie = values => {
    this.settingsService
      .postSerie(values, this.context.franchise)
      .then(
        response =>
          response.status === 200 && this.setState({ series: response.data })
      )
      .catch(err => console.log(err));
  };

  editSerie = values => {
    this.settingsService
      .editSerie(values, this.context.franchise)
      .then(
        response =>
          response.status === 200 && this.setState({ series: response.data })
      )
      .catch(err => console.log(err));
  };
  deleteSerie = selected => {
    this.settingsService
      .deleteSerie(selected._id, this.context.franchise)
      .then(response => {
        if (response.status === 200) {
          let series = [...this.state.series];
          series.some((method, index) => {
            if (method._id === selected._id) {
              series.splice(index, 1);
              return true;
            } else return false;
          });
          this.setState({ series });
        }
      });
  };
  editInfo = values => {
    // console.log("editInfo", s / ues);

    this.settingsService
      .editInfo(values, this.context.franchise)
      .then(
        response =>
          response.status === 200 &&
          this.setState({ billing_info: response.data })
      )
      .catch(err => console.log(err));
  };

  handleCertificateUpload = values => {
    // console.log("handleCertificateUpload url", values);
    this.settingsService
      .addCertificateURL(values.filename, this.context.franchise)
      .then(response => {
        response.status === 200 &&
          this.setState({ certificate: response.data });
      })
      .catch(err => console.log(err));
  };

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px"
        }}
      >
        <BillingSettings
          payment_methods={
            this.state.payment_methods ? this.state.payment_methods : null
          }
          createPaymentMethod={this.createPaymentMethod}
          editPaymentMethod={this.editPaymentMethod}
          deletePaymentMethod={this.deletePaymentMethod}
          billing_info={this.state.billing_info}
          editInfo={this.editInfo}
          series={this.state.series ? this.state.series : null}
          createSerie={this.createSerie}
          editSerie={this.editSerie}
          deleteSerie={this.deleteSerie}
          translation={
            this.context.translation.templates
              ? this.context.translation.templates.settings
              : null
          }
          franchise={{
            _id: this.context.franchise,
            certificate: this.state.certificate
          }}
          server={`${process.env.REACT_APP_API_FRANCHISE_BILLING}/`}
          handleCertificateUpload={this.handleCertificateUpload}
        />
      </Grid>
    );
  }
}

export default BillingSettingsComponent;
