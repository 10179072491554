module.exports = {
  sideDrawer: {
    crm: "CRM",
    jobs: "Jobs",
    support: "Soporte",
    billing: "Facturación",
    pipeline: "Pipeline",
    services: "Services",
    campus: "Campus",
    projects: "Proyectos",
    contacts: "Contactos",
    marketplace: "Marketplace",
    next_tasks: "Próximas tareas",
    projects_best_practices: "Best Practices",
    settings: "Ajustes de facturación",
    staff: "Staff",
    academy: "Academy",
  },
  contacts: {
    list: {
      title: "Contactos",
      contact_type: {
        user: "Usuario",
        person: "Persona de contacto",
        client: "Cliente",
      },
      buttons: { add_contact: "+Contacto", import_contacts: "Importar" },
      datatables: {
        contact_list: {
          title: "Contactos",
          columns: {
            id: "id",
            type: "Tipo",
            city: "Ciudad",
            name: "Nombre",
            email: "Email",
            phone: "Teléfono",
          },
          type: {
            client: "Cliente",
            person: "Persona de contacto",
          },

          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
        },
      },
      dialogs: {
        add_contact: {
          tabs: {
            client: "Cliente",
            person_of_contact: "Persona de contacto",
            billing: "Datos de facturación",
          },
          buttons: { add: "Añadir", save: "Guardar", cancel: "Cancelar" },
          titles: {
            add_contact: "Añadir contacto",
            edit_contact: "Guardar contacto",
            view_contact: "Ver contacto",
          },
          inputs: {
            contact_name: "Nombre del contacto",
            billing_info_name: "Razón social",
            zipcode: "Código Postal",
            town: "Ciudad",
            province: "Provincia",
          },
          forms: {
            client: {
              inputs: {
                contact_web: "Web",
                contact_city: "Población",
                contact_email: "Email",
                contact_phone: "Teléfono",
              },
              selects: {
                client_type: {
                  school: "Centro educativo",
                  company: "Empresa",
                  placeholder: "Tipo de Cliente",
                  public_admin: "Administración pública",
                  individual: "Particular",
                  club: "Club deportivo",
                },
              },
            },
            person_of_contact: {
              inputs: {
                person_job: "Cargo",
                person_name: "Nombre",
                person_email: "Email",
                person_phone: "Teléfono",
              },
              buttons: { add_person_of_contact: "Añadir persona de contacto" },
            },
            billing_info: {
              inputs: {
                name: "Razón social",
                address: "Dirección fiscal",
                vat_number: "CIF/NIF",
                town: "Ciudad",
                zipcode: "Código postal",
                province: "Provincia",
              },
              buttons: { add_person_of_contact: "Añadir persona de contacto" },
            },
          },
        },
        import_contacts: {
          titles: { loading: "Cargando..." },
          buttons: {
            select: "Seleccionar",
            download_template: "Descargar plantilla",
            import: "Importar",
            cancel: "Cancelar",
          },
          datatables: {
            import_contacts: {
              title: "Importar contactos",
              textLabels: {
                body: {
                  noMatch: "Lo sentimos, no hay resultados",
                  toolTip: "Ordenar",
                },
                pagination: {
                  next: "Siguente",
                  previous: "Anterior",
                  rowsPerPage: "Filas por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Buscar",
                  downloadCsv: "Descargar CSV",
                  print: "Imprimir",
                  viewColumns: "Ver Columnas",
                  filterTable: "Filtrar Tabla",
                },
                filter: {
                  all: "Todos",
                  title: "FILTROS",
                  reset: "RESETEAR",
                },
                viewColumns: {
                  title: "Mostrar Columnas",
                  titleAria: "Mostrar/Ocultar Columnas de la tabla",
                },
                selectedRows: {
                  text: "Columna(s) seleccionadas",
                  delete: "Borrar",
                  deleteAria: "Borrar Columnas Seleccionadas",
                },
              },
              columns: {
                name: "Nombre comercial",
                billingInfo_name: "Razón social",
                city: "Población",
                email: "Email",
                web: "Web",
                telephone: "Teléfono",
                clientTypeLabel: "Tipo de cliente",
                person_of_contact: {
                  name: "Nombre",
                  email: "Email",
                  telephone: "Teléfono",
                  job: "Cargo",
                },
              },
            },
          },
        },
      },
    },
    single: {
      contact_type: {
        user: "Usuario",
        person: "Persona de contacto",
        client: "Cliente",
      },
      client_type: {
        school: "Centro educativo",
        company: "Empresa",
        public_admin: "Administración pública",
        individual: "Particular",
        club: "Club deportivo",
      },
      boards: {
        projects_lane: "Proyectos",
        opportunities_lane: "Oportunidades",
      },
      buttons: {
        edit_contact: "Editar",
        delete_contact: "Borrar contacto",
        add_opportunity: "+ Oportunidad",
      },
      datatables: {
        tasks: {
          title: "Tareas",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            subject: "Asunto",
            date: "Fecha",
            person_of_contact: "Persona de contacto",
            type: "Tipo",
            status: "Estado",
            opportunity_status: "Estado Oportunidad",
          },
          status: {
            pending: "Pendiente",
            done: "Hecho",
          },
          opportunity_status: {
            lead: "Contacto",
            offer: "Oferta económica",
            proposal: "Propuesta",
            hibernate: "Hibernando",
          },
          type: {
            call: "Llamada",
            email: "Email",
            visit: "Visita",
            meeting: "Reunión",
          },
          date: {
            from: "Desde",
            to: "Hasta",
          },
        },
        projects: {
          title: "Proyectos",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            name: "Nombre",
            date: "Fecha inicio de ejecución",
            service_type: "Tipo de servicio",
            status: "Fase",
            client: "Cliente",
          },
          date: {
            from: "Desde",
            to: "Hasta",
          },
          status: {
            plan: "Plan",
            docheck: "Do&Check",
            close: "Close",
          },
          service_type: {
            event: "Evento",
            academy: "Escuela Deportiva",
            campus: "Campus",
          },
          popovers: { edit: "Editar", view: "Ver", disable: "Borrar" },
        },
        opportunities: {
          title: "Oportunidades",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            name: "Nombre",
            date: "Fecha estimada de ejecución",
            type: "Tipo de Servicio",
            budget: "Valor estimado",
            status: "Estado",
            client: "Cliente",
          },
          date: {
            from: "Desde",
            to: "Hasta",
          },
          status: [
            { label: "Contacto" },
            { label: "Propuesta" },
            { label: "Oferta económica" },
            { label: "Hibernando" },
            { label: "Ganada" },
            { label: "Perdida" },
          ],
          type: {
            event: "Evento",
            academy: "Escuela Deportiva",
            campus: "Campus",
          },

          popovers: { edit: "Editar", view: "Ver", disable: "Borrar" },
        },
        invoices: {
          title: "Facturas recibidas",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            docnumber: "Número de factura",
            date: "Fecha",
            due_date: "Fecha de vencimiento",
            status: "Estado",
            category: "Categoría",
            subtotal: "Subtotal",
            tax: "Impuestos",
            total: "Total",
          },
          status: {
            uploaded_to_face: "Subida a FACE",
            pending: "Pendiente",
            overdue: "Vencido",
            amended: "Rectificada",
            amending: "Rectificativa",
            draft: "Borrador",
            paid: "Pagada",
          },
          category: {
            events: "Evento",
            campus: "Campus",
            academies: "Escuela deportiva",
            others: "Otros",
          },
          popovers: { download: "Descargar", view: "Ver" },
        },
      },
      dialogs: {
        add_task: {
          buttons: { add: "Añadir", save: "Guardar", cancel: "Cancelar" },
          titles: { add_task: "Añadir tarea", edit_task: "Editar tarea" },
          inputs: {
            task_date: "Fecha",
            task_details: "Descripción",
            task_subject: "Asunto",
          },
          selects: {
            task_type: {
              call: "Llamada",
              email: "Email",
              visit: "Visita",
              meeting: "Reunión",
              placeholder: "Tipo de tarea",
            },
            task_status: {
              done: "Realizada",
              pending: "Pendiente",
              placeholder: "Estado de la tarea",
            },
            task_contact: { placeholder: "Contacto asociado" },
            task_opportunity_status: {
              lead: {
                label: "Contacto",
                tooltip: "Primer contacto iniciado con el ciente",
              },
              offer: {
                label: "Oferta económica",
                tooltip: "Negociando condiciones económicas",
              },
              placeholder:
                "¿En qué estado de la oportunidad se realiza esta tarea?",
              proposal: { label: "Propuesta", tooltip: "Propuesta enviada" },
              hibernate: {
                label: "Hibernando",
                tooltip:
                  "La oportunidad ha dejado de estar activa pero es posible retomarla en el futuro",
              },
            },
          },
        },
        delete_contact: {
          info: "¿Estás seguro de borrar el contacto? Se perderán todos los proyectos y oportunidades asociados al mismo",
          buttons: { delete: "Borrar", cancel: "Cancelar" },
          titles: { delete_contact: "Borrar contacto" },
        },
        delete_person_of_contact: {
          info: "¿Estás seguro de borrar la persona de contacto?",
          buttons: { delete: "Borrar", cancel: "Cancelar" },
          titles: { delete_person_of_contact: "Borrar persona de contacto" },
        },
        edit_contact: {
          tabs: { client: "Cliente", billing: "Datos de facturación" },
          buttons: { save: "Guardar", cancel: "Cancelar" },
          titles: { edit_contact: "Editar contacto" },
          inputs: { contact_name: "Nombre" },
          forms: {
            client: {
              inputs: {
                contact_web: "Web",
                contact_city: "Población",
                contact_email: "Email",
                contact_phone: "Teléfono",
              },
              selects: {
                client_type: {
                  school: "Centro educativo",
                  company: "Empresa",
                  placeholder: "Tipo de Cliente",
                  public_admin: "Administración pública",
                },
              },
            },
            person_of_contact: {
              inputs: {
                person_job: "Cargo",
                person_name: "Nombre",
                person_email: "Email",
                person_phone: "Teléfono",
              },
              buttons: { add_person_of_contact: "Añadir persona de contacto" },
            },
            billing_info: {
              inputs: {
                name: "Razón social",
                address: "Dirección fiscal",
                vat_number: "CIF/NIF",
                town: "Ciudad",
                zipcode: "Código postal",
                province: "Provincia",
              },
              buttons: { add_person_of_contact: "Añadir persona de contacto" },
            },
          },
        },
        add_person_of_contact: {
          buttons: { add: "Añadir", save: "Guardar", cancel: "Cancelar" },
          titles: {
            add_person_of_contact: "Añadir Persona de contacto",
            edit_person_of_contact: "Editar Persona de contacto",
            view_person_of_contact: "Ver Persona contacto",
          },
          inputs: {
            person_name: "Nombre",
            person_job: "Cargo",
            person_email: "Email",
            person_phone: "Teléfono",
          },
        },
        add_opportunity: {
          buttons: { add: "Añadir", save: "Guardar", cancel: "Cancelar" },
          selects: {
            currency: {
              gbp: "£",
              sol: "S./",
              usd: "$",
              eur: "€",
              placeholder: "Moneda",
            },
            opportunity_source: {
              own: "Propia",
              product: "Producto Sportmadness",
              placeholder: "Fuente de la oportunidad",
            },
            opportunity_status: {
              lead: {
                label: "Contacto",
                tooltip: "Primer contacto iniciado con el ciente",
              },
              placeholder: "Estado de la oportunidad",
              offer: {
                label: "Oferta económica",
                tooltip: "Negociando condiciones económicas",
              },
              proposal: { label: "Propuesta", tooltip: "Propuesta enviada" },
              hibernate: {
                label: "Hibernando",
                tooltip:
                  "La oportunidad ha dejado de estar activa pero es posible retomarla en el futuro",
              },
            },
            opportunity_service_type: {
              event: "Evento",
              campus: "Campus",
              academy: "Escuela deportiva",
              placeholder: "Tipo de servicio",
            },
            opportunity_contact: { placeholder: "Contacto asociado" },
          },
          titles: {
            add_opportunity: "Añadir oportunidad",
            edit_opportunity: "Editar oportunidad",
          },
          inputs: {
            opportunity_name: "Nombre de la oportunidad",
            opportunity_date: "Fecha prevista de ejecución",
            opportunity_estimated_value: "Valor estimado",
          },
        },
        delete_task: {
          titles: {
            delete_task: "Borrar tarea",
          },
          info: "¿Deseas eliminar esta tarea?",
          buttons: {
            delete: "Borrar",
            cancel: "Cancelar",
          },
        },
        view_invoice: {
          title: "Factura",
          docnumber: "Número de factura",
          date: "Fecha",
          due_date: "Fecha de vencimiento",
          payment_date: "Fecha de pago",
          client: "Cliente",
          status: "Estado",
          subtotal: "Subtotal",
          tax: "Impuestos",
          total: "Total",
          selects: {
            status: {
              placeholder: "Estado",
              uploaded_to_face: "Subida a FACE",
              pending: "Pendiente",
              overdue: "Vencida",
              amended: "Rectificada",
              amending: "Rectificativa",
              draft: "Borrador",
              paid: "Pagada",
            },
          },
          inputs: {
            date: "Fecha de pago",
          },
          buttons: {
            save: "Guardar",
          },
          pdf: {
            message: "No se ha podido generar el PDF. Intentalo de nuevo",
            document: {
              docnumber: "Número de factura",
              date: "Fecha",
              due_date: "Fecha de vencimiento",

              issuer: "Emisor",
              receiver: "Receptor",
              subtotal: "Subtotal",
              tax: "Impuestos",
              total: "Total",
              method: "Forma de pago",
              items: {
                name: "Concepto",
                quantity: "Cantidad",
                price: "Precio",
                subtotal: "Subtotal",
                total: "Total",
                tax: {
                  label: "Impuesto",
                  amount: "Impuesto €",
                },
              },
              taxes: {
                iva_7_7: "IVA 7,7%",
                iva_8_1: "IVA 8.1%",
                iva_16: "IVA 16%",
                iva_17: "IVA 17%",
                iva_18: "IVA 18%",
                iva_19: "IVA 19%",
                iva_20: "IVA 20%",
                iva_21: "IVA 21%",
                iva_10: "IVA 10%",
                no_iva: "No IVA",
                no_igic: "No IGIC",
                igic_7: "IGIC 7%",
                iva_22: "IVA 22%",
                iva_23: "IVA 23%",
              },
            },
          },
        },
      },
      tabs: {
        projects: "Proyectos",
        opportunities: "Oportunidades",
        tasks: "Tareas",
        invoices: "Facturas",
      },
      contact_info: {
        titles: {
          contact_web: "Web",
          contact_city: "Población",
          contact_type: "Tipo de cliente",
          contact_email: "Email",
          contact_phone: "Teléfono",
          contact_billing_info_name: "Razón social",
        },
        people_of_contact: {
          titles: { people_of_contact: "Personas de contacto" },
          tooltips: {
            add_person_of_contact: "Añadir persona de contacto",
            edit_person_of_contact: "Editar persona de contacto",
            view_person_of_contact: "Ver persona de contacto",
            delete_person_of_contact: "Borrar persona de contacto",
          },
        },
      },
    },
  },
  opportunities: {
    single: {
      buttons: { add_task: "Añadir Tarea" },
      opportunity_status: {
        won: "Ganada",
        lead: "Contacto",
        lost: "Perdida",
        offer: "Oferta económica",
        proposal: "Propuesta",
        hibernate: "Hibernando",
      },
      dialogs: {
        add_task: {
          buttons: { add: "Añadir", save: "Guardar", cancel: "Cancelar" },
          titles: { add_task: "Añadir tarea", edit_task: "Editar tarea" },
          inputs: {
            task_date: "Fecha",
            task_details: "Descripción",
            task_subject: "Asunto",
          },
          selects: {
            task_type: {
              call: "Llamada",
              email: "Email",
              visit: "Visita",
              meeting: "Reunión",
              placeholder: "Tipo de tarea",
            },
            task_status: {
              done: "Realizada",
              pending: "Pendiente",
              placeholder: "Estado de la tarea",
            },
            task_contact: { placeholder: "Persona de contacto asociada" },
            task_opportunity_status: {
              lead: {
                label: "Contacto",
                tooltip: "Primer contacto iniciado con el ciente",
              },
              offer: {
                label: "Oferta económica",
                tooltip: "Negociando condiciones económicas",
              },
              placeholder:
                "¿En qué estado de la oportunidad se realiza esta tarea?",
              proposal: { label: "Propuesta", tooltip: "Propuesta enviada" },
              hibernate: {
                label: "Hibernando",
                tooltip:
                  "La oportunidad ha dejado de estar activa pero es posible retomarla en el futuro",
              },
            },
          },
        },
        edit_opportunity: {
          buttons: { add: "Añadir", save: "Guardar", cancel: "Cancelar" },
          selects: {
            currency: {
              gbp: "£",
              sol: "S./",
              usd: "$",
              eur: "€",
              placeholder: "Moneda",
            },
            opportunity_source: {
              own: "Propia",
              product: "Producto Sportmadness",
              placeholder: "Fuente de la oportunidad",
            },
            opportunity_status: {
              lead: {
                label: "Contacto",
                tooltip: "Primer contacto iniciado con el ciente",
              },
              offer: {
                label: "Oferta económica",
                tooltip: "Negociando condiciones económicas",
              },
              placeholder: "Fuente de la oportunidad",
              proposal: { label: "Propuesta", tooltip: "Propuesta enviada" },
              hibernate: {
                label: "Hibernando",
                tooltip:
                  "La oportunidad ha dejado de estar activa pero es posible retomarla en el futuro",
              },
              won: {
                label: "Ganada",
                tooltip:
                  "El cliente ha aceptado la propuesta y se ejecutará el servicio",
              },
              lost: {
                label: "Perdida",
                tooltip: "El cliente ha descartado la propuesta",
              },
            },
            opportunity_service_type: {
              event: "Evento",
              campus: "Campus",
              academy: "Escuela deportiva",
              placeholder: "Tipo de servicio",
            },
            opportunity_contact: { placeholder: "Contacto asociado" },
          },
          titles: {
            add_opportunity: "Añadir oportunidad",
            edit_opportunity: "Editar oportunidad",
          },
          inputs: {
            opportunity_date: "Fecha prevista de ejecución",
            opportunity_name: "Nombre de la oportunidad",
            opportunity_estimated_value: "Valor estimado",
          },
        },
        delete_opportunity: {
          titles: {
            delete_opportunity: "Borrar oportunidad",
          },
          info: "¿Deseas eliminar esta oportunidad?",
          buttons: {
            delete: "Borrar",
            cancel: "Cancelar",
          },
        },
        delete_task: {
          titles: {
            delete_task: "Borrar tarea",
          },
          info: "¿Deseas eliminar esta tarea?",
          buttons: {
            delete: "Borrar",
            cancel: "Cancelar",
          },
        },
      },
      opportunity_service_type: {
        event: "Evento",
        campus: "Campamento",
        academy: "Escuela deportiva",
      },
      datatables: {
        tasks: {
          title: "Tareas",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            subject: "Asunto",
            date: "Fecha",
            person_of_contact: "Persona de contacto",
            type: "Tipo",
            status: "Estado",
            opportunity_status: "Estado Oportunidad",
          },
          status: {
            pending: "Pendiente",
            done: "Hecho",
          },
          date: {
            from: "Desde",
            to: "Hasta",
          },
          opportunity_status: {
            lead: "Contacto",
            offer: "Oferta económica",
            proposal: "Propuesta",
            hibernate: "Hibernando",
            won: "Ganada",
            lost: "Perdida",
          },
          type: {
            call: "Llamada",
            email: "Email",
            visit: "Visita",
            meeting: "Reunión",
          },
        },
      },
      opportunity_info: {
        opportunity_status: "Estado actual de la oportunidad: ",
        opportunity_contact: "Contacto",
        opportunity_service_type: "Tipo de servicio: ",
        opportunity_estimated_value: "Valor estimado: ",
        opportunity_people_of_contact: "Personas de contacto",
      },
    },
    list: {
      buttons: {
        add_opportunity: "+ Oportunidad",
        view_past_opportunities: "Ver histórico",
      },
      views: {
        board: {
          pipeline: {
            lead: "Contacto",
            offer: "Oferta económica",
            proposal: "Propuesta",
            hibernate: "Hibernando",
          },
        },
        datatable: {
          title: "Oportunidades",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            name: "Nombre",
            date: "Fecha estimada de ejecución",
            service_type: "Servicio",
            budget: "Valor estimado",
            status: "Estado",
            client: "Cliente",
          },
          status: [
            { label: "Contacto" },
            { label: "Propuesta" },
            { label: "Oferta económica" },
            { label: "Hibernando" },
            { label: "Ganada" },
            { label: "Perdida" },
          ],
          service_type: {
            event: "Evento",
            academy: "Escuela Deportiva",
            campus: "Campus",
          },
          date: {
            from: "Desde",
            to: "Hasta",
          },
        },
      },
      dialogs: {
        add_opportunity: {
          buttons: { add: "Añadir", save: "Guardar", cancel: "Cancelar" },
          selects: {
            currency: {
              gbp: "£",
              sol: "S./",
              usd: "$",
              eur: "€",
              placeholder: "Moneda",
            },
            opportunity_source: {
              own: "Propia",
              product: "Producto Sportmadness",
              placeholder: "Fuente de la oportunidad",
            },
            opportunity_status: {
              lead: {
                label: "Contacto",
                tooltip: "Primer contacto iniciado con el ciente",
              },
              offer: {
                label: "Oferta económica",
                tooltip: "Negociando condiciones económicas",
              },
              placeholder: "Estado de la oportunidad",
              proposal: { label: "Propuesta", tooltip: "Propuesta enviada" },
              hibernate: {
                label: "Hibernando",
                tooltip:
                  "La oportunidad ha dejado de estar activa pero es posible retomarla en el futuro",
              },
            },
            opportunity_service_type: {
              event: "Evento",
              campus: "Campus",
              academy: "Escuela deportiva",
              placeholder: "Tipo de servicio",
            },
            opportunity_contact: { placeholder: "Contacto asociado" },
          },
          titles: {
            add_opportunity: "Añadir oportunidad",
            edit_opportunity: "Editar oportunidad",
          },
          inputs: {
            opportunity_date: "Fecha prevista de ejecución",
            opportunity_name: "Nombre de la oportunidad",
            opportunity_estimated_value: "Valor estimado",
          },
        },
      },
    },
    past: {
      title: "Histórico de oportunidades",
      buttons: {
        view_open_opportunities: "Oportunidades en curso",
      },
      tabs: {
        won: "Ganadas",
        lost: "Perdidas",
      },
      datatables: {
        won: {
          title: "Ganadas",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            name: "Nombre",
            date: "Fecha prevista de ejecución",
            client: "Cliente",
            service_type: "Tipo de servicio",
            budget: "Valor estimado",
          },
          type: {
            event: "Evento",
            academy: "Escuela Deportiva",
            campus: "Campus",
          },
        },
        lost: {
          title: "Perdidas",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            name: "Nombre",
            client: "Cliente",
            service_type: "Tipo de servicio",
            date: "Fecha estimada de ejecución",
            budget: "Valor estimado",
          },
          type: {
            event: "Evento",
            academy: "Escuela Deportiva",
            campus: "Campus",
          },
        },
      },
    },
  },
  dashboards: {
    crm: {
      cards: {
        open_opportunities: {
          title: "Oportunidades abiertas",
        },
        total_budget: {
          title: "Valor estimado pipeline",
        },
      },
      datatables: {
        tasks: {
          title: "Próximas tareas",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            service: "Oportunidad",
            subject: "Asunto",
            date: "Fecha",
            person_of_contact: "Persona de contacto",
            client_name: "Cliente",
            type: "Tipo",
            status: "Estado",
            opportunity_status: "Estado Oportunidad",
          },
          status: {
            pending: "Pendiente",
            done: "Hecho",
          },
          opportunity_status: {
            lead: "Contacto",
            offer: "Oferta económica",
            proposal: "Propuesta",
            hibernate: "Hibernando",
          },
          type: {
            call: "Llamada",
            email: "Email",
            visit: "Visita",
            meeting: "Reunión",
          },
        },
      },
    },
    projects: {
      cards: {
        open_projects: {
          title: "Proyectos abiertos",
        },
        total_budget: {
          title: "Valor estimado pipeline",
        },
      },
      datatables: {
        tasks: {
          title: "Próximas tareas",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            projectId: "Id del proyecto",
            projectName: "Nombre proyecto",
            name: "Asunto",
            date: "Fecha",
            status: "Estado",
          },
          status: {
            pending: "Pendiente",
            doing: "En proceso",
            done: "Hecho",
          },
          date: {
            from: "Desde ",
            to: "Hasta ",
          },
        },
      },
    },
  },
  projects: {
    best_practices: {
      single: {
        title: "Best Practices",
        boards: {
          pipeline: {
            lane_titles: {
              plan: "Plan",
              docheck: "Do & Check",
              close: "Close",
            },
          },
        },
        buttons: {
          add_project: "+ Proyecto desde Plantilla",
        },
        dialogs: {
          add_project: {
            titles: {
              add_project: "Ver Proyecto",
              edit_project: "Editar Proyecto",
            },
            buttons: {
              back: "Volver",
              next: "Continuar",
              finish: "Guardar",
              save: "Guardar",
            },
            steps: {
              step0: "Selecciona un tipo de proyecto",
              step1: "Completa la información ",
            },

            inputs: {
              project_name: "Nombre del proyecto",
              project_start_date: "Fecha de inicio",
              project_finish_date: "Fecha de fin",
              budget: "Valor estimado",
            },
            selects: {
              status: {
                placeholder: "Fase del proyecto",
                plan: "Plan",
                docheck: "Do&Check",
                close: "Close",
              },
              project_type: {
                placeholder: "Tipo de proyecto",
                b2b: "B2B",
                b2c: "B2C",
              },
              currency: {
                gbp: "£",
                sol: "S./",
                usd: "$",
                eur: "€",
                placeholder: "Moneda",
              },
              project_source: {
                placeholder: "¿Tu proyecto parte de una oportunidad ganada?",
                opportunity: "Si",
                new: "No, nuevo proyecto",
              },
              opportunity: {
                placeholder: "Elige la oportunidad",
              },
              opportunity_contact: {
                placeholder: "Contacto asociado",
              },
              service_type: {
                event: "Evento",
                campus: "Campus",
                academy: "Escuela deportiva",
                placeholder: "Tipo de servicio",
              },
              template: {
                placeholder: "Elige tu plantilla",
              },
            },
          },
        },
      },
      list: {
        title: "Best Practices",
        filter_by_type: "Filtrar por tipo",
        filter_by_tags: "Filtrar por tags",
        types: {
          event: "Evento",
          campus: "Campus",
          academy: "Escuela",
        },
      },
    },
    list: {
      title: "Proyectos",
      boards: {
        pipeline: {
          lane_titles: {
            plan: "Plan",
            docheck: "Do & Check",
            close: "Close",
          },
        },
      },
      buttons: {
        add_project: "+ Proyecto",
      },
      dialogs: {
        add_project: {
          titles: {
            add_project: "Ver Proyecto",
            edit_project: "Editar Proyecto",
          },
          buttons: {
            back: "Volver",
            next: "Continuar",
            finish: "Guardar",
          },
          steps: {
            step0: "Selecciona un tipo de proyecto",
            step1: "Completa la información ",
            step2: "Elige una plantilla",
          },

          inputs: {
            project_name: "Nombre del proyecto",
            project_start_date: "Fecha de inicio",
            project_finish_date: "Fecha de fin",
            budget: "Valor estimado",
          },
          selects: {
            status: {
              placeholder: "Fase del proyecto",
              plan: "Plan",
              docheck: "Do&Check",
              close: "Close",
            },
            project_type: {
              placeholder: "Tipo de proyecto",
              b2b: "B2B",
              b2c: "B2C",
            },
            currency: {
              gbp: "£",
              sol: "S./",
              usd: "$",
              eur: "€",
              placeholder: "Moneda",
            },
            project_source: {
              placeholder: "¿Tu proyecto parte de una oportunidad ganada?",
              opportunity: "Si",
              new: "No, nuevo proyecto",
            },
            opportunity: {
              placeholder: "Elige la oportunidad",
            },
            opportunity_contact: {
              placeholder: "Contacto asociado",
            },
            service_type: {
              event: "Evento",
              campus: "Campus",
              academy: "Escuela deportiva",
              placeholder: "Tipo de servicio",
            },
            template: {
              placeholder: "Elige tu plantilla",
            },
          },
        },
      },
      views: {
        columns: {
          status: {
            plan: "Plan",
            docheck: "Do & Check",
            close: "Close",
          },
        },
        datatable: {
          title: "Proyectos",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            name: "Nombre",
            startDate: "Fecha de inicio",
            type: "Tipo de Servicio",
            statusExecution: "Estado",
            client: "Cliente",
          },
          date: {
            from: "Desde",
            to: "Hasta",
          },
          status: {
            plan: "Plan",
            docheck: "Do & Check",
            close: "Close",
          },
          type: {
            event: "Evento",
            academy: "Escuela Deportiva",
            campus: "Campus",
          },
          popovers: { edit: "Editar", view: "Ver", disable: "Borrar" },
        },
      },
    },
    single: {
      alerts: {
        delete_project: {
          success: {
            text: "Se ha eliminado correctamente el proyecto",
            submit: "Cerrar",
          },
          warning: {
            text: "No se ha eliminado el proyecto, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
        delete_task: {
          success: {
            text: "Se ha eliminado correctamente la tarea",
            submit: "Cerrar",
          },
          warning: {
            text: "No se ha eliminado la tarea, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
        download_project: {
          error: {
            text: "Este proyecto no se ha podido descargar correctamente o no existe",
            submit: "Volver atrás",
          },
        },
        create_task: {
          success: {
            text: "Se ha creado correctamente la tarea",
            submit: "Cerrar",
          },
          warning: {
            text: "No se ha creado la tarea, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
        edit_task: {
          success: {
            text: "Se ha editado correctamente la tarea",
            submit: "Cerrar",
          },
          warning: {
            text: "No se ha editado la tarea, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
      },
      buttons: {
        saved: "Guardado",
        exit_edit: "Salir de edición",
      },
      calendar: {
        messages: {
          date: "Fecha",
          time: "Hora",
          event: "Tarea Proyecto",
          allDay: "Todo el día",
          week: "Semana",
          work_week: "Semana laboral",
          day: "Día",
          month: "Mes",
          previous: "Anterior",
          next: "Siguiente",
          yesterday: "Ayer",
          tomorrow: "Mañana",
          today: "Hoy",
          agenda: "Agenda",
          noEventsInRange: "No hay eventos en este rango de fecha",
          showMore: function (e) {
            return "+" + e + " más";
          },
        },
      },
      tabs: {
        info: "Resumen",
        plan: "Plan",
        docheck: "Do&Check",
        close: "Close",
      },
      task_states: {
        pending: "No empezado",
        doing: "En proceso",
        done: "Hecho",
        out_of_date: "Fuera de fecha",
        blocked: "Bloqueada",
      },
      dialogs: {
        add_task: {
          titles: {
            add_task: "Añadir tarea",
            edit_task: "Editar tarea",
          },
          buttons: {
            save: "Guardar",
            cancel: "Cancelar",
          },
          inputs: {
            name: "Nombre de la tarea",
            details: "Descripción",
            date: "Fecha",
            start: "Desde",
            end: "Hasta",
            period: {
              placeholder: "Cada ",
              day: "dia/s",
              week: "Selecciona los días de la semana en los que se repetirá la tarea semanalmente ",
              month: "Cada mes",
            },
            monthday: {
              day: "El día",
              each_month: "de cada mes",
            },
          },
          selects: {
            recurrence: {
              placeholder: "Tipo de tarea",
              once: "Puntual",
              recurring: "Recurrente",
            },

            frequency: {
              placeholder: "Frecuencia",
              day: "Diario",
              week: "Semanal",
              month: "Mes",
            },
            weekday: {
              placeholder: "El",
              monday: "Lunes",
              tuesday: "Martes",
              weednesday: "Miércoles",
              thursday: "Jueves",
              friday: "Viernes",
              saturday: "Sábado",
              sunday: "Domingo",
            },
          },
        },
        add_inside_task: {
          titles: {
            add_task: "Añadir tarea",
          },
          buttons: {
            save: "Guardar",
            cancel: "Cancelar",
            next: "Siguiente",
            back: "Anterior",
            finish: "Finalizar",
          },
          inputs: {
            name: "Nombre de la tarea",
            name_parent: "Titulo del grupo",
            details: "Descripción",
            date: "Fecha",
            start: "Desde",
            end: "Hasta",
            period: {
              placeholder: "Cada ",
              day: "dia/s",
              week: "Selecciona los días de la semana en los que se repetirá la tarea semanalmente ",
              month: "Cada mes",
            },
            monthday: {
              day: "El día",
              each_month: "de cada mes",
            },
          },
          selects: {
            recurrence: {
              placeholder: "Tipo de tarea",
              once: "Puntual",
              recurring: "Recurrente",
            },

            frequency: {
              placeholder: "Frecuencia",
              day: "Diario",
              week: "Semanal",
              month: "Mensual",
            },
            weekday: {
              placeholder: "El",
              monday: "Lunes",
              tuesday: "Martes",
              weednesday: "Miércoles",
              thursday: "Jueves",
              friday: "Viernes",
              saturday: "Sábado",
              sunday: "Domingo",
            },
          },
          steps: {
            step_1: "Añadir titulo",
            step_2: "Añadir tarea",
          },
        },
        delete_project: {
          titles: {
            delete_project: "Eliminar proyecto",
          },
          info: "¿Estás seguro que deseas eliminar el proyecto? Perderás toda la información añadida hasta ahora en este proyecto",
          buttons: {
            cancel: "Cancelar",
            delete: "Eliminar",
          },
        },
        delete_task: {
          titles: {
            delete_task: "Eliminar tarea",
          },
          info: "¿Estás seguro que deseas eliminar la tarea? Se eliminarán todas sus tareas descendientes",
          buttons: {
            cancel: "Cancelar",
            delete: "Eliminar",
          },
        },
      },
      drawers: {
        task_detail: {
          deadline: "Fecha límite",
          task_states: {
            pending: "No empezado",
            doing: "En proceso",
            done: "Hecho",
            out_of_date: "Fuera de fecha",
            blocked: "Bloqueada",
          },
          buttons: {
            complete_task: "Completar tarea",
            begin_task: "Comenzar tarea",
            completed_task: "Tarea completada",
            cancel: "Cancelar",
            save: "Guardar",
          },
        },
      },
      tab_project_info: {
        titles: {
          service_type: "Tipo de servicio",
          project_type: "Tipo de proyecto",
          estimated_value: "Valor estimado",
          date_start: "Fecha inicio",
          date_end: "Fecha fin",
          contact: "Contacto",
        },
        service_type: {
          campus: "Campus",
          event: "Evento",
          academy: "Escuela deportiva",
        },
        dialogs: {
          view_invoice: {
            title: "Factura",
            docnumber: "Número de factura",
            date: "Fecha",
            due_date: "Fecha de vencimiento",
            payment_date: "Fecha de pago",
            client: "Cliente",
            status: "Estado",
            subtotal: "Subtotal",
            tax: "Impuestos",
            total: "Total",
            selects: {
              status: {
                placeholder: "Estado",
                uploaded_to_face: "Subida a FACE",
                pending: "Pendiente",
                overdue: "Vencida",
                amended: "Rectificada",
                amending: "Rectificativa",
                draft: "Borrador",
                paid: "Pagada",
              },
            },
            inputs: {
              date: "Fecha de pago",
            },
            buttons: {
              save: "Guardar",
            },
            pdf: {
              message: "No se ha podido generar el PDF. Intentalo de nuevo",
              document: {
                docnumber: "Número de factura",
                date: "Fecha",
                due_date: "Fecha de vencimiento",

                issuer: "Emisor",
                receiver: "Receptor",
                subtotal: "Subtotal",
                tax: "Impuestos",
                total: "Total",
                method: "Forma de pago",
                items: {
                  name: "Concepto",
                  quantity: "Cantidad",
                  price: "Precio",
                  subtotal: "Subtotal",
                  total: "Total",
                  tax: {
                    label: "Impuesto",
                    amount: "Impuesto €",
                  },
                },
                taxes: {
                  iva_7_7: "IVA 7,7%",
                  iva_8_1: "IVA 8.1%",
                  iva_16: "IVA 16%",
                  iva_17: "IVA 17%",
                  iva_18: "IVA 18%",
                  iva_19: "IVA 19%",
                  iva_20: "IVA 20%",
                  iva_21: "IVA 21%",
                  iva_10: "IVA 10%",
                  no_iva: "No IVA",
                  no_igic: "No IGIC",
                  igic_7: "IGIC 7%",
                  iva_22: "IVA 22%",
                  iva_23: "IVA 23%",
                },
              },
            },
          },
        },
        datatables: {
          invoices: {
            title: "Facturas recibidas",
            textLabels: {
              body: {
                noMatch: "Lo sentimos, no hay resultados",
                toolTip: "Ordenar",
              },
              pagination: {
                next: "Siguente",
                previous: "Anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de",
              },
              toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver Columnas",
                filterTable: "Filtrar Tabla",
              },
              filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "RESETEAR",
              },
              viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas de la tabla",
              },
              selectedRows: {
                text: "Columna(s) seleccionadas",
                delete: "Borrar",
                deleteAria: "Borrar Columnas Seleccionadas",
              },
            },
            columns: {
              id: "id",
              docnumber: "Número de factura",
              date: "Fecha",
              due_date: "Fecha de vencimiento",
              status: "Estado",
              category: "Categoría",
              subtotal: "Subtotal",
              tax: "Impuestos",
              total: "Total",
            },
            status: {
              uploaded_to_face: "Subida a FACE",
              pending: "Pendiente",
              overdue: "Vencido",
              amended: "Rectificada",
              amending: "Rectificativa",
              draft: "Borrador",
              paid: "Pagada",
            },
            category: {
              events: "Evento",
              campus: "Campus",
              academies: "Escuela deportiva",
              others: "Otros",
            },
            popovers: { download: "Descargar", view: "Ver" },
          },
        },
      },
    },
  },
  invoices: {
    list: {
      title: "Facturas",
      compare: "Comparar",
      alerts: {
        download_invoices: {
          error: {
            text: "Error al descargar las facturas. Refresca la página. Si el problema persiste, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
        get_pdf: {
          error: {
            text: "Error al generar pdf. Refresca la página. Si el problema persiste, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
        edit_status: {
          error: {
            text: "Error al actualizar el estado de la factura. Intentalo de nuevo. Si el problema persiste, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
        duplicate: {
          error: {
            text: "Algo ha fallado al duplicar la factura. Intentalo de nuevo. Si el problema persiste, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
        amend: {
          success: {
            text: "Factura rectificada correctamente. ",
            submit: "Aceptar",
          },
          error: {
            text: "Error al rectificar el estado de la factura. Intentalo de nuevo. Si el problema persiste, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
        delete: {
          success: {
            text: "Factura borrada correctamente. ",
            submit: "Aceptar",
          },
          error: {
            text: "Algo ha fallado al intentar borrar la factura. Intentalo de nuevo. Si el problema persiste, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
        face: {
          error: {
            text: "Algo ha fallado al  descargar la factura. Intentalo de nuevo. Si el problema persiste, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
      },
      buttons: {
        add_invoice: "Añadir factura",
      },
      dashboard: {
        total: "Subtotal",
        month: "Este mes",
        last_month: "Último mes",
        events: "Eventos",
        academies: "Escuelas deportivas",
        campus: "Campus",
        other: "Otros",
        paid: "Pagado (incluye impuestos)",
        pending: "Pendiente (incluye impuestos)",
        overdue: "Vencido (incluye impuestos)",
      },
      chart: {
        events: "Eventos",
        campus: "Campus",
        academies: "Escuelas deportivas",
        others: "Otros",
      },
      datatables: {
        invoices: {
          title: "Facturas",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            docnumber: "Número de factura",
            date: "Fecha",
            due_date: "Fecha de vencimiento",
            client: "Cliente",
            status: "Estado",
            subtotal: "Subtotal",
            tax: "Impuestos",
            total: "Total",
          },
          date: {
            from: "Desde",
            to: "Hasta",
          },
          status: {
            uploaded_to_face: "Subida a FACE",
            pending: "Pendiente",
            overdue: "Vencido",
            amended: "Rectificada",
            amending: "Rectificativa",
            draft: "Borrador",
            paid: "Pagada",
          },
          popovers: {
            download: "Descargar",
            edit: "Editar",
            view: "Ver",
            delete: "Borrar",
            duplicate: "Duplicar",
            amend: "Rectificar",
            download_face: "Descarga FACE",
          },
        },
      },
      dialogs: {
        view_invoice: {
          title: "Factura",
          docnumber: "Número de factura",
          date: "Fecha",
          due_date: "Fecha de vencimiento",
          payment_date: "Fecha de pago",
          client: "Cliente",
          status: "Estado",
          subtotal: "Subtotal",
          tax: "Impuestos",
          total: "Total",
          selects: {
            status: {
              placeholder: "Estado",
              uploaded_to_face: "Subida a FACE",
              pending: "Pendiente",
              overdue: "Vencida",
              amended: "Rectificada",
              amending: "Rectificativa",
              draft: "Borrador",
              paid: "Pagada",
            },
          },
          inputs: {
            date: "Fecha de pago",
          },
          buttons: {
            save: "Guardar",
          },
          pdf: {
            message: "No se ha podido generar el PDF. Intentalo de nuevo",
            document: {
              docnumber: "Número de factura",
              date: "Fecha",
              due_date: "Fecha de vencimiento",

              issuer: "Emisor",
              receiver: "Receptor",
              subtotal: "Subtotal",
              tax: "Impuestos",
              total: "Total",
              method: "Forma de pago",
              items: {
                name: "Concepto",
                quantity: "Cantidad",
                price: "Precio",
                subtotal: "Subtotal",
                total: "Total",
                tax: {
                  label: "Impuesto",
                  amount: "Impuesto €",
                },
              },
              taxes: {
                iva_7_7: "IVA 7,7%",
                iva_8_1: "IVA 8.1%",
                iva_16: "IVA 16%",
                iva_17: "IVA 17%",
                iva_18: "IVA 18%",
                iva_19: "IVA 19%",
                iva_20: "IVA 20%",
                iva_21: "IVA 21%",
                iva_22: "IVA 22%",
                iva_10: "IVA 10%",
                no_iva: "No IVA",
                no_igic: "No IGIC",
                igic_7: "IGIC 7%",
                iva_23: "IVA 23%",
              },
            },
          },
        },
        delete_invoice: {
          title: "Borrar factura",
          info: "¿Estas seguro de borrar esta factura? Se perderá y no podrás recuperarla",
          buttons: {
            delete: "Borrar",
            cancel: "Cancelar",
          },
        },
      },
    },
    single: {
      add: "Añadir factura",
      edit: "Editar factura",
      buttons: {
        save: "Guardar",
        add: "Añadir",
        cancel: "Cancelar",
        add_item: "Añadir línea",
      },
      inputs: {
        date: "Fecha",
        due_date: "Fecha de vencimiento",
        irpf: "IRPF",
        items: {
          name: "Concepto",
          description: "Descripción",
          quantity: "Cantidad",
          price: "Precio",
          subtotal: "Subtotal",
          total: "Total",
        },
        message: "Mensaje visible en la factura",
      },
      selects: {
        client: {
          placeholder: "Cliente",
        },
        series: {
          placeholder: "Serie",
        },
        taxes: {
          placeholder: "Impuesto",
          iva_7_7: "IVA 7,7%",
          iva_8_1: "IVA 8.1%",
          iva_16: "IVA 16%",
          iva_17: "IVA 17%",
          iva_18: "IVA 18%",
          iva_19: "IVA 19%",
          iva_20: "IVA 20%",
          iva_21: "IVA 21%",
          iva_22: "IVA 22%",
          iva_10: "IVA 10%",
          no_iva: "No IVA",
          no_igic: "No IGIC",
          igic_7: "IGIC 7%",
          iva_23: "IVA 23%",
        },
        categories: {
          placeholder: "Categoría",
          events: "Eventos",
          academies: "Escuelas deportivas",
          campus: "Campus",
          others: "Otros",
        },
        payment_method: {
          placeholder: "Forma de pago",
        },
        projects: {
          placeholder: "Proyecto",
        },
      },
      titles: {
        subtotal: "Subtotal",
        tax: "Impuesto",
        irpf: "IRPF",
        total: "Total",
      },
      alerts: {
        edit_invoice: {
          error: {
            text: "Error al editar la factura. Intentalo de nuevo. Si el problema persiste, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
        get_invoice: {
          error: {
            text: "Error al descargar la factura. Intentalo de nuevo. Si el problema persiste, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
        get_info: {
          error: {
            text: "Error al editar la información de contactos. Intentalo de nuevo. Si el problema persiste, ponte en contacto con nosotros",
            submit: "Cerrar",
          },
        },
      },
    },

    subscriptions: {
      title: "Mis facturas",
      datatables: {
        invoices: {
          title: "Facturas recibidas",
          textLabels: {
            body: {
              noMatch: "Lo sentimos, no hay resultados",
              toolTip: "Ordenar",
            },
            pagination: {
              next: "Siguente",
              previous: "Anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver Columnas",
              filterTable: "Filtrar Tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "RESETEAR",
            },
            viewColumns: {
              title: "Mostrar Columnas",
              titleAria: "Mostrar/Ocultar Columnas de la tabla",
            },
            selectedRows: {
              text: "Columna(s) seleccionadas",
              delete: "Borrar",
              deleteAria: "Borrar Columnas Seleccionadas",
            },
          },
          columns: {
            id: "id",
            docnumber: "Número de factura",
            date: "Fecha",
            due_date: "Fecha de vencimiento",
            status: "Estado",
            category: "Categoría",
            subtotal: "Subtotal",
            tax: "Impuestos",
            total: "Total",
          },
          status: {
            uploaded_to_face: "Subida a FACE",
            pending: "Pendiente",
            overdue: "Vencido",
            amended: "Rectificada",
            amending: "Rectificativa",
            draft: "Borrador",
            paid: "Pagada",
          },
          category: {
            initial_fee: "Canon de entrada",
            recurring_fee: "Subscripción",
            office: "Paquete Office",
            marketing: "Marketing",
            others: "Otros",
            marketplace: "Marketplace",
          },
          popovers: { download: "Descargar", view: "Ver" },
        },
      },
      dialogs: {
        view_invoice: {
          title: "Factura",
          docnumber: "Número de factura",
          date: "Fecha",
          due_date: "Fecha de vencimiento",
          payment_date: "Fecha de pago",
          client: "Cliente",
          status: "Estado",
          subtotal: "Subtotal",
          tax: "Impuestos",
          total: "Total",
          selects: {
            status: {
              placeholder: "Estado",
              uploaded_to_face: "Subida a FACE",
              pending: "Pendiente",
              overdue: "Vencida",
              amended: "Rectificada",
              amending: "Rectificativa",
              draft: "Borrador",
              paid: "Pagada",
            },
          },

          pdf: {
            message: "No se ha podido generar el PDF. Intentalo de nuevo",
            document: {
              docnumber: "Número de factura",
              date: "Fecha",
              due_date: "Fecha de vencimiento",

              issuer: "Emisor",
              receiver: "Receptor",
              subtotal: "Subtotal",
              tax: "Impuestos",
              total: "Total",
              method: "Forma de pago",
              items: {
                name: "Concepto",
                quantity: "Cantidad",
                price: "Precio",
                subtotal: "Subtotal",
                total: "Total",
                tax: {
                  label: "Impuesto",
                  amount: "Impuesto €",
                },
              },
              taxes: {
                iva_7_7: "IVA 7,7%",
                iva_8_1: "IVA 8.1%",
                iva_16: "IVA 16%",
                iva_17: "IVA 17%",
                iva_18: "IVA 18%",
                iva_19: "IVA 19%",
                iva_20: "IVA 20%",
                iva_21: "IVA 21%",
                iva_10: "IVA 10%",
                no_iva: "No IVA",
                no_igic: "No IGIC",
                igic_7: "IGIC 7%",
                iva_22: "IVA 22%",
                iva_23: "IVA 23%",
              },
            },
          },
        },
      },
    },
  },
  alerts: {
    create_contact: {
      success: {
        text: "Se he creado el contacto correctamente",
        submit: "Aceptar",
      },
      warning: {
        text: "Ha habido algún problema al añadir el contacto, intentalo de nuevo",
        submit: "Aceptar",
      },
    },
    create_opportunity: {
      success: {
        text: "Se he creado la oportunidad correctamente",
        submit: "Aceptar",
      },
      warning: {
        text: "Ha habido algún problema al añadir la oportunidad, intentalo de nuevo",
        submit: "Aceptar",
      },
    },
    create_project: {
      success: {
        text: "Se he creado el proyecto correctamente",
        submit: "Aceptar",
      },
      warning: {
        text: "Ha habido algún problema al añadir el proyecto, intentalo de nuevo",
        submit: "Aceptar",
      },
    },
    create_task: {
      success: {
        text: "Se ha creado la tarea correctamente",
        submit: "Aceptar",
      },
      warning: {
        text: "Ha habido algún problema al crear la tarea, intentalo de nuevo",
        submit: "Aceptar",
      },
    },
    delete_contact: {
      success: {
        text: "Se ha eliminado correctamente el contacto",
        submit: "Aceptar",
      },
      warning: {
        text: "Ha habido algún problema al añdir el contacto, intentalo de nuevo",
        submit: "Aceptar",
      },
    },
    delete_opportunity: {
      success: {
        text: "Se ha eliminado correctamente la oportunidad",
        submit: "Aceptar",
      },
      warning: {
        text: "Ha habido algún problema al añdir la oportunidad, intentalo de nuevo",
        submit: "Aceptar",
      },
    },
    delete_project: {
      success: {
        text: "Se ha eliminado correctamente el proyecto",
        submit: "Aceptar",
      },
      warning: {
        text: "Ha habido algún problema al añdir el proyecto, intentalo de nuevo",
        submit: "Aceptar",
      },
    },
    delete_task: {
      success: {
        text: "Se ha eliminado la tarea correctamente",
        submit: "Aceptar",
      },
      warning: {
        text: "Ha habido algún problema al eliminar la tarea, intentalo de nuevo",
        submit: "Aceptar",
      },
    },
    download_boards: {
      error: {
        text: "Error al descargar las oportunidades de tu pipeline. Ponte en contacto con nosotros",
        submit: "Cerrar",
      },
    },
    download_contact: {
      error: {
        text: "Error al descargar el contacto. Ponte en contacto con nosotros",
        submit: "Cerrar",
      },
    },
    download_contacts: {
      error: {
        text: "Error al descargar los contactos. Ponte en contacto con nosotros",
        submit: "Cerrar",
      },
    },
    download_template: {
      error: {
        text: "Error al descargar la template. Ponte en contacto con nosotros",
        submit: "Cerrar",
      },
    },
    download_templates: {
      error: {
        text: "Error al descargar los templates. Ponte en contacto con nosotros",
        submit: "Cerrar",
      },
    },
    download_opportunity: {
      error: {
        text: "Error al descargar la oportunidad. Ponte en contacto con nosotros",
        submit: "Cerrar",
      },
    },
    download_opportunities: {
      error: {
        text: "Error al descargar las oportunidades. Ponte en contacto con nosotros",
        submit: "Cerrar",
      },
    },
    download_project: {
      error: {
        text: "Error  al descargar el proyecto. Ponte en contacto con nosotros",
        submit: "Cerrar",
      },
    },
    download_projects: {
      error: {
        text: "Error al descargar los proyectos. Ponte en contacto con nosotros",
        submit: "Cerrar",
      },
    },
    edit_contact: {
      success: {
        text: "Se ha editado el contacto correctamente",
        submit: "Aceptar",
      },
      warning: {
        text: "Ha habido algún problema al editar el contacto, intentalo de nuevo",
        submit: "Aceptar",
      },
    },
    edit_opportunity: {
      success: {
        text: "Se ha editado la oportunidad correctamente",
        submit: "Aceptar",
      },
      warning: {
        text: "Ha habido algún problema al editar la oportunidad, intentalo de nuevo",
        submit: "Aceptar",
      },
    },
    edit_task: {
      success: {
        text: "Se ha editado la tarea correctamente",
        submit: "Aceptar",
      },
      warning: {
        text: "Ha habido algún problema al editar la tarea, intentalo de nuevo",
        submit: "Aceptar",
      },
    },
  },
  settings: {
    title: "Ajustes de facturación",
    tabs: {
      series: "Series",
      payment_methods: "Métodos de pago",
      billing_info: "Datos fiscales",
      certificate: "Certificado digital",
    },
    series: {
      buttons: {
        add: "+ serie",
      },
      dialogs: {
        add: {
          titles: {
            edit_serie: "Editar serie",
            add_serie: "Añadir serie",
          },
          buttons: {
            save: "Guardar",
            add: "Añadir",
            cancel: "Cancelar",
          },
          inputs: {
            name: "Nombre de la serie (interno)",
            prefix: "Prefijo",
            sufix: "Sufijo",
            last_invoice: "Último número de factura",
          },
          selects: {
            type: {
              placeholder: "Tipo de serie",
              regular: "Normal",
              amending: "Rectificativa",
            },
          },
        },
        delete: {
          title: "Borrar serie",
          info: "Estás seguro de que quieres borrar este serie? Se perderá la información en todas las facturas asociadas a la misma",
          buttons: {
            delete: "Borrar",
            cancel: "Cancelar",
          },
        },
      },
      datatable: {
        title: "Series",
        textLabels: {
          body: {
            noMatch: "Lo sentimos, no hay resultados",
            toolTip: "Ordenar",
          },
          pagination: {
            next: "Siguente",
            previous: "Anterior",
            rowsPerPage: "Filas por página:",
            displayRows: "de",
          },
          toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Ver Columnas",
            filterTable: "Filtrar Tabla",
          },
          filter: {
            all: "Todos",
            title: "FILTROS",
            reset: "RESETEAR",
          },
          viewColumns: {
            title: "Mostrar Columnas",
            titleAria: "Mostrar/Ocultar Columnas de la tabla",
          },
          selectedRows: {
            text: "Columna(s) seleccionadas",
            delete: "Borrar",
            deleteAria: "Borrar Columnas Seleccionadas",
          },
        },
        columns: {
          id: "id",
          name: "Serie",
          prefix: "Prefijo",
          sufix: "Sufijo",
          type: "Tipo",
          lastInvoice: "Último número de factura",
        },
        type: {
          regular: "Normal",
          amending: "Rectificativa",
        },
      },
    },
    payment_methods: {
      buttons: {
        add: "+ Método de pago",
      },
      dialogs: {
        add: {
          titles: {
            edit_payment_method: "Editar método de pago",
            add_payment_method: "Añadir método de pago",
          },
          buttons: {
            save: "Guardar",
            add: "Añadir",
            cancel: "Cancelar",
          },
          inputs: {
            name: "Nombre",
            account_number: "Número de cuenta",
            message: "Mensaje. Aparecerá en la factura",
          },
          selects: {
            type: {
              placeholder: "Tipo",
              bank: "Cuenta bancaria",
              cash: "Contado",
              stripe: "Stripe",
            },
          },
        },
        delete: {
          title: "Borrar método de pago",
          info: "Estás seguro de que quieres borrar este método de pago? Se perderá la información en todas las facturas asociadas al mismo",
          buttons: {
            delete: "Borrar",
            cancel: "Cancelar",
          },
        },
      },
      datatable: {
        title: "Métodos de pago",
        type: {
          bank: "Cuenta bancaria",
          cash: "Contado",
          stripe: "Stripe",
        },
        textLabels: {
          body: {
            noMatch: "Lo sentimos, no hay resultados",
            toolTip: "Ordenar",
          },
          pagination: {
            next: "Siguente",
            previous: "Anterior",
            rowsPerPage: "Filas por página:",
            displayRows: "de",
          },
          toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Ver Columnas",
            filterTable: "Filtrar Tabla",
          },
          filter: {
            all: "Todos",
            title: "FILTROS",
            reset: "RESETEAR",
          },
          viewColumns: {
            title: "Mostrar Columnas",
            titleAria: "Mostrar/Ocultar Columnas de la tabla",
          },
          selectedRows: {
            text: "Columna(s) seleccionadas",
            delete: "Borrar",
            deleteAria: "Borrar Columnas Seleccionadas",
          },
        },
        columns: {
          id: "id",
          name: "Nombre del método de pago ",
          type: "Tipo",
          account_number: "Número de cuenta",
        },
      },
    },
    billing_info: {
      inputs: {
        legal_entity:
          "Marca esta opción si eres empresa, si eres autónomo/a dejala desmarcada (solo para España)",
        name: "Razón social/Nombre",
        vat_number: "CIF/NIF",
        address: "Dirección fiscal",
        town: "Ciudad",
        zipcode: "Código postal",
        province: "Provincia",
      },
      buttons: {
        save: "Guardar",
        add: "Añadir",
        cancel: "Cancelar",
      },
    },
  },
  academy: {
    list: {
      filter_by_category: "Filtrar por categoría",
      filter_by_tags: "Filtrar por etiquetas",
      categories: {
        product: "Producto",
        marketing: "Marketing",
        tech: "Tecnología",
        ideas: "Ideas",
      },
    },
  },
  web: {
    campus: {
      list: {
        title: "Campus",
        buttons: {
          add_campus: "AÑADIR CAMPUS",
        },
        dialogs: {
          add_campus: {
            title: "Añadir campus",
            inputs: {
              name: "Nombre campus",
              date_start: "Fecha inicio",
              date_end: "Fecha fin",
              target_users: "Objetivo de usuarios",
            },
            selects: {
              project: "Proyecto",
            },
          },
        },
        datatables: {
          campus: {
            columns: {
              id: "id",
              name: "Nombre",
              date_start: "Fecha inicio",
              date_end: "Fecha fin",
              target_users: "Objetivo usuarios",
              user_count: "Usuarios actuales",
            },
          },
        },
        texts: {
          users: "Usuarios",
          satisfaction: "Satisfacción",
        },
      },
      single: {
        title: "Campus",
        templates: {
          error:
            "Error al cargar la información. Recarga la página e inténtalo de nuevo. Si persiste el problema tras varios intentos, ponte en contacto con nosotros.",
          loading: "Cargando información, por favor espere.",
          downloading: "Descargando datos, por favor espere",
        },
        buttons: {
          add_campus: "AÑADIR CAMPUS",
        },
        dialogs: {
          assign_users_to_group: {
            title: "Asignar usuarios a un grupo",
            selects: {
              groups: "Busca un grupo",
            },
            buttons: {
              cancel: "Cancelar",
              submit: "Asignar",
            },
          },
          delete_campus: {
            title: "Borrar campus",
            text: "¿Deseas borrar el campus?",
            buttons: {
              cancel: "Cancelar",
              submit: "Eliminar",
            },
          },
          edit_campus: {
            title: "Editar Campus",
            inputs: {
              name: "Nombre campus",
              date_start: "Fecha inicio",
              date_end: "Fecha fin",
              target_users: "Objetivo de usuarios",
            },
            buttons: {
              cancel: "Cancelar",
              save: "Guardar",
            },
          },
          add_group: {
            title: "Añadir grupo",
            inputs: {
              name: "Nombre",
              date_start: "Fecha inicio",
              date_end: "Fecha fin",
              size: "Tamaño de grupo",
              last_check_in_time: "Ultima hora de check-in",
            },
            buttons: {
              cancel: "Cancelar",
              submit: "Guardar",
            },
          },
          add_user: {
            title: "Añadir usuario",
            inputs: {
              name: "Nombre",
              dob: "Fecha de nacimiento",
              allergies: "Alergias",
              comments: "Comentarios",
              kinship: "Parentesco",
              email: "Email",
              phone: "Telefono",
            },
            texts: {
              responsable: "Responsable",
            },
            buttons: {
              add_responsable: "Añadir responsable",
              cancel: "Cancelar",
              next: "Siguiente",
              back: "Atrás",
              save: "Guardar",
            },
            steps: ["Información del menor", "Información de los responsables"],
          },
          add_staff_trainer: {
            title: "Añadir entrenador/a",
            steps: {
              default: ["Elige grupo", "Selecciona tipo de entrenador/a"],
              select: [
                "Elige grupo",
                "Selecciona tipo de entrenador/a",
                "Selecciona un entrenador/a",
              ],
              create: [
                "Elige grupo",
                "Selecciona tipo de entrenador/a",
                "Información personal",
                "Deportes",
              ],
            },
            selects: {
              groups: "Busca un grupo",
              sports: "Deportes impartidos",
              staff: "Busca un entrenador/a",
            },
            buttons: {
              select_staff: "Seleccionar entrenador/a existente",
              create_staff: "Crear nuevo entrenador",
              back: "Atrás",
              next: "Siguiente",
              save: "Guardar",
              cancel: "Cancelar",
              empty_options: "Crear nuevo entrenador",
            },
            inputs: {
              name: "Nombre",
              email: "Email",
              phone: "Telefono",
              identification_number: "DNI",
              ssn: "Número de Seguridad Social",
              bank_account_number: "Número de cuenta bancaria",
            },
            texts: {
              optional: "Opcional",
              empty_options: "No hay staff creado o disponible para asignar",
              sports:
                "Estos son los deportes que puede impartir este entrenador/coordinador",
            },
          },
          add_staff_coordinator: {
            title: "Añadir coordinador/a",
            steps: {
              default: ["Elige asignacion"],
              group: [
                "Elige asignacion",
                "Elige grupo",
                "Selecciona tipo de entrenador",
              ],
              campus: ["Elige asignacion", "Selecciona tipo de entrenador"],
              select_group: [
                "Elige asignacion",
                "Elige grupo",
                "Selecciona tipo de entrenador",
                "Selecciona un entrenador/a",
              ],
              create_group: [
                "Elige asignacion",
                "Elige grupo",
                "Selecciona tipo de entrenador",
                "Información personal",
                "Deportes",
              ],
              select_campus: [
                "Elige asignacion",
                "Selecciona tipo de entrenador",
                "Selecciona un entrenador/a",
              ],
              create_campus: [
                "Elige asignacion",
                "Selecciona tipo de entrenador",
                "Información personal",
                "Deportes",
              ],
            },
            selects: {
              groups: "Busca un grupo",
              sports: "Deportes impartidos",
              staff: "Busca un coordinador/a",
            },
            buttons: {
              select_staff: "Seleccionar coordinador/a existente",
              create_staff: "Crear nuevo coordinador",
              assign_to_campus: "Asignar al campus",
              assign_to_group: "Asignar al grupo",
              back: "Atrás",
              next: "Siguiente",
              submit: "Guardar",
              cancel: "Cancelar",
              empty_options: "Crear nuevo coordinador",
            },
            inputs: {
              name: "Nombre",
              email: "Email",
              phone: "Telefono",
              identification_number: "DNI",
              ssn: "Número de Seguridad Social",
              bank_account_number: "Número de cuenta bancaria",
            },
            texts: {
              optional: "Opcional",
              empty_options: "No hay staff creado o disponible para asignar",
              sports:
                "Estos son los deportes que puede impartir este entrenador/coordinador",
            },
          },
          import_users: {
            title: "Importar usuarios",
            buttons: {
              download_template: "Descargar plantilla",
              select: "Seleccionar fichero",
              cancel: "Cancelar",
              import: "Importar usuarios",
            },
            titles: {
              loading: "Leyendo fichero... por favor espere",
              error: "Error, plantilla incorrecta",
            },
          },
        },
        datatables: {
          import_users: {
            columns: {
              name: "Nombre",
              dob: "Fecha de nacimiento",
              allergies: "Alergias",
              comments: "Comentarios",
              parents_0_name: "Nombre",
              parents_0_email: "Email",
              parents_0_phone: "Telefono",
              parents_0_kinship: "Parentesco",
              parents_1_name: "Nombre",
              parents_1_email: "Email",
              parents_1_phone: "Telefono",
              parents_1_kinship: "Parentesco",
            },
          },
        },
        groups: {
          datatables: {
            groups: {
              columns: {
                _id: "id",
                name: "Nombre",
                trainers: "Entrenadores",
                coordinators: "Coordinadores",
                users_size: "Nº de niños/as",
              },
            },
          },
        },
        users: {
          datatables: {
            users: {
              columns: {
                key: "key",
                name: "Nombre",
                emergency_contact: "Contacto de emergencia",
                coordinators: "Coordinadores",
                groups: "Grupos",
              },
              toolbar: {
                assign_users_group: "Asignar a un grupo",
              },
            },
          },
          drawers: {
            user: {
              name: "Nombre",
              allergies: "Alergias",
              comments: "Comentarios",
              parents: "Padres",
              extraResponsibles: "Responsables autorizados",
              groups: "Grupos",
              inputs: {
                name: "Nombre",
                allergies: "Alergias",
                comments: "Comentarios",
                phone: "Teléfono",
                email: "Email",
              },
              selects: {
                groups: "Grupos",
              },
              buttons: {
                cancel: "Cancelar",
                edit: "Editar",
                delete: "Borrar",
              },
              texts: {
                delete_user: {
                  title: "Borrar ",
                  text: "¿Está seguro que desea eliminarlo?",
                },
              },
            },
          },
        },
        venues: {
          scheduler: {
            resourceGroupName: "Grupos",
            resourceVenueName: "Instalaciones",
            resourceName: "",
            taskName: "Actividades",
            agendaViewHeader: "Agenda",
            views: {
              day: "Dia",
              week: "Semana",
              month: "Mes",
            },
            dialog_translation: {
              title: "Añadir instalación",
              inputs: {
                name: "Nombre",
              },
              buttons: {
                submit: "Añadir",
                cancel: "Cancelar",
              },
            },
          },
          dialogs: {
            edit_venue: {
              title: "Editar instalación",
              inputs: {
                name: "Nombre",
              },
              buttons: {
                submit: "Editar",
                cancel: "Cancelar",
              },
            },
            delete_venue: {
              title: "Eliminar instalación",
              text: "Si eliminas esta instalación, todas las actividades asignadas a esta instalación se quedaran sin ninguna instalación asignada",
              buttons: {
                submit: "Eliminar",
                cancel: "Cancelar",
              },
            },
          },
          buttons: {
            add_resource: "Añadir instalación",
            per_venues: "Por instalaciones",
            per_groups: "Por grupos",
          },
          datatables: {
            venues: {
              columns: {
                id: "Id instalación",
                name: "Nombre instalación",
              },
            },
          },
        },
        staff: {
          datatables: {
            staff: {
              columns: {
                _id: "id",
                name: "Nombre",
                role: "Rol",
                groups: "Grupos",
              },
            },
          },
          drawers: {
            staff: {
              name: "Nombre",
              roles: {
                coordinator: "Coordinador",
                trainer: "Entrenador",
              },
              personal_info: "Información personal",
              comments: "Comentarios",
              sports: "Deportes impartidos",
              certificates: "Certificados",
              groups: "Grupos",
              inputs: {
                name: "Nombre",
                phone: "Teléfono",
                email: "Email",
              },
              selects: {
                groups: "Grupos",
                role: "Rol",
              },
              buttons: {
                cancel: "Cancelar",
                edit: "Editar",
                delete: "Borrar",
              },
              texts: {
                delete_staff: {
                  title: "Borrar ",
                  text: "¿Está seguro que desea eliminarlo?",
                },
              },
            },
          },
        },
        satisfaction: {
          datatables: {
            satisfactions: {
              columns: {
                id: "id",
                parent: "Padre/Madre",
                repeat: "Repetiría",
                sportmadness: "Sportmadness",
                service: "Campus",
              },
            },
          },
          texts: {
            text_1: "Esto es lo que opinan los usuarios de tu campus",
            text_2_0: "El ",
            text_2_1: " repetería la experiencia en el campamento",
            text_3: "También les hemos preguntado si recomendarían ... ",
            text_4: "Así han valorado tu propuesta de valor",
            text_5:
              "¿Qué valoración han dado a la app como herramienta de comunicación?",
          },
          chips: {
            venues: "Instalaciones",
            activities: "Actividades",
            trainers: "Entrenadores",
            campus: "Campus",
            sportmadness: "Sportmadness",
          },
          drawers: {
            satisfaction: {
              title: "Satisfacción",
              texts: {
                repeat: "¿Repetiría la experiencía?",
                recommend: "¿Recomendaría ... a un amigo o conocido?",
                proposal: "Así ha valorado tu propuesta de valor",
                app: "¿Qué valoración ha dado a la app como herramienta de comunicación?",
                highlights: "¿Qué destacarías?",
                improve: "¿Qué mejorarías?",
              },
              options: {
                yes: "Sí",
                no: "No",
              },
              chips: {
                venues: "Instalaciones",
                activities: "Actividades",
                trainers: "Entrenadores",
                campus: "Campus",
                sportmadness: "Sportmadness",
              },
            },
          },
        },
        tabs: {
          venues: "Instalaciones",
          groups: "Grupos",
          users: "Usuarios",
          staff: "Staff",
          satisfaction: "Satisfacción",
        },
        popovers: {
          campus: {
            label: "+",
            labels: {
              add_group: "Añadir grupo",
              add_user: "Añadir usuario",
              import_users: "Importar usuarios",
              add_staff_trainer: "Añadir entrenador",
              add_staff_coordinator: "Añadir coordinador",
            },
          },
        },
      },
      group: {
        single: {
          templates: {
            error:
              "Error al cargar la información. Recarga la página e inténtalo de nuevo. Si persiste el problema tras varios intentos, ponte en contacto con nosotros.",
            loading: "Cargando información, por favor espere.",
            downloading: "Descargando datos, por favor espere",
          },
          dialogs: {
            delete_group: {
              title: "Borrar grupo",
              text: "¿Deseas borrar el grupo?",
              buttons: {
                cancel: "Cancelar",
                submit: "Eliminar",
              },
            },
            edit_group: {
              title: "Editar Grupo",
              inputs: {
                name: "Nombre grupo",
                date_start: "Fecha inicio",
                date_end: "Fecha fin",
                size: "Tamaño grupo",
              },
              buttons: {
                cancel: "Cancelar",
                save: "Guardar",
              },
            },
            add_staff_trainer: {
              title: "Añadir entrenador/a",
              steps: {
                default: ["Selecciona o crea un/a entrenador/a"],
                select: [
                  "Selecciona o crea un/a entrenador/a",
                  "Selecciona un entrenador/a",
                ],
                create: [
                  "Selecciona o crea un/a entrenador/a",
                  "Información personal",
                  "Deportes",
                ],
              },
              selects: {
                groups: "Busca un grupo",
                sports: "Deportes impartidos",
                staff: "Busca un entrenador/a",
              },
              buttons: {
                select_staff: "Seleccionar entrenador/a existente",
                create_staff: "Crear nuevo/a entrenador/a",
                back: "Atrás",
                next: "Siguiente",
                save: "Guardar",
                cancel: "Cancelar",
                empty_options: "Crear nuevo entrenador",
              },
              inputs: {
                name: "Nombre",
                email: "Email",
                phone: "Telefono",
                identification_number: "DNI",
                ssn: "Número de Seguridad Social",
                bank_account_number: "Número de cuenta bancaria",
              },
              texts: {
                optional:
                  "Opcional (el entrenador/a podrá completar estos datos desde su perfil)",
                empty_options: "No hay staff creado o disponible para asignar",
                sports:
                  "Estos son los deportes que puede impartir este entrenador/coordinador",
              },
            },
            add_staff_coordinator: {
              title: "Añadir coordinador/a",
              steps: {
                default: ["Selecciona o crea un/a cordinador/a"],
                select: [
                  "Selecciona o crea un/a cordinador/a",
                  "Selecciona un coordinador/a",
                ],
                create: [
                  "Selecciona o crea un/a cordinador/a",
                  "Información personal",
                  "Deportes",
                ],
              },
              selects: {
                groups: "Busca un grupo",
                sports: "Deportes impartidos",
                staff: "Busca un coordinador/a",
              },
              buttons: {
                select_staff: "Seleccionar coordinador/a existente",
                create_staff: "Crear nuevo coordinador/a",
                assign_to_campus: "Asignar al campus",
                assign_to_group: "Asignar al grupo",
                back: "Atrás",
                next: "Siguiente",
                submit: "Guardar",
                cancel: "Cancelar",
                empty_options: "Crear nuevo coordinador/a",
              },
              inputs: {
                name: "Nombre",
                email: "Email",
                phone: "Telefono",
                identification_number: "DNI",
                ssn: "Número de Seguridad Social",
                bank_account_number: "Número de cuenta bancaria",
              },
              texts: {
                optional:
                  "Opcional (el coordinador/a podrá completar estos datos desde su perfil)",
                empty_options: "No hay staff creado o disponible para asignar",
                sports:
                  "Estos son los deportes que puede impartir este entrenador/coordinador",
              },
            },
            add_activity: {
              texts: {
                date_repeats: "Fechas de la actividad",
                details_family: "Descripción para las familias",
                details_family_text:
                  "Esta información será visible por los padres y madres",
                details_staff: "Descripción para el staff",
                details_staff_text:
                  "Esta información será visible para entrenadores y coordinadores. Los padres no lo verán",
              },
              inputs: {
                date_start: "Desde",
                date_end: "Hasta",
                name: "Nombre",
              },
              selects: {
                venue: "Instalación",
              },
              buttons: {
                cancel: "Cancelar",
                edit: "Editar",
                submit: "Guardar",
                import_activities: "Importar horario",
              },
              dialogs: {
                import_activities: {
                  title: "Importar actividades",
                  buttons: {
                    download_template: "Descargar plantilla",
                    select: "Seleccionar archivo",
                    cancel: "Cancelar",
                    import: "Importar",
                  },
                  titles: {
                    loading: "Cargando",
                    error: "Error de plantilla",
                  },
                  title_instructions: "Instrucciones de importación de horario",
                  text_instructions:
                    "Descarga la plantilla y completa los horarios de tu actividad. Observarás que necesitas incluir el 'id de la instalación' en la tercera columna, para obtenerlo:",
                  instructions: [
                    "Dirigete a la pantalla principal del campus",
                    "En el tab de actividades, selecciona la opcion de listado",
                    "El id de instalación es el que tienes que introducir en el excel en la columna de instalación",
                  ],
                },
              },
              datatables: {
                import_activities: {
                  columns: {
                    start: "Comienzo",
                    end: "Fin",
                    venue: "Instalación",
                  },
                },
              },
            },
          },
          activities: {
            dialogs: {
              activity: {
                texts: {
                  date_repeats: "Horario de la actividad",
                  details_family: "Descripción para las familias",
                  details_family_text:
                    "Esta información será visible por los padres y madres",
                  details_staff: "Descripción para el staff",
                  details_staff_text:
                    "Esta información será visible para entrenadores/as y coordinadores/as. Los padres/madres no lo verán",
                  date_start: "Inicio",
                  date_end: "Fin",
                  venue: "Pista",
                },
                inputs: {
                  date_start: "Desde",
                  date_end: "Hasta",
                },
                selects: {
                  venue: "Instalación",
                },
                buttons: {
                  cancel: "Cancelar",
                  edit: "Editar",
                  submit: "Guardar",
                  import_activities: "Importar horario",
                },
                dialogs: {
                  import_activities: {
                    title: "Importar actividades",
                    buttons: {
                      download_template: "Descargar plantilla",
                      select: "Seleccionar archivo",
                      cancel: "Cancelar",
                      import: "Importar",
                    },
                    titles: {
                      loading: "Cargando",
                      error: "Error de plantilla",
                    },
                    title_instructions:
                      "Instrucciones de importación de horario",
                    text_instructions:
                      "Descarga la plantilla y completa los horarios de tu actividad. Observarás que necesitas incluir el 'id de la instalación' en la tercera columna, para obtenerlo:",
                    instructions: [
                      "Dirigete a la pantalla principal del campus",
                      "En el tab de actividades, selecciona la opcion de listado",
                      "El id de instalación es el que tienes que introducir en el excel en la columna de instalación",
                    ],
                  },
                },
                datatables: {
                  import_activities: {
                    columns: {
                      start: "Comienzo",
                      end: "Fin",
                      venue: "Instalación",
                    },
                  },
                },
              },
            },
            datatables: {
              activities: {
                columns: { id: "id", name: "Nombre" },
              },
            },
            calendar: {
              messages: {
                date: "Fecha",
                time: "Hora",
                event: "Tarea Proyecto",
                allDay: "Todo el día",
                week: "Semana",
                work_week: "Semana laboral",
                day: "Día",
                month: "Mes",
                previous: "Anterior",
                next: "Siguiente",
                yesterday: "Ayer",
                tomorrow: "Mañana",
                today: "Hoy",
                agenda: "Agenda",
                noEventsInRange: "No hay eventos en este rango de fecha",
              },
            },
          },
          attendance: {
            datatables: {
              attendance: {
                columns: {
                  id: "id",
                  date: "Fecha",
                  attending: "Asisten",
                  not_attending: "No asisten",
                  percentage: "Porcentaje",
                },
              },
            },
            drawers: {
              attendance: {
                texts: {
                  comments_empty: "Sin comentarios",
                  checkin: "Check in",
                  checkout: "Check out",
                  checkin_empty: "Check in no realizado",
                  checkout_empty: "Check out no realizado",
                },
                buttons: {
                  back: "Atrás",
                },
              },
            },
          },
          users: {
            datatables: {
              users: {
                columns: {
                  id: "id",
                  name: "Nombre",
                  emergency_contact: "Contacto de emergencia",
                  coordinators: "Coordinadores",
                  groups: "Grupos",
                },
              },
            },
            drawers: {
              user: {
                name: "Nombre",
                allergies: "Alergias",
                comments: "Comentarios",
                parents: "Padres",
                extraResponsibles: "Responsables autorizados",
                groups: "Grupos",
                inputs: {
                  name: "Nombre",
                  allergies: "Alergias",
                  comments: "Comentarios",
                  phone: "Teléfono",
                  email: "Email",
                },
                selects: {
                  groups: "Grupos",
                },
                buttons: {
                  cancel: "Cancelar",
                  edit: "Editar",
                  delete: "Borrar",
                },
                texts: {
                  delete_user: {
                    title: "Eliminar del grupo ",
                    text: "¿Está seguro que desea eliminar este/a niño/a del grupo?",
                  },
                },
              },
            },
          },
          staff: {
            datatables: {
              staff: {
                columns: { id: "id", name: "Nombre", role: "Rol" },
              },
            },
            drawers: {
              staff: {
                name: "Nombre",
                roles: {
                  coordinator: "Coordinador",
                  trainer: "Entrenador",
                },
                personal_info: "Información personal",
                comments: "Comentarios",
                sports: "Deportes impartidos",
                certificates: "Certificados",
                groups: "Grupos",
                inputs: {
                  name: "Nombre",
                  phone: "Teléfono",
                  email: "Email",
                },
                selects: {
                  groups: "Grupos",
                  role: "Rol",
                },
                buttons: {
                  cancel: "Cancelar",
                  edit: "Editar",
                  delete: "Borrar",
                },
                texts: {
                  delete_staff: {
                    title: "Eliminar del grupo ",
                    text: "¿Está seguro que desea eliminar este/a entrenador/a del grupo?",
                  },
                },
              },
            },
          },
          tabs: {
            activities: "Actividades",
            users: "Inscritos",
            staff: "Staff",
            attendance: "Asistencia",
          },
          popovers: {
            campus: {
              label: "+",
              labels: {
                add_activity: "Añadir actividad",
                add_staff_trainer: "Añadir entrenador",
                add_staff_coordinator: "Añadir coordinador",
              },
            },
          },
        },
      },
    },
    staff: {
      list: {
        title: "Staff",
        buttons: {
          download_shift_report: "Descargar reporte de turnos",
        },
        datatables: {
          staff: {
            columns: {
              id: "id",
              name: "Nombre",
              email: "Email",
            },
          },
        },
      },
      single: {
        title: "Turnos",
        roles: { trainer: "Entrenador", coordinator: "Coordinador" },
        personal_info: "Información personal",
        email: "Email",
        phone: "Teléfono",
        ssn: "Número de la Seguridad Social",
        bank_account_number: "Número de cuenta bancaria",
        identification_number: "DNI",
        services: "Servicios",
        sports: "Deportes",
        certificates: "Certificados",
        inputs: {
          name: "Nombre",
          phone: "Teléfono",
          identification_number: "DNI",
          ssn: "Número de la Seguridad Social",
          bank_account_number: "Número de cuenta bancaria",
        },
        buttons: { cancel: "Cancelar", edit: "Guardar" },
        drawers: {
          shifts: {
            texts: {
              check_in: "Entrada",
              check_out: "Salida",
            },
            inputs: {
              comments: "Comentarios",
            },
            buttons: {
              submit: "Guardar",
            },
          },
        },
        datatables: {
          shifts: {
            columns: {
              id: "id",
              day: "Día",
              hourStart: "Comienzo",
              hourEnd: "Fin",
              duration: "Duración",
            },
          },
        },
      },
    },
    inbox: {
      selects: {
        service: "Elige un servicio para ver los canales disponibles",
      },
      blocks: {
        general: {
          announcements: "Comunicados",
          staff: "Incidencias",
        },
        groups: {
          title: "Grupos",
          search_placeholder: "Buscar grupo",
        },
        direct_messages: {
          title: "Chat",
          search_placeholder:
            "Buscar usuario (coordinadores, entrenadores o padres)",
          buttons: { select: "Nueva conversación" },
          dialogs: {
            new_conversation: {
              title: "Buscar usuario",
              buttons: {
                select: "Nueva conversación",
              },
            },
          },
        },
      },
    },
  },
};
