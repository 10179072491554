import React from "react";
import { numeral, Chip } from "react-library-sm";

import moment from "moment";

const SubscriptionsTemplate = (translation) => {
  return {
    title: translation.invoices.subscriptions.title,
    datatables: {
      invoices: {
        title: "Invoices",
        columns: [
          {
            label: "id",
            name: "_id",
            options: { display: false, filter: false },
          },
          {
            label:
              translation.invoices.subscriptions.datatables.invoices.columns
                .date,
            name: "date",
            options: {
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[1]).format("DD/MM/YYYY"),
            },
          },
          {
            label:
              translation.invoices.subscriptions.datatables.invoices.columns
                .docnumber,
            name: "docnumber",
          },
          {
            label:
              translation.invoices.subscriptions.datatables.invoices.columns
                .due_date,
            name: "due_date",
            options: {
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[3]).format("DD/MM/YYYY"),
            },
          },
          {
            label:
              translation.invoices.subscriptions.datatables.invoices.columns
                .status,
            name: "status",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) => `Status ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.invoices.subscriptions.datatables.invoices.status
                ),
                logic(status, filterVal) {
                  const show =
                    filterVal.indexOf(
                      translation.invoices.subscriptions.datatables.invoices
                        .status[status]
                    ) >= 0;
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation.invoices.subscriptions.datatables.invoices
                        .status[tableMeta.rowData[4]]
                    }
                    style={
                      tableMeta.rowData[4] === "pending"
                        ? {
                            borderRadius: "5px",
                            background: "transparent",
                            border: "1px solid #ffe082",
                            color: "#ffe082",
                          }
                        : tableMeta.rowData[4] === "paid"
                        ? {
                            borderRadius: "5px",
                            border: "1px solid #77ffb5",
                            color: "#77ffb5",
                            background: "transparent",
                          }
                        : tableMeta.rowData[4] === "payment_failed" ||
                          tableMeta.rowData[4] === "overdue"
                        ? {
                            borderRadius: "5px",
                            border: "1px solid #E95A58",
                            color: "#fff",
                            background: "transparent",
                          }
                        : {
                            borderRadius: "5px",
                            border: "1px solid #80b9d6",
                            color: "#80b9d6",
                            background: "transparent",
                          }
                    }
                  />
                );
              },
            },
          },
          {
            label:
              translation.invoices.subscriptions.datatables.invoices.columns
                .category,
            name: "category",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) => `Category ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.invoices.subscriptions.datatables.invoices
                    .category
                ),
                logic(category, filterVal) {
                  const show =
                    filterVal.indexOf(
                      translation.invoices.subscriptions.datatables.invoices
                        .category[category]
                    ) >= 0;
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation.invoices.subscriptions.datatables.invoices
                        .category[tableMeta.rowData[5]]
                    }
                  />
                );
              },
            },
          },
          {
            label: "Subtotal",
            name: "subtotal",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[6]).format("0,0[.]00 $"),
            },
          },
          {
            label: "Tax",
            name: "tax",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[7]).format("0,0[.]00 $"),
            },
          },
          {
            label: "Total",
            name: "total",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[8]).format("0,0[.]00 $"),
            },
          },
          {
            label: "",
            name: "options",
          },
        ],
      },
    },
    dialogs: {
      view_invoice: {
        title: translation.invoices.subscriptions.dialogs.view_invoice.title,
        docnumber:
          translation.invoices.subscriptions.dialogs.view_invoice.docnumber,
        date: translation.invoices.subscriptions.dialogs.view_invoice.date,
        due_date:
          translation.invoices.subscriptions.dialogs.view_invoice.due_date,
        payment_date:
          translation.invoices.subscriptions.dialogs.view_invoice.payment_date,
        client: translation.invoices.subscriptions.dialogs.view_invoice.client,
        status: translation.invoices.subscriptions.dialogs.view_invoice.status,
        subtotal:
          translation.invoices.subscriptions.dialogs.view_invoice.subtotal,
        tax: translation.invoices.subscriptions.dialogs.view_invoice.tax,
        total: translation.invoices.subscriptions.dialogs.view_invoice.total,
        selects: {
          status: {
            placeholder:
              translation.invoices.subscriptions.dialogs.view_invoice.selects
                .status.placeholder,
            items: [
              {
                value: "pending",
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice
                    .selects.status.pending,
              },
              {
                value: "overdue",
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice
                    .selects.status.overdue,
              },
              {
                value: "paid",
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice
                    .selects.status.paid,
              },
              {
                value: "amended",
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice
                    .selects.status.amended,
              },
              {
                value: "amending",
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice
                    .selects.status.amending,
              },
            ],
          },
        },

        pdf: {
          message:
            translation.invoices.subscriptions.dialogs.view_invoice.pdf.message,
          document: {
            docnumber:
              translation.invoices.subscriptions.dialogs.view_invoice.pdf
                .document.docnumber,
            date: translation.invoices.subscriptions.dialogs.view_invoice.pdf
              .document.date,
            due_date:
              translation.invoices.subscriptions.dialogs.view_invoice.pdf
                .document.due_date,
            issuer:
              translation.invoices.subscriptions.dialogs.view_invoice.pdf
                .document.issuer,
            receiver:
              translation.invoices.subscriptions.dialogs.view_invoice.pdf
                .document.receiver,
            subtotal:
              translation.invoices.subscriptions.dialogs.view_invoice.pdf
                .document.subtotal,
            tax: translation.invoices.subscriptions.dialogs.view_invoice.pdf
              .document.tax,
            total:
              translation.invoices.subscriptions.dialogs.view_invoice.pdf
                .document.total,
            method:
              translation.invoices.subscriptions.dialogs.view_invoice.pdf
                .document.method,
            items: {
              name: translation.invoices.subscriptions.dialogs.view_invoice.pdf
                .document.items.name,

              quantity:
                translation.invoices.subscriptions.dialogs.view_invoice.pdf
                  .document.items.quantity,
              price:
                translation.invoices.subscriptions.dialogs.view_invoice.pdf
                  .document.items.price,
              subtotal:
                translation.invoices.subscriptions.dialogs.view_invoice.pdf
                  .document.items.subtotal,
              tax: {
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.items.tax.label,
                amount:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.items.tax.amount,
              },
            },
            taxes: [
              {
                percentage: 0.077,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.iva_7_7,
                value: "iva_7_7",
              },
              {
                percentage: 0.081,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.iva_8_1,
                value: "iva_8_1",
              },
              {
                percentage: 0.16,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.iva_16,
                value: "iva_16",
              },
              {
                percentage: 0.17,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.iva_17,
                value: "iva_17",
              },
              {
                percentage: 0.18,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.iva_18,
                value: "iva_18",
              },
              {
                percentage: 0.19,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.iva_19,
                value: "iva_19",
              },
              {
                percentage: 0.2,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.iva_20,
                value: "iva_20",
              },
              {
                percentage: 0.21,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.iva_21,
                value: "iva_21",
              },
              {
                percentage: 0.23,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.iva_23,
                value: "iva_23",
              },
              {
                percentage: 0.1,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.iva_10,
                value: "iva_10",
              },
              {
                percentage: 0,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.no_iva,
                value: "no_iva",
              },
              {
                percentage: 0,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.no_igic,
                value: "no_igic",
              },
              {
                percentage: 0.07,
                label:
                  translation.invoices.subscriptions.dialogs.view_invoice.pdf
                    .document.taxes.igic_7,
                value: "igic_7",
              },
            ],
          },
        },
      },
    },
  };
};
export { SubscriptionsTemplate };
