const ProjectBestPracticesListTemplate = (translation) => {
  return {
    title: translation.projects.best_practices.list.title,
    filter_by_type: translation.projects.best_practices.list.filter_by_type,
    filter_by_tags: translation.projects.best_practices.list.filter_by_tags,

    types: {
      event: translation.projects.best_practices.list.types.event,
      campus: translation.projects.best_practices.list.types.campus,
      academy: translation.projects.best_practices.list.types.academy,
    },
  };
};

export { ProjectBestPracticesListTemplate };
