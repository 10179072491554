import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { WebInbox } from "react-library-sm";
import RocketChatServices from "services/auth/rocket-chat.services";
import CoreServices from "services/services-core/core.services";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserContext from "providers/UserProvider";

class WebManagerInbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      rocket_channels: null,
      groups: null,
      users: null,
      direct_messages: null,
      services: null,
      channels: null,
    };
    this.coreService = new CoreServices();
    this.rocketChatService = new RocketChatServices();
  }

  static contextType = UserContext;

  componentDidMount() {
    this.coreService.getServices().then((response) => {
      // console.log("response getServices", response);
      response.status === 200 &&
        this.setState({
          isLoading: false,
          services: response.data,
        });
    });
  }
  getServiceChannels = (serviceId) => {
    // console.log("serviceId", serviceId);
    return this.rocketChatService
      .getChannels({
        serviceId: serviceId,
        onlyDirectMessages: false,
      })
      .then((response) => {
        // console.log("response getServiceChannels", response);

        if (response.status === 200) {
          this.setState({
            rocket_channels: response.data.rocket_channels,
            groups: response.data.groups,
            users: response.data.users,
            direct_messages: response.data.direct_messages,
          });
          return Promise.resolve(response.data);
        }
      });
  };
  updateDirectMessages = (selectedService) => {
    // const _direct_messages = [...this.state.direct_messages];

    return this.rocketChatService
      .getChannels({
        serviceId: selectedService,
        onlyDirectMessages: true,
      })
      .then((response) => {
        // console.log("response updateDirectMessages", response);

        if (response.status === 200) {
          this.setState({
            ...this.state,
            direct_messages: response.data.direct_messages,
          });
        }
      });
  };

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {this.state.isLoading ? (
          <CircularProgress size={100} />
        ) : (
          <WebInbox
            services={this.state.services}
            getServiceChannels={this.getServiceChannels}
            updateDirectMessages={this.updateDirectMessages}
            direct_messages={this.state.direct_messages}
            auth_token={localStorage.getItem("rocket-chat-auth_token")}
            chatServer={process.env.REACT_APP_ROCKET_CHAT_SERVER}
            translation={this.context.translation.templates.web.inbox}
          />
        )}
      </Grid>
    );
  }
}
export { WebManagerInbox };
