import Axios from "core/axiosCampus";

class StaffServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_STAFF;
    this.service = new Axios(this.api_url);
  }

  // --------------------------------- LIST

  // ----- GET

  getStaffList = (franchise) =>
    franchise
      ? this.service.get(`/staff?franchiseId=${franchise}`)
      : this.service.get(`/staff`);

  getStaffCampusList = (campusId) =>
    this.service.get(`/staff?service=Campus&serviceId=${campusId}`);

  getShiftList = () => this.service.get(`/shifts`);

  // --------------------------------- SINGLE

  // ----- GET

  getStaffSingle = (franchise, staffId) =>
    this.service.get(`/staff/${staffId}?franchiseId=${franchise}`);

  // ----- POST

  /**
   * option
   *
   * case campus
   * {
   *  action:"campus"
   * }
   *
   * case group
   * {
   *  action:"group",
   *  groupId:string
   * }
   */
  postStaffAndAssignCampusGroup = (franchise, campusId, option, data) => {
    switch (option.action) {
      case "campus":
        return this.service.post(
          `campus/${campusId}/staff?franchiseId=${franchise}&addTo=${option.action}`,
          data
        );

      case "group":
        return this.service.post(
          `campus/${campusId}/staff?franchiseId=${franchise}&addTo=${option.action}&group=${option.groupId}`,
          data
        );

      default:
        return Promise.reject();
    }
  };

  // ----- PUT

  putShiftComment = (shiftId, data) =>
    this.service.put(`/shifts/${shiftId}`, data);

  putSingleStaff = (franchise, staffId, data) =>
    this.service.put(`/staff/${staffId}?franchiseId=${franchise}`, data);

  /**
   * action : enum (edit,delete)
   */
  putCampusSingleStaff = (franchise, campusId, staffId, action, data) =>
    this.service.put(
      `/campus/${campusId}/staff/${staffId}?action=${action}&franchiseId=${franchise}`,
      data
    );

  // ----- DELETE

  /**
   * action : enum (edit,delete)
   */
  deleteCampusSingleStaff = (franchise, campusId, staffId, action) =>
    this.service.delete(
      `/campus/${campusId}/staff/${staffId}?action=${action}&franchiseId=${franchise}`
    );
}

export default StaffServices;
