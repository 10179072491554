import React, { Component } from "react";
import AcademyServices from "services/franchise-academy/academy.services";
import { Grid } from "@material-ui/core";
import { FranchiseAcademyCoursesSingle } from "react-library-sm";
import UserContext from "providers/UserProvider";

class AcademyCoursesSingleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: "",
      title: ""
    };
    this.academyService = new AcademyServices();
  }

  static contextType = UserContext;

  componentDidMount() {
    this.academyService
      .getCourse(this.props.match.params.id, this.context.language.value)
      .then(response => {
        this.setState({
          course: response.data[0].languages.blocks.html,
          title: response.data[0].languages.name,
          description: response.data[0].languages.description
        });
      });
  }

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px"
        }}
      >
        {alert}
        <FranchiseAcademyCoursesSingle
          data={this.state.course}
          title={this.state.title}
          description={this.state.description}
        />
      </Grid>
    );
  }
}

export default AcademyCoursesSingleComponent;
