import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";

const WebStaffSingleTemplate = (translation) => {
  // console.log('translation en OpportunitiesTemplate', translation);
  return {
    title: translation.title,
    roles: {
      trainer: translation.roles.trainer,
      coordinator: translation.roles.coordinator,
    },
    personal_info: translation.personal_info,
    email: translation.email,
    phone: translation.phone,
    ssn: translation.ssn,
    bank_account_number: translation.bank_account_number,
    identification_number: translation.identification_number,
    services: translation.services,
    sports: translation.sports,
    certificates: translation.certificates,
    inputs: {
      name: translation.inputs.name,
      phone: translation.inputs.phone,
      identification_number: translation.inputs.identification_number,
      ssn: translation.inputs.ssn,
      bank_account_number: translation.inputs.bank_account_number,
    },
    buttons: {
      cancel: translation.buttons.cancel,
      edit: translation.buttons.edit,
    },
    drawers: {
      shifts: {
        texts: {
          check_in: translation.drawers.shifts.texts.check_in,
          check_out: translation.drawers.shifts.texts.check_out,
        },
        inputs: {
          comments: translation.drawers.shifts.inputs.comments,
        },
        buttons: {
          submit: translation.drawers.shifts.buttons.submit,
        },
      },
    },
    datatables: {
      shifts: {
        columns: [
          {
            label: translation.datatables.shifts.columns.id,
            name: "_id",
            options: {
              display: false,
              filter: false,
            },
          },
          {
            name: "day",
            label: translation.datatables.shifts.columns.day,
          },
          {
            name: "hourStart",
            label: translation.datatables.shifts.columns.hourStart,
          },
          {
            name: "hourEnd",
            label: translation.datatables.shifts.columns.hourEnd,
          },
          {
            name: "duration",
            label: translation.datatables.shifts.columns.duration,
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: false,
              customBodyRender: (value, tableMeta, updateValue) => (
                <Link
                  to={`/staff/${tableMeta.rowData[0]}`}
                  style={{ color: "#fafafa" }}
                >
                  <VisibilityIcon />
                </Link>
              ),
            },
          },
        ],
        options: {
          selectableRows: false,
          download: false,
          print: false,
        },
      },
    },
  };
};

export { WebStaffSingleTemplate };
