import Axios from "core/axios";

class InvoicesServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_BILLING;
    this.service = new Axios(this.api_url);
  }

  getInvoices = (franchiseId,year) =>
    this.service.get(`/invoices?franchiseId=${franchiseId}&year=${year}`);

  getInvoiceInfo = (franchiseId) =>
    this.service.get(`/invoices/info?franchiseId=${franchiseId}`);

  getInvoice = (id) => this.service.get(`/invoices/${id}`);

  postInvoice = (data, franchiseId) =>
    this.service.post(`/invoices?franchiseId=${franchiseId}`, data);

  putInvoice = (data, franchiseId) =>
    this.service.put(`/invoices/${data._id}?franchiseId=${franchiseId}`, data);

  deleteInvoice = (id) => this.service.delete(`/invoices/${id}`);

  updateStatus = (data, franchiseId) =>
    this.service.put(
      `/invoices/${data.id}/status?franchiseId=${franchiseId}`,
      data
    );
  duplicateInvoice = (id, franchiseId) =>
    this.service.post(`/invoices/${id}/duplicate?franchiseId=${franchiseId}`);

  amendInvoice = (id, franchiseId) =>
    this.service.put(`/invoices/${id}/amend?franchiseId=${franchiseId}`);
  sendNotification = (ids) => this.service.put(`/invoices/notification`, ids);

  getSubscriptionInvoices = (franchiseId) => {
    return this.service.get(`/subscriptions?franchiseId=${franchiseId}`);
  };

  getSubscriptionInvoice = (id) => {
    return this.service.get(`/subscriptions/invoices/${id}`);
  };

  downloadFace = (id, password) =>
    this.service.post(`/invoices/${id}/face`, password);
}

export default InvoicesServices;
