import React, { Component } from "react";
import {
  FranchiseOpportunitySingle as OpportunitySingle,
  Alert,
} from "react-library-sm";
import { Grid } from "@material-ui/core";
import ContactsServices from "services/franchise-crm/contacts.services";
import OpportunitiesServices from "services/franchise-crm/opportunities.services";
import TasksServices from "services/franchise-crm/tasks.services";
import UserContext from "providers/UserProvider";
import BoardsServices from "services/franchise-crm/boards.services";
import { Redirect } from "react-router-dom";

class OpportunityContainer extends Component {
  constructor(props) {
    super(props);
    this.titles = {
      contact: "Contacto",
      people: "Personas de contacto",
      opportunity: "Detalle de la oportunidad",
      estimatedValue: "Valor estimado: ",
      state: "Estado actual: ",
      serviceType: "Tipo de servicio: ",
    };
    this.state = {
      opportunity: {},
      deletedOpportunity: false,
    };
    this.contactService = new ContactsServices();
    this.opportunityService = new OpportunitiesServices();
    this.taskService = new TasksServices();
    this.boardService = new BoardsServices();
    this.opportunityId = null;
  }

  static contextType = UserContext;

  componentDidMount() {
    this.opportunityId = this.props.match.params.id;
    this.opportunityService
      .getOpportunity(this.props.match.params.id, this.context.franchise)
      .then((response) => {
        this.createLogicOpportunity({ ...response.data[0] });
      })
      .catch((err) => {
        // console.log("err get project", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            text: this.context.translation.templates.opportunity.alerts
              .download_opportunity.error.text,
            linkText:
              this.context.translation.templates.opportunity.alerts
                .download_opportunity.error.submit,
            link: "/pipeline",
          },
        });
      });
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };

  createLogicOpportunity = (opportunity, updated) => {
    opportunity.contact = opportunity.client[0];

    // console.log("response0", opportunity);
    const typeOpportunityStatus =
      this.context.translation.templates.opportunity.opportunity_status.find(
        (value) => value.value === opportunity.statusSells
      );
    const typeOpportunityServiceType =
      this.context.translation.templates.opportunity.opportunity_service_type.find(
        (value) => value.value === opportunity.type
      );
    // const type = false;
    // console.log("type", type);
    typeOpportunityStatus
      ? (opportunity.statusLabel = typeOpportunityStatus.label)
      : (opportunity.statusLabel = opportunity.statusSells + " no reconocido");
    typeOpportunityServiceType
      ? (opportunity.serviceTypeLabel = typeOpportunityServiceType.label)
      : (opportunity.serviceTypeLabel = opportunity.type + " no reconocido");

    const tasks = opportunity.tasks.map((task, index) => {
      // console.log("task" + index, task);
      return {
        ...task,
        person_of_contact: task.contact.name ? task.contact.name : "",
      };
    });

    opportunity.contact.people = opportunity.contact.people
      .filter((person) => person.visible)
      .map((person) => {
        return { ...person, value: person._id, label: person.name };
      });
    if (updated) {
      this.setState({
        opportunity,
        tasks,
        alertAPI: true,
        alert: {
          open: true,
          title: this.context.translation.alerts.titles.success,
          status: "success",
          text: this.context.translation.templates.opportunity.alerts
            .edit_opportunity.success.text,
          submit:
            this.context.translation.templates.opportunity.alerts
              .edit_opportunity.success.submit,
          onSubmit: this.handleOnCloseAlert,
        },
      });
    } else {
      this.setState({ opportunity, tasks });
    }
  };

  editOpportunity = (data) => {
    const opportunity = { ...this.state.opportunity };
    const editOpportunity = { ...data };
    const tasks = [...this.state.tasks];
    editOpportunity.client = editOpportunity.client.map((client) => {
      return client._id;
    });
    editOpportunity.tasks = tasks;

    if (editOpportunity.statusSells !== opportunity.statusSells) {
      // CUANDO ES DIFERENTE
      this.opportunityService
        .updateOpportunity(editOpportunity, this.context.franchise)
        .then((response) => {
          // console.log("response opportunity", response);
          //OLD UPDATE BOARD
          this.createLogicOpportunity(response.data, true);
        })
        .catch((err) => {
          // console.log("err update opp", err);
          this.setState({
            alertAPI: true,
            alert: {
              open: true,
              title: this.context.translation.alerts.titles.warning,
              status: "warning",
              text: this.context.translation.templates.opportunity.alerts
                .edit_opportunity.warning.text,
              linkText:
                this.context.translation.templates.opportunity.alerts
                  .edit_opportunity.warning.submit,
              link: "/pipeline",
            },
          });
        });
    } else {
      // CUANDO ES IGUAL
      this.opportunityService
        .updateOpportunity(editOpportunity, this.context.franchise)
        .then((response) => {
          // console.log("response opportunity", response);
          this.createLogicOpportunity(response.data, true);
        })
        .catch((err) => {
          // console.log("err update project", err.response);
          this.setState({
            alertAPI: true,
            alert: {
              open: true,
              title: this.context.translation.alerts.titles.warning,
              status: "warning",
              text: this.context.translation.templates.opportunity.alerts
                .edit_opportunity.warning.text,
              linkText:
                this.context.translation.templates.opportunity.alerts
                  .edit_opportunity.warning.submit,
              link: "/pipeline",
            },
          });
        });
    }
    // console.log("response form edit opp", editOpportunity);
  };

  deleteOpportunity = () => {
    // console.log(this.props.match.params.id);
    this.opportunityService
      .deleteOpportunity(this.props.match.params.id, this.context.franchise)
      .then((response) => {
        // console.log("delete board respn", response);
        this.setState({ deletedOpportunity: true });
      })
      .catch((err) => {
        // console.log("err delete project", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.opportunity.alerts
              .delete_opportunity.warning.text,
            submit:
              this.context.translation.templates.opportunity.alerts
                .delete_opportunity.warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  createTask = (data) => {
    const task = {
      ...data,
      contact: data.person_of_contact,
    };
    this.taskService
      .addTask(this.props.match.params.id, this.context.franchise, task)
      .then((response) => {
        // console.log("response add task", response);
        const tasks = [...this.state.tasks];

        const createdTask = response.data.tasks[response.data.tasks.length - 1];
        tasks.push({
          ...createdTask,
          person_of_contact: createdTask.contact.name,
        });
        this.setState({
          tasks,
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.opportunity.alerts
              .create_task.success.text,
            submit:
              this.context.translation.templates.opportunity.alerts.create_task
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err add task", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.opportunity.alerts
              .create_task.warning.text,
            submit:
              this.context.translation.templates.opportunity.alerts.create_task
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  editTask = (data) => {
    const task = {
      ...data,
      contact: data.person_of_contact,
    };
    this.taskService
      .editTask(this.props.match.params.id, this.context.franchise, task)
      .then((response) => {
        let tasks = [...this.state.tasks];

        tasks.some((task, index) => {
          if (task._id === data._id) {
            tasks[index] = {
              ...response.data.tasks.find((res) => res._id === task._id),
              person_of_contact: this.state.opportunity.contact.people.find(
                (person) => person._id === data.person_of_contact
              ).name,
            };
            return true;
          } else return false;
        });

        this.setState({
          tasks,
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.opportunity.alerts
              .edit_task.success.text,
            submit:
              this.context.translation.templates.opportunity.alerts.edit_task
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err edit task", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.opportunity.alerts
              .edit_task.warning.text,
            submit:
              this.context.translation.templates.opportunity.alerts.edit_task
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  deleteTask = (data) => {
    this.taskService
      .deleteTask(this.props.match.params.id, this.context.franchise, data._id)
      .then((response) => {
        if (response.status === 201) {
          let tasks = [...this.state.tasks];
          tasks = tasks.filter((task) => task._id !== data._id);
          this.setState({
            tasks,
            alertAPI: true,
            alert: {
              open: true,
              title: this.context.translation.alerts.titles.success,
              status: "success",
              text: this.context.translation.templates.opportunity.alerts
                .delete_task.success.text,
              submit:
                this.context.translation.templates.opportunity.alerts
                  .delete_task.success.submit,
              onSubmit: this.handleOnCloseAlert,
            },
          });
        }
      })
      .catch((err) => {
        // console.log("err delete task", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.opportunity.alerts
              .delete_task.warning.text,
            submit:
              this.context.translation.templates.opportunity.alerts.edit_task
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  render() {
    if (this.opportunityId !== this.props.match.params.id) {
      this.opportunityId = this.props.match.params.id;
      this.opportunityService
        .getOpportunity(this.opportunityId, this.context.franchise)
        .then((response) => {
          this.createLogicOpportunity({ ...response.data[0] });
        })
        .catch((err) => {
          // console.log("err get project", err.response);
          this.setState({
            alertAPI: true,
            alert: {
              open: true,
              title: this.context.translation.alerts.titles.error,
              status: "error",
              text: this.context.translation.templates.opportunity.alerts
                .download_opportunity.error.text,
              submit:
                this.context.translation.templates.opportunity.alerts
                  .download_opportunity.error.submit,
              onSubmit: this.handleOnCloseAlert,
            },
          });
        });
    }
    // console.log("id opportunity", this.props.match.params.id);
    let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;

    let content = this.state.deletedOpportunity ? (
      <Redirect to="/pipeline" />
    ) : (
      <Grid
        container
        style={{
          position: "fixed",
          width: this.context.menuOpen ? "80%" : "100%",
        }}
      >
        {alert}
        <OpportunitySingle
          translation={this.context.translation.templates.opportunity}
          opportunity={this.state.opportunity}
          createTask={this.createTask}
          editTask={this.editTask}
          deleteTask={this.deleteTask}
          tasks={this.state.tasks || []}
          editOpportunity={this.editOpportunity}
          deleteOpportunity={this.deleteOpportunity}
          locale={this.context.locale}
          currency={this.context.currency}
        />
      </Grid>
    );

    return content;
  }
}

export default OpportunityContainer;
