import React, { Component } from "react";
import InvoiceServices from "services/franchise-billing/invoices.services";
import UserContext from "providers/UserProvider";
import { InvoiceList, Grid, setLocale, Alert } from "react-library-sm";
import { Redirect } from "react-router-dom";
import FileSaver from "file-saver";
import moment from "moment";
import momentTz from "moment-timezone";
import { createDashboard } from "utils/dashboard";

class InvoiceListComponent extends Component {
  constructor(props) {
    super(props);
    this.invoiceService = new InvoiceServices();
    this.state = {
      compareInvoices: [],
      invoices: [],
      invoice_pdf: null,
      invoice: null,
      duplicated: false,
      invoiceDuplicated: null,
      invoicesToPDF: [],
      alertAPI: false,
      alert: null,
      dashboard: {},
      year: moment().format("YYYY"),
      yearsDownloaded: [],
      compareYears: [],
    };
    setLocale("ES");
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };
  static contextType = UserContext;

  createDateCategoryInInvoices = (invoices) => {
    return (
      invoices?.map((invoice) => {
        return {
          ...invoice,
          dateCategory: `${invoice.date.substring(0, 4)}_${invoice.category}`,
        };
      }) || []
    );
  };

  callApi = (year) => {
    this.invoiceService
      .getInvoices(this.context.franchise, year || this.state.year)
      .then((response) => {
        // console.log("response get invoices", response);
        // console.log(
        //   "dashboard created",
        //   createDashboard(response.data.invoices)
        // );
        if (year) {
          this.setState(
            {
              compareInvoices: [
                ...this.state.compareInvoices,
                ...this.createDateCategoryInInvoices(response.data.invoices),
              ],
              dashboard: {
                ...this.state.dashboard,
                [`total_${year}`]: response.data.invoices.reduce(
                  (total, currentInvoice) => currentInvoice.subtotal + total,
                  0
                ),
              },
            },
            () => {
              console.log(this.state.dashboard);
            }
          );
        } else {
          const invoicesWithTimeZone = response.data.invoices.map(
            this.generateInvoiceWithTimeZone
          );

          console.log(invoicesWithTimeZone);
          this.setState(
            {
              invoices: invoicesWithTimeZone,
              dashboard: createDashboard(
                [...invoicesWithTimeZone],
                this.state.year,
                this.state.dashboard
              ),
              year: this.state.year,
            },
            () => {
              console.log(this.state.dashboard);
            }
          );
        }
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            linkText:
              this.context.translation.templates.invoices.alerts
                .download_invoices.error.submit,
            link: "/invoices",
          },
        })
      );
  };

  generateInvoiceWithTimeZone = (invoice) => ({
    ...invoice,
    date: momentTz(invoice.date)
      .tz("Europe/Madrid")
      .format("YYYY-MM-DDTHH:mm:ss"),
  });

  componentDidMount() {
    this.callApi();
  }

  handleChangeYear = (year) => {
    this.setState({ year }, () => {
      this.callApi();
    });
  };

  handleSelectYears = (years) => {
    console.log(years);
    const stateYears = [...this.state.yearsDownloaded];
    const yearsToDownload = years.filter((year) => !stateYears.includes(year));

    yearsToDownload.forEach((year) => {
      this.callApi(year);
      stateYears.push(year);
    });

    this.setState({ yearsDownloaded: stateYears, compareYears: years });
  };

  getInvoicesPdf = (ids) => {
    Promise.all([...ids.map((id) => this.invoiceService.getInvoice(id))])
      .then((responses) => {
        const invoicesToPDF = [];
        responses.forEach((response) => {
          invoicesToPDF.push(response.data);
        });

        this.setState({ invoicesToPDF });
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            linkText:
              this.context.translation.templates.invoices.alerts
                .download_invoices.error.submit,
            link: "/invoices",
            onSubmit: this.handleOnCloseAlert,
          },
        })
      );
  };

  getInvoicePdf = (id) => {
    this.invoiceService
      .getInvoice(id)
      .then((response) => {
        this.setState({ invoice_pdf: response.data });
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            linkText:
              this.context.translation.templates.invoices.alerts.get_pdf.error
                .submit,
            link: "/invoices",
            onSubmit: this.handleOnCloseAlert,
          },
        })
      );
  };

  editStatus = (values) => {
    this.invoiceService
      .updateStatus(values, this.context.franchise)
      .then((response) => {
        if (response.status === 200) {
          let invoices = [...this.state.invoices];
          invoices.some((invoice, index) => {
            if (invoice._id === response.data.invoice._id) {
              invoices[index] = {
                ...response.data.invoice,
                client:
                  response.data.invoice.franchise ||
                  response.data.invoice.client,
              };
              return true;
            } else return false;
          });

          this.setState({ invoices, dashboard: createDashboard(invoices) });
        }
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            linkText:
              this.context.translation.templates.invoices.alerts.edit_status
                .error.submit,
            link: "/invoices",
            onSubmit: this.handleOnCloseAlert,
          },
        })
      );
  };

  handleDuplicateInvoice = (id) => {
    // console.log("franchise duplicate handle", this.context.franchise);
    this.invoiceService
      .duplicateInvoice(id, this.context.franchise)
      .then((response) => {
        // console.log("duplicateInvoice response,", response);
        this.setState({
          invoiceDuplicated: response.data._id,
          duplicated: true,
        });
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            linkText:
              this.context.translation.templates.invoices.alerts.duplicate.error
                .submit,
            link: "/invoices",
            onSubmit: this.handleOnCloseAlert,
          },
        })
      );
  };

  handleAmendInvoice = (id) => {
    this.invoiceService
      .amendInvoice(id, this.context.franchise)
      .then((response) => {
        // console.log("amendInvoice response", response);
        let invoices = [...this.state.invoices];
        invoices = invoices.map((_invoice) => {
          if (_invoice._id === id) {
            return { ..._invoice, status: "amended" };
          } else {
            return { ..._invoice };
          }
        });

        invoices.unshift({ ...response.data.invoice });
        this.setState({
          invoices,
          dashboard: createDashboard(invoices),
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.invoices.alerts.amend
              .success.submit,
            submit:
              this.context.translation.templates.invoices.alerts.amend.success
                .submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            linkText:
              this.context.translation.templates.invoices.alerts.amend.error
                .submit,
            link: "/invoices",
            onSubmit: this.handleOnCloseAlert,
          },
        })
      );
  };

  handleDeleteInvoice = (invoice) => {
    // console.log("invoice to delete", invoice);
    this.invoiceService
      .deleteInvoice(invoice._id)
      .then((response) => {
        // console.log("response delete invoice", response.data);
        let invoices = [...this.state.invoices];
        invoices = invoices.filter((_invoice) => _invoice._id !== invoice._id);
        this.setState({
          invoices,
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.invoices.alerts.delete
              .success.submit,
            submit:
              this.context.translation.templates.invoices.alerts.delete.success
                .submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            linkText:
              this.context.translation.templates.invoices.alerts.delete.error
                .submit,
            link: "/invoices",
            onSubmit: this.handleOnCloseAlert,
          },
        })
      );
  };

  downloadFace = (values) => {
    const { id, password } = values;
    console.log("invoice to download", values);

    this.invoiceService
      .downloadFace(id, password)
      .then((response) => {
        const invoice = new Blob([response.data], {
          type: "text/xml",
        });

        FileSaver.saveAs(invoice, "factura.xsig");
        console.log(
          "downloadFace response,",
          JSON.stringify(response.data),
          invoice
        );
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "warning",
            submit:
              this.context.translation.templates.invoices.alerts.face.warning
                .submit,
            text: this.context.translation.templates.invoices.alerts.face
              .warning.text,
            onSubmit: this.handleOnCloseAlert,
          },
        })
      );
  };

  getFilteredInvoices = (categories, statuses, dates) => {
    // console.log(
    //   "categories",
    //   categories,
    //   "statuses",
    //   statuses,
    //   "dates",
    //   dates,
    //   "dashboard",
    //   this.state.dashboard
    // );
    if (
      categories.length === 0 &&
      statuses.length === 0 &&
      !dates.from &&
      !dates.to
    ) {
      return Promise.reject();
    }

    const filteredInvoices = this.state.invoices.filter((invoice) => {
      // console.log(
      //   "check de fecha",
      //   invoice.date,
      //   dates.from && dates.to
      //     ? moment(invoice.date).isBetween(moment(dates.from), moment(dates.to))
      //     : dates.from
      //     ? moment(invoice.date).isSameOrAfter(dates.from)
      //     : dates.to
      //     ? moment(invoice.date).isSameOrBefore(dates.to)
      //     : true
      // );
      return (
        (categories.length > 0
          ? categories.includes(invoice.category)
          : true) &&
        (statuses.length > 0 ? statuses.includes(invoice.status) : true) &&
        (dates.from && dates.to
          ? moment(invoice.date).isBetween(moment(dates.from), moment(dates.to))
          : dates.from
          ? moment(invoice.date).isSameOrAfter(dates.from)
          : dates.to
          ? moment(invoice.date).isSameOrBefore(dates.to)
          : true)
      );
    });

    // console.log(filteredInvoices);
    return Promise.resolve({
      data: {
        dashboard: createDashboard(filteredInvoices),
        invoices: filteredInvoices,
      },
    });
  };

  render() {
    let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {alert}
        {this.state.duplicated ? (
          <Redirect
            to={`/invoices/${
              this.state.invoiceDuplicated && this.state.invoiceDuplicated
            }/edit`}
          />
        ) : null}
        <InvoiceList
          invoices={this.state.invoices}
          compareInvoices={this.state.compareInvoices}
          getInvoicePdf={this.getInvoicePdf}
          invoice_pdf={this.state.invoice_pdf}
          editStatus={this.editStatus}
          handleDuplicateInvoice={this.handleDuplicateInvoice}
          handleDeleteInvoice={this.handleDeleteInvoice}
          handleAmendInvoice={this.handleAmendInvoice}
          handleSelectInvoicesToDownload={this.getInvoicesPdf}
          handleSelectInvoicesToSend={this.handleSendNotificationInvoices}
          handleDownloadFaceInvoicePopover={
            this.handleDownloadFaceInvoicePopover
          }
          invoicesToPDF={this.state.invoicesToPDF}
          dashboard={this.state.dashboard}
          franchise
          translation={
            this.context.translation.templates
              ? this.context.translation.templates.invoices
              : null
          }
          checkPassword={this.downloadFace}
          locale={this.context.locale}
          year={this.state.year}
          handleChangeYear={this.handleChangeYear}
          getFilteredInvoices={this.getFilteredInvoices}
          years={this.state.compareYears}
          handleSelectYears={this.handleSelectYears}
        />
      </Grid>
    );
  }
}

export default InvoiceListComponent;
