import Axios from "core/axios";

class OpportunitiesServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_CRM;
    this.service = new Axios(this.api_url);
  }

  getOpportunities = franchise => {
    return this.service.get("/opportunities?franchise=" + franchise);
  };

  addOpportunity = data => {
    return this.service.post("/opportunities", data);
  };

  updateOpportunity = (data, franchise, position) => {
    return this.service.put(
      "/opportunities/" +
        data._id +
        "?franchise=" +
        franchise +
        "&position=" +
        (position || 0),
      data
    );
  };

  deleteOpportunity = (id, franchise) => {
    return this.service.delete(
      "/opportunities/" + id + "?franchise=" + franchise
    );
  };

  //   addContact = data => {
  //     return this.service.post("/contacts", data);
  //   };

  //   updateContact = data => {
  //     return this.service.put(`/contacts/${data._id}`, data);
  //   };

  getOpportunity = (id, franchise) => {
    return this.service.get("/opportunities/" + id + "?franchise=" + franchise);
  };
}

export default OpportunitiesServices;
