import Axios from "core/axiosCampus";

class WebCampusServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_CAMPUS;
    this.service = new Axios(this.api_url);
  }

  // --------------------------------- LIST

  // ----- GET

  getCampusList = () => this.service.get(`/campus`);

  // ----- POST

  postCampusSingle = (data) => this.service.post(`/campus`, data);

  postCampusGroupSingleActivity = (campusId, groupId, data) =>
    this.service.post(`/campus/${campusId}/groups/${groupId}/activities`, data);

  // --------------------------------- SINGLE

  // ----- GET

  getCampusSingle = (campusId) => this.service.get(`/campus/${campusId}`);

  getCampusGroupSingle = (campusId, groupId) =>
    this.service.get(`/campus/${campusId}/groups/${groupId}`);

  // ----- POST

  postCampusSingleGroups = (campusId, data) =>
    this.service.post(`/campus/${campusId}/groups`, data);

  postCampusSingleUsers = (campusId, data) =>
    this.service.post(`/campus/${campusId}/users`, data);

  postCampusSingleVenues = (campusId, data) =>
    this.service.post(`/campus/${campusId}/venues`, data);

  // ----- PUT

  putCampusSingle = (campusId, data) =>
    this.service.put(`/campus/${campusId}`, data);

  putCampusGroupSingle = (campusId, groupId, data) =>
    this.service.put(`/campus/${campusId}/groups/${groupId}`, data);

  putCampusSingleAssignUsersGroup = (campusId, groupId, data) =>
    this.service.put(`/campus/${campusId}/groups/${groupId}/users`, data);

  putCampusSingleVenue = (campusId, venueId, data) =>
    this.service.put(`/campus/${campusId}/venues/${venueId}`, data);

  putCampusSingleUser = (campusId, childKey, action, data) =>
    this.service.put(
      `/campus/${campusId}/users/${childKey}?action=${action}`,
      data
    );

  putCampusGroupSingleActivity = (campusId, groupId, activityId, data) =>
    this.service.put(
      `/campus/${campusId}/groups/${groupId}/activities/${activityId}`,
      data
    );

  // ----- DELETE
  deleteCampusSingleVenue = (campusId, venueId) =>
    this.service.delete(`/campus/${campusId}/venues/${venueId}`);

  deleteCampusGroupSingleActivity = (campusId, groupId, activityId) =>
    this.service.delete(
      `/campus/${campusId}/groups/${groupId}/activities/${activityId}`
    );

  deleteCampusGroupSingle = (campusId, groupId) =>
    this.service.delete(`/campus/${campusId}/groups/${groupId}`);

  deleteCampusSingle = (campusId) => this.service.delete(`/campus/${campusId}`);
}

export default WebCampusServices;
