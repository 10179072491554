const alerts = {
  create_contact: {
    success: {
      text: "Se he creado el contacto correctamente",
      submit: "Aceptar"
    },
    warning: {
      text:
        "Ha habido algún problema al añadir el contacto, intentalo de nuevo",
      submit: "Aceptar"
    }
  },
  create_opportunity: {
    success: {
      text: "Se he creado la oportunidad correctamente",
      submit: "Aceptar"
    },
    warning: {
      text:
        "Ha habido algún problema al añadir la oportunidad, intentalo de nuevo",
      submit: "Aceptar"
    }
  },
  create_project: {
    success: {
      text: "Se he creado el proyecto correctamente",
      submit: "Aceptar"
    },
    warning: {
      text:
        "Ha habido algún problema al añadir el proyecto, intentalo de nuevo",
      submit: "Aceptar"
    }
  },
  create_task: {
    success: {
      text: "Se ha creado la tarea correctamente",
      submit: "Aceptar"
    },
    warning: {
      text: "Ha habido algún problema al crear la tarea, intentalo de nuevo",
      submit: "Aceptar"
    }
  },
  delete_contact: {
    success: {
      text: "Se ha eliminado correctamente el contacto",
      submit: "Aceptar"
    },
    warning: {
      text: "Ha habido algún problema al añdir el contacto, intentalo de nuevo",
      submit: "Aceptar"
    }
  },
  delete_opportunity: {
    success: {
      text: "Se ha eliminado correctamente la oportunidad",
      submit: "Aceptar"
    },
    warning: {
      text:
        "Ha habido algún problema al añdir la oportunidad, intentalo de nuevo",
      submit: "Aceptar"
    }
  },
  delete_project: {
    success: {
      text: "Se ha eliminado correctamente el proyecto",
      submit: "Aceptar"
    },
    warning: {
      text: "Ha habido algún problema al añdir el proyecto, intentalo de nuevo",
      submit: "Aceptar"
    }
  },
  delete_task: {
    success: {
      text: "Se ha eliminado la tarea correctamente",
      submit: "Aceptar"
    },
    warning: {
      text: "Ha habido algún problema al eliminar la tarea, intentalo de nuevo",
      submit: "Aceptar"
    }
  },
  download_boards: {
    error: {
      text:
        "Error al descargar las oportunidades del board. Ponte en contacto con nosotros",
      submit: "Cerrar"
    }
  },
  download_contact: {
    error: {
      text: "Error al descargar el contacto. Ponte en contacto con nosotros",
      submit: "Cerrar"
    }
  },
  download_contacts: {
    error: {
      text: "Error al descargar los contactos. Ponte en contacto con nosotros",
      submit: "Cerrar"
    }
  },
  download_template: {
    error: {
      text: "Error al descargar la template. Ponte en contacto con nosotros",
      submit: "Cerrar"
    }
  },
  download_templates: {
    error: {
      text: "Error al descargar los templates. Ponte en contacto con nosotros",
      submit: "Cerrar"
    }
  },
  download_opportunity: {
    error: {
      text: "Error al descargar la oportunidad. Ponte en contacto con nosotros",
      submit: "Cerrar"
    }
  },
  download_opportunities: {
    error: {
      text:
        "Error al descargar las oportunidades. Ponte en contacto con nosotros",
      submit: "Cerrar"
    }
  },
  download_project: {
    error: {
      text: "Error  al descargar el proyecto. Ponte en contacto con nosotros",
      submit: "Cerrar"
    }
  },
  download_projects: {
    error: {
      text: "Error al descargar los proyectos. Ponte en contacto con nosotros",
      submit: "Cerrar"
    }
  },
  edit_contact: {
    success: {
      text: "Se ha editado el contacto correctamente",
      submit: "Aceptar"
    },
    warning: {
      text:
        "Ha habido algún problema al editar el contacto, intentalo de nuevo",
      submit: "Aceptar"
    }
  },
  edit_opportunity: {
    success: {
      text: "Se ha editado la oportunidad correctamente",
      submit: "Aceptar"
    },
    warning: {
      text:
        "Ha habido algún problema al editar la oportunidad, intentalo de nuevo",
      submit: "Aceptar"
    }
  },
  edit_task: {
    success: {
      text: "Se ha editado la tarea correctamente",
      submit: "Aceptar"
    },
    warning: {
      text: "Ha habido algún problema al editar la tarea, intentalo de nuevo",
      submit: "Aceptar"
    }
  }
};

export { alerts };
