import React from 'react';
import { Typography, Chip } from 'react-library-sm';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { options } from 'assets/mui-datatables/options-default';

import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const DashboardCRMTemplate = (translation) => {
	return {
		cards: {
			open_opportunities: {
				title: translation.dashboards.crm.cards.open_opportunities.title
			},
			total_budget: {
				title: translation.dashboards.crm.cards.total_budget.title
			}
		},
		datatables: {
			tasks: {
				title: translation.dashboards.crm.datatables.tasks.title,
				textLabels: translation.dashboards.crm.datatables.tasks.textLabels,
				columns: [
					{
						label: translation.dashboards.crm.datatables.tasks.columns.id,
						name: '_id',
						options: { display: false, filter: false }
					},
					{
						label: translation.dashboards.crm.datatables.tasks.columns.projectId,
						name: 'projectId',
						options: { display: false, filter: false }
					},
					{
						label: translation.dashboards.crm.datatables.tasks.columns.subject,
						name: 'subject',
						options: { filter: false }
					},
					{
						label: translation.dashboards.crm.datatables.tasks.columns.date,
						name: 'date',
						options: {
							filter: true,
							filterType: 'custom',
							customFilterListRender: (v) => {
								const values = v.map((_v) => moment(_v).format('DD-MM-YYYY HH:mm'));
								if (v[0] && v[1]) {
									return `${translation.opportunities.single.datatables.tasks.date
										.from} ${values[0]}, ${translation.opportunities.single.datatables.tasks.date
										.to} ${values[1]}`;
								} else if (values[0]) {
									return `${translation.opportunities.single.datatables.tasks.date
										.from} ${values[0]}`;
								} else if (values[1]) {
									return `${translation.opportunities.single.datatables.tasks.date.to} ${values[1]}`;
								}
								return false;
							},
							filterOptions: {
								names: [],
								logic(date, filters) {
									const momFilt = filters.map((filt) => moment(filt, 'DD-MM-YYYY HH:mm'));
									const momDate = moment(date, 'DD-MM-YYYY HH:mm');
									let show = true;

									if (filters[0] && filters[1]) {
										show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
									} else if (filters[0]) {
										show = momDate.isSameOrAfter(momFilt[0]);
									} else if (filters[1]) {
										show = momDate.isSameOrBefore(momFilt[1]);
									}
									return !show;
								},
								display: (filterList, onChange, index, column) => {
									// return <RangeDatePicker
									//   time
									//   translation={translation.opportunities.single.datatables.tasks.date ? translation.opportunities.single.datatables.tasks.date: null}
									//   values={filterList}
									//   onChange={onChange}
									//   index={index}
									//   column={column}
									// />
									return [
										<MuiPickersUtilsProvider utils={MomentUtils} locale="es">
											<KeyboardDateTimePicker
												ampm={false}
												clearable
												label={translation.opportunities.single.datatables.tasks.date.from}
												format="DD/MM/YYY HH:mm"
												value={filterList[index][0] || null}
												onChange={(event) => {
													filterList[index][0] = moment(event, 'DD/MM/YYY HH:mm');
													onChange(filterList[index], index, column);
												}}
												style={{ marginRight: '5%' }}
											/>
											<KeyboardDateTimePicker
												ampm={false}
												clearable
												label={translation.opportunities.single.datatables.tasks.date.to}
												format="DD/MM/YYY HH:mm"
												value={filterList[index][1] || null}
												onChange={(event) => {
													filterList[index][1] = moment(event, 'DD/MM/YYY HH:mm');
													onChange(filterList[index], index, column);
												}}
											/>
										</MuiPickersUtilsProvider>
									];
								}
							},
							customBodyRender: (value, tableMeta, updateValue) =>
								moment(tableMeta.rowData[3]).format('DD/MM/YYYY HH:mm')
						}
					},
					{
						label: translation.dashboards.crm.datatables.tasks.columns.person_of_contact,
						name: 'person_of_contact',
						options: {
							filter: false
						}
					},
					{
						label: translation.dashboards.crm.datatables.tasks.columns.client_name,
						name: 'client_name',
						options: {
							filter: false
						}
					},
					{
						label: translation.dashboards.crm.datatables.tasks.columns.service,
						name: 'projectName',
						options: {
							filter: false
						}
					},
					{
						label: translation.dashboards.crm.datatables.tasks.columns.type,
						name: 'type',
						options: {
							filter: true,
							filterType: 'checkbox',
							customFilterListRender: (v) =>
								`${translation.opportunities.single.datatables.tasks.columns.type} ${v}`,
							filterOptions: {
								names: Object.values(translation.opportunities.single.datatables.tasks.type),
								logic(type, filterVal) {
									const show =
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.type.call
										) >= 0 &&
											type === 'call') ||
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.type.email
										) >= 0 &&
											type === 'email') ||
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.type.meeting
										) >= 0 &&
											type === 'meeting') ||
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.type.visit
										) >= 0 &&
											type === 'visit');
									return !show;
								}
							},
							customBodyRender: (value, tableMeta, updateValue) => (
								<Typography variant="body2" gutterBottom>
									{translation.dashboards.crm.datatables.tasks.type[tableMeta.rowData[7]]}
								</Typography>
							)
						}
					},
					{
						label: translation.dashboards.crm.datatables.tasks.columns.status,
						name: 'status',
						options: {
							filter: true,
							filterType: 'checkbox',
							customFilterListRender: (v) =>
								`${translation.opportunities.single.datatables.tasks.columns.status} ${v}`,
							filterOptions: {
								names: Object.values(translation.opportunities.single.datatables.tasks.status),
								logic(status, filterVal) {
									const show =
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.status.pending
										) >= 0 &&
											status === 'pending') ||
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.status.done
										) >= 0 &&
											status === 'done');
									return !show;
								}
							},
							customBodyRender: (value, tableMeta, updateValue) => (
								<Chip
									label={translation.dashboards.crm.datatables.tasks.status[tableMeta.rowData[8]]}
									style={
										tableMeta.rowData[6] === 'done' ? (
											{
												background: '#77ffb5',
												color: '#616161'
											}
										) : (
											{
												background: '#ffe082',
												color: '#616161'
											}
										)
									}
								/>
							)
						}
					},
					{
						label: translation.dashboards.crm.datatables.tasks.columns.opportunity_status,
						name: 'opportunity_status',
						options: {
							filter: true,
							filterType: 'checkbox',
							customFilterListRender: (v) =>
								`${translation.opportunities.single.datatables.tasks.columns.opportunity_status} ${v}`,
							filterOptions: {
								names: Object.values(
									translation.opportunities.single.datatables.tasks.opportunity_status
								),
								logic(opportunity_status, filterVal) {
									const show =
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.opportunity_status.lead
										) >= 0 &&
											opportunity_status === 'lead') ||
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.opportunity_status.offer
										) >= 0 &&
											opportunity_status === 'offer') ||
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.opportunity_status
												.proposal
										) >= 0 &&
											opportunity_status === 'proposal') ||
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.opportunity_status
												.hibernate
										) >= 0 &&
											opportunity_status === 'hibernate') ||
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.opportunity_status.won
										) >= 0 &&
											opportunity_status === 'won') ||
										(filterVal.indexOf(
											translation.opportunities.single.datatables.tasks.opportunity_status.lost
										) >= 0 &&
											opportunity_status === 'lost');
									return !show;
								}
							},
							customBodyRender: (value, tableMeta, updateValue) => (
								<Chip
									label={
										translation.dashboards.crm.datatables.tasks.opportunity_status[
											tableMeta.rowData[9]
										]
									}
								/>
							)
						}
					},
					{
						label: '',
						name: 'options',
						options: {
							filter: false,
							sort: false,
							viewColumns: false,
							empty: true,
							label: true,
							customBodyRender: (value, tableMeta, updateValue) => {
								return (
									<Link to={`/pipeline/${tableMeta.rowData[1]}/view`} style={{ color: '#fff' }}>
										<RemoveRedEye />
									</Link>
								);
							}
						}
					}
				],
				options: options
			}
		}
	};
};

export { DashboardCRMTemplate };
