import Spanish from "./icons/spain.png";
import English from "./icons/united-kingdom.png";
import French from "./icons/france.png";
import Catalonia from "./icons/catalonia.png";
import Portugal from "./icons/portugal.png";
import Basque from "./icons/basque.png";
import German from "./icons/germany.png";
import Chinese from "./icons/china.png";
import Italian from "./icons/italy.png";

const languages = [
  {
    value: "es",
    text: "Spanish",
    valueImage: Spanish,
    translation: "./assets/languages/i18n",
  },
  {
    value: "en",
    text: "English",
    valueImage: English,
    translation: "./assets/languages/i18n",
  },
  // {
  //   value: "ca",
  //   text: "Catalá",
  //   valueImage: Catalonia,
  //   translation: "./assets/languages/i18n",
  // },
  {
    value: "pt",
    text: "Português",
    valueImage: Portugal,
    translation: "./assets/languages/i18n",
  },
  // {
  //   value: "eu",
  //   text: "Euskera",
  //   valueImage: Basque,
  //   translation: "./assets/languages/i18n",
  // },
  {
    value: "de",
    text: "Deutsch",
    valueImage: German,
    translation: "./assets/languages/i18n",
  },
  {
    value: "it",
    text: "Italiano",
    valueImage: Italian,
    translation: "./assets/languages/i18n",
  },
  {
    value: "fr",
    text: "Français",
    valueImage: French,
    translation: "./assets/languages/i18n",
  },
  // {
  //   value: "zh-CN",
  //   text: "(中国人) Chinese ",
  //   valueImage: Chinese,
  //   translation: "./assets/languages/i18n",
  // },
];

export default languages;
