import moment from "moment";

const WebCampusGroupSingleTemplate = (translation) => {
  return {
    templates: {
      error: translation.templates.error,
      loading: translation.templates.loading,
      downloading: translation.templates.downloading,
    },
    dialogs: {
      delete_group: {
        title: translation.dialogs.delete_group.title,
        text: translation.dialogs.delete_group.text,
        buttons: {
          cancel: translation.dialogs.delete_group.buttons.cancel,
          submit: translation.dialogs.delete_group.buttons.submit,
        },
      },
      edit_group: {
        title: translation.dialogs.edit_group.title,
        inputs: {
          name: translation.dialogs.edit_group.inputs.name,
          date_start: translation.dialogs.edit_group.inputs.date_start,
          date_end: translation.dialogs.edit_group.inputs.date_end,
          size: translation.dialogs.edit_group.inputs.size,
        },
        buttons: {
          cancel: translation.dialogs.edit_group.buttons.cancel,
          save: translation.dialogs.edit_group.buttons.save,
        },
      },
      add_staff_trainer: {
        title: translation.dialogs.add_staff_trainer.title,
        steps: {
          default: translation.dialogs.add_staff_trainer.steps.default,
          select: translation.dialogs.add_staff_trainer.steps.select,
          create: translation.dialogs.add_staff_trainer.steps.create,
        },
        selects: {
          groups: translation.dialogs.add_staff_trainer.selects.groups,
          sports: translation.dialogs.add_staff_trainer.selects.sports,
          staff: translation.dialogs.add_staff_trainer.selects.staff,
        },
        buttons: {
          select_staff:
            translation.dialogs.add_staff_trainer.buttons.select_staff,
          create_staff:
            translation.dialogs.add_staff_trainer.buttons.create_staff,
          back: translation.dialogs.add_staff_trainer.buttons.back,
          next: translation.dialogs.add_staff_trainer.buttons.next,
          save: translation.dialogs.add_staff_trainer.buttons.save,
          cancel: translation.dialogs.add_staff_trainer.buttons.cancel,
          empty_options:
            translation.dialogs.add_staff_trainer.buttons.empty_options,
        },
        inputs: {
          name: translation.dialogs.add_staff_trainer.inputs.name,
          email: translation.dialogs.add_staff_trainer.inputs.email,
          phone: translation.dialogs.add_staff_trainer.inputs.phone,
          identification_number:
            translation.dialogs.add_staff_trainer.inputs.identification_number,
          ssn: translation.dialogs.add_staff_trainer.inputs.ssn,
          bank_account_number:
            translation.dialogs.add_staff_trainer.inputs.bank_account_number,
        },
        texts: {
          optional: translation.dialogs.add_staff_trainer.texts.optional,
          empty_options:
            translation.dialogs.add_staff_trainer.texts.empty_options,
          sports: translation.dialogs.add_staff_trainer.texts.sports,
        },
      },
      add_staff_coordinator: {
        title: translation.dialogs.add_staff_coordinator.title,
        steps: {
          default: translation.dialogs.add_staff_coordinator.steps.default,
          select: translation.dialogs.add_staff_coordinator.steps.select,
          create: translation.dialogs.add_staff_coordinator.steps.create,
        },
        selects: {
          groups: translation.dialogs.add_staff_coordinator.selects.groups,
          sports: translation.dialogs.add_staff_coordinator.selects.sports,
          staff: translation.dialogs.add_staff_coordinator.selects.staff,
        },
        buttons: {
          select_staff:
            translation.dialogs.add_staff_coordinator.buttons.select_staff,
          create_staff:
            translation.dialogs.add_staff_coordinator.buttons.create_staff,
          back: translation.dialogs.add_staff_coordinator.buttons.back,
          next: translation.dialogs.add_staff_coordinator.buttons.next,
          save: translation.dialogs.add_staff_coordinator.buttons.save,
          cancel: translation.dialogs.add_staff_coordinator.buttons.cancel,
          empty_options:
            translation.dialogs.add_staff_coordinator.buttons.empty_options,
        },
        inputs: {
          name: translation.dialogs.add_staff_coordinator.inputs.name,
          email: translation.dialogs.add_staff_coordinator.inputs.email,
          phone: translation.dialogs.add_staff_coordinator.inputs.phone,
          identification_number:
            translation.dialogs.add_staff_coordinator.inputs
              .identification_number,
          ssn: translation.dialogs.add_staff_coordinator.inputs.ssn,
          bank_account_number:
            translation.dialogs.add_staff_coordinator.inputs
              .bank_account_number,
        },
        texts: {
          optional: translation.dialogs.add_staff_coordinator.texts.optional,
          empty_options:
            translation.dialogs.add_staff_coordinator.texts.empty_options,
          sports: translation.dialogs.add_staff_coordinator.texts.sports,
        },
      },
      add_activity: {
        texts: {
          date_repeats: translation.dialogs.add_activity.texts.date_repeats,
          details_family: translation.dialogs.add_activity.texts.details_family,
          details_family_text:
            translation.dialogs.add_activity.texts.details_family_text,
          details_staff: translation.dialogs.add_activity.texts.details_staff,
          details_staff_text:
            translation.dialogs.add_activity.texts.details_staff_text,
          date_start: translation.dialogs.add_activity.texts.date_start,
          date_end: translation.dialogs.add_activity.texts.date_end,
          venue: translation.dialogs.add_activity.texts.venue,
        },
        inputs: {
          date_start: translation.dialogs.add_activity.inputs.date_start,
          date_end: translation.dialogs.add_activity.inputs.date_end,
          name: translation.dialogs.add_activity.inputs.name,
        },
        selects: {
          venue: translation.dialogs.add_activity.selects.venue,
        },
        buttons: {
          cancel: translation.dialogs.add_activity.buttons.cancel,
          edit: translation.dialogs.add_activity.buttons.edit,
          submit: translation.dialogs.add_activity.buttons.submit,
          import_activities:
            translation.dialogs.add_activity.buttons.import_activities,
        },
        dialogs: {
          import_activities: {
            title:
              translation.dialogs.add_activity.dialogs.import_activities.title,
            buttons: {
              download_template:
                translation.dialogs.add_activity.dialogs.import_activities
                  .buttons.download_template,
              select:
                translation.dialogs.add_activity.dialogs.import_activities
                  .buttons.select,
              cancel:
                translation.dialogs.add_activity.dialogs.import_activities
                  .buttons.cancel,
              import:
                translation.dialogs.add_activity.dialogs.import_activities
                  .buttons.import,
            },
            titles: {
              loading:
                translation.dialogs.add_activity.dialogs.import_activities
                  .titles.loading,
              error:
                translation.dialogs.add_activity.dialogs.import_activities
                  .titles.error,
            },
            title_instructions:
              translation.dialogs.add_activity.dialogs.import_activities
                .title_instructions,
            text_instructions:
              translation.dialogs.add_activity.dialogs.import_activities
                .text_instructions,
            instructions:
              translation.dialogs.add_activity.dialogs.import_activities
                .instructions,
          },
        },
        datatables: {
          import_activities: {
            columns: [
              {
                name: "start",
                label:
                  translation.dialogs.add_activity.datatables.import_activities
                    .columns.start,
                options: {
                  customBodyRender: (value, tableMeta, updateValue) =>
                    moment(value).format("DD/MM/YYYY"),
                },
              },
              {
                name: "end",
                label:
                  translation.dialogs.add_activity.datatables.import_activities
                    .columns.end,
                options: {
                  customBodyRender: (value, tableMeta, updateValue) =>
                    moment(value).format("DD/MM/YYYY"),
                },
              },
              {
                name: "venue",
                label:
                  translation.dialogs.add_activity.datatables.import_activities
                    .columns.venue,
              },
            ],
          },
        },
      },
    },
    activities: {
      dialogs: {
        activity: {
          texts: {
            date_repeats:
              translation.activities.dialogs.activity.texts.date_repeats,
            details_family:
              translation.activities.dialogs.activity.texts.details_family,
            details_family_text:
              translation.activities.dialogs.activity.texts.details_family_text,
            details_staff:
              translation.activities.dialogs.activity.texts.details_staff,
            details_staff_text:
              translation.activities.dialogs.activity.texts.details_staff_text,
            date_start:
              translation.activities.dialogs.activity.texts.date_start,
            date_end: translation.activities.dialogs.activity.texts.date_end,
            venue: translation.activities.dialogs.activity.texts.venue,
          },
          inputs: {
            date_start:
              translation.activities.dialogs.activity.inputs.date_start,
            date_end: translation.activities.dialogs.activity.inputs.date_end,
            name: translation.activities.dialogs.activity.inputs.name,
          },
          selects: {
            venue: translation.activities.dialogs.activity.selects.venue,
          },
          buttons: {
            cancel: translation.activities.dialogs.activity.buttons.cancel,
            edit: translation.activities.dialogs.activity.buttons.edit,
            submit: translation.activities.dialogs.activity.buttons.submit,
            import_activities:
              translation.activities.dialogs.activity.buttons.import_activities,
          },
          dialogs: {
            import_activities: {
              title:
                translation.activities.dialogs.activity.dialogs
                  .import_activities.title,
              buttons: {
                download_template:
                  translation.activities.dialogs.activity.dialogs
                    .import_activities.buttons.download_template,
                select:
                  translation.activities.dialogs.activity.dialogs
                    .import_activities.buttons.select,
                cancel:
                  translation.activities.dialogs.activity.dialogs
                    .import_activities.buttons.cancel,
                import:
                  translation.activities.dialogs.activity.dialogs
                    .import_activities.buttons.import,
              },
              titles: {
                loading:
                  translation.activities.dialogs.activity.dialogs
                    .import_activities.titles.loading,
                error:
                  translation.activities.dialogs.activity.dialogs
                    .import_activities.titles.error,
              },
              title_instructions:
                translation.activities.dialogs.activity.dialogs
                  .import_activities.title_instructions,
              text_instructions:
                translation.activities.dialogs.activity.dialogs
                  .import_activities.text_instructions,
              instructions:
                translation.activities.dialogs.activity.dialogs
                  .import_activities.instructions,
            },
          },
          datatables: {
            import_activities: {
              columns: [
                {
                  name: "start",
                  label:
                    translation.activities.dialogs.activity.datatables
                      .import_activities.columns.start,
                  options: {
                    customBodyRender: (value, tableMeta, updateValue) =>
                      moment(value).format("DD/MM/YYYY"),
                  },
                },
                {
                  name: "end",
                  label:
                    translation.activities.dialogs.activity.datatables
                      .import_activities.columns.end,
                  options: {
                    customBodyRender: (value, tableMeta, updateValue) =>
                      moment(value).format("DD/MM/YYYY"),
                  },
                },
                {
                  name: "venue",
                  label:
                    translation.activities.dialogs.activity.datatables
                      .import_activities.columns.venue,
                },
              ],
            },
          },
        },
      },
      datatables: {
        activities: {
          columns: [
            {
              name: "_id",
              label: translation.activities.datatables.activities.columns.id,
              options: {
                display: false,
                filter: false,
              },
            },
            {
              name: "name",
              label: translation.activities.datatables.activities.columns.name,
            },
            {
              name: "options",
              label: "",
            },
          ],

          options: {
            selectableRows: false,
            download: false,
            print: false,
          },
        },
      },
      calendar: {
        messages: {
          date: translation.activities.calendar.messages.date,
          time: translation.activities.calendar.messages.time,
          event: translation.activities.calendar.messages.event,
          allDay: translation.activities.calendar.messages.allDay,
          week: translation.activities.calendar.messages.week,
          work_week: translation.activities.calendar.messages.work_week,
          day: translation.activities.calendar.messages.day,
          month: translation.activities.calendar.messages.month,
          previous: translation.activities.calendar.messages.previous,
          next: translation.activities.calendar.messages.next,
          yesterday: translation.activities.calendar.messages.yesterday,
          tomorrow: translation.activities.calendar.messages.tomorrow,
          today: translation.activities.calendar.messages.today,
          agenda: translation.activities.calendar.messages.agenda,
          noEventsInRange:
            translation.activities.calendar.messages.noEventsInRange,
          showMore: function (e) {
            return "+" + e + " más";
          },
        },
      },
    },
    attendance: {
      datatables: {
        attendance: {
          columns: [
            {
              label: translation.attendance.datatables.attendance.columns.id,
              name: "_id",
              options: {
                display: false,
                filter: false,
              },
            },
            {
              name: "date",
              label: translation.attendance.datatables.attendance.columns.date,
            },
            {
              name: "attending",
              label:
                translation.attendance.datatables.attendance.columns.attending,
            },
            {
              name: "not_attending",
              label:
                translation.attendance.datatables.attendance.columns
                  .not_attending,
            },
            {
              name: "percentage",
              label:
                translation.attendance.datatables.attendance.columns.percentage,
              options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                  value + "%",
              },
            },
          ],
          options: {
            selectableRows: false,
            download: false,
            print: false,
          },
        },
      },
      drawers: {
        attendance: {
          texts: {
            comments_empty:
              translation.attendance.drawers.attendance.texts.comments_empty,
            checkin: translation.attendance.drawers.attendance.texts.checkin,
            checkout: translation.attendance.drawers.attendance.texts.checkout,
            checkin_empty:
              translation.attendance.drawers.attendance.texts.checkin_empty,
            checkout_empty:
              translation.attendance.drawers.attendance.texts.checkout_empty,
          },
          buttons: {
            back: translation.attendance.drawers.attendance.buttons.back,
          },
        },
      },
    },
    users: {
      datatables: {
        users: {
          columns: [
            {
              label: translation.users.datatables.users.columns.key,
              name: "key",
              options: {
                display: false,
                filter: false,
              },
            },
            {
              name: "name",
              label: translation.users.datatables.users.columns.name,
            },
            {
              name: "emergency_contact",
              label:
                translation.users.datatables.users.columns.emergency_contact,
            },
            {
              name: "coordinators",
              label: translation.users.datatables.users.columns.coordinators,
              options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                  tableMeta.rowData[3].map((data) => data.name).join(", "),
              },
            },
            {
              name: "groups",
              label: translation.users.datatables.users.columns.groups,
              options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                  tableMeta.rowData[4].map((data) => data.name).join(", "),
              },
            },
          ],
          options: {
            selectableRows: false,
            download: false,
            print: false,
          },
        },
      },
      drawers: {
        user: {
          name: translation.users.drawers.user.name,
          allergies: translation.users.drawers.user.allergies,
          comments: translation.users.drawers.user.comments,
          parents: translation.users.drawers.user.parents,
          extraResponsibles: translation.users.drawers.user.extraResponsibles,
          groups: translation.users.drawers.user.groups,
          inputs: {
            name: translation.users.drawers.user.inputs.name,
            allergies: translation.users.drawers.user.inputs.allergies,
            comments: translation.users.drawers.user.inputs.comments,
            phone: translation.users.drawers.user.inputs.phone,
            email: translation.users.drawers.user.inputs.email,
          },
          selects: {
            groups: translation.users.drawers.user.selects.groups,
          },
          buttons: {
            cancel: translation.users.drawers.user.buttons.cancel,
            edit: translation.users.drawers.user.buttons.edit,
            delete: translation.users.drawers.user.buttons.delete,
          },
          texts: {
            delete_user: {
              title: translation.users.drawers.user.texts.delete_user.title,
              text: translation.users.drawers.user.texts.delete_user.text,
            },
          },
        },
      },
    },
    staff: {
      datatables: {
        staff: {
          columns: [
            {
              label: "id",
              name: "id",
              options: {
                display: false,
                filter: false,
              },
            },
            {
              name: "name",
              label: translation.staff.datatables.staff.columns.name,
            },
            {
              name: "role",
              label: translation.staff.datatables.staff.columns.role,
            },
          ],
          options: {
            selectableRows: false,
            download: false,
            print: false,
          },
        },
      },
      drawers: {
        staff: {
          name: translation.staff.drawers.staff.name,
          roles: {
            coordinator: translation.staff.drawers.staff.roles.coordinator,
            trainer: translation.staff.drawers.staff.roles.trainer,
          },
          personal_info: translation.staff.drawers.staff.personal_info,
          comments: translation.staff.drawers.staff.comments,
          sports: translation.staff.drawers.staff.sports,
          certificates: translation.staff.drawers.staff.certificates,
          groups: translation.staff.drawers.staff.groups,
          inputs: {
            name: translation.staff.drawers.staff.inputs.name,
            phone: translation.staff.drawers.staff.inputs.phone,
            email: translation.staff.drawers.staff.inputs.email,
          },
          selects: {
            groups: translation.staff.drawers.staff.selects.groups,
            role: translation.staff.drawers.staff.selects.role,
          },
          buttons: {
            cancel: translation.staff.drawers.staff.buttons.cancel,
            edit: translation.staff.drawers.staff.buttons.edit,
            delete: translation.staff.drawers.staff.buttons.delete,
          },
          texts: {
            delete_staff: {
              title: translation.staff.drawers.staff.texts.delete_staff.title,
              text: translation.staff.drawers.staff.texts.delete_staff.text,
            },
          },
        },
      },
    },
    tabs: [
      { value: "activities", label: translation.tabs.activities },
      { value: "users", label: translation.tabs.users },
      { value: "staff", label: translation.tabs.staff },
      { value: "attendance", label: translation.tabs.attendance },
    ],
    popovers: {
      campus: {
        label: translation.popovers.campus.label,
        labels: [
          {
            _id: "add_activity",
            variant: "button",
            perform: "campus:group:add_activity",
            label: translation.popovers.campus.labels.add_activity,
          },
          {
            _id: "add_staff_trainer",
            variant: "button",
            perform: "campus:add_staff_trainer",
            label: translation.popovers.campus.labels.add_staff_trainer,
          },
          {
            _id: "add_staff_coordinator",
            variant: "button",
            perform: "campus:add_staff_coordinator",
            label: translation.popovers.campus.labels.add_staff_coordinator,
          },
        ],
      },
    },
  };
};

export { WebCampusGroupSingleTemplate };
