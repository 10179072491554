const popovers = {
  contact: {
    single: {
      datatables: {
        tasks: {
          view: "Ver",
          edit: "Editar",
          delete: "Borrar"
        },
        projects: {
          view: "Ver",
          edit: "Editar",
          delete: "Borrar"
        },
        opportunities: {
          view: "Ver",
          edit: "Editar",
          delete: "Borrar"
        }
      }
    }
  },
  opportunities: {
    single: {
      datatables: {
        tasks: {
          view: "Ver",
          edit: "Editar",
          delete: "Borrar"
        }
      }
    }
  }
};

export { popovers };
