import React, { Component } from "react";
import { WebCampusGroupSingle } from "react-library-sm";
import moment from "moment";
import { Grid } from "@material-ui/core";
import WebCampusServices from "services/franchise-campus/web.campus.services";
import UserContext from "providers/UserProvider";
import StaffServices from "services/franchise-staff/staff.services";
import CoreServices from "services/services-core/core.services";
import { Redirect } from "react-router-dom";
import { s3GetObject } from "core/S3";

class ManagerWebCampusGroupSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "downloading",
      campusName: "",
      campusGroupData: null,
      calendarEvents: [],
      staff: [],
      sm_staff: [],
      campus_staff: [],
      activities: [],
      attendance: {
        downloaded: false,
        attendances: [],
      },
      deleted: false,
    };
    this.campusService = new WebCampusServices();
    this.staffService = new StaffServices();
    this.coreService = new CoreServices();
  }

  static contextType = UserContext;

  // dependencies
  // users.groups -> groups.staff
  // staff -> groups

  componentDidMount() {
    this.campusService
      .getCampusGroupSingle(
        this.props.match.params.campusId,
        this.props.match.params.groupId
      )
      .then((response) => {
        // console.log(response);
        this.setState({ status: "success" }, () => {
          this.createGroupCampus(response.data);
        });
      })
      .catch((err) => {
        // console.log(err.response);
        this.setState({ status: "error" });
      });
  }

  /** SHORTCUTS */

  createGroupCampus = (campusGroup) => {
    const campusGroupData = {
      name: campusGroup.groups[0].name,

      date: {
        start: moment(campusGroup.groups[0].date.start, "DD/MM/YYYY"),
        end: moment(campusGroup.groups[0].date.end, "DD/MM/YYYY"),
      },
      size: campusGroup.groups[0].size,
    };
    const groups = campusGroup.groups;
    // console.log("groups campus created");
    const campusName = campusGroup.name;
    // console.log("name campus created", campusName);
    const users = this.createUsers(campusGroup.users, campusGroup.groups);
    // console.log("users created", users);
    const venues = campusGroup.venues;
    // console.log("venues created", venues);
    const staff = this.createStaff(campusGroup.groups);
    // console.log("staff created", staff);
    const activities = this.createActivities(
      campusGroup.groups[0].activities,
      campusGroup.venues
    );
    // console.log("activities created", activities);

    //TODO calendar
    const calendarEvents = this.createCalendar(
      campusGroup.groups,
      campusGroup.venues
    );
    // console.log("calendar created", calendarEvents);
    this.setState({
      campusGroupData,
      campusName,
      activities,
      groups,
      users,
      staff,
      venues,
      calendarEvents,
    });
  };

  createCampusGroupFromAddStaff = (groups) => {
    this.createGroupCampus({
      groups: [groups],
      users: this.state.users,
      name: this.state.campusName,
      venues: this.state.venues,
    });
  };
  /** END SHORTCUTS */

  /**
   *  Lógica para crear las actividades
   */
  createActivities = (activities, venues) => {
    return activities.map((activity) => {
      return {
        ...activity,
        repeats: activity.repeats.map((repeat) => {
          return {
            ...repeat,
            venueName: venues.find((venue) => venue._id === repeat.venue)
              ? venues.find((venue) => venue._id === repeat.venue).name || ""
              : "",
            start: moment(repeat.start),
            end: moment(repeat.end),
          };
        }),
      };
    });
  };

  /*
        Logica para crear usuarios
        - Dependencias:
          Usuarios
          Grupos
        */
  createUsers = (users, groups) => {
    const auxUsers = users.map((user) => {
      return {
        ...user,
        emergency_contact: user.parents
          ? user.parents[0]
            ? `${user.parents[0].name} / ${user.parents[0].phone}`
            : "Sin contactos asignados"
          : "Sin contactos asignados",
        //filtrar grupos en los que el usuario aparece
        coordinators: groups
          .filter((group) =>
            user.groups.find((_group) => _group.key === group._id)
              ? true
              : false
          )
          //recorrer grupos para
          .map((group) =>
            group.staff
              //filtrar en los que está el rol de coordinador
              .filter((staffWorker) => staffWorker.role === "coordinator")
              //devolver array de coordinadores
              .map((staffWorker) => {
                return { _id: staffWorker._id, name: staffWorker.name };
              })
          )
          //pasar a un unico array
          .flat()
          //eliminar coordinadores repetidos
          .reduce(
            (unique, item) =>
              unique.find((_item) => item._id === _item._id)
                ? unique
                : [...unique, item],
            []
          ),
      };
    });

    return auxUsers;
  };

  /*
        Logica para crear staff
        - Dependencias:
          Grupos
        */
  createStaff = (groups) => {
    const auxStaff = groups
      // recorrer grupos y sacar staff
      .map((group) =>
        group.staff.map((staffWorker) => {
          return {
            ...staffWorker,
            groups: [{ _id: group._id, name: group.name }],
          };
        })
      )
      // pasar a un unico array
      .flat()
      // eliminar repetidos uniendo los grupos en los que aparezcan
      .reduce(
        (unique, item) =>
          unique.find((_item) => item.id === _item.id)
            ? unique.map((uniqueItem) => {
                if (uniqueItem.id === item.id) {
                  let auxGroups = [...uniqueItem.groups];
                  item.groups.forEach((group) => {
                    if (!auxGroups.includes(group)) {
                      auxGroups.push(group);
                    }
                  });
                  return { ...uniqueItem, groups: auxGroups };
                }
                return { ...uniqueItem };
              })
            : [...unique, item],
        []
      )
      // preparar para vista
      .map((staffWorker) => {
        return {
          ...staffWorker,
          groups: staffWorker.groups,
          downloaded: false,
        };
      });

    return auxStaff;
  };

  /*
        Logica para crear actividades por instalacion
        - Dependencias:
          Grupos
          Instalaciones
        */
  createCalendar = (groups, venues) => {
    //Recorremos venues para crear los componentes de agrupacion de react-big-sheduler
    const calendarEvents = groups
      //recorremos grupos
      .map((group) => {
        return (
          group.activities
            //recorremos actividades
            .map((activity) => {
              return (
                activity.repeats
                  //filtramos repeticiones de actividad por venue
                  .map((repeat) => {
                    return {
                      start: moment(repeat.start).toDate(),
                      end: moment(repeat.end).toDate(),
                      title: repeat.venue
                        ? venues.find((venue) => venue._id === repeat.venue)
                          ? activity.name +
                            " | " +
                            venues.find((venue) => venue._id === repeat.venue)
                              .name
                          : activity.name
                        : activity.name,
                      idActivity: activity._id,
                    };
                  })
              );
            })
            .flat()
        );
      })
      .flat();

    return calendarEvents;
  };

  /* -------------     HANDLE FUNCTIONS     ------------- */

  // Funciones campus

  handleEditGroup = (data) => {
    const sendGroup = {
      ...data,
      date: {
        start: moment(data.date.start).format("DD/MM/YYYY"),
        end: moment(data.date.end).format("DD/MM/YYYY"),
      },
    };
    this.setState({ status: "loading" }, () => {
      this.campusService
        .putCampusGroupSingle(
          this.props.match.params.campusId,
          this.props.match.params.groupId,
          sendGroup
        )
        .then((response) => {
          // console.log("response edit group", response);
          const groups = this.state.groups;
          groups[0].name = response.data.name;
          groups[0].date = response.data.date;
          groups[0].size = response.data.size;
          const users = this.state.users.map((user) => {
            return {
              ...user,
              groups: user.groups.map((_group) => {
                if (_group.key === response.data._id) {
                  return { ..._group, name: response.data.name };
                } else {
                  return _group;
                }
              }),
            };
          });
          this.setState({ status: "success" }, () => {
            this.createGroupCampus({
              users,
              venues: this.state.venues,
              name: this.state.campusName,
              groups,
            });
          });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
    // data.date.start = data.date.start.format("DD/MM/YYYY");
    // data.date.end = data.date.end.format("DD/MM/YYYY");
  };

  handleDeleteGroup = () => {
    this.setState({ status: "loading" }, () => {
      this.campusService
        .deleteCampusGroupSingle(
          this.props.match.params.campusId,
          this.props.match.params.groupId
        )
        .then((response) => {
          // console.log("response delete campus group single", response);
          this.setState({ deleted: true });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  // Funciones popover
  handleAddActivity = (data) => {
    const sendActivity = {
      ...data,
      repeats: data.repeats.map((repeat) => {
        return {
          ...repeat,
          start: moment(repeat.start).format("YYYY-MM-DDTHH:mm:ssZ"),
          end: moment(repeat.end).format("YYYY-MM-DDTHH:mm:ssZ"),
        };
      }),
    };
    this.setState({ status: "loading" }, () => {
      this.campusService
        .postCampusGroupSingleActivity(
          this.props.match.params.campusId,
          this.props.match.params.groupId,
          sendActivity
        )
        .then((response) => {
          // console.log("response add activity", response);
          const groups = this.state.groups;
          groups[0].activities.push(response.data);
          this.setState({ status: "success" }, () => {
            this.createGroupCampus({
              users: this.state.users,
              venues: this.state.venues,
              name: this.state.campusName,
              groups,
            });
          });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
    // console.log("data activity to add", sendActivity);
  };

  handleAddStaffTrainer = (data) => {
    const staff = { ...data };
    const option = data.option;
    // console.log("staff", staff);
    // console.log("option", option);
    this.setState({ status: "loading" }, () => {
      //OPTION SELECT => PUT
      switch (option) {
        case "select":
          const dataEmployee = this.state.sm_staff.find(
            (staffWorker) => staffWorker._id === staff.staffId
          );
          const selectData = {
            groups: { addTo: [staff.groupId], removeFrom: [] },
            employee: {
              name: dataEmployee.name,
              role: staff.role,
              rocketChatId: dataEmployee.rocket_chat.id,
            },
          };
          this.staffService
            .putCampusSingleStaff(
              this.context.franchise,
              this.props.match.params.campusId,
              staff.staffId,
              "edit",
              selectData
            )
            .then((response) => {
              // console.log("added staff by select", response);
              const groups = response.data.groups.find(
                (group) => group._id === this.props.match.params.groupId
              );
              if (groups) {
                this.setState({ status: "success" }, () => {
                  this.createCampusGroupFromAddStaff(groups);
                });
              }
            })
            .catch((err) => {
              // console.log(err.response);
              this.setState({ status: "error" });
            });
          break;
        case "create":
          const createGroupData = {
            name: staff.name,
            email: staff.email,
            phone: staff.phone,
            identificationNumber: staff.identificationNumber,
            ssn: staff.ssn,
            bankAccountNumber: staff.bankAccountNumber,
            sports: staff.sports,
            role: staff.role,
          };
          // console.log("call to api");

          this.staffService
            .postStaffAndAssignCampusGroup(
              this.context.franchise,
              this.props.match.params.campusId,
              { action: "group", groupId: staff.groupId },
              [createGroupData]
            )
            .then((response) => {
              // console.log("call to api ending and response");
              // console.log("response add trainer and assign group", response);
              this.setState({ status: "success" }, () => {
                this.createCampusGroupFromAddStaff(response.data);
              });
            })
            .catch((err) => {
              // console.log(err.response);
              this.setState({ status: "error" });
            });
          break;
        default:
          break;
      }
    });
  };

  handleAddStaffCoordinator = (data) => {
    const staff = { ...data };
    const option = data.option;
    // console.log("staff", staff);
    // console.log("option", option);
    this.setState({ status: "loading" }, () => {
      //OPTION SELECT => PUT
      switch (option) {
        case "select":
          const dataEmployee = this.state.sm_staff.find(
            (staffWorker) => staffWorker._id === staff.staffId
          );
          const selectData = {
            groups: { addTo: [staff.groupId], removeFrom: [] },
            employee: {
              name: dataEmployee.name,
              role: staff.role,
              rocketChatId: dataEmployee.rocket_chat.id,
            },
          };
          this.staffService
            .putCampusSingleStaff(
              this.context.franchise,
              this.props.match.params.campusId,
              staff.staffId,
              "edit",
              selectData
            )
            .then((response) => {
              // console.log("added staff by select", response);
              const groups = response.data.groups.find(
                (group) => group._id === this.props.match.params.groupId
              );
              if (groups) {
                this.setState({ status: "success" }, () => {
                  this.createCampusGroupFromAddStaff(groups);
                });
              }
            })
            .catch((err) => {
              // console.log(err.response);
              this.setState({ status: "error" });
            });
          break;
        case "create":
          const createGroupData = {
            name: staff.name,
            email: staff.email,
            phone: staff.phone,
            identificationNumber: staff.identificationNumber,
            ssn: staff.ssn,
            bankAccountNumber: staff.bankAccountNumber,
            sports: staff.sports,
            role: staff.role,
          };
          // console.log("call to api");

          this.staffService
            .postStaffAndAssignCampusGroup(
              this.context.franchise,
              this.props.match.params.campusId,
              { action: "group", groupId: staff.groupId },
              [createGroupData]
            )
            .then((response) => {
              // console.log("call to api ending and response");
              // console.log("response add trainer and assign group", response);
              this.setState({ status: "success" }, () => {
                this.createCampusGroupFromAddStaff(response.data);
              });
            })
            .catch((err) => {
              // console.log(err.response);
              this.setState({ status: "error" });
            });
          break;
        default:
          break;
      }
    });
  };

  handleDownloadSMStaff = () => {
    return this.staffService
      .getStaffList(this.context.franchise)
      .then((response) => {
        // console.log("response download staff", response.data);
        const sm_staff = response.data;
        this.staffService
          .getStaffCampusList(this.props.match.params.campusId)
          .then((response) => {
            // console.log("response download campus_staff", response.data);

            this.setState({ sm_staff, campus_staff: response.data });
            return Promise.resolve();
          })
          .catch((err) => {
            // console.log(err.response);
            this.setState({ status: "error" });
          });
      });
  };

  // Funciones Activities
  handleDeleteActivity = (id) => {
    this.setState({ status: "loading" }, () => {
      this.campusService
        .deleteCampusGroupSingleActivity(
          this.props.match.params.campusId,
          this.props.match.params.groupId,
          id
        )
        .then((response) => {
          // console.log(response);
          const groups = this.state.groups;
          groups[0].activities = groups[0].activities.filter(
            (activity) => activity._id !== id
          );

          this.setState({ status: "success" }, () => {
            this.createGroupCampus({
              groups,
              users: this.state.users,
              name: this.state.campusName,
              venues: this.state.venues,
            });
          });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
    // console.log("id delete activity", id);
  };

  handleEditActivity = (data) => {
    const sendActivity = {
      description: data.description,
      staffDetails: data.staffDetails,
      name: data.name,
      repeats: data.repeats.map((repeat) => {
        return {
          ...repeat,
          start: moment(repeat.start).format("YYYY-MM-DDTHH:mm:ssZ"),
          end: moment(repeat.end).format("YYYY-MM-DDTHH:mm:ssZ"),
        };
      }),
    };
    this.setState({ status: "loading" }, () => {
      this.campusService
        .putCampusGroupSingleActivity(
          this.props.match.params.campusId,
          this.props.match.params.groupId,
          data._id,
          sendActivity
        )
        .then((response) => {
          // console.log("response edit activity", response);

          const groups = this.state.groups;
          groups[0].activities = groups[0].activities.map((activity) => {
            if (activity._id === data._id) {
              return response.data;
            } else {
              return activity;
            }
          });
          this.setState({ status: "success" }, () => {
            this.createGroupCampus({
              users: this.state.users,
              venues: this.state.venues,
              name: this.state.campusName,
              groups,
            });
          });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
    // console.log("data edit activity", data);
  };

  // Funciones Users
  handleEditUser = (data) => {
    const user = {
      child: {
        name: data.name,
        comments: data.comments,
        allergies: data.allergies,
        status: data.status,
        parents: data.parents,
      },
      childChanged: data.childChanged,
      groups: data.groups,
      extraResponsiblesChanged: data.extraResponsiblesChanged,
      extraResponsibles: data.extraResponsibles || null,
    };
    // console.log("user to send api", user);
    this.setState({ status: "loading" }, () => {
      this.campusService
        .putCampusSingleUser(
          this.props.match.params.campusId,
          data.key,
          "edit",
          user
        )
        .then((response) => {
          // console.log("response", response);
          let users = [...this.state.users];
          users = users.map((_user) => {
            if (_user._id === response.data._id) {
              return { ...response.data };
            } else {
              return _user;
            }
          });

          users = this.createUsers(users, this.state.groups);

          this.setState({ users, status: "success" });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  handleDeleteUser = (key) => {
    const user = this.state.users.find((_user) => _user.key === key);
    if (user) {
      const sendUser = {
        child: {
          name: user.name,
          comments: user.comments,
          allergies: user.allergies,
          status: user.status,
          parents: user.parents,
        },
        childChanged: false,
        groups: { removeFrom: [this.props.match.params.groupId], addTo: [] },
        extraResponsiblesChanged: false,
        extraResponsibles: null,
      };

      this.setState({ status: "loading" }, () => {
        this.campusService
          .putCampusSingleUser(
            this.props.match.params.campusId,
            key,
            "edit",
            sendUser
          )
          .then((response) => {
            // console.log("response delete user from group", response);
            const users = this.state.users.filter((user) => user.key !== key);

            this.setState({ users, status: "success" });
          })
          .catch((err) => {
            // console.log(err.response);
            this.setState({ status: "error" });
          });
      });
    }
  };

  // Funciones staff

  downloadStaffInfo = (id) => {
    // console.log(id);
    return this.staffService
      .getStaffSingle(this.context.franchise, id)
      .then((response) => {
        let staff = this.state.staff;
        let staffToSend;

        staff = staff.map((staffWorker) => {
          if (staffWorker.id === id) {
            //MERGE 2 objetos para mantener staff logica visual
            staffToSend = {
              ...staffWorker,
              ...response.data,
              sports: response.data.sports.canTeach,
              downloaded: true,
            };
            return staffToSend;
          } else {
            return staffWorker;
          }
        });
        // console.log("value staff to send drawer", staffToSend);

        this.setState({ staff });
        return Promise.resolve(staffToSend);
      })
      .catch((err) => {
        this.setState({ status: "error" });

        return Promise.reject();
      });
  };

  handleViewCertificate = (key) => {
    return s3GetObject(key).then((response) => {
      // console.log("id ", response);
      return response;
    });
  };

  handleDeleteStaff = (id) => {
    const staff = this.state.staff.find(
      (staffWorker) => staffWorker._id === id
    );
    if (staff) {
      // console.log("staff", staff);
      const sendStaff = {
        groups: { addTo: [], removeFrom: [this.props.match.params.groupId] },
        employee: {
          name: staff.name,
          role: staff.role,
          rocketChatId: staff.rocket_chat.id,
        },
      };
      // console.log("send delete staff", sendStaff);
      this.setState({ status: "loading" }, () => {
        this.staffService
          .putCampusSingleStaff(
            this.context.franchise,
            this.props.match.params.campusId,
            staff.id,
            "edit",
            sendStaff
          )
          .then((response) => {
            // console.log("added staff by select", response);
            const groups = response.data.groups.find(
              (group) => group._id === this.props.match.params.groupId
            );
            if (groups) {
              this.setState({ status: "success" }, () => {
                this.createCampusGroupFromAddStaff(groups);
              });
            }
          })
          .catch((err) => {
            // console.log(err.response);
            this.setState({ status: "error" });
          });
      });
    }
  };

  // Funciones Attendance
  handleDownloadAttendances = () => {
    this.setState({ status: "loading" }, () => {
      this.coreService
        .getAttendanceList(
          this.props.match.params.campusId,
          this.props.match.params.groupId
        )
        .then((response) => {
          // console.log("response download attendance", response);
          const attendance = {
            downloaded: true,
            attendances: response.data.map((_attendance) => {
              return { ..._attendance, downloaded: false };
            }),
          };
          // console.log("attendance downloaded", attendance);
          this.setState({ attendance, status: "success" });
        })
        .catch((err) => {
          // console.log(err.response);
          this.setState({ status: "error" });
        });
    });
  };

  handleDownloadAttendance = (id) => {
    return this.coreService
      .getAttendanceSingle(id)
      .then((response) => {
        // console.log("response download specific attendance", response);
        const attendance = this.state.attendance;
        attendance.attendances = attendance.attendances.map((_attendance) => {
          if (_attendance._id === id) {
            return {
              ..._attendance,
              attendance: response.data[0].attendance,
            };
          } else {
            return _attendance;
          }
        });
        this.setState({ attendance });
        return Promise.resolve(
          attendance.attendances.find((_attendance) => _attendance._id === id)
        );
      })
      .catch((err) => {
        // console.log(err.response);
        this.setState({ status: "error" });
      });
  };

  render() {
    if (this.state.deleted) {
      return <Redirect to={`/campus/${this.props.match.params.campusId}`} />;
    }
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <WebCampusGroupSingle
          role="manager"
          //STATUS
          status={this.state.status}
          //CAMPUS GROUP
          groupId={this.props.match.params.groupId}
          campusId={this.props.match.params.campusId}
          campusGroupData={this.state.campusGroupData}
          campusName={this.state.campusName}
          handleEditGroup={this.handleEditGroup}
          handleDeleteGroup={this.handleDeleteGroup}
          //VENUES
          venues={this.state.venues}
          //SM STAFF
          sm_staff={this.state.sm_staff}
          campus_staff={this.state.campus_staff}
          //POPOVER
          handleAddActivity={this.handleAddActivity}
          handleAddStaffTrainer={this.handleAddStaffTrainer}
          handleAddStaffCoordinator={this.handleAddStaffCoordinator}
          handleDownloadSMStaff={this.handleDownloadSMStaff}
          //ACTIVITIES
          calendarEvents={this.state.calendarEvents}
          activities={this.state.activities}
          handleDeleteActivity={this.handleDeleteActivity}
          handleEditActivity={this.handleEditActivity}
          //USERS
          users={this.state.users}
          handleEditUser={this.handleEditUser}
          handleDeleteUser={this.handleDeleteUser}
          handleAssignUsersToGroup={this.handleAssignUsersToGroup}
          //STAFF
          staff={this.state.staff}
          downloadStaffInfo={this.downloadStaffInfo}
          handleEditStaff={this.handleEditStaff}
          handleDeleteStaff={this.handleDeleteStaff}
          handleViewCertificate={this.handleViewCertificate}
          //ATTENDANCE
          attendance={this.state.attendance}
          handleDownloadAttendances={this.handleDownloadAttendances}
          handleDownloadAttendance={this.handleDownloadAttendance}
          //TRANSLATION
          translation={
            this.context.translation.templates.web.campus.group.single
          }
        />
      </Grid>
    );
  }
}

export { ManagerWebCampusGroupSingle };
