import Axios from "core/axios";

class BoardsServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_CRM;
    this.service = new Axios(this.api_url);
  }

  getBoards = franchise => {
    return this.service.get("/franchises/" + franchise + "/boards");
  };

  updateBoards = (franchise, data) => {
    return this.service.put("/franchises/" + franchise + "/boards", data);
  };
}

export default BoardsServices;
