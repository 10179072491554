import React, { Component } from "react";
import LogoSM from "assets/img/white-logo-lg.png";
import { Login } from "react-library-sm";
import { Auth } from "aws-amplify";
import RocketChatServices from "services/auth/rocket-chat.services";

class LoginComponent extends Component {
  state = {
    email: null,
    password: null,
    challengeName: null,
    userCognito: null,
  };
  rocketChatServices = new RocketChatServices();

  handlerNewPassword = async (values) => {
    const loggedUser = await Auth.completeNewPassword(
      this.state.userCognito, // the Cognito User Object
      values.password // the new password
    );

    this.rocketChatServices.setNewPwd(values).then((response) => {
      //  console.log("response", response);
      this.checkUserCognito(loggedUser);
    });
  };

  loginClickHandler = async (values) => {
    // console.log(values);
    try {
      this.setState({ email: values.email });
      const user = await Auth.signIn(values.email, values.password);
      localStorage.setItem("email", values.email);
      this.checkUserCognito(user);
      this.rocketChatServices
        .login({
          email: values.email,
          password: values.password,
          attributes: user.attributes,
        })
        .then((response) => {
          // console.log("rocket repon", response);

          localStorage.setItem(
            "rocket-chat-auth_token",
            response.data.auth_token
          );
          localStorage.setItem("rocket-chat-user_id", response.data.user_id);
          localStorage.setItem("rocket-chat-username", response.data.username);
        })
        .catch((err) => {
          // console.log("rocker err", err);
        });
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
      }

      this.setState({ challengeName: err.code });
      // console.log(err);
    }
  };

  checkUserCognito = (user) => {
    // console.log("user", user);
    if (
      user.challengeName === "SMS_MFA" ||
      user.challengeName === "SOFTWARE_TOKEN_MFA" ||
      user.challengeName === "NEW_PASSWORD_REQUIRED" ||
      user.challengeName === "MFA_SETUP"
    ) {
      //     // You need to get the code from the UI TextFields
      //     // and then trigger the following function with a button click
      //     // const code = getCodeFromUserTextField();
      //     // // If MFA is enabled, sign-in should be confirmed with the confirmation code
      //     // const loggedUser = await Auth.confirmSignIn(
      //     //   user, // Return object from Auth.signIn()
      //     //   code, // Confirmation code
      //     //   mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      //     // );
      //   } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      //     const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
      //     // You need to get the new password and required attributes from the UI TextFields
      //     // and then trigger the following function with a button click
      //     // For example, the email and phone_number are required attributes
      //     // const { username, email, phone_number } = getInfoFromUserTextField();
      //     // const loggedUser = await Auth.completeNewPassword(
      //     //   user, // the Cognito User Object
      //     //   newPassword, // the new password
      //     //   // OPTIONAL, the required attributes
      //     //   {
      //     //     email,
      //     //     phone_number
      //     //   }
      //     // );
      //   } else if (user.challengeName === "MFA_SETUP") {
      //     // This happens when the MFA method is TOTP
      //     // The user needs to setup the TOTP before using it
      //     // More info please check the Enabling MFA part
      //     //Auth.setupTOTP(user);
      if (
        user.challengeParam.userAttributes["custom:typeUser"] === "Franchise"
      ) {
        this.setState({
          challengeName: user.challengeName,
          userCognito: user,
        });
      } else {
        Auth.signOut().then((data) => {
          this.setState({
            challengeName: "UserNotFoundException",
          });
        });
      }
    } else {
      // The user directly signs in
      // console.log(user);
      if (
        user.signInUserSession.accessToken.payload["cognito:groups"][0] ===
        "franchises"
      ) {
        this.setState(
          {
            userCognito: user,
          },
          () => {
            this.props.loginSuccess(this.state.userCognito);
          }
        );
      } else {
        Auth.signOut().then((data) => {
          this.setState({
            challengeName: "UserNotFoundException",
          });
        });
      }
    }
  };

  render() {
    return (
      <Login
        variant={this.state.challengeName}
        login={this.loginClickHandler}
        newPwdRequired={this.handlerNewPassword}
        email={this.state.email}
        src={LogoSM}
      />
    );
  }
}

export default LoginComponent;
