const textLabels = {
	body: {
		noMatch: 'Lo sentimos, no hay resultados',
		toolTip: 'Ordenar'
	},
	pagination: {
		next: 'Siguente',
		previous: 'Anterior',
		rowsPerPage: 'Filas por página:',
		displayRows: 'de'
	},
	toolbar: {
		search: 'Buscar',
		downloadCsv: 'Descargar CSV',
		print: 'Imprimir',
		viewColumns: 'Ver Columnas',
		filterTable: 'Filtrar Tabla'
	},
	filter: {
		all: 'Todos',
		title: 'FILTROS',
		reset: 'RESETEAR'
	},
	viewColumns: {
		title: 'Mostrar Columnas',
		titleAria: 'Mostrar/Ocultar Columnas de la tabla'
	},
	selectedRows: {
		text: 'Columna(s) seleccionadas',
		delete: 'Borrar',
		deleteAria: 'Borrar Columnas Seleccionadas'
	}
};

export { textLabels };
