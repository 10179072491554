import React from "react";
import { Chip, numeral } from "react-library-sm";
import moment from "moment";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardDateTimePicker,
//   KeyboardDatePicker
// } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";

const ProjectTemplate = (translation) => {
  // let popovers;
  // const getPopovers = (lang) => {
  // 	popovers = require(`assets/languages/i18n/translations/${lang}/popovers`).popovers;
  // };

  return {
    alerts: {
      delete_project: {
        success: {
          text: translation.alerts.delete_project.success.text,
          submit: translation.alerts.delete_project.success.submit,
        },
        warning: {
          text: translation.alerts.delete_project.warning.text,
          submit: translation.alerts.delete_project.warning.submit,
        },
      },
      delete_task: {
        success: {
          text: translation.alerts.delete_task.success.text,
          submit: translation.alerts.delete_task.success.submit,
        },
        warning: {
          text: translation.alerts.delete_task.warning.text,
          submit: translation.alerts.delete_task.warning.submit,
        },
      },
      download_project: {
        error: {
          text: translation.alerts.download_project.error.text,
          submit: translation.alerts.download_project.error.submit,
        },
      },
      create_task: {
        success: {
          text: translation.alerts.create_task.success.text,
          submit: translation.alerts.create_task.success.submit,
        },
        warning: {
          text: translation.alerts.create_task.warning.text,
          submit: translation.alerts.create_task.warning.submit,
        },
      },
      edit_task: {
        success: {
          text: translation.alerts.edit_task.success.text,
          submit: translation.alerts.edit_task.success.submit,
        },
        warning: {
          text: translation.alerts.edit_task.warning.text,
          submit: translation.alerts.edit_task.warning.submit,
        },
      },
    },
    calendar: {
      messages: {
        date: translation.projects.single.calendar.messages.date.date,
        time: translation.projects.single.calendar.messages.time,
        event: translation.projects.single.calendar.messages.event,
        allDay: translation.projects.single.calendar.messages.allDay,
        week: translation.projects.single.calendar.messages.week,
        work_week: translation.projects.single.calendar.messages.work_week,
        day: translation.projects.single.calendar.messages.day,
        month: translation.projects.single.calendar.messages.month,
        previous: translation.projects.single.calendar.messages.previous,
        next: translation.projects.single.calendar.messages.next,
        yesterday: translation.projects.single.calendar.messages.yesterday,
        tomorrow: translation.projects.single.calendar.messages.tomorrow,
        today: translation.projects.single.calendar.messages.today,
        agenda: translation.projects.single.calendar.messages.agenda,
        noEventsInRange:
          translation.projects.single.calendar.messages.noEventsInRange,
        showMore: translation.projects.single.calendar.messages.showMore,
      },
    },
    buttons: {
      saved: translation.projects.single.buttons.saved,
      exit_edit: translation.projects.single.buttons.exit_edit,
    },
    tabs: [
      {
        value: "info",
        label: translation.projects.single.tabs.info,
      },
      {
        value: "plan",
        label: translation.projects.single.tabs.plan,
      },
      {
        value: "docheck",
        label: translation.projects.single.tabs.do,
      },
      {
        value: "close",
        label: translation.projects.single.tabs.check,
      },
    ],
    // task_states: [
    //   {
    //     value: "pending",
    //     label: translation.projects.single.task_states.pending
    //   },
    //   {
    //     value: "doing",
    //     label: translation.projects.single.task_states.doing
    //   },
    //   {
    //     value: "done",
    //     label: translation.projects.single.task_states.done
    //   },
    //   {
    //     value: "blocked",
    //     label: translation.projects.single.task_states.blocked
    //   }
    // ]
    task_states: {
      pending: {
        value: "pending",
        label: translation.projects.single.task_states.pending,
      },
      doing: {
        value: "doing",
        label: translation.projects.single.task_states.doing,
      },
      done: {
        value: "done",
        label: translation.projects.single.task_states.done,
      },
      blocked: {
        value: "blocked",
        label: translation.projects.single.task_states.blocked,
      },
      out_of_date: {
        value: "out_of_date",
        label: translation.projects.single.task_states.out_of_date,
      },
    },
    dialogs: {
      add_task: {
        titles: {
          add_task:
            translation.projects.single.dialogs.add_task.titles.add_task,
          edit_task:
            translation.projects.single.dialogs.add_task.titles.edit_task,
        },
        buttons: {
          save: translation.projects.single.dialogs.add_task.buttons.save,
          cancel: translation.projects.single.dialogs.add_task.buttons.cancel,
        },
        inputs: {
          name: translation.projects.single.dialogs.add_task.inputs.name,
          details: translation.projects.single.dialogs.add_task.inputs.details,
          date: translation.projects.single.dialogs.add_task.inputs.date,
          start: translation.projects.single.dialogs.add_task.inputs.start,
          end: translation.projects.single.dialogs.add_task.inputs.end,
          period: {
            placeholder:
              translation.projects.single.dialogs.add_task.inputs.period
                .placeholder,
            day: translation.projects.single.dialogs.add_task.inputs.period.day,
            week: translation.projects.single.dialogs.add_task.inputs.period
              .week,
            month:
              translation.projects.single.dialogs.add_task.inputs.period.month,
          },
          monthday: {
            day: translation.projects.single.dialogs.add_task.inputs.monthday
              .day,
            each_month:
              translation.projects.single.dialogs.add_task.inputs.monthday
                .each_month,
          },
        },
        selects: {
          recurrence: {
            placeholder:
              translation.projects.single.dialogs.add_task.selects.recurrence
                .placeholder,
            items: [
              {
                value: "once",
                label:
                  translation.projects.single.dialogs.add_task.selects
                    .recurrence.once,
              },
              {
                value: "recurring",
                label:
                  translation.projects.single.dialogs.add_task.selects
                    .recurrence.recurring,
              },
            ],
          },
          frequency: {
            placeholder:
              translation.projects.single.dialogs.add_task.selects.frequency
                .placeholder,
            items: [
              {
                value: "day",
                label:
                  translation.projects.single.dialogs.add_task.selects.frequency
                    .day,
              },
              {
                value: "week",
                label:
                  translation.projects.single.dialogs.add_task.selects.frequency
                    .week,
              },
              {
                value: "month",
                label:
                  translation.projects.single.dialogs.add_task.selects.frequency
                    .month,
              },
            ],
          },
          weekday: {
            placeholder:
              translation.projects.single.dialogs.add_task.selects.weekday
                .placeholder,
            items: [
              {
                value: 1,
                label:
                  translation.projects.single.dialogs.add_task.selects.weekday
                    .monday,
              },
              {
                value: 2,
                label:
                  translation.projects.single.dialogs.add_task.selects.weekday
                    .tuesday,
              },
              {
                value: 3,
                label:
                  translation.projects.single.dialogs.add_task.selects.weekday
                    .weednesday,
              },
              {
                value: 4,
                label:
                  translation.projects.single.dialogs.add_task.selects.weekday
                    .thursday,
              },
              {
                value: 5,
                label:
                  translation.projects.single.dialogs.add_task.selects.weekday
                    .friday,
              },
              {
                value: 6,
                label:
                  translation.projects.single.dialogs.add_task.selects.weekday
                    .saturday,
              },
              {
                value: 7,
                label:
                  translation.projects.single.dialogs.add_task.selects.weekday
                    .sunday,
              },
            ],
          },
        },
      },
      add_inside_task: {
        titles: {
          add_task:
            translation.projects.single.dialogs.add_inside_task.titles.add_task,
        },
        buttons: {
          save: translation.projects.single.dialogs.add_inside_task.buttons
            .save,
          cancel:
            translation.projects.single.dialogs.add_inside_task.buttons.cancel,
          next: translation.projects.single.dialogs.add_inside_task.buttons
            .next,
          back: translation.projects.single.dialogs.add_inside_task.buttons
            .back,
          finish:
            translation.projects.single.dialogs.add_inside_task.buttons.finish,
        },
        inputs: {
          name: translation.projects.single.dialogs.add_inside_task.inputs.name,
          name_parent:
            translation.projects.single.dialogs.add_inside_task.inputs
              .name_parent,
          details:
            translation.projects.single.dialogs.add_inside_task.inputs.details,
          date: translation.projects.single.dialogs.add_inside_task.inputs.date,
          start:
            translation.projects.single.dialogs.add_inside_task.inputs.start,
          end: translation.projects.single.dialogs.add_inside_task.inputs.end,
          period: {
            placeholder:
              translation.projects.single.dialogs.add_inside_task.inputs.period
                .placeholder,
            day: translation.projects.single.dialogs.add_inside_task.inputs
              .period.day,
            week: translation.projects.single.dialogs.add_inside_task.inputs
              .period.week,
            month:
              translation.projects.single.dialogs.add_inside_task.inputs.period
                .month,
          },
          monthday: {
            day: translation.projects.single.dialogs.add_inside_task.inputs
              .monthday.day,
            each_month:
              translation.projects.single.dialogs.add_inside_task.inputs
                .monthday.each_month,
          },
        },
        selects: {
          recurrence: {
            placeholder:
              translation.projects.single.dialogs.add_inside_task.selects
                .recurrence.placeholder,
            items: [
              {
                value: "once",
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .recurrence.once,
              },
              {
                value: "recurring",
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .recurrence.recurring,
              },
            ],
          },
          frequency: {
            placeholder:
              translation.projects.single.dialogs.add_inside_task.selects
                .frequency.placeholder,
            items: [
              {
                value: "day",
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .frequency.day,
              },
              {
                value: "week",
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .frequency.week,
              },
              {
                value: "month",
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .frequency.month,
              },
            ],
          },
          weekday: {
            placeholder:
              translation.projects.single.dialogs.add_inside_task.selects
                .weekday.placeholder,
            items: [
              {
                value: 1,
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .weekday.monday,
              },
              {
                value: 2,
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .weekday.tuesday,
              },
              {
                value: 3,
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .weekday.weednesday,
              },
              {
                value: 4,
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .weekday.thursday,
              },
              {
                value: 5,
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .weekday.friday,
              },
              {
                value: 6,
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .weekday.saturday,
              },
              {
                value: 7,
                label:
                  translation.projects.single.dialogs.add_inside_task.selects
                    .weekday.sunday,
              },
            ],
          },
        },
        steps: [
          {
            label:
              translation.projects.single.dialogs.add_inside_task.steps.step_1,
          },
          {
            label:
              translation.projects.single.dialogs.add_inside_task.steps.step_2,
          },
        ],
      },
      delete_project: {
        titles: {
          delete_project:
            translation.projects.single.dialogs.delete_project.titles
              .delete_project,
        },
        info: translation.projects.single.dialogs.delete_project.info,
        buttons: {
          cancel:
            translation.projects.single.dialogs.delete_project.buttons.cancel,
          delete:
            translation.projects.single.dialogs.delete_project.buttons.delete,
        },
      },
      delete_task: {
        titles: {
          delete_task:
            translation.projects.single.dialogs.delete_task.titles.delete_task,
        },
        info: translation.projects.single.dialogs.delete_task.info,
        buttons: {
          cancel:
            translation.projects.single.dialogs.delete_task.buttons.cancel,
          delete:
            translation.projects.single.dialogs.delete_task.buttons.delete,
        },
      },
    },
    drawers: {
      task_detail: {
        deadline: translation.projects.single.drawers.task_detail.deadline,
        task_states: {
          pending: {
            value: "pending",
            label:
              translation.projects.single.drawers.task_detail.task_states
                .pending,
          },
          doing: {
            value: "doing",
            label:
              translation.projects.single.drawers.task_detail.task_states.doing,
          },
          done: {
            value: "done",
            label:
              translation.projects.single.drawers.task_detail.task_states.done,
          },
          blocked: {
            value: "blocked",
            label:
              translation.projects.single.drawers.task_detail.task_states
                .blocked,
          },
          out_of_date: {
            value: "out_of_date",
            label:
              translation.projects.single.drawers.task_detail.task_states
                .out_of_date,
          },
        },
        buttons: {
          complete_task:
            translation.projects.single.drawers.task_detail.buttons
              .complete_task,
          begin_task:
            translation.projects.single.drawers.task_detail.buttons.begin_task,
          completed_task:
            translation.projects.single.drawers.task_detail.buttons
              .completed_task,
          cancel:
            translation.projects.single.drawers.task_detail.buttons.cancel,
          save: translation.projects.single.drawers.task_detail.buttons.save,
        },
      },
    },
    tab_project_info: {
      titles: {
        service_type:
          translation.projects.single.tab_project_info.titles.service_type,
        project_type:
          translation.projects.single.tab_project_info.titles.project_type,
        estimated_value:
          translation.projects.single.tab_project_info.titles.estimated_value,
        date_start:
          translation.projects.single.tab_project_info.titles.date_start,
        date_end: translation.projects.single.tab_project_info.titles.date_end,
        contact: translation.projects.single.tab_project_info.titles.contact,
      },
      service_type: {
        campus:
          translation.projects.single.tab_project_info.service_type.campus,
        event: translation.projects.single.tab_project_info.service_type.event,
        academy:
          translation.projects.single.tab_project_info.service_type.academy,
      },
      dialogs: {
        view_invoice: {
          title:
            translation.projects.single.tab_project_info.dialogs.view_invoice
              .title,
          docnumber:
            translation.projects.single.tab_project_info.dialogs.view_invoice
              .docnumber,
          date: translation.projects.single.tab_project_info.dialogs
            .view_invoice.date,
          due_date:
            translation.projects.single.tab_project_info.dialogs.view_invoice
              .due_date,
          payment_date:
            translation.projects.single.tab_project_info.dialogs.view_invoice
              .payment_date,
          client:
            translation.projects.single.tab_project_info.dialogs.view_invoice
              .client,
          status:
            translation.projects.single.tab_project_info.dialogs.view_invoice
              .status,
          subtotal:
            translation.projects.single.tab_project_info.dialogs.view_invoice
              .subtotal,
          tax: translation.projects.single.tab_project_info.dialogs.view_invoice
            .tax,
          total:
            translation.projects.single.tab_project_info.dialogs.view_invoice
              .total,
          selects: {
            status: {
              placeholder:
                translation.projects.single.tab_project_info.dialogs
                  .view_invoice.selects.status.placeholder,
              items: [
                {
                  value: "pending",
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.selects.status.pending,
                },
                {
                  value: "overdue",
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.selects.status.overdue,
                },
                {
                  value: "paid",
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.selects.status.paid,
                },
                {
                  value: "amended",
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.selects.status.amended,
                },
                {
                  value: "amending",
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.selects.status.amending,
                },
              ],
            },
          },
          inputs: {
            date: translation.projects.single.tab_project_info.dialogs
              .view_invoice.date,
          },
          buttons: {
            save: translation.projects.single.tab_project_info.dialogs
              .view_invoice.buttons.save,
          },
          pdf: {
            message:
              translation.projects.single.tab_project_info.dialogs.view_invoice
                .pdf.message,
            document: {
              docnumber:
                translation.projects.single.tab_project_info.dialogs
                  .view_invoice.pdf.document.docnumber,
              date: translation.projects.single.tab_project_info.dialogs
                .view_invoice.pdf.document.date,
              due_date:
                translation.projects.single.tab_project_info.dialogs
                  .view_invoice.pdf.document.due_date,
              issuer:
                translation.projects.single.tab_project_info.dialogs
                  .view_invoice.pdf.document.issuer,
              receiver:
                translation.projects.single.tab_project_info.dialogs
                  .view_invoice.pdf.document.receiver,
              subtotal:
                translation.projects.single.tab_project_info.dialogs
                  .view_invoice.pdf.document.subtotal,
              tax: translation.projects.single.tab_project_info.dialogs
                .view_invoice.pdf.document.tax,
              total:
                translation.projects.single.tab_project_info.dialogs
                  .view_invoice.pdf.document.total,
              method:
                translation.projects.single.tab_project_info.dialogs
                  .view_invoice.pdf.document.method,
              items: {
                name: translation.projects.single.tab_project_info.dialogs
                  .view_invoice.pdf.document.items.name,

                quantity:
                  translation.projects.single.tab_project_info.dialogs
                    .view_invoice.pdf.document.items.quantity,
                price:
                  translation.projects.single.tab_project_info.dialogs
                    .view_invoice.pdf.document.items.price,
                subtotal:
                  translation.projects.single.tab_project_info.dialogs
                    .view_invoice.pdf.document.items.subtotal,
                tax: {
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.pdf.document.items.tax.label,
                  amount:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.pdf.document.items.tax.amount,
                },
              },
              taxes: [
                {
                  percentage: 0.077,
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.pdf.document.taxes.iva_7_7,
                  value: "iva_7_7",
                },
                {
                  percentage: 0.081,
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.pdf.document.taxes.iva_8_1,
                  value: "iva_8_1",
                },
                {
                  percentage: 0.19,
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.pdf.document.taxes.iva_19,
                  value: "iva_19",
                },
                {
                  percentage: 0.2,
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.pdf.document.taxes.iva_20,
                  value: "iva_20",
                },
                {
                  percentage: 0.18,
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.pdf.document.taxes.iva_18,
                  value: "iva_18",
                },
                {
                  percentage: 0.21,
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.pdf.document.taxes.iva_21,
                  value: "iva_21",
                },
                {
                  percentage: 0.1,
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.pdf.document.taxes.iva_10,
                  value: "iva_10",
                },
                {
                  percentage: 0,
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.pdf.document.taxes.no_iva,
                  value: "no_iva",
                },
                {
                  percentage: 0.07,
                  label:
                    translation.projects.single.tab_project_info.dialogs
                      .view_invoice.pdf.document.taxes.igic_65,
                  value: "igic_7",
                },
              ],
            },
          },
        },
      },
      datatables: {
        invoices: {
          title: "Invoices",
          columns: [
            {
              label: "id",
              name: "_id",
              options: { display: false, filter: false },
            },
            {
              label:
                translation.projects.single.tab_project_info.datatables.invoices
                  .columns.date,
              name: "date",
              options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                  moment(tableMeta.rowData[1]).format("DD/MM/YYYY"),
              },
            },
            {
              label:
                translation.projects.single.tab_project_info.datatables.invoices
                  .columns.docnumber,
              name: "docnumber",
            },
            {
              label:
                translation.projects.single.tab_project_info.datatables.invoices
                  .columns.due_date,
              name: "due_date",
              options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                  moment(tableMeta.rowData[3]).format("DD/MM/YYYY"),
              },
            },
            {
              label:
                translation.projects.single.tab_project_info.datatables.invoices
                  .columns.status,
              name: "status",
              options: {
                filter: true,
                filterType: "checkbox",
                customFilterListRender: (v) => `Status ${v}`,
                filterOptions: {
                  names: Object.values(
                    translation.projects.single.tab_project_info.datatables
                      .invoices.status
                  ),
                  logic(status, filterVal) {
                    const show =
                      filterVal.indexOf(
                        translation.projects.single.tab_project_info.datatables
                          .invoices.status[status]
                      ) >= 0;
                    return !show;
                  },
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <Chip
                      label={
                        translation.projects.single.tab_project_info.datatables
                          .invoices.status[tableMeta.rowData[4]]
                      }
                      style={
                        tableMeta.rowData[4] === "pending"
                          ? {
                              borderRadius: "5px",
                              background: "transparent",
                              border: "1px solid #ffe082",
                              color: "#ffe082",
                            }
                          : tableMeta.rowData[4] === "paid"
                          ? {
                              borderRadius: "5px",
                              border: "1px solid #77ffb5",
                              color: "#77ffb5",
                              background: "transparent",
                            }
                          : tableMeta.rowData[4] === "payment_failed" ||
                            tableMeta.rowData[4] === "overdue"
                          ? {
                              borderRadius: "5px",
                              border: "1px solid #E95A58",
                              color: "#fff",
                              background: "transparent",
                            }
                          : {
                              borderRadius: "5px",
                              border: "1px solid #80b9d6",
                              color: "#80b9d6",
                              background: "transparent",
                            }
                      }
                    />
                  );
                },
              },
            },
            {
              label:
                translation.projects.single.tab_project_info.datatables.invoices
                  .columns.category,
              name: "category",
              options: {
                filter: true,
                filterType: "checkbox",
                customFilterListRender: (v) =>
                  `${translation.projects.single.tab_project_info.datatables.invoices.columns.category} ${v}`,
                filterOptions: {
                  names: Object.values(
                    translation.projects.single.tab_project_info.datatables
                      .invoices.category
                  ),
                  logic(category, filterVal) {
                    const show =
                      (filterVal.indexOf(
                        translation.projects.single.tab_project_info.datatables
                          .invoices.category.events
                      ) >= 0 &&
                        category === "events") ||
                      (filterVal.indexOf(
                        translation.projects.single.tab_project_info.datatables
                          .invoices.category.academies
                      ) >= 0 &&
                        category === "academies") ||
                      (filterVal.indexOf(
                        translation.projects.single.tab_project_info.datatables
                          .invoices.category.campus
                      ) >= 0 &&
                        category === "campus");
                    return !show;
                  },
                },
                customBodyRender: (value, tableMeta, updateValue) => (
                  <Chip
                    label={
                      translation.projects.single.tab_project_info.datatables
                        .invoices.category[tableMeta.rowData[5]]
                    }
                  />
                ),
              },
            },
            {
              label: "Subtotal",
              name: "subtotal",
              options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) =>
                  numeral(tableMeta.rowData[6]).format("0,0[.]00 $"),
              },
            },
            {
              label: "Tax",
              name: "tax",
              options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) =>
                  numeral(tableMeta.rowData[7]).format("0,0[.]00 $"),
              },
            },
            {
              label: "Total",
              name: "total",
              options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) =>
                  numeral(tableMeta.rowData[8]).format("0,0[.]00 $"),
              },
            },
            {
              label: "",
              name: "options",
            },
          ],
        },
      },
    },
  };
};

export { ProjectTemplate };
