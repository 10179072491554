import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import App from "./App";
import config from "./config";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";

console.log("Prod Version", "6.0.0");
// console.log("Actual Version", "4.1.0");
console.log("Library", "6.0.1");
console.log("API auth", "v1_3_0");
console.log("API franchise-projects", "v1_3_1");
console.log("API franchise-crm", "v1_5_1");
console.log("API franchise-billing", "v1_3_2");
console.log("API franchise-academy", "v1_1_1");
console.log("API campus", "prod v1_1_0");

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.storage.IDENTITY_POOL_ID,
  },
  Storage: {
    region: config.storage.REGION,
    bucket: config.storage.BUCKET,
    identityPoolId: config.storage.IDENTITY_POOL_ID,
  },
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
