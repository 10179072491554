import React, { Component } from "react";
import userContext from "providers/UserProvider";
import { FranchiseProjectsBestPracticesList } from "react-library-sm";
import { Grid } from "@material-ui/core";
import ProjectsServices from "services/franchise-projects/projects.services";

class ProjectsBestPracticesList extends Component {
  static contextType = userContext;
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
    };
    this.projectServices = new ProjectsServices();
  }

  componentDidMount() {
    this.projectServices
      .getBestPractices(this.context.languageFranchise)
      .then((response) => {
        const templates = response.data.map((template) => {
          return {
            _id: template._id,
            tags: template.languages.tags,
            type: template.type,
            name: template.languages.name,
            description: template.languages.description,
          };
        });
        // console.log("response templates ", templates);
        this.setState({
          templates,
        });
      });
  }

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <FranchiseProjectsBestPracticesList
          templates={this.state.templates}
          // translation={
          //   this.context.translation.templates.projectBestPracticesList
          // }
        />
      </Grid>
    );
  }
}

export { ProjectsBestPracticesList };
