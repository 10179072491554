const ProjectBestPracticesTemplate = (translation) => {
  return {
    alerts: {
      download_contacts: {
        error: {
          text: translation.alerts.download_contacts.error.text,
          submit: translation.alerts.download_contacts.error.submit,
        },
      },
      download_templates: {
        error: {
          text: translation.alerts.download_templates.error.text,
          submit: translation.alerts.download_templates.error.submit,
        },
      },
      download_opportunities: {
        error: {
          text: translation.alerts.download_opportunities.error.text,
          submit: translation.alerts.download_opportunities.error.submit,
        },
      },
      create_project: {
        success: {
          text: translation.alerts.create_project.success.text,
          submit: translation.alerts.create_project.success.submit,
        },
        warning: {
          text: translation.alerts.create_project.warning.text,
          submit: translation.alerts.create_project.warning.submit,
        },
      },
    },
    title: translation.projects.best_practices.single.title,

    buttons: {
      add_project:
        translation.projects.best_practices.single.buttons.add_project,
    },
    dialogs: {
      add_project: {
        titles: {
          add_project:
            translation.projects.best_practices.single.dialogs.add_project
              .titles.add_project,
          edit_project:
            translation.projects.best_practices.single.dialogs.add_project
              .titles.edit_project,
        },
        buttons: {
          back:
            translation.projects.best_practices.single.dialogs.add_project
              .buttons.back,
          next:
            translation.projects.best_practices.single.dialogs.add_project
              .buttons.next,
          save:
            translation.projects.best_practices.single.dialogs.add_project
              .buttons.save,
          finish:
            translation.projects.best_practices.single.dialogs.add_project
              .buttons.finish,
        },

        steps: [
          {
            value: 0,
            label:
              translation.projects.best_practices.single.dialogs.add_project
                .steps.step0,
          },
          {
            value: 1,
            label:
              translation.projects.best_practices.single.dialogs.add_project
                .steps.step1,
          },
        ],
        inputs: {
          project_name:
            translation.projects.best_practices.single.dialogs.add_project
              .inputs.project_name,
          project_start_date:
            translation.projects.best_practices.single.dialogs.add_project
              .inputs.project_start_date,
          project_finish_date:
            translation.projects.best_practices.single.dialogs.add_project
              .inputs.project_finish_date,
          budget:
            translation.projects.best_practices.single.dialogs.add_project
              .inputs.budget,
        },
        selects: {
          status: {
            placeholder:
              translation.projects.best_practices.single.dialogs.add_project
                .selects.status.placeholder,
            items: [
              {
                value: "plan",
                label:
                  translation.projects.best_practices.single.dialogs.add_project
                    .selects.status.plan,
              },
              {
                value: "docheck",
                label:
                  translation.projects.best_practices.single.dialogs.add_project
                    .selects.status.docheck,
              },
              {
                value: "close",
                label:
                  translation.projects.best_practices.single.dialogs.add_project
                    .selects.status.close,
              },
            ],
          },
          project_type: {
            placeholder:
              translation.projects.best_practices.single.dialogs.add_project
                .selects.project_type.placeholder,
            items: [
              {
                value: "b2b",
                label:
                  translation.projects.best_practices.single.dialogs.add_project
                    .selects.project_type.b2b,
              },
              {
                value: "b2c",
                label:
                  translation.projects.best_practices.single.dialogs.add_project
                    .selects.project_type.b2c,
              },
            ],
          },
          project_source: {
            placeholder:
              translation.projects.best_practices.single.dialogs.add_project
                .selects.project_source.placeholder,
            items: [
              {
                value: "opportunity",
                label:
                  translation.projects.best_practices.single.dialogs.add_project
                    .selects.project_source.opportunity,
              },
              {
                value: "new",
                label:
                  translation.projects.best_practices.single.dialogs.add_project
                    .selects.project_source.new,
              },
            ],
          },
          currency: {
            placeholder:
              translation.opportunities.list.dialogs.add_opportunity.selects
                .currency.placeholder,
            items: [
              {
                value: "euro",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.eur,
              },
              {
                value: "dolar",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.usd,
              },
              {
                value: "sol",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.sol,
              },
              {
                value: "gbp",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.gbp,
              },
            ],
          },
          opportunity: {
            placeholder:
              translation.projects.best_practices.single.dialogs.add_project
                .selects.opportunity.placeholder,
          },
          opportunity_contact: {
            placeholder:
              translation.projects.best_practices.single.dialogs.add_project
                .selects.opportunity_contact.placeholder,
          },
          service_type: {
            placeholder:
              translation.projects.best_practices.single.dialogs.add_project
                .selects.service_type.placeholder,
            items: [
              {
                value: "event",
                label:
                  translation.projects.best_practices.single.dialogs.add_project
                    .selects.service_type.event,
              },
              {
                value: "campus",
                label:
                  translation.projects.best_practices.single.dialogs.add_project
                    .selects.service_type.campus,
              },
              {
                value: "academy",
                label:
                  translation.projects.best_practices.single.dialogs.add_project
                    .selects.service_type.academy,
              },
            ],
          },
          template: {
            placeholder:
              translation.projects.best_practices.single.dialogs.add_project
                .selects.template.placeholder,
          },
        },
      },
    },
  };
};

export { ProjectBestPracticesTemplate };
