import React from "react";
import { Chip, Typography } from "react-library-sm";
import moment from "moment";

import { options } from "assets/mui-datatables/options-default";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const OpportunityTemplate = translation => {
  let popovers;
  const getPopovers = lang => {
    popovers = require(`assets/languages/i18n/translations/${lang}/popovers`)
      .popovers;
  };

  getPopovers(localStorage.getItem("sportmadness-manager-lang"));
  return {
    alerts: {
      create_task: {
        success: {
          text: translation.alerts.create_task.success.text,
          submit: translation.alerts.create_task.success.submit
        },
        warning: {
          text: translation.alerts.create_task.warning.text,
          submit: translation.alerts.create_task.warning.submit
        }
      },
      delete_opportunity: {
        success: {
          text: translation.alerts.delete_opportunity.success.text,
          submit: translation.alerts.delete_opportunity.success.submit
        },
        warning: {
          text: translation.alerts.delete_opportunity.warning.text,
          submit: translation.alerts.delete_opportunity.warning.submit
        }
      },
      delete_task: {
        success: {
          text: translation.alerts.delete_task.success.text,
          submit: translation.alerts.delete_task.success.submit
        },
        warning: {
          text: translation.alerts.delete_task.warning.text,
          submit: translation.alerts.delete_task.warning.submit
        }
      },
      download_opportunity: {
        error: {
          text: translation.alerts.download_opportunity.error.text,
          submit: translation.alerts.download_opportunity.error.submit
        }
      },
      edit_opportunity: {
        success: {
          text: translation.alerts.edit_opportunity.success.text,
          submit: translation.alerts.edit_opportunity.success.submit
        },
        warning: {
          text: translation.alerts.edit_opportunity.warning.text,
          submit: translation.alerts.edit_opportunity.warning.submit
        }
      },
      edit_task: {
        success: {
          text: translation.alerts.edit_task.success.text,
          submit: translation.alerts.edit_task.success.submit
        },
        warning: {
          text: translation.alerts.edit_task.warning.text,
          submit: translation.alerts.edit_task.warning.submit
        }
      }
    },
    buttons: {
      add_task: translation.opportunities.single.buttons.add_task
    },
    dialogs: {
      edit_opportunity: {
        titles: {
          add_opportunity:
            translation.opportunities.single.dialogs.edit_opportunity.titles
              .add_opportunity,
          edit_opportunity:
            translation.opportunities.single.dialogs.edit_opportunity.titles
              .edit_opportunity
        },
        buttons: {
          add:
            translation.opportunities.single.dialogs.edit_opportunity.buttons
              .add,
          cancel:
            translation.opportunities.single.dialogs.edit_opportunity.buttons
              .cancel,
          save:
            translation.opportunities.single.dialogs.edit_opportunity.buttons
              .save
        },
        inputs: {
          opportunity_name:
            translation.opportunities.single.dialogs.edit_opportunity.inputs
              .opportunity_name,
          opportunity_date:
            translation.opportunities.single.dialogs.edit_opportunity.inputs
              .opportunity_date,
          opportunity_estimated_value:
            translation.opportunities.single.dialogs.edit_opportunity.inputs
              .opportunity_estimated_value
        },
        selects: {
          currency: {
            placeholder:
              translation.opportunities.single.dialogs.edit_opportunity.selects
                .currency.placeholder,
            items: [
              {
                value: "euro",
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.currency.eur
              },
              {
                value: "dolar",
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.currency.usd
              },
              {
                value: "sol",
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.currency.sol
              },
              {
                value: "pound",
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.currency.gbp
              }
            ]
          },
          opportunity_contact: {
            placeholder: "Contacto asociado"
          },
          opportunity_source: {
            placeholder:
              translation.opportunities.single.dialogs.edit_opportunity.selects
                .opportunity_source.placeholder,
            items: [
              {
                value: "own",
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_source.own
              },
              {
                value: "product",
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_source.product
              }
            ]
          },
          opportunity_status: {
            placeholder:
              translation.opportunities.single.dialogs.edit_opportunity.selects
                .opportunity_status.placeholder,
            items: [
              {
                value: 0,
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.lead.label,
                tooltip:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.lead.tooltip
              },
              {
                value: 1,
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.proposal.label,
                tooltip:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.proposal.tooltip
              },
              {
                value: 2,
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.offer.label,
                tooltip:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.offer.tooltip
              },
              {
                value: 3,
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.hibernate.label,
                tooltip:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.hibernate.tooltip
              },
              {
                value: 4,
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.won.label,
                tooltip:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.won.tooltip
              },
              {
                value: 5,
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.lost.label,
                tooltip:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_status.lost.tooltip
              }
            ]
          },
          opportunity_service_type: {
            placeholder:
              translation.opportunities.single.dialogs.edit_opportunity.selects
                .opportunity_service_type.placeholder,
            items: [
              {
                value: "event",
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_service_type.event
              },
              {
                value: "campus",
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_service_type.campus
              },
              {
                value: "academy",
                label:
                  translation.opportunities.single.dialogs.edit_opportunity
                    .selects.opportunity_service_type.academy
              }
            ]
          }
        }
      },
      add_task: {
        buttons: {
          add: translation.opportunities.single.dialogs.add_task.buttons.add,
          save: translation.opportunities.single.dialogs.add_task.buttons.save,
          cancel:
            translation.opportunities.single.dialogs.add_task.buttons.cancel
        },
        inputs: {
          task_date:
            translation.opportunities.single.dialogs.add_task.inputs.task_date,
          task_details:
            translation.opportunities.single.dialogs.add_task.inputs
              .task_details,
          task_subject:
            translation.opportunities.single.dialogs.add_task.inputs
              .task_bubject
        },
        selects: {
          task_type: {
            placeholder:
              translation.opportunities.single.dialogs.add_task.selects
                .task_type.placeholder,
            items: [
              {
                value: "call",
                label:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_type.call
              },
              {
                value: "email",
                label:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_type.email
              },
              {
                value: "meeting",
                label:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_type.meeting
              },
              {
                value: "visit",
                label:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_type.visit
              }
            ]
          },
          task_status: {
            placeholder:
              translation.opportunities.single.dialogs.add_task.selects
                .task_status.placeholder,
            items: [
              {
                value: "pending",
                label:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_status.pending
              },
              {
                value: "done",
                label:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_status.done
              }
            ]
          },
          task_opportunity_status: {
            placeholder:
              translation.opportunities.single.dialogs.add_task.selects
                .task_opportunity_status.placeholder,
            items: [
              {
                value: "lead",
                label:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_opportunity_status.lead.label,
                tooltip:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_opportunity_status.lead.tooltip
              },
              {
                value: "proposal",
                label:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_opportunity_status.proposal.label,
                tooltip:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_opportunity_status.proposal.tooltip
              },
              {
                value: "offer",
                label:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_opportunity_status.offer.label,
                tooltip:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_opportunity_status.offer.tooltip
              },
              {
                value: "hibernate",
                label:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_opportunity_status.hibernate.label,
                tooltip:
                  translation.opportunities.single.dialogs.add_task.selects
                    .task_opportunity_status.hibernate.tooltip
              }
            ]
          },
          task_contact: {
            placeholder:
              translation.opportunities.single.dialogs.add_task.selects
                .task_contact.placeholder
          }
        }
      },
      delete_opportunity: {
        title:
          translation.opportunities.single.dialogs.delete_opportunity.titles
            .delete_opportunity,
        info: translation.opportunities.single.dialogs.delete_opportunity.info,
        buttons: {
          delete:
            translation.opportunities.single.dialogs.delete_opportunity.buttons
              .delete,
          cancel:
            translation.opportunities.single.dialogs.delete_opportunity.buttons
              .cancel
        }
      },
      delete_task: {
        title:
          translation.opportunities.single.dialogs.delete_task.titles
            .delete_task,
        info: translation.opportunities.single.dialogs.delete_task.info,
        buttons: {
          delete:
            translation.opportunities.single.dialogs.delete_task.buttons.delete,
          cancel:
            translation.opportunities.single.dialogs.delete_task.buttons.cancel
        }
      }
    },
    opportunity_info: {
      opportunity_contact:
        translation.opportunities.single.opportunity_info.opportunity_contact,
      opportunity_estimated_value:
        translation.opportunities.single.opportunity_info
          .opportunity_estimated_value,
      opportunity_status:
        translation.opportunities.single.opportunity_info.opportunity_status,
      opportunity_service_type:
        translation.opportunities.single.opportunity_info
          .opportunity_service_type,
      opportunity_people_of_contact:
        translation.opportunities.single.opportunity_info
          .opportunity_people_of_contact
    },
    opportunity_status: [
      {
        value: 0,
        label: translation.opportunities.single.opportunity_status.lead
      },
      {
        value: 1,
        label: translation.opportunities.single.opportunity_status.proposal
      },
      {
        value: 2,
        label: translation.opportunities.single.opportunity_status.offer
      },
      {
        value: 3,
        label: translation.opportunities.single.opportunity_status.hibernate
      },
      {
        value: 4,
        label: translation.opportunities.single.opportunity_status.won
      },
      {
        value: 5,
        label: translation.opportunities.single.opportunity_status.lost
      }
    ],
    opportunity_service_type: [
      {
        value: "event",
        label: translation.opportunities.single.opportunity_service_type.event
      },
      {
        value: "campus",
        label: translation.opportunities.single.opportunity_service_type.campus
      },
      {
        value: "academy",
        label: translation.opportunities.single.opportunity_service_type.academy
      }
    ],
    datatables: {
      tasks: {
        title: translation.opportunities.single.datatables.tasks.title,
        textLabels:
          translation.opportunities.single.datatables.tasks.textLabels,
        columns: [
          {
            label: translation.opportunities.single.datatables.tasks.columns.id,
            name: "_id",
            options: { display: false, filter: false }
          },
          {
            label:
              translation.opportunities.single.datatables.tasks.columns.subject,
            name: "subject",
            options: {
              filter: false
            }
          },
          {
            label:
              translation.opportunities.single.datatables.tasks.columns.date,
            name: "date",
            options: {
              filter: true,
              filterType: "custom",
              customFilterListRender: v => {
                const values = v.map(_v =>
                  moment(_v).format("DD-MM-YYYY HH:mm")
                );
                if (v[0] && v[1]) {
                  return `${translation.opportunities.single.datatables.tasks.date.from} ${values[0]}, ${translation.opportunities.single.datatables.tasks.date.to} ${values[1]}`;
                } else if (values[0]) {
                  return `${translation.opportunities.single.datatables.tasks.date.from} ${values[0]}`;
                } else if (values[1]) {
                  return `${translation.opportunities.single.datatables.tasks.date.to} ${values[1]}`;
                }
                return false;
              },
              filterOptions: {
                names: [],
                logic(date, filters) {
                  const momFilt = filters.map(filt =>
                    moment(filt, "DD-MM-YYYY HH:mm")
                  );
                  const momDate = moment(date, "DD-MM-YYYY HH:mm");
                  let show = true;

                  if (filters[0] && filters[1]) {
                    show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                  } else if (filters[0]) {
                    show = momDate.isSameOrAfter(momFilt[0]);
                  } else if (filters[1]) {
                    show = momDate.isSameOrBefore(momFilt[1]);
                  }
                  return !show;
                },
                display: (filterList, onChange, index, column) => {
                  // return <RangeDatePicker
                  //   time
                  //   translation={translation.opportunities.single.datatables.tasks.date ? translation.opportunities.single.datatables.tasks.date: null}
                  //   values={filterList}
                  //   onChange={onChange}
                  //   index={index}
                  //   column={column}
                  // />
                  return [
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.opportunities.single.datatables.tasks.date
                            .from
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][0] || null}
                        onChange={event => {
                          filterList[index][0] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                        style={{ marginRight: "5%" }}
                      />
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.opportunities.single.datatables.tasks.date
                            .to
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][1] || null}
                        onChange={event => {
                          filterList[index][1] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ];
                }
              },
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[2]).format("DD/MM/YYYY HH:mm")
            }
          },
          {
            label:
              translation.opportunities.single.datatables.tasks.columns
                .person_of_contact,
            name: "person_of_contact",
            options: {
              filter: false
            }
          },

          {
            label:
              translation.opportunities.single.datatables.tasks.columns.type,
            name: "type",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: v =>
                `${translation.opportunities.single.datatables.tasks.columns.type} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.opportunities.single.datatables.tasks.type
                ),
                logic(type, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks.type
                        .call
                    ) >= 0 &&
                      type === "call") ||
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks.type
                        .email
                    ) >= 0 &&
                      type === "email") ||
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks.type
                        .meeting
                    ) >= 0 &&
                      type === "meeting") ||
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks.type
                        .visit
                    ) >= 0 &&
                      type === "visit");
                  return !show;
                }
              },
              customBodyRender: (value, tableMeta, updateValue) => (
                <Typography variant="body2" gutterBottom>
                  {
                    translation.opportunities.single.datatables.tasks.type[
                      tableMeta.rowData[4]
                    ]
                  }
                </Typography>
              )
            }
          },
          {
            label:
              translation.opportunities.single.datatables.tasks.columns.status,
            name: "status",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: v =>
                `${translation.opportunities.single.datatables.tasks.columns.status} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.opportunities.single.datatables.tasks.status
                ),
                logic(status, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks.status
                        .pending
                    ) >= 0 &&
                      status === "pending") ||
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks.status
                        .done
                    ) >= 0 &&
                      status === "done");
                  return !show;
                }
              },
              customBodyRender: (value, tableMeta, updateValue) => (
                <Chip
                  label={
                    translation.opportunities.single.datatables.tasks.status[
                      tableMeta.rowData[5]
                    ]
                  }
                  style={
                    tableMeta.rowData[5] === "done"
                      ? {
                          background: "#77ffb5",
                          color: "#616161"
                        }
                      : {
                          background: "#ffe082",
                          color: "#616161"
                        }
                  }
                />
              )
            }
          },
          {
            label:
              translation.opportunities.single.datatables.tasks.columns
                .opportunity_status,
            name: "opportunity_status",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: v =>
                `${translation.opportunities.single.datatables.tasks.columns.opportunity_status} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.opportunities.single.datatables.tasks
                    .opportunity_status
                ),
                logic(opportunity_status, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks
                        .opportunity_status.lead
                    ) >= 0 &&
                      opportunity_status === "lead") ||
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks
                        .opportunity_status.offer
                    ) >= 0 &&
                      opportunity_status === "offer") ||
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks
                        .opportunity_status.proposal
                    ) >= 0 &&
                      opportunity_status === "proposal") ||
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks
                        .opportunity_status.hibernate
                    ) >= 0 &&
                      opportunity_status === "hibernate") ||
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks
                        .opportunity_status.won
                    ) >= 0 &&
                      opportunity_status === "won") ||
                    (filterVal.indexOf(
                      translation.opportunities.single.datatables.tasks
                        .opportunity_status.lost
                    ) >= 0 &&
                      opportunity_status === "lost");
                  return !show;
                }
              },
              customBodyRender: (value, tableMeta, updateValue) => (
                <Chip
                  label={
                    translation.opportunities.single.datatables.tasks
                      .opportunity_status[tableMeta.rowData[6]]
                  }
                />
              )
            }
          },
          {
            label: "",
            name: "options"
          }
        ],
        options: options
      }
    },
    popovers: { tasks: popovers.opportunities.single.datatables.tasks }
  };
};

export { OpportunityTemplate };
