import Axios from "core/axios";

class NotificationsServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_CRM;
    this.service = new Axios(this.api_url);
  }

  getNotifications = franchise => {
    return this.service.get("/notifications?franchise=" + franchise);
  };
}

export default NotificationsServices;
