import React, { Component } from "react";
import { PastOpportunities, Grid } from "react-library-sm";

import OpportunitiesServices from "services/franchise-crm/opportunities.services";
import UserContext from "providers/UserProvider";

class PastOpportunitiesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wonOpportunities: [],
      lostOpportunities: [],
    };
    this.opportunityService = new OpportunitiesServices();
  }

  static contextType = UserContext;

  componentDidMount() {
    this.opportunityService
      .getOpportunities(this.context.franchise)
      .then((response) => {
        // console.log("response PastOpportunities", response);

        const _opportunities = { won: [], lost: [] };

        response.data.forEach((opportunity) => {
          // console.log("status sells", opportunity.statusSells);
          if (opportunity.statusSells === 4)
            _opportunities.won.push({
              ...opportunity,
              client: opportunity.client[0].name,
            });
          else if (opportunity.statusSells === 5)
            _opportunities.lost.push({
              ...opportunity,
              client: opportunity.client[0].name,
            });
          else return;
        });

        // console.log('_opportunities', _opportunities);

        this.setState({
          wonOpportunities: _opportunities.won,
          lostOpportunities: _opportunities.lost,
        });
      })
      .catch((err) => {
        // console.log('err get projects', err.response);
      });
  }
  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <Grid item md={12}>
          <PastOpportunities
            wonOpportunities={this.state.wonOpportunities}
            lostOpportunities={this.state.lostOpportunities}
            translation={
              this.context.translation.templates
                ? this.context.translation.templates.pastOpportunities
                : null
            }
          />
        </Grid>
      </Grid>
    );
  }
}

export default PastOpportunitiesContainer;
