import moment from "moment";

let beginPreviousMonth = moment().subtract(1, "M").format("YYYY-MM-01");
let endPreviousMonth =
  moment().subtract(1, "M").format("YYYY-MM-") + moment().daysInMonth();

let begintCurrentMonth = moment().subtract(1, "M").format("YYYY-MM-01");
let endCurrentMonth =
  moment().subtract(1, "M").format("YYYY-MM-") + moment().daysInMonth();

export const createDashboard = (invoices, year, dashboard) => {
  // console.log(
  //   "invoices to check dashboard",
  //   invoices.filter((invoice) => invoice.category === "others")
  // );
  const events = invoices
    .filter((invoice) => invoice.category === "events")
    .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0);

  const academies = invoices
    .filter((invoice) => invoice.category === "academies")
    .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0);

  const other = invoices
    .filter((invoice) => invoice.category === "others")
    .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0);

  const campus = invoices
    .filter((invoice) => invoice.category === "campus")
    .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0);

  const total = invoices.reduce(
    (total, currentInvoice) => currentInvoice.subtotal + total,
    0
  );
  return {
    ...dashboard,
    events,
    [`events_${year}`]: events,
    academies,
    [`academies_${year}`]: academies,
    other,
    [`other_${year}`]: other,
    campus,
    [`campus_${year}`]: campus,
    lastMonth: invoices
      .filter((invoice) =>
        moment(invoice.date).isBetween(beginPreviousMonth, endPreviousMonth)
      )
      .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0),
    month: invoices
      .filter((invoice) =>
        moment(invoice.date).isBetween(begintCurrentMonth, endCurrentMonth)
      )
      .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0),
    paid: invoices
      .filter((invoice) => invoice.status === "paid")
      .reduce((total, currentInvoice) => currentInvoice.total + total, 0),
    pending: invoices
      .filter(
        (invoice) =>
          invoice.status === "pending" || invoice.status === "uploaded_to_face"
      )
      .reduce((total, currentInvoice) => currentInvoice.total + total, 0),
    total,
    [`total_${year}`]: total,
  };
};
