const WebInboxTemplate = (translation) => {
  return {
    selects: { service: translation.selects.service },
    blocks: {
      general: {
        announcements: translation.blocks.general.announcements,
        staff: translation.blocks.general.staff,
      },
      groups: {
        title: translation.blocks.groups.title,
        search_placeholder: translation.blocks.groups.search_placeholder,
      },
      direct_messages: {
        title: translation.blocks.direct_messages.title,
        search_placeholder:
          translation.blocks.direct_messages.search_placeholder,
        buttons: {
          select: translation.blocks.direct_messages.buttons.select,
        },
        dialogs: {
          new_conversation: {
            title:
              translation.blocks.direct_messages.dialogs.new_conversation.title,
            buttons: {
              select:
                translation.blocks.direct_messages.dialogs.new_conversation
                  .buttons.select,
            },
          },
        },
      },
    },
  };
};

export { WebInboxTemplate };
