import React, { Component } from "react";
import userContext from "providers/UserProvider";
import { FranchiseDashboard as DasboardComponent } from "react-library-sm";
import { Grid } from "@material-ui/core";
import DashboardServices from "services/franchise-crm/dashboard.services";
class Dashboard extends Component {
  static contextType = userContext;
  constructor(props) {
    super(props);
    this.dashboardServices = new DashboardServices();
    this.state = {
      numOpportunities: null,
      totalBudget: null,
      tasks: [],
    };
  }

  componentDidMount() {
    this.props.history.push("/invoices");
    this.dashboardServices
      .getDashboard(this.context.franchise)
      .then((response) => {
        // console.log("response get dashboard", response);

        const _tasks = response.data.tasks.map((task) => {
          return {
            ...task,
            person_of_contact: task.contact.name,
            client_name: task.contact.client.name,
          };
        });
        this.setState({
          numOpportunities: response.data.opportunities.count,
          totalBudget: response.data.opportunities.total,
          tasks: _tasks,
        });
      })
      .catch((err) => {
        // console.log("err get dashboard", err.response);
      });
  }

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <DasboardComponent
          numOpportunities={this.state.numOpportunities}
          totalBudget={this.state.totalBudget}
          tasks={this.state.tasks}
          translation={this.context.translation.templates.crmdashboard}
          locale={this.context.locale}
        />
      </Grid>
    );
  }
}

export default Dashboard;
