import Axios from "core/axiosCampus";

class CoreServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_SERVICES_CORE;
    this.service = new Axios(this.api_url);
  }

  // --------------------------------- LIST

  // ----- GET

  getAttendanceList = (campusId, groupId) =>
    this.service.get(
      `/attendance?service=Campus&serviceId=${campusId}&group=${groupId}`
    );

  // --------------------------------- SINGLE

  getSatisfactionService = (serviceId) =>
    this.service.get(`/satisfaction?view=service&serviceId=${serviceId}`);

  getAttendanceSingle = (attendanceId) =>
    this.service.get(`/attendance/${attendanceId}`);
  // ----- GET

  // ----- POST

  // ----- PUT

  // ----- DELETE
  //--------------- Services
  getServices = () => this.service.get(`/services`);
}

export default CoreServices;
