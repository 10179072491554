const InvoiceTemplate = (translation) => {
  return {
    add: translation.invoices.single.add,
    edit: translation.invoices.single.edit,
    buttons: {
      save: translation.invoices.single.buttons.save,
      add: translation.invoices.single.buttons.add,
      cancel: translation.invoices.single.buttons.cancel,
      add_item: translation.invoices.single.buttons.add_item,
    },
    inputs: {
      date: translation.invoices.single.inputs.date,
      due_date: translation.invoices.single.inputs.due_date,
      irpf: translation.invoices.single.inputs.irpf,
      items: {
        name: translation.invoices.single.inputs.items.name,
        description: translation.invoices.single.inputs.items.description,
        quantity: translation.invoices.single.inputs.items.quantity,
        price: translation.invoices.single.inputs.items.price,
        subtotal: translation.invoices.single.inputs.items.subtotal,
        total: translation.invoices.single.inputs.items.total,
      },
      message: translation.invoices.single.inputs.message,
    },
    selects: {
      client: {
        placeholder: translation.invoices.single.selects.client.placeholder,
      },
      series: {
        placeholder: translation.invoices.single.selects.series.placeholder,
      },
      taxes: {
        placeholder: translation.invoices.single.selects.taxes.placeholder,
        items: [
          {
            percentage: 0.077,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .iva_7_7,
            value: "iva_7_7",
          },
          {
            percentage: 0.081,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .iva_8_1,
            value: "iva_8_1",
          },
          {
            percentage: 0.19,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .iva_19,
            value: "iva_19",
          },
          {
            percentage: 0.2,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .iva_20,
            value: "iva_20",
          },
          {
            percentage: 0.21,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .iva_21,
            value: "iva_21",
          },
          {
            percentage: 0.16,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .iva_16,
            value: "iva_16",
          },
          {
            percentage: 0.17,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .iva_17,
            value: "iva_17",
          },
          {
            percentage: 0.18,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .iva_18,
            value: "iva_18",
          },
          {
            percentage: 0.1,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .iva_10,
            value: "iva_10",
          },
          {
            percentage: 0,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .no_iva,
            value: "no_iva",
          },
          {
            percentage: 0,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .no_igic,
            value: "no_igic",
          },
          {
            percentage: 0.07,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .igic_7,
            value: "igic_7",
          },
          {
            percentage: 0.22,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .iva_22,
            value: "iva_22",
          },
          {
            percentage: 0.23,
            label:
              translation.invoices.list.dialogs.view_invoice.pdf.document.taxes
                .iva_23,
            value: "iva_23",
          },
        ],
      },
      categories: {
        placeholder: translation.invoices.single.selects.categories.placeholder,
        items: [
          {
            label: translation.invoices.single.selects.categories.events,
            value: "events",
          },
          {
            label: translation.invoices.single.selects.categories.campus,
            value: "campus",
          },
          {
            label: translation.invoices.single.selects.categories.academies,
            value: "academies",
          },
          {
            label: translation.invoices.single.selects.categories.others,
            value: "others",
          },
        ],
      },
      payment_method: {
        placeholder:
          translation.invoices.single.selects.payment_method.placeholder,
      },
      projects: {
        placeholder: translation.invoices.single.selects.projects.placeholder,
      },
    },
    titles: {
      subtotal: translation.invoices.single.titles.subtotal,
      tax: translation.invoices.single.titles.tax,
      irpf: translation.invoices.single.titles.irpf,
      total: translation.invoices.single.titles.total,
    },
    dialogs: {
      add_contact: {
        titles: {
          add_contact:
            translation.contacts.list.dialogs.add_contact.titles.add_contact,
        },
        buttons: {
          cancel: translation.contacts.list.dialogs.add_contact.buttons.cancel,
          save: translation.contacts.list.dialogs.add_contact.buttons.save,
          add: translation.contacts.list.dialogs.add_contact.buttons.add,
        },
        inputs: {
          contact_name:
            translation.contacts.list.dialogs.add_contact.inputs.contact_name,
        },
        forms: {
          client: {
            inputs: {
              contact_city:
                translation.contacts.list.dialogs.add_contact.forms.client
                  .inputs.contact_city,
              contact_email:
                translation.contacts.list.dialogs.add_contact.forms.client
                  .inputs.contact_email,
              contact_phone:
                translation.contacts.list.dialogs.add_contact.forms.client
                  .inputs.contact_phone,
              contact_web:
                translation.contacts.list.dialogs.add_contact.forms.client
                  .inputs.contact_web,
            },
            selects: {
              client_type: {
                placeholder:
                  translation.contacts.list.dialogs.add_contact.forms.client
                    .selects.client_type.placeholder,
                items: [
                  {
                    value: "school",
                    label:
                      translation.contacts.list.dialogs.add_contact.forms.client
                        .selects.client_type.school,
                  },
                  {
                    value: "publicAdmin",
                    label:
                      translation.contacts.list.dialogs.add_contact.forms.client
                        .selects.client_type.public_admin,
                  },
                  {
                    value: "company",
                    label:
                      translation.contacts.list.dialogs.add_contact.forms.client
                        .selects.client_type.company,
                  },
                  {
                    value: "individual",
                    label:
                      translation.contacts.list.dialogs.add_contact.forms.client
                        .selects.client_type.individual,
                  },
                  {
                    value: "club",
                    label:
                      translation.contacts.list.dialogs.add_contact.forms.client
                        .selects.client_type.club,
                  },
                ],
              },
            },
          },
          person_of_contact: {
            inputs: {
              person_name:
                translation.contacts.list.dialogs.add_contact.forms
                  .person_of_contact.inputs.person_name,
              person_job:
                translation.contacts.list.dialogs.add_contact.forms
                  .person_of_contact.inputs.person_job,
              person_email:
                translation.contacts.list.dialogs.add_contact.forms
                  .person_of_contact.inputs.person_email,
              person_phone:
                translation.contacts.list.dialogs.add_contact.forms
                  .person_of_contact.inputs.person_phone,
            },
            buttons: {
              add_person_of_contact:
                translation.contacts.list.dialogs.add_contact.forms
                  .person_of_contact.buttons.add_person_of_contact,
            },
          },
          billing_info: {
            inputs: {
              name: translation.contacts.list.dialogs.add_contact.forms
                .billing_info.inputs.name,
              address:
                translation.contacts.list.dialogs.add_contact.forms.billing_info
                  .inputs.address,
              zipcode:
                translation.contacts.list.dialogs.add_contact.forms.billing_info
                  .inputs.zipcode,
              town: translation.contacts.list.dialogs.add_contact.forms
                .billing_info.inputs.town,
              province:
                translation.contacts.list.dialogs.add_contact.forms.billing_info
                  .inputs.province,
              vat_number:
                translation.contacts.list.dialogs.add_contact.forms.billing_info
                  .inputs.vat_number,
            },
          },
        },
        tabs: [
          {
            value: "client",
            label: translation.contacts.list.dialogs.add_contact.tabs.client,
          },
          {
            value: "person_of_contact",
            label:
              translation.contacts.list.dialogs.add_contact.tabs
                .person_of_contact,
          },
          {
            value: "billing",
            label: translation.contacts.list.dialogs.add_contact.tabs.billing,
          },
          {
            value: "face",
            label: "Datos FACE",
          },
        ],
        tabs_edit: [
          {
            value: "client",
            label: translation.contacts.list.dialogs.add_contact.tabs.client,
          },
          {
            value: "billing",
            label: translation.contacts.list.dialogs.add_contact.tabs.billing,
          },
          {
            value: "face",
            label: "Datos FACE",
          },
        ],
      },
    },
    alerts: {
      edit_invoice: {
        error: {
          text: translation.invoices.single.alerts.edit_invoice.error.text,
          submit: translation.invoices.single.alerts.edit_invoice.error.submit,
        },
      },
      get_invoice: {
        error: {
          text: translation.invoices.single.alerts.get_invoice.error.text,
          submit: translation.invoices.single.alerts.get_invoice.error.submit,
        },
      },
      get_info: {
        error: {
          text: translation.invoices.single.alerts.get_info.error.text,
          submit: translation.invoices.single.alerts.get_info.error.submit,
        },
      },
    },
  };
};
export { InvoiceTemplate };
