import React from "react";
import { Chip } from "react-library-sm";
import moment from "moment";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import { Link } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
const ProjectsTemplate = translation => {
  // let popovers;
  // const getPopovers = (lang) => {
  // 	popovers = require(`assets/languages/i18n/translations/${lang}/popovers`).popovers;
  // };

  return {
    alerts: {
      download_projects: {
        error: {
          text: translation.alerts.download_projects.error.text,
          submit: translation.alerts.download_projects.error.submit
        }
      },
      download_contacts: {
        error: {
          text: translation.alerts.download_contacts.error.text,
          submit: translation.alerts.download_contacts.error.submit
        }
      },
      download_templates: {
        error: {
          text: translation.alerts.download_templates.error.text,
          submit: translation.alerts.download_templates.error.submit
        }
      },
      download_opportunities: {
        error: {
          text: translation.alerts.download_opportunities.error.text,
          submit: translation.alerts.download_opportunities.error.submit
        }
      },
      create_project: {
        success: {
          text: translation.alerts.create_project.success.text,
          submit: translation.alerts.create_project.success.submit
        },
        warning: {
          text: translation.alerts.create_project.warning.text,
          submit: translation.alerts.create_project.warning.submit
        }
      }
    },
    title: translation.projects.list.title,
    buttons: {
      add_project: translation.projects.list.buttons.add_project
    },
    dialogs: {
      add_project: {
        titles: {
          add_project:
            translation.projects.list.dialogs.add_project.titles.add_project,
          edit_project:
            translation.projects.list.dialogs.add_project.titles.edit_project
        },
        buttons: {
          back: translation.projects.list.dialogs.add_project.buttons.back,
          next: translation.projects.list.dialogs.add_project.buttons.next,
          finish: translation.projects.list.dialogs.add_project.buttons.finish
        },

        steps: [
          {
            value: 0,
            label: translation.projects.list.dialogs.add_project.steps.step0
          },
          {
            value: 1,
            label: translation.projects.list.dialogs.add_project.steps.step1
          },
          {
            value: 2,
            label: translation.projects.list.dialogs.add_project.steps.step2
          }
        ],
        inputs: {
          project_name:
            translation.projects.list.dialogs.add_project.inputs.project_name,
          project_start_date:
            translation.projects.list.dialogs.add_project.inputs
              .project_start_date,
          project_finish_date:
            translation.projects.list.dialogs.add_project.inputs
              .project_finish_date,
          budget: translation.projects.list.dialogs.add_project.inputs.budget
        },
        selects: {
          status: {
            placeholder:
              translation.projects.list.dialogs.add_project.selects.status
                .placeholder,
            items: [
              {
                value: "plan",
                label:
                  translation.projects.list.dialogs.add_project.selects.status
                    .plan
              },
              {
                value: "docheck",
                label:
                  translation.projects.list.dialogs.add_project.selects.status
                    .docheck
              },
              {
                value: "close",
                label:
                  translation.projects.list.dialogs.add_project.selects.status
                    .close
              }
            ]
          },
          project_type: {
            placeholder:
              translation.projects.list.dialogs.add_project.selects.project_type
                .placeholder,
            items: [
              {
                value: "b2b",
                label:
                  translation.projects.list.dialogs.add_project.selects
                    .project_type.b2b
              },
              {
                value: "b2c",
                label:
                  translation.projects.list.dialogs.add_project.selects
                    .project_type.b2c
              }
            ]
          },
          project_source: {
            placeholder:
              translation.projects.list.dialogs.add_project.selects
                .project_source.placeholder,
            items: [
              {
                value: "opportunity",
                label:
                  translation.projects.list.dialogs.add_project.selects
                    .project_source.opportunity
              },
              {
                value: "new",
                label:
                  translation.projects.list.dialogs.add_project.selects
                    .project_source.new
              }
            ]
          },
          currency: {
            placeholder:
              translation.opportunities.list.dialogs.add_opportunity.selects
                .currency.placeholder,
            items: [
              {
                value: "euro",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.eur
              },
              {
                value: "dolar",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.usd
              },
              {
                value: "sol",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.sol
              },
              {
                value: "gbp",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.gbp
              }
            ]
          },
          opportunity: {
            placeholder:
              translation.projects.list.dialogs.add_project.selects.opportunity
                .placeholder
          },
          opportunity_contact: {
            placeholder:
              translation.projects.list.dialogs.add_project.selects
                .opportunity_contact.placeholder
          },
          service_type: {
            placeholder:
              translation.projects.list.dialogs.add_project.selects.service_type
                .placeholder,
            items: [
              {
                value: "event",
                label:
                  translation.projects.list.dialogs.add_project.selects
                    .service_type.event
              },
              {
                value: "campus",
                label:
                  translation.projects.list.dialogs.add_project.selects
                    .service_type.campus
              },
              {
                value: "academy",
                label:
                  translation.projects.list.dialogs.add_project.selects
                    .service_type.academy
              }
            ]
          },
          template: {
            placeholder:
              translation.projects.list.dialogs.add_project.selects.template
                .placeholder
          }
        }
      }
    },
    views: {
      columns: {
        status: {
          plan: translation.projects.list.views.columns.status.plan,
          docheck: translation.projects.list.views.columns.status.docheck,
          close: translation.projects.list.views.columns.status.close
        }
      },
      datatable: {
        title: translation.projects.list.views.datatable.title,
        textLabels: translation.projects.list.views.datatable.textLabels,
        columns: [
          {
            label: translation.projects.list.views.datatable.columns.id,
            name: "_id",
            options: { display: false, filter: false }
          },
          {
            label: translation.projects.list.views.datatable.columns.name,
            name: "name",
            options: { filter: false }
          },
          {
            label: translation.projects.list.views.datatable.columns.startDate,
            name: "startDate",
            options: {
              filter: true,
              filterType: "custom",
              customFilterListRender: v => {
                const values = v.map(_v =>
                  moment(_v).format("DD-MM-YYYY HH:mm")
                );
                if (v[0] && v[1]) {
                  return `${translation.projects.list.views.datatable.date.from} ${values[0]}, ${translation.projects.list.views.datatable.date.to} ${values[1]}`;
                } else if (values[0]) {
                  return `${translation.projects.list.views.datatable.date.from} ${values[0]}`;
                } else if (values[1]) {
                  return `${translation.projects.list.views.datatable.date.to} ${values[1]}`;
                }
                return false;
              },
              filterOptions: {
                names: [],
                logic(startDate, filters) {
                  const momFilt = filters.map(filt =>
                    moment(filt, "DD-MM-YYYY HH:mm")
                  );
                  const momDate = moment(startDate, "DD-MM-YYYY HH:mm");
                  let show = true;

                  if (filters[0] && filters[1]) {
                    show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                  } else if (filters[0]) {
                    show = momDate.isSameOrAfter(momFilt[0]);
                  } else if (filters[1]) {
                    show = momDate.isSameOrBefore(momFilt[1]);
                  }
                  return !show;
                },
                display: (filterList, onChange, index, column) => {
                  // return <RangeDatePicker
                  //   time
                  //   translation={translation.opportunities.single.datatables.tasks.date ? translation.opportunities.single.datatables.tasks.date: null}
                  //   values={filterList}
                  //   onChange={onChange}
                  //   index={index}
                  //   column={column}
                  // />
                  return [
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.projects.list.views.datatable.date.from
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][0] || null}
                        onChange={event => {
                          filterList[index][0] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                        style={{ marginRight: "5%" }}
                      />
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.projects.list.views.datatable.date.to
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][1] || null}
                        onChange={event => {
                          filterList[index][1] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ];
                }
              },
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[2]).format("DD/MM/YYYY")
            }
          },
          {
            label: translation.projects.list.views.datatable.columns.type,
            name: "type",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: v =>
                `${translation.projects.list.views.datatable.columns.type} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.projects.list.views.datatable.type
                ),
                logic(type, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.projects.list.views.datatable.type.event
                    ) >= 0 &&
                      type === "event") ||
                    (filterVal.indexOf(
                      translation.projects.list.views.datatable.type.academy
                    ) >= 0 &&
                      type === "academy") ||
                    (filterVal.indexOf(
                      translation.projects.list.views.datatable.type.campus
                    ) >= 0 &&
                      type === "campus");
                  return !show;
                }
              },
              customBodyRender: (value, tableMeta, updateValue) => (
                <Chip
                  label={
                    translation.projects.list.views.datatable.type[
                      tableMeta.rowData[3]
                    ]
                  }
                />
              )
            }
          },
          {
            label: translation.projects.list.views.datatable.columns.client,
            name: "client.0.name",
            options: { filter: false }
          },
          {
            label:
              translation.projects.list.views.datatable.columns.statusExecution,
            name: "statusExecution",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: v =>
                `${translation.projects.list.views.datatable.columns.status} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.projects.list.views.datatable.status
                ),
                logic(statusExecution, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.projects.list.views.datatable.status.plan
                    ) >= 0 &&
                      statusExecution === "plan") ||
                    (filterVal.indexOf(
                      translation.projects.list.views.datatable.status.docheck
                    ) >= 0 &&
                      statusExecution === "docheck") ||
                    (filterVal.indexOf(
                      translation.projects.list.views.datatable.status.close
                    ) >= 0 &&
                      statusExecution === "close");

                  return !show;
                }
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation.projects.list.views.datatable.status[
                        tableMeta.rowData[5]
                      ]
                    }
                  />
                );
              }
            }
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Link
                    to={"/projects/" + tableMeta.rowData[0] + "/view"}
                    style={{ color: "#fff" }}
                  >
                    <RemoveRedEye />
                  </Link>
                );
              }
            }
          }
        ]
      }
    }
  };
};

export { ProjectsTemplate };
