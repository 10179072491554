import React, { Component } from "react";

import InvoiceServices from "services/franchise-billing/invoices.services";
import ContactsServices from "services/franchise-crm/contacts.services";
import UserContext from "providers/UserProvider";
import { InvoiceSingle, Grid, Skeleton, Alert } from "react-library-sm";
import { Redirect } from "react-router-dom";

class InvoiceSingleComponent extends Component {
  constructor(props) {
    super(props);

    this.invoiceService = new InvoiceServices();
    this.contactService = new ContactsServices();

    this.state = {
      info: null,
      created: false,
      invoice: null,
      invoice_pdf: null,
      deleted: false,
      alertAPI: false,
      alert: null,
    };
  }
  static contextType = UserContext;

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };
  componentDidMount() {
    // console.log("this.props.variant", this.props.variant);
    if (this.props.variant !== "add") {
      this.invoiceService
        .getInvoice(this.props.id)
        .then((response) => {
          // console.log("getInvoice respoonse", response);

          this.setState({
            invoice: { ...response.data, client: response.data.client._id },
          });
        })
        .catch((err) =>
          this.setState({
            alertAPI: true,
            alert: {
              open: true,
              title: this.context.translation.alerts.titles.error,
              status: "error",
              linkText:
                this.context.translation.templates.invoices.single.alerts
                  .get_invoice.error.submit,
              link: "/invoices",
              onSubmit: this.handleOnCloseAlert,
            },
          })
        );
    }

    this.invoiceService
      .getInvoiceInfo(this.context.franchise)
      .then((response) => {
        // console.log("getInvoiceInfo respoonse", response);
        const info = {
          clients: response.data.clients.map((client) => {
            return {
              value: client._id,
              label: client.name,
              billingInfo: client.billingInfo,
            };
          }),
          series: response.data.series.map((serie) => {
            return { ...serie, value: serie._id, label: serie.name };
          }),
          payment_methods: response.data.payment_methods.map((method) => {
            return { ...method, value: method._id, label: method.name };
          }),
          projects: response.data.projects.map((project) => {
            return { ...project, value: project._id, label: project.name };
          }),
        };

        this.setState({ info });
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            linkText:
              this.context.translation.templates.invoice.alerts.get_info.error
                .submit,
            link: "/invoices",
            onSubmit: this.handleOnCloseAlert,
          },
        })
      );
  }

  createInvoice = (values) => {
    // console.log("createInvoice values", JSON.stringify(values));

    this.invoiceService
      .postInvoice(values, this.context.franchise)
      .then((response) => {
        // console.log("response postInvoice", response);
        if (response.status === 200) {
          this.setState({ created: true });
          // console.log("factura creada");
        }
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            linkText:
              this.context.translation.templates.invoice.alerts.create_invoice
                .error.submit,
            link: "/invoices",
            onSubmit: this.handleOnCloseAlert,
          },
        })
      );
  };

  editInvoice = (values) => {
    // console.log("editInvoice values", values);

    this.invoiceService
      .putInvoice(values, this.context.franchise)
      .then((response) => {
        // console.log("response putInvoice", response);
        if (response.status === 200) {
          this.setState({ edited: true });
          // console.log("factura creada")
        }
      })
      .catch((err) =>
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            linkText:
              this.context.translation.templates.invoice.alerts.edit_invoice
                .error.submit,
            link: "/invoices",
            onSubmit: this.handleOnCloseAlert,
          },
        })
      );
  };

  handleCancel = (values) => this.setState({ cancel: true });

  handleCreateContact = (data) => {
    this.contactService
      .createContact(data, this.context.franchise)
      .then((response) => {
        // console.log("response en create contact list", response);
        let info = { ...this.state.info };
        info.clients.push({
          value: response.data._id,
          label: response.data.name,
          billingInfo: response.data.billingInfo,
        });
        this.setState({
          info,
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.contacts.alerts
              .create_contact.success.text,
            submit:
              this.context.translation.templates.contacts.alerts.create_contact
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err create contact", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.contacts.alerts
              .create_contact.warning.text,
            submit:
              this.context.translation.templates.contacts.alerts.create_contact
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  editContact = (data) => {
    // console.log("change contact", data);

    this.contactService
      .updateContact(data)
      .then((response) => {
        // console.log("response update contact", response);
        let info = { ...this.state.info };
        info.clients.some((contact, index) => {
          if (contact._id === response.data._id) {
            info.clients[index] = {
              ...response.data,
              value: response.data._id,
              label: response.data.name,
            };
            return true;
          } else return false;
        });

        this.setState({
          info,
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.contact.alerts.edit_contact
              .success.text,
            submit:
              this.context.translation.templates.contact.alerts.edit_contact
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err update contact", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.contact.alerts.edit_contact
              .warning.text,
            submit:
              this.context.translation.templates.contact.alerts.edit_contact
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  getContactInfo = (id) => {
    // console.log("getContactInfo id", id);
    return this.contactService.getContact(id, this.context.franchise);
  };
  render() {
    let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    return this.state.created || this.state.edited || this.state.cancel ? (
      <Redirect to={`/invoices`} />
    ) : (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {alert}
        {this.props.variant === "edit" &&
        this.state.invoice &&
        this.state.info ? (
          <InvoiceSingle
            variant={this.props.variant}
            info={this.state.info}
            createInvoice={this.createInvoice}
            editInvoice={this.editInvoice}
            editStatus={this.editStatus}
            invoice={this.state.invoice}
            handleCancelEdit={this.handleCancel}
            drawerOpen={this.props.drawerOpen}
            translation={
              this.context.translation.templates
                ? this.context.translation.templates.invoice
                : null
            }
            franchise
            createContact={this.handleCreateContact}
            editContact={this.editContact}
            getContactInfo={this.getContactInfo}
          />
        ) : this.props.variant === "add" ? (
          this.state.info && (
            <InvoiceSingle
              variant={this.props.variant}
              info={this.state.info}
              createInvoice={this.createInvoice}
              editInvoice={this.editInvoice}
              editStatus={this.editStatus}
              invoice={this.state.invoice}
              translation={
                this.context.translation.templates
                  ? this.context.translation.templates.invoice
                  : null
              }
              franchise
              createContact={this.handleCreateContact}
              editContact={this.editContact}
              drawerOpen={this.props.drawerOpen}
              getContactInfo={this.getContactInfo}
              locale={this.context.locale}
            />
          )
        ) : (
          <Skeleton></Skeleton>
        )}
      </Grid>
    );
  }
}

export default InvoiceSingleComponent;
