import Axios from "core/axios";

class TasksServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_CRM;
    this.service = new Axios(this.api_url);
  }

  addTask = (idProject, franchise, data) => {
    return this.service.post(
      "/opportunities/" + idProject + "/tasks?franchise=" + franchise,
      data
    );
  };

  editTask = (idProject, franchise, data) => {
    return this.service.put(
      "/opportunities/" +
      idProject +
      "/tasks/" +
      data._id +
      "?franchise=" +
      franchise,
      data
    );
  };

  deleteTask = (idProject, franchise, idTask) => {
    return this.service.delete(
      "/opportunities/" +
      idProject +
      "/tasks/" +
      idTask +
      "?franchise=" +
      franchise
    );
  };
}

export default TasksServices;
