import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";

const WebCampusListTemplate = translation => {
  // console.log('translation en OpportunitiesTemplate', translation);
  return {
    title: translation.title,
    buttons: {
      add_campus: translation.buttons.add_campus
    },
    dialogs: {
      add_campus: {
        title: translation.dialogs.add_campus.title,
        inputs: {
          name: translation.dialogs.add_campus.inputs.name,
          date_start: translation.dialogs.add_campus.inputs.date_start,
          date_end: translation.dialogs.add_campus.inputs.date_end,
          target_users: translation.dialogs.add_campus.inputs.target_users
        },
        selects: {
          project: translation.dialogs.add_campus.selects.project
        }
      }
    },
    datatables: {
      campus: {
        columns: [
          {
            label: translation.datatables.campus.columns.id,
            name: "_id",
            options: {
              display: false,
              filter: false
            }
          },
          {
            name: "name",
            label: translation.datatables.campus.columns.name
          },
          {
            name: "date.start",
            label: translation.datatables.campus.columns.date_start,
            options: {
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(value, "DD/MM/YYYY").format("DD/MM/YYYY")
            }
          },
          {
            name: "date.end",
            label: translation.datatables.campus.columns.date_end,
            options: {
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(value, "DD/MM/YYYY").format("DD/MM/YYYY")
            }
          },
          {
            name: "targetUsers",
            label: translation.datatables.campus.columns.target_users
          },
          {
            name: "userCount",
            label: translation.datatables.campus.columns.user_count
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: false,
              customBodyRender: (value, tableMeta, updateValue) => (
                <Link
                  to={`/campus/${tableMeta.rowData[0]}`}
                  style={{ color: "#fafafa" }}
                >
                  <VisibilityIcon />
                </Link>
              )
            }
          }
        ],
        options: {
          selectableRows: false,
          download: false,
          print: false
        }
      }
    },
    texts: {
      users: translation.texts.users,
      satisfaction: translation.texts.satisfaction
    }
  };
};

export { WebCampusListTemplate };
