import React, { Component } from "react";
import userContext from "providers/UserProvider";
import { FranchiseProjectsBestPracticesSingle } from "react-library-sm";
import { Grid } from "@material-ui/core";
import ContactsServices from "services/franchise-crm/contacts.services";
import OpportunitiesServices from "services/franchise-crm/opportunities.services";
import ProjectsServices from "services/franchise-projects/projects.services";
import { Redirect } from "react-router-dom";

class ProjectsBestPracticesSingle extends Component {
  static contextType = userContext;
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      contactsDownloaded: false,
      opportunities: [],
      opportunitiesDownloaded: false,
      template: null,
      type: "event",
      projectCreated: false,
    };
    this.projectServices = new ProjectsServices();
    this.contactServices = new ContactsServices();
    this.opportunityServices = new OpportunitiesServices();
  }

  componentDidMount() {
    this.projectServices
      .getBestPractice(
        this.props.match.params.id,
        this.context.languageFranchise
      )
      .then((response) => {
        // console.log("response ", response);
        const template = response.data;
        this.setState({
          template: {
            _id: template._id,
            name: template.languages.name,
            details: template.languages.details,
            phases: template.languages.phases,
          },
          type: template.type,
        });
      });
  }

  getDialogData = () => {
    if (!this.state.contactsDownloaded || !this.state.opportunitiesDownloaded) {
      return this.contactServices
        .getContacts(this.context.franchise)
        .then((response) => {
          const contacts = [...response.data]
            .filter((contact) => contact.type === "client")
            .map((contact) => {
              return {
                ...contact,
                value: contact._id,
                label: contact.name,
              };
            });
          return this.opportunityServices
            .getOpportunities(this.context.franchise)
            .then((response) => {
              const opportunities = [...response.data]
                .filter((opportunity) => opportunity.statusSells === 4)
                .map((opportunity) => {
                  return {
                    ...opportunity,
                    value: opportunity._id,
                    label: opportunity.name,
                  };
                });
              this.setState({
                contacts,
                opportunities,
                contactsDownloaded: true,
                opportunitiesDownloaded: true,
              });
              return {
                data: { contacts: contacts, opportunities: opportunities },
              };
            })
            .catch((err) => {
              //TODO Create Alert
              alert("error download opportunities", err);
            });
        })
        .catch((err) => {
          //TODO Create Alert
          alert("error download contacts", err);
        });
    } else {
      return Promise.resolve({
        data: {
          contacts: this.state.contacts,
          opportunities: this.state.opportunities,
        },
      });
    }
  };
  createProject = (values) => {
    // console.log("createProject", values);
    //B2B
    if (values.contact) {
      this.projectServices
        .addProject(
          { ...values, contacts: [values.contact] },
          values.template,
          this.context.languageFranchise,
          this.context.franchise
        )
        .then((response) => {
          // console.log("response create project", response);
          this.setState({ projectCreated: response.data._id });
        });
    }
    //B2C
    else {
      this.projectServices
        .addProject(
          { ...values },
          values.template,
          this.context.languageFranchise,
          this.context.franchise
        )
        .then((response) => {
          // console.log("response create project", response);
          this.setState({ projectCreated: response.data._id });
        });
    }
  };

  render() {
    // console.log(this.context.translation);
    let redirect = this.state.projectCreated && (
      <Redirect to={`/projects/${this.state.projectCreated}`} />
    );
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {redirect}
        <FranchiseProjectsBestPracticesSingle
          template={this.state.template}
          type={this.state.type}
          getDialogData={this.getDialogData}
          createProject={this.createProject}
          translation={this.context.translation.templates.projectBestPractices}
        />
      </Grid>
    );
  }
}

export { ProjectsBestPracticesSingle };
