import React from "react";
import moment from "moment";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { ViewTypes } from "react-library-sm";

const WebCampusSingleTemplate = (translation) => {
  // console.log('translation en OpportunitiesTemplate', translation);
  return {
    title: translation.title,
    templates: {
      error: translation.templates.error,
      loading: translation.templates.loading,
      downloading: translation.templates.downloading,
    },
    dialogs: {
      assign_users_to_group: {
        title: translation.dialogs.assign_users_to_group.title,
        selects: {
          groups: translation.dialogs.assign_users_to_group.selects.groups,
        },
        buttons: {
          cancel: translation.dialogs.assign_users_to_group.buttons.cancel,
          submit: translation.dialogs.assign_users_to_group.buttons.submit,
        },
      },
      delete_campus: {
        title: translation.dialogs.delete_campus.title,
        text: translation.dialogs.delete_campus.text,
        buttons: {
          cancel: translation.dialogs.delete_campus.buttons.cancel,
          submit: translation.dialogs.delete_campus.buttons.submit,
        },
      },
      edit_campus: {
        title: translation.dialogs.edit_campus.title,
        inputs: {
          name: translation.dialogs.edit_campus.inputs.name,
          date_start: translation.dialogs.edit_campus.inputs.date_start,
          date_end: translation.dialogs.edit_campus.inputs.date_end,
          target_users: translation.dialogs.edit_campus.inputs.target_users,
        },
        buttons: {
          cancel: translation.dialogs.edit_campus.buttons.cancel,
          save: translation.dialogs.edit_campus.buttons.save,
        },
      },
      add_group: {
        title: translation.dialogs.add_group.title,
        inputs: {
          name: translation.dialogs.add_group.inputs.name,
          date_start: translation.dialogs.add_group.inputs.date_start,
          date_end: translation.dialogs.add_group.inputs.date_end,
          size: translation.dialogs.add_group.inputs.size,
          last_check_in_time:
            translation.dialogs.add_group.inputs.last_check_in_time,
        },
        buttons: {
          cancel: translation.dialogs.add_group.buttons.cancel,
          submit: translation.dialogs.add_group.buttons.submit,
        },
      },
      add_user: {
        title: translation.dialogs.add_user.title,
        inputs: {
          name: translation.dialogs.add_user.inputs.name,
          dob: translation.dialogs.add_user.inputs.dob,
          allergies: translation.dialogs.add_user.inputs.allergies,
          comments: translation.dialogs.add_user.inputs.comments,
          kinship: translation.dialogs.add_user.inputs.kinship,
          email: translation.dialogs.add_user.inputs.email,
          phone: translation.dialogs.add_user.inputs.phone,
        },
        texts: {
          responsable: translation.dialogs.add_user.texts.responsable,
        },
        buttons: {
          add_responsable: translation.dialogs.add_user.buttons.add_responsable,
          cancel: translation.dialogs.add_user.buttons.cancel,
          next: translation.dialogs.add_user.buttons.next,
          back: translation.dialogs.add_user.buttons.back,
          save: translation.dialogs.add_user.buttons.save,
        },
        steps: translation.dialogs.add_user.steps,
      },
      add_staff_trainer: {
        title: translation.dialogs.add_staff_trainer.title,
        steps: {
          default: translation.dialogs.add_staff_trainer.steps.default,
          select: translation.dialogs.add_staff_trainer.steps.select,
          create: translation.dialogs.add_staff_trainer.steps.create,
        },
        selects: {
          groups: translation.dialogs.add_staff_trainer.selects.groups,
          sports: translation.dialogs.add_staff_trainer.selects.sports,
          staff: translation.dialogs.add_staff_trainer.selects.staff,
        },
        buttons: {
          select_staff:
            translation.dialogs.add_staff_trainer.buttons.select_staff,
          create_staff:
            translation.dialogs.add_staff_trainer.buttons.create_staff,
          back: translation.dialogs.add_staff_trainer.buttons.back,
          next: translation.dialogs.add_staff_trainer.buttons.next,
          save: translation.dialogs.add_staff_trainer.buttons.save,
          cancel: translation.dialogs.add_staff_trainer.buttons.cancel,
          empty_options:
            translation.dialogs.add_staff_trainer.buttons.empty_options,
        },
        inputs: {
          name: translation.dialogs.add_staff_trainer.inputs.name,
          email: translation.dialogs.add_staff_trainer.inputs.email,
          phone: translation.dialogs.add_staff_trainer.inputs.phone,
          identification_number:
            translation.dialogs.add_staff_trainer.inputs.identification_number,
          ssn: translation.dialogs.add_staff_trainer.inputs.ssn,
          bank_account_number:
            translation.dialogs.add_staff_trainer.inputs.bank_account_number,
        },
        texts: {
          optional: translation.dialogs.add_staff_trainer.texts.optional,
          empty_options:
            translation.dialogs.add_staff_trainer.texts.empty_options,
          sports: translation.dialogs.add_staff_trainer.texts.sports,
        },
      },
      add_staff_coordinator: {
        title: translation.dialogs.add_staff_coordinator.title,
        steps: {
          default: translation.dialogs.add_staff_coordinator.steps.default,
          group: translation.dialogs.add_staff_coordinator.steps.group,
          campus: translation.dialogs.add_staff_coordinator.steps.campus,
          select_group:
            translation.dialogs.add_staff_coordinator.steps.select_group,
          create_group:
            translation.dialogs.add_staff_coordinator.steps.create_group,
          select_campus:
            translation.dialogs.add_staff_coordinator.steps.select_campus,
          create_campus:
            translation.dialogs.add_staff_coordinator.steps.create_campus,
        },
        selects: {
          groups: translation.dialogs.add_staff_coordinator.selects.groups,
          sports: translation.dialogs.add_staff_coordinator.selects.sports,
          staff: translation.dialogs.add_staff_coordinator.selects.staff,
        },
        buttons: {
          select_staff:
            translation.dialogs.add_staff_coordinator.buttons.select_staff,
          create_staff:
            translation.dialogs.add_staff_coordinator.buttons.create_staff,
          assign_to_campus:
            translation.dialogs.add_staff_coordinator.buttons.assign_to_campus,
          assign_to_group:
            translation.dialogs.add_staff_coordinator.buttons.assign_to_group,
          back: translation.dialogs.add_staff_coordinator.buttons.back,
          next: translation.dialogs.add_staff_coordinator.buttons.next,
          submit: translation.dialogs.add_staff_coordinator.buttons.submit,
          cancel: translation.dialogs.add_staff_coordinator.buttons.cancel,
          empty_options:
            translation.dialogs.add_staff_coordinator.buttons.empty_options,
        },
        inputs: {
          name: translation.dialogs.add_staff_coordinator.inputs.name,
          email: translation.dialogs.add_staff_coordinator.inputs.email,
          phone: translation.dialogs.add_staff_coordinator.inputs.phone,
          identification_number:
            translation.dialogs.add_staff_coordinator.inputs
              .identification_number,
          ssn: translation.dialogs.add_staff_coordinator.inputs.ssn,
          bank_account_number:
            translation.dialogs.add_staff_coordinator.inputs
              .bank_account_number,
        },
        texts: {
          optional: translation.dialogs.add_staff_coordinator.texts.optional,
          empty_options:
            translation.dialogs.add_staff_coordinator.texts.empty_options,
          sports: translation.dialogs.add_staff_coordinator.texts.sports,
        },
      },
      import_users: {
        title: translation.dialogs.import_users.title,
        buttons: {
          download_template:
            translation.dialogs.import_users.buttons.download_template,
          select: translation.dialogs.import_users.buttons.select,
          cancel: translation.dialogs.import_users.buttons.cancel,
          import: translation.dialogs.import_users.buttons.import,
        },
        titles: {
          loading: translation.dialogs.import_users.titles.loading,
          error: translation.dialogs.import_users.titles.error,
        },
      },
    },
    datatables: {
      import_users: {
        columns: [
          {
            name: "name",
            label: translation.datatables.import_users.columns.name,
          },
          {
            name: "dob",
            label: translation.datatables.import_users.columns.dob,
            options: {
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(value).format("DD/MM/YYYY"),
            },
          },
          {
            name: "allergies",
            label: translation.datatables.import_users.columns.allergies,
          },
          {
            name: "comments",
            label: translation.datatables.import_users.columns.comments,
          },
          {
            name: "parents_0_name",
            label: translation.datatables.import_users.columns.parents_0_name,
          },
          {
            name: "parents_0_email",
            label: translation.datatables.import_users.columns.parents_0_email,
          },
          {
            name: "parents_0_phone",
            label: translation.datatables.import_users.columns.parents_0_phone,
          },
          {
            name: "parents_0_kinship",
            label:
              translation.datatables.import_users.columns.parents_0_kinship,
          },
          {
            name: "parents_1_name",
            label: translation.datatables.import_users.columns.parents_1_name,
          },
          {
            name: "parents_1_email",
            label: translation.datatables.import_users.columns.parents_1_email,
          },
          {
            name: "parents_1_phone",
            label: translation.datatables.import_users.columns.parents_1_phone,
          },
          {
            name: "parents_1_kinship",
            label:
              translation.datatables.import_users.columns.parents_1_kinship,
          },
        ],
      },
    },
    groups: {
      datatables: {
        groups: {
          columns: [
            {
              name: "_id",
              label: translation.groups.datatables.groups.columns._id,
              options: {
                display: false,
                filter: false,
              },
            },
            {
              name: "name",
              label: translation.groups.datatables.groups.columns.name,
            },
            {
              name: "trainers",
              label: translation.groups.datatables.groups.columns.trainers,
              options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                  value.map((trainer) => trainer.name).join(", "),
              },
            },
            {
              name: "coordinators",
              label: translation.groups.datatables.groups.columns.coordinators,
              options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                  value.map((coordinator) => coordinator.name).join(", "),
              },
            },
            {
              name: "users_size",
              label: translation.groups.datatables.groups.columns.users_size,
            },
          ],
          options: {
            selectableRows: false,
            download: false,
            print: false,
          },
        },
      },
    },
    users: {
      datatables: {
        users: {
          columns: [
            {
              name: "key",
              label: translation.users.datatables.users.columns.key,
              options: {
                display: false,
                filter: false,
              },
            },
            {
              name: "name",
              label: translation.users.datatables.users.columns.name,
            },
            {
              name: "emergency_contact",
              label:
                translation.users.datatables.users.columns.emergency_contact,
            },
            {
              name: "coordinators",
              label: translation.users.datatables.users.columns.coordinators,
              options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                  tableMeta.rowData[3].map((data) => data.name).join(", "),
              },
            },
            {
              name: "groups",
              label: translation.users.datatables.users.columns.groups,
              options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                  tableMeta.rowData[4].map((data) => data.name).join(", "),
              },
            },
          ],
          toolbar: {
            assign_users_group: [
              {
                label:
                  translation.users.datatables.users.toolbar.assign_users_group,
                action: "assign_users_to_group",
                icon: <GroupAddIcon />,
              },
            ],
          },
        },
      },
      drawers: {
        user: {
          name: translation.users.drawers.user.name,
          allergies: translation.users.drawers.user.allergies,
          comments: translation.users.drawers.user.comments,
          parents: translation.users.drawers.user.parents,
          extraResponsibles: translation.users.drawers.user.extraResponsibles,
          groups: translation.users.drawers.user.groups,
          inputs: {
            name: translation.users.drawers.user.inputs.name,
            allergies: translation.users.drawers.user.inputs.allergies,
            comments: translation.users.drawers.user.inputs.comments,
            phone: translation.users.drawers.user.inputs.phone,
            email: translation.users.drawers.user.inputs.email,
          },
          selects: {
            groups: translation.users.drawers.user.selects.groups,
          },
          buttons: {
            cancel: translation.users.drawers.user.buttons.cancel,
            edit: translation.users.drawers.user.buttons.edit,
            delete: translation.users.drawers.user.buttons.delete,
          },
          texts: {
            delete_user: {
              title: translation.users.drawers.user.texts.delete_user.title,
              text: translation.users.drawers.user.texts.delete_user.text,
            },
          },
        },
      },
    },
    venues: {
      scheduler: {
        dayCellWidth: 230,
        customCellWidth: 80,
        // minuteStep: 10,
        eventItemHeight: 62,
        eventItemLineHeight: 64,
        dayMaxEvents: 10,
        weekMaxEvents: 100,
        monthMaxEvents: 100,
        yearMaxEvents: 100,
        resourceGroupName: translation.venues.scheduler.resourceGroupName,
        resourceVenueName: translation.venues.scheduler.resourceVenueName,
        resourceName: "",
        taskName: translation.venues.scheduler.taskName,
        agendaViewHeader: translation.venues.scheduler.agendaViewHeader,
        addMorePopoverHeaderFormat: "YYYY/MM dddd",
        eventItemPopoverDateFormat: "D MMM",
        nonAgendaDayCellHeaderFormat: "HH:mm",
        nonAgendaOtherCellHeaderFormat: "ddd|M/D",
        views: [
          {
            viewName: translation.venues.scheduler.views.day,
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: translation.venues.scheduler.views.week,
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: translation.venues.scheduler.views.month,
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
        dialog_translation: {
          title: translation.venues.scheduler.dialog_translation.title,
          inputs: {
            name: translation.venues.scheduler.dialog_translation.inputs.name,
          },
          buttons: {
            submit:
              translation.venues.scheduler.dialog_translation.buttons.submit,
            cancel:
              translation.venues.scheduler.dialog_translation.buttons.cancel,
          },
        },
      },
      dialogs: {
        edit_venue: {
          title: translation.venues.dialogs.edit_venue.title,
          inputs: {
            name: translation.venues.dialogs.edit_venue.inputs.name,
          },
          buttons: {
            submit: translation.venues.dialogs.edit_venue.buttons.submit,
            cancel: translation.venues.dialogs.edit_venue.buttons.cancel,
          },
        },
        delete_venue: {
          title: translation.venues.dialogs.delete_venue.title,
          text: translation.venues.dialogs.delete_venue.text,
          buttons: {
            submit: translation.venues.dialogs.delete_venue.buttons.submit,
            cancel: translation.venues.dialogs.delete_venue.buttons.cancel,
          },
        },
      },
      buttons: {
        add_resource: translation.venues.buttons.add_resource,
        per_venues: translation.venues.buttons.per_venues,
        per_groups: translation.venues.buttons.per_groups,
      },
      datatables: {
        venues: {
          columns: [
            {
              name: "id",
              label: translation.venues.datatables.venues.columns.id,
            },
            {
              name: "name",
              label: translation.venues.datatables.venues.columns.name,
            },
          ],
        },
      },
    },
    staff: {
      datatables: {
        staff: {
          columns: [
            {
              name: "id",
              label: translation.staff.datatables.staff.columns.id,
              options: {
                display: false,
                filter: false,
              },
            },
            {
              name: "name",
              label: translation.staff.datatables.staff.columns.name,
            },
            {
              name: "role",
              label: translation.staff.datatables.staff.columns.role,
            },
            {
              name: "groups",
              label: translation.staff.datatables.staff.columns.groups,
              options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                  value.map((data) => data.name).join(", "),
              },
            },
          ],
          options: {
            selectableRows: false,
            download: false,
            print: false,
          },
        },
      },
      drawers: {
        staff: {
          name: translation.staff.drawers.staff.name,
          roles: {
            coordinator: translation.staff.drawers.staff.roles.coordinator,
            trainer: translation.staff.drawers.staff.roles.trainer,
          },
          personal_info: translation.staff.drawers.staff.personal_info,
          comments: translation.staff.drawers.staff.comments,
          sports: translation.staff.drawers.staff.sports,
          certificates: translation.staff.drawers.staff.certificates,
          groups: translation.staff.drawers.staff.groups,
          inputs: {
            name: translation.staff.drawers.staff.inputs.name,
            phone: translation.staff.drawers.staff.inputs.phone,
            email: translation.staff.drawers.staff.inputs.email,
          },
          selects: {
            groups: translation.staff.drawers.staff.selects.groups,
            role: translation.staff.drawers.staff.selects.role,
          },
          buttons: {
            cancel: translation.staff.drawers.staff.buttons.cancel,
            edit: translation.staff.drawers.staff.buttons.edit,
            delete: translation.staff.drawers.staff.buttons.delete,
          },
          texts: {
            delete_staff: {
              title: translation.staff.drawers.staff.texts.delete_staff.title,
              text: translation.staff.drawers.staff.texts.delete_staff.text,
            },
          },
        },
      },
    },
    satisfaction: {
      datatables: {
        satisfactions: {
          columns: [
            {
              name: "_id",
              label:
                translation.satisfaction.datatables.satisfactions.columns.id,
              options: {
                display: false,
                filter: false,
              },
            },
            {
              name: "parent",
              label:
                translation.satisfaction.datatables.satisfactions.columns
                  .parent,
            },
            {
              name: "recommends.repeat",
              label:
                translation.satisfaction.datatables.satisfactions.columns
                  .repeat,
              options: {
                customBodyRender: (value, tableMeta, rowData) =>
                  value ? "Sí" : "No",
              },
            },
            {
              name: "recommends.sportmadness",
              label:
                translation.satisfaction.datatables.satisfactions.columns
                  .sportmadness,
            },
            {
              name: "recommends.service",
              label:
                translation.satisfaction.datatables.satisfactions.columns
                  .service,
            },
          ],
        },
      },
      texts: {
        text_1: translation.satisfaction.texts.text_1,
        text_2_0: translation.satisfaction.texts.text_2_0,
        text_2_1: translation.satisfaction.texts.text_2_1,
        text_3: translation.satisfaction.texts.text_3,
        text_4: translation.satisfaction.texts.text_4,
        text_5: translation.satisfaction.texts.text_5,
      },
      chips: {
        venues: translation.satisfaction.chips.venues,
        activities: translation.satisfaction.chips.activities,
        trainers: translation.satisfaction.chips.trainers,
        campus: translation.satisfaction.chips.campus,
        sportmadness: translation.satisfaction.chips.sportmadness,
      },
      drawers: {
        satisfaction: {
          title: translation.satisfaction.drawers.satisfaction.title,
          texts: {
            repeat: translation.satisfaction.drawers.satisfaction.texts.repeat,
            recommend:
              translation.satisfaction.drawers.satisfaction.texts.recommend,
            proposal:
              translation.satisfaction.drawers.satisfaction.texts.proposal,
            app: translation.satisfaction.drawers.satisfaction.texts.app,
            highlights:
              translation.satisfaction.drawers.satisfaction.texts.highlights,
            improve:
              translation.satisfaction.drawers.satisfaction.texts.improve,
          },
          options: {
            yes: translation.satisfaction.drawers.satisfaction.options.yes,
            no: translation.satisfaction.drawers.satisfaction.options.no,
          },
          chips: {
            venues: translation.satisfaction.drawers.satisfaction.chips.venues,
            activities:
              translation.satisfaction.drawers.satisfaction.chips.activities,
            trainers:
              translation.satisfaction.drawers.satisfaction.chips.trainers,
            campus: translation.satisfaction.drawers.satisfaction.chips.campus,
            sportmadness:
              translation.satisfaction.drawers.satisfaction.chips.sportmadness,
          },
        },
      },
    },
    tabs: [
      { value: "venues", label: translation.tabs.venues },
      { value: "groups", label: translation.tabs.groups },
      { value: "users", label: translation.tabs.users },
      { value: "staff", label: translation.tabs.staff },
      { value: "satisfaction", label: translation.tabs.satisfaction },
    ],
    popovers: {
      campus: {
        label: translation.popovers.campus.label,
        labels: [
          {
            _id: "add_group",
            perform: "campus:add_group",
            variant: "button",
            label: translation.popovers.campus.labels.add_group,
          },
          {
            _id: "add_user",
            variant: "button",
            perform: "campus:add_user",
            label: translation.popovers.campus.labels.add_user,
          },
          {
            _id: "import_users",
            variant: "button",
            perform: "campus:import_users",
            label: translation.popovers.campus.labels.import_users,
          },
          {
            _id: "add_staff_trainer",
            variant: "button",
            perform: "campus:add_staff_trainer",
            label: translation.popovers.campus.labels.add_staff_trainer,
          },
          {
            _id: "add_staff_coordinator",
            variant: "button",
            perform: "campus:add_staff_coordinator",
            label: translation.popovers.campus.labels.add_staff_coordinator,
          },
        ],
      },
    },
  };
};

export { WebCampusSingleTemplate };
