import axios from "axios";
import { Auth } from "aws-amplify";

class axiosClient {
  constructor(API_SERVICE_URL) {
    this.client = axios.create({
      baseURL: API_SERVICE_URL,
    });
  }

  get = (url) => {
    return Auth.currentSession().then((response) => {
      return this.client.get(url, {
        headers: {
          Authorization: response.getIdToken().getJwtToken(),
          accesstoken: response.getAccessToken().getJwtToken(),
          "Content-Type": "application/json",
          "rocket-auth-token": localStorage.getItem("rocket-chat-auth_token"),
          "rocket-user-id": localStorage.getItem("rocket-chat-user_id"),
          "rocket-username": localStorage.getItem("rocket-chat-username"),
        },
      });
    });
  };

  post = (url, data) => {
    return Auth.currentSession().then((response) => {
      return this.client.post(url, data, {
        headers: {
          Authorization: response.getIdToken().getJwtToken(),
          accesstoken: response.getAccessToken().getJwtToken(),
          "Content-Type": "application/json",
          "rocket-auth-token": localStorage.getItem("rocket-chat-auth_token"),
          "rocket-user-id": localStorage.getItem("rocket-chat-user_id"),
          "rocket-username": localStorage.getItem("rocket-chat-username"),
        },
      });
    });
  };

  put = (url, data) => {
    return Auth.currentSession().then((response) => {
      return this.client.put(url, data, {
        headers: {
          Authorization: response.getIdToken().getJwtToken(),
          accesstoken: response.getAccessToken().getJwtToken(),
          "Content-Type": "application/json",
          "rocket-auth-token": localStorage.getItem("rocket-chat-auth_token"),
          "rocket-user-id": localStorage.getItem("rocket-chat-user_id"),
          "rocket-username": localStorage.getItem("rocket-chat-username"),
        },
      });
    });
  };

  delete = (url) => {
    return Auth.currentSession().then((response) => {
      return this.client.delete(url, {
        headers: {
          Authorization: response.getIdToken().getJwtToken(),
          accesstoken: response.getAccessToken().getJwtToken(),
          "Content-Type": "application/json",
          "rocket-auth-token": localStorage.getItem("rocket-chat-auth_token"),
          "rocket-user-id": localStorage.getItem("rocket-chat-user_id"),
          "rocket-username": localStorage.getItem("rocket-chat-username"),
        },
      });
    });
  };
}

export default axiosClient;
