import React, { Component } from "react";
import {
  FranchiseProjectSingle as ProjectsSingle,
  Paper,
  Alert,
} from "react-library-sm";

import UserContext from "providers/UserProvider";

import ProjectsServices from "services/franchise-projects/projects.services";

import { Redirect } from "react-router-dom";

class ProjectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      invoices: [],
      deletedProject: false,
      tasksCollapse: false,
      alertAPI: false,
      alert: {},
      notificationToOpen: this.props.location
        ? this.props.location.state
          ? this.props.location.state.notification
          : null
        : null,
      projectId: this.props.match.params.id,
      changedProject: false,
    };
    this.props.history.replace({
      pathname: this.props.location.pathname,
      state: {},
    });
    this.projectService = new ProjectsServices();
  }

  static contextType = UserContext;

  componentDidMount() {
    this.downloadProject(this.state.projectId, this.state.notificationToOpen);
  }

  downloadProject = (id, notificationId) => {
    this.projectService
      .getProject(id, this.context.franchise)
      .then((response) => {
        // console.log("response getProject", response);
        const project = {
          ...response.data[0],
          template: {
            phases: response.data[0].template,
            name: response.data[0].infoProject.name,
            infoProject: response.data[0].infoProject,
            invoices: response.data[0].invoices,
            contact: response.data[0].client && response.data[0].client[0],
          },
        };
        // console.log("project", project);
        this.setState({
          project,
          invoices: response.data.invoices,
          projectId: id,
          notificationToOpen: notificationId,
        });
        this.props.history.replace({
          pathname: this.props.location.pathname,
          state: {},
        });
      })
      .catch((err) => {
        // console.log("err download project", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            text: this.context.translation.templates.project.alerts
              .download_project.error.text,
            linkText:
              this.context.translation.templates.project.alerts.download_project
                .error.submit,
            link: "/projects",
          },
        });
      });
  };

  editStatusTask = (status, key, tasksCollapse) => {
    // console.log("cambiar key:" + key + " a " + status);

    const taskToEdit = {
      ...this.state.project.template.phases.find((item) => item.key === key),
    };
    taskToEdit.status = status;

    this.projectService
      .updateTask(
        this.state.project._id,
        taskToEdit.key,
        taskToEdit,
        this.context.franchise
      )
      .then((response) => {
        // console.log("response update task", response);
        this.setState({
          project: {
            ...response.data,
            template: {
              phases: response.data.template,
              name: response.data.infoProject.name,
              infoProject: response.data.infoProject,
              contact: response.data.client && response.data.client[0],
            },
          },
          tasksCollapse,
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.project.alerts.edit_task
              .success.text,
            submit:
              this.context.translation.templates.project.alerts.edit_task
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err edit task status", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.project.alerts.edit_task
              .warning.text,
            submit:
              this.context.translation.templates.project.alerts.edit_task
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  editStatusTaskRecurrency = (
    status,
    _idTaskRecurrency,
    key,
    tasksCollapse
  ) => {
    const taskToEdit = {
      ...this.state.project.template.phases.find((item) => item.key === key),
    };
    if (taskToEdit.repeat) {
      taskToEdit.repeats = taskToEdit.repeats.map((_taskRecurrency) => {
        // console.log("_taskRecurrency.key", _taskRecurrency.key);
        if (_taskRecurrency.key === _idTaskRecurrency) {
          return { ..._taskRecurrency, status };
        } else {
          return { ..._taskRecurrency };
        }
      });

      this.projectService
        .updateTask(
          this.state.project._id,
          taskToEdit.key,
          taskToEdit,
          this.context.franchise
        )
        .then((response) => {
          // console.log("response update task", response);
          this.setState({
            project: {
              ...response.data,
              template: {
                phases: response.data.template,
                name: response.data.infoProject.name,
                infoProject: response.data.infoProject,
                contact: response.data.client && response.data.client[0],
              },
            },
            tasksCollapse,
            alertAPI: true,
            alert: {
              open: true,
              title: this.context.translation.alerts.titles.success,
              status: "success",
              text: this.context.translation.templates.project.alerts.edit_task
                .success.text,
              submit:
                this.context.translation.templates.project.alerts.edit_task
                  .success.submit,
              onSubmit: this.handleOnCloseAlert,
            },
          });
        })
        .catch((err) => {
          // console.log("err edit task recurrency", err);
          this.setState({
            alertAPI: true,
            alert: {
              open: true,
              title: this.context.translation.alerts.titles.warning,
              status: "warning",
              text: this.context.translation.templates.project.alerts.edit_task
                .warning.text,
              submit:
                this.context.translation.templates.project.alerts.edit_task
                  .warning.submit,
              onSubmit: this.handleOnCloseAlert,
            },
          });
        });
    } else {
      // console.log("error tarea no recurrente");
    }
  };

  editContentTask = (task) => {
    // console.log("cambiar contenido de task: " + task);
    this.projectService
      .updateTask(
        this.state.project._id,
        task.key,
        task,
        this.context.franchise
      )
      .then((response) => {
        // console.log("response update content task", response);
        this.setState({
          project: {
            ...response.data,
            template: {
              phases: response.data.template,
              name: response.data.infoProject.name,
              infoProject: response.data.infoProject,
              contact: response.data.client && response.data.client[0],
            },
          },
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.project.alerts.edit_task
              .success.text,
            submit:
              this.context.translation.templates.project.alerts.edit_task
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err edit content task", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.project.alerts.edit_task
              .warning.text,
            submit:
              this.context.translation.templates.project.alerts.edit_task
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  createInsideTask = (newTask, nameParent, parent, newKeyParent) => {
    // console.log("newTask", newTask);
    // console.log("nameParent", nameParent);
    // console.log("parent", parent);
    // console.log("newKeyParent", newKeyParent);
    const body = {
      newTask,
      keyPadre: parent,
      namePadre: nameParent,
      keyNew: newKeyParent,
    };
    this.projectService
      .addInsideTask(body, this.props.match.params.id, this.context.franchise)
      .then((response) => {
        // console.log("response createInsideTask", response);
        this.setState({
          project: {
            ...response.data,
            template: {
              phases: response.data.template,
              name: response.data.infoProject.name,
              infoProject: response.data.infoProject,
              contact: response.data.client && response.data.client[0],
            },
          },
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.project.alerts.create_task
              .success.text,
            submit:
              this.context.translation.templates.project.alerts.create_task
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err createInsideTask", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.project.alerts.create_task
              .warning.text,
            submit:
              this.context.translation.templates.project.alerts.create_task
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  createAfterTask = (newTask, parent) => {
    // console.log("newTask", newTask);
    // console.log("parent", parent);

    this.projectService
      .addTask(newTask, this.props.match.params.id, this.context.franchise)
      .then((response) => {
        // console.log("response createAfterTask", response);
        this.setState({
          project: {
            ...response.data,
            template: {
              phases: response.data.template,
              name: response.data.infoProject.name,
              infoProject: response.data.infoProject,
              contact: response.data.client && response.data.client[0],
            },
          },
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.project.alerts.create_task
              .success.text,
            submit:
              this.context.translation.templates.project.alerts.create_task
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err create after task", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.project.alerts.create_task
              .warning.text,
            submit:
              this.context.translation.templates.project.alerts.create_task
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  deleteProject = () => {
    this.projectService
      .deleteProject(this.props.match.params.id, this.context.franchise)
      .then((response) => {
        // console.log("response delete project", response.data);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.project.alerts
              .delete_project.success.text,
            submit:
              this.context.translation.templates.project.alerts.delete_project
                .success.submit,
            onSubmit: this.handleOnCloseDeleteProjectAlert,
            onClose: this.handleOnCloseDeleteProjectAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err data", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.project.alerts
              .delete_project.warning.text,
            submit:
              this.context.translation.templates.project.alerts.delete_project
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
            onClose: this.handleOnCloseAlert,
          },
        });
      });
  };

  deleteTask = (task) => {
    // console.log("task id", task);
    this.projectService
      .deleteTask(this.state.project._id, task.key, this.context.franchise)
      .then((response) => {
        // console.log("response delete task", response.data);
        this.setState({
          project: {
            ...response.data,
            template: {
              phases: response.data.template,
              name: response.data.infoProject.name,
              infoProject: response.data.infoProject,
              contact: response.data.client && response.data.client[0],
            },
          },
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.project.alerts.delete_task
              .success.text,
            submit:
              this.context.translation.templates.project.alerts.delete_task
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        // console.log("err data", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.project.alerts.delete_task
              .warning.text,
            submit:
              this.context.translation.templates.project.alerts.delete_task
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
            onClose: this.handleOnCloseAlert,
          },
        });
      });
  };

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };

  handleOnCloseDeleteProjectAlert = () => {
    this.setState({ alert: {}, alertAPI: false, deletedProject: true });
  };

  reloadNotification = () => {
    this.setState({
      notificationToOpen: this.props.location.state.notification,
    });
    this.props.history.replace({
      pathname: this.props.location.pathname,
      state: {},
    });
  };

  render() {
    if (this.state.projectId !== this.props.match.params.id) {
      this.downloadProject(
        this.props.match.params.id,
        this.props.location.state.notification
      );
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.notification &&
      this.props.location.state.notification !== this.state.notification
    ) {
      this.reloadNotification();
    }
    let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    let content = this.state.deletedProject ? (
      <Redirect to="/projects" />
    ) : (
      <Paper elevation={0} style={{ background: "transparent" }}>
        {alert}
        <ProjectsSingle
          project={this.state.project ? this.state.project.template : null}
          translation={this.context.translation.templates.project}
          createTask={this.createTask}
          editStatusTask={this.editStatusTask}
          editStatusTaskRecurrency={this.editStatusTaskRecurrency}
          editContentTask={this.editContentTask}
          createAfterTask={this.createAfterTask}
          createInsideTask={this.createInsideTask}
          deleteProject={this.deleteProject}
          editTask={this.editContentTask}
          deleteTask={this.deleteTask}
          tasksCollapse={this.state.tasksCollapse}
          notificationToOpen={this.state.notificationToOpen}
          changedProject={this.state.changedProject}
          handleChangedProject={() => {
            this.setState({ changedProject: false });
          }}
        />
      </Paper>
    );
    return content;
  }
}

export default ProjectContainer;
