import React from "react";
import { Chip, numeral } from "react-library-sm";
import moment from "moment";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import { Link } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const OpportunitiesTemplate = (translation) => {
  return {
    alerts: {
      create_opportunity: {
        success: {
          text: translation.alerts.create_opportunity.success.text,
          submit: translation.alerts.create_opportunity.success.submit,
        },
        warning: {
          text: translation.alerts.create_opportunity.warning.text,
          submit: translation.alerts.create_opportunity.warning.submit,
        },
      },
      download_boards: {
        error: {
          text: translation.alerts.download_boards.error.text,
          submit: translation.alerts.download_boards.error.submit,
        },
      },
      download_contacts: {
        error: {
          text: translation.alerts.download_contacts.error.text,
          submit: translation.alerts.download_contacts.error.submit,
        },
      },
      download_opportunity: {
        error: {
          text: translation.alerts.download_opportunity.error.text,
          submit: translation.alerts.download_opportunity.error.submit,
        },
      },
      edit_opportunity: {
        success: {
          text: translation.alerts.edit_opportunity.success.text,
          submit: translation.alerts.edit_opportunity.success.submit,
        },
        warning: {
          text: translation.alerts.edit_opportunity.warning.text,
          submit: translation.alerts.edit_opportunity.warning.submit,
        },
      },
    },
    buttons: {
      add_opportunity: translation.opportunities.list.buttons.add_opportunity,
      view_past_opportunities:
        translation.opportunities.list.buttons.view_past_opportunities,
    },
    dialogs: {
      add_opportunity: {
        titles: {
          add_opportunity:
            translation.opportunities.list.dialogs.add_opportunity.titles
              .add_opportunity,
          edit_opportunity:
            translation.opportunities.list.dialogs.add_opportunity.titles
              .edit_opportunity,
        },
        buttons: {
          add: translation.opportunities.list.dialogs.add_opportunity.buttons
            .add,
          cancel:
            translation.opportunities.list.dialogs.add_opportunity.buttons
              .cancel,
          save: translation.opportunities.list.dialogs.add_opportunity.buttons
            .save,
        },
        inputs: {
          opportunity_name:
            translation.opportunities.list.dialogs.add_opportunity.inputs
              .opportunity_name,
          opportunity_date:
            translation.opportunities.list.dialogs.add_opportunity.inputs
              .opportunity_date,
          opportunity_estimated_value:
            translation.opportunities.list.dialogs.add_opportunity.inputs
              .opportunity_estimated_value,
        },
        selects: {
          currency: {
            placeholder:
              translation.opportunities.list.dialogs.add_opportunity.selects
                .currency.placeholder,
            items: [
              {
                value: "euro",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.eur,
              },
              {
                value: "dolar",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.usd,
              },
              {
                value: "sol",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.sol,
              },
              {
                value: "pound",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .currency.gbp,
              },
            ],
          },
          opportunity_contact: {
            placeholder: "Contacto asociado",
          },
          opportunity_source: {
            placeholder:
              translation.opportunities.list.dialogs.add_opportunity.selects
                .opportunity_source.placeholder,
            items: [
              {
                value: "own",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_source.own,
              },
              {
                value: "product",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_source.product,
              },
            ],
          },
          opportunity_status: {
            placeholder:
              translation.opportunities.list.dialogs.add_opportunity.selects
                .opportunity_status.placeholder,
            items: [
              {
                value: 0,
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_status.lead.label,
                tooltip:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_status.lead.tooltip,
              },
              {
                value: 1,
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_status.proposal.label,
                tooltip:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_status.proposal.tooltip,
              },
              {
                value: 2,
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_status.offer.label,
                tooltip:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_status.offer.tooltip,
              },
              {
                value: 3,
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_status.hibernate.label,
                tooltip:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_status.hibernate.tooltip,
              },
            ],
          },
          opportunity_service_type: {
            placeholder:
              translation.opportunities.list.dialogs.add_opportunity.selects
                .opportunity_service_type.placeholder,
            items: [
              {
                value: "event",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_service_type.event,
              },
              {
                value: "campus",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_service_type.campus,
              },
              {
                value: "academy",
                label:
                  translation.opportunities.list.dialogs.add_opportunity.selects
                    .opportunity_service_type.academy,
              },
            ],
          },
        },
      },
    },
    views: {
      board: {
        pipeline: [
          {
            id: 0,
            title: translation.opportunities.list.views.board.pipeline.lead,
          },
          {
            id: 1,
            title: translation.opportunities.list.views.board.pipeline.proposal,
          },
          {
            id: 2,
            title: translation.opportunities.list.views.board.pipeline.offer,
          },
          {
            id: 3,
            title:
              translation.opportunities.list.views.board.pipeline.hibernate,
          },
        ],
      },
      datatable: {
        title: translation.opportunities.list.views.datatable.title,
        textLabels: translation.opportunities.list.views.datatable.textLabels,
        columns: [
          {
            label: translation.opportunities.list.views.datatable.columns.id,
            name: "_id",
            options: { display: false },
          },
          {
            label: translation.opportunities.list.views.datatable.columns.name,
            name: "name",
          },
          {
            label: translation.opportunities.list.views.datatable.columns.date,
            name: "date",
            options: {
              filter: true,
              filterType: "custom",
              customFilterListRender: (v) => {
                const values = v.map((_v) =>
                  moment(_v).format("DD-MM-YYYY HH:mm")
                );
                if (v[0] && v[1]) {
                  return `${translation.contacts.single.datatables.opportunities.date.from} ${values[0]}, ${translation.contacts.single.datatables.opportunities.date.to} ${values[1]}`;
                } else if (values[0]) {
                  return `${translation.contacts.single.datatables.opportunities.date.from} ${values[0]}`;
                } else if (values[1]) {
                  return `${translation.contacts.single.datatables.opportunities.date.to} ${values[1]}`;
                }
                return false;
              },
              filterOptions: {
                names: [],
                logic(date, filters) {
                  const momFilt = filters.map((filt) =>
                    moment(filt, "DD-MM-YYYY HH:mm")
                  );
                  const momDate = moment(date, "DD-MM-YYYY HH:mm");
                  let show = true;

                  if (filters[0] && filters[1]) {
                    show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                  } else if (filters[0]) {
                    show = momDate.isSameOrAfter(momFilt[0]);
                  } else if (filters[1]) {
                    show = momDate.isSameOrBefore(momFilt[1]);
                  }
                  return !show;
                },
                display: (filterList, onChange, index, column) => {
                  return [
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.opportunities.list.views.datatable.date
                            .from
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][0] || null}
                        onChange={(event) => {
                          filterList[index][0] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                        style={{ marginRight: "5%" }}
                      />
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.opportunities.list.views.datatable.date.to
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][1] || null}
                        onChange={(event) => {
                          filterList[index][1] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                      />
                    </MuiPickersUtilsProvider>,
                  ];
                },
              },
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[2]).format("DD/MM/YYYY"),
            },
          },
          {
            label:
              translation.opportunities.list.views.datatable.columns
                .service_type,
            name: "type",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) =>
                `${translation.opportunities.list.views.datatable.columns.service_type} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.opportunities.list.views.datatable.service_type
                ),
                logic(service_type, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.opportunities.list.views.datatable
                        .service_type.event
                    ) >= 0 &&
                      service_type === "event") ||
                    (filterVal.indexOf(
                      translation.opportunities.list.views.datatable
                        .service_type.academy
                    ) >= 0 &&
                      service_type === "academy") ||
                    (filterVal.indexOf(
                      translation.opportunities.list.views.datatable
                        .service_type.campus
                    ) >= 0 &&
                      service_type === "campus");
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => (
                <Chip
                  label={
                    tableMeta.rowData[3]
                      ? translation.opportunities.list.views.datatable
                          .service_type[tableMeta.rowData[3]]
                      : "Sin tipo"
                  }
                />
              ),
            },
          },
          {
            label:
              translation.opportunities.list.views.datatable.columns.budget,
            name: "budget",
            options: {
              filter: false,
              customBodyRender: (value, tableMeta, updateValue) =>
                numeral(tableMeta.rowData[4]).format("0,0[.]00 $"),
            },
          },
          {
            label:
              translation.opportunities.list.views.datatable.columns.client,
            name: "client.0.name",
          },
          {
            label:
              translation.opportunities.list.views.datatable.columns.status,
            name: "statusSells",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: (v) =>
                `${translation.opportunities.list.views.datatable.columns.status} ${v}`,
              filterOptions: {
                names:
                  translation.opportunities.list.views.datatable.status.map(
                    (lab) => lab.label
                  ),
                logic(status, filterVal) {
                  const show =
                    filterVal.indexOf(
                      translation.opportunities.list.views.datatable.status[
                        status
                      ].label
                    ) >= 0;
                  return !show;
                },
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      tableMeta.rowData[6]
                        ? translation.opportunities.list.views.datatable.status[
                            tableMeta.rowData[6]
                          ].label
                        : translation.opportunities.list.views.datatable
                            .status[0].label
                    }
                    style={
                      tableMeta.rowData[6] === 4
                        ? tableMeta.rowData[6] === 5
                          ? {
                              background: "#E95A58",
                              color: "#616161",
                            }
                          : {
                              background: "#77ffb5",
                              color: "#616161",
                            }
                        : {
                            background: "#ffe082",
                            color: "#616161",
                          }
                    }
                  />
                );
              },
            },
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Link
                    to={"/pipeline/" + tableMeta.rowData[0] + "/view"}
                    style={{ color: "#fff" }}
                  >
                    <RemoveRedEye />
                  </Link>
                );
              },
            },
          },
        ],
      },
    },
  };
};

export { OpportunitiesTemplate };
