import React, { Component } from "react";
import ContactsServices from "services/franchise-crm/contacts.services";
import { FranchiseContacts as Contacts, Grid, Alert } from "react-library-sm";
import UserContext from "providers/UserProvider";

class ContactsContainer extends Component {
  constructor(props) {
    super(props);
    this.service = new ContactsServices();
    this.state = {
      contacts: [],
      alertAPI: false,
      alert: null,
    };
  }

  componentDidMount() {
    /* ---------- LLAMADA API BBDD ---------- */
    this.service
      .getContacts(this.context.franchise)
      .then((response) => {
        // console.log("response get contacts", response);
        const contacts = response.data.map((contact) => {
          return contact;
        });
        this.setState({ contacts });
        // console.log("response get", response);
      })
      .catch((err) => {
        console.error("err get contacts", err.response);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            text: this.context.translation.templates.contacts.alerts
              .download_contacts.error.text,
            linkText:
              this.context.translation.templates.contacts.alerts
                .download_contacts.error.submit,
            link: "/",
          },
        });
      });
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };

  static contextType = UserContext;

  handleCreateContact = (data) => {
    this.service
      .createContact(data, this.context.franchise)
      .then((response) => {
        // console.log('response en create contact list', response);
        let contacts = [...this.state.contacts];
        contacts = contacts.concat(response);
        this.setState({
          contacts,
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.success,
            status: "success",
            text: this.context.translation.templates.contacts.alerts
              .create_contact.success.text,
            submit:
              this.context.translation.templates.contacts.alerts.create_contact
                .success.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => {
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.contacts.alerts
              .create_contact.warning.text,
            submit:
              this.context.translation.templates.contacts.alerts.create_contact
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  handleImportContacts = (data) => {
    data.forEach((dat) => {
      const auxContact = this.unflatten(dat);

      const finalContact = {
        ...auxContact,
        people: [],
        franchise: this.context.franchise,
      };
      this.service
        .addContact(finalContact)
        .then((response) => {
          finalContact._id = response.data._id;
          let personFinalContact = [...auxContact.people];
          const reqPersonFinalContact = Promise.all(
            (personFinalContact = personFinalContact.map((contact) => {
              contact.client = finalContact._id;
              contact.franchise = this.context.franchise;
              return this.service
                .addPersonOfContact(contact)
                .then((responsePerson) => {
                  this.state.contacts.push({
                    ...contact,
                    _id: response.data._id,
                  });
                  finalContact.people.push(responsePerson.data._id);
                })
                .catch((err) => {
                  // console.log("err add person of contact", err.response);
                });
            }))
          );

          reqPersonFinalContact
            .then((x) => {
              return this.service.updateContact(finalContact);
            })
            .then((response) => {
              const contacts = [...this.state.contacts];
              contacts.push(finalContact);
              this.setState({
                contacts,
                alertAPI: true,
                alert: {
                  open: true,
                  title: this.context.translation.alerts.titles.success,
                  status: "success",
                  text: this.context.translation.templates.contacts.alerts
                    .create_contact.success.text,
                  submit:
                    this.context.translation.templates.contacts.alerts
                      .create_contact.success.submit,
                  onSubmit: this.handleOnCloseAlert,
                },
              });
              // console.log(response);
            })
            .catch((err) => {
              // console.log("err update contact", err.response);
              this.setState({
                alertAPI: true,
                alert: {
                  open: true,
                  title: this.context.translation.alerts.titles.warning,
                  status: "warning",
                  text: this.context.translation.templates.contacts.alerts
                    .create_contact.warning.text,
                  submit:
                    this.context.translation.templates.contacts.alerts
                      .create_contact.warning.submit,
                  onSubmit: this.handleOnCloseAlert,
                },
              });
            });
        })
        .catch((err) => {
          // console.log("err add contact", err.response);
          this.setState({
            alertAPI: true,
            alert: {
              open: true,
              title: this.context.translation.alerts.titles.warning,
              status: "warning",
              text: this.context.translation.templates.contacts.alerts
                .create_contact.warning.text,
              submit:
                this.context.translation.templates.contacts.alerts
                  .create_contact.warning.submit,
              onSubmit: this.handleOnCloseAlert,
            },
          });
        });
    });
  };

  unflatten = (data) => {
    const result = {};
    for (const i in data) {
      const keys = i.split("_");
      keys.reduce(function (r, e, j) {
        return (
          r[e] ||
          (r[e] = isNaN(Number(keys[j + 1]))
            ? keys.length - 1 === j
              ? data[i]
              : {}
            : [])
        );
      }, result);
    }
    return result;
  };

  render() {
    let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    let content = (
      <Contacts
        options={{
          textLabels:
            this.context.translation.templates.contacts.datatables.contact_list
              .textLabels,
          selectableRows: "none",
          responsive: "scrollFullHeight",
        }}
        data={this.state.contacts}
        language={this.context.language.value}
        createContact={this.handleCreateContact}
        importContacts={this.handleImportContacts}
        translation={
          this.context.translation.templates
            ? this.context.translation.templates.contacts
            : null
        }
      />
    );
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {alert}
        <Grid item md={12} key={this.state.contacts.length}>
          {content}
        </Grid>
      </Grid>
    );
  }
}

export default ContactsContainer;
