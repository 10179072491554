import Axios from "core/axios";

class CognitoServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_AUTH;
    this.service = new Axios(this.api_url);
  }

  getFranchise = cognitoId => {
    return this.service.get("/users/cognito/" + cognitoId);
  };
}

export default CognitoServices;
