import Axios from "core/axios";

class DashboardServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_PROJECTS;
    this.service = new Axios(this.api_url);
  }

  getDashboard = franchise => {
    return this.service.get("/dashboard?franchise=" + franchise);
  };
}

export default DashboardServices;
