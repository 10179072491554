import React from 'react';
import smLogo from 'assets/img/black-logo.png';

import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	logo: {
		padding: '8px',
		height: '60px',
		boxSizing: 'border-box',
		borderRadius: '5px'
	},
	image: {
		width: '180px',
		'margin-top': '7px'
	}
}));

const logo = (props) => {
	const classes = styles();
	return (
		<div className={classes.logo}>
			<img src={smLogo} alt="LogoSportmadness" className={classes.image} />
		</div>
	);
};

export default logo;
