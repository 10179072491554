import React, { Component } from "react";
import { FranchiseProjectList as ProjectsList, Alert } from "react-library-sm";
import UserContext from "providers/UserProvider";
import ProjectsServices from "services/franchise-projects/projects.services";
import ContactsServices from "services/franchise-crm/contacts.services";
import OpportunitiesServices from "services/franchise-crm/opportunities.services";
import { Paper } from "@material-ui/core";

class ProjectsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      opportunities: [],
      contacts: [],
      templates: [],
      alertAPI: false,
      alert: {},
    };
    this.language = null;
    this.contactService = new ContactsServices();
    this.projectService = new ProjectsServices();
    this.opportunityService = new OpportunitiesServices();
  }

  static contextType = UserContext;

  componentDidMount() {
    // DESCARGA projects
    this.projectService
      .getProjects(this.context.franchise)
      .then((response) => {
        // console.log("response getProjects", response);
        const projects = [...response.data].map((project) => {
          return {
            ...project,
            ...project.infoProject,
            startDate: project.infoProject && project.infoProject.dateStart,
          };
        });
        // console.log("projects filtered", projects);
        this.setState({
          projects,
        });
      })
      .catch((err) => {
        // console.log("error download projects", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            text: this.context.translation.templates.projects.alerts
              .download_projects.error.text,
            linkText:
              this.context.translation.templates.projects.alerts
                .download_projects.error.submit,
            link: "/",
          },
        });
      });

    // DESCARGA CONTACTOS
    this.contactService
      .getContacts(this.context.franchise)
      .then((response) => {
        // console.log("response getContacts", response);
        let contacts = [...response.data];
        contacts = contacts
          .filter((contact) => contact.type === "client")
          .map((contact) => {
            return {
              ...contact,
              value: contact._id,
              label: contact.name,
            };
          });

        this.setState({ contacts });
      })
      .catch((err) => {
        // console.log("error download contacts", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            text: this.context.translation.templates.projects.alerts
              .download_contacts.error.text,
            linkText:
              this.context.translation.templates.projects.alerts
                .download_contacts.error.submit,
            link: "/",
          },
        });
      });

    // DESCARGA templates
    this.projectService
      .getTemplates(this.context.languageFranchise)
      .then((response) => {
        // console.log("response templates", response.data);
        let templates = [...response.data];
        templates = templates.map((template) => {
          return {
            type: template.type,
            label: template.languages.name,
            value: template._id,
          };
        });
        // console.log("final templates", templates);
        this.setState({ templates });
      })
      .catch((err) => {
        // console.log("error download templates", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            text: this.context.translation.templates.projects.alerts
              .download_templates.error.text,
            linkText:
              this.context.translation.templates.projects.alerts
                .download_templates.error.submit,
            link: "/",
          },
        });
      });

    // DESCARGA opportunities
    this.opportunityService
      .getOpportunities(this.context.franchise)
      .then((response) => {
        // console.log("response getOpportunities", response);
        const opportunities = [...response.data]
          .filter((opportunity) => opportunity.statusSells === 4)
          .map((opportunity) => {
            return {
              ...opportunity,
              value: opportunity._id,
              label: opportunity.name,
            };
          });
        this.setState({ opportunities });
      })
      .catch((err) => {
        // console.log("error download opportunities", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.error,
            status: "error",
            text: this.context.translation.templates.projects.alerts
              .download_opportunities.error.text,
            linkText:
              this.context.translation.templates.projects.alerts
                .download_opportunities.error.submit,
            link: "/",
          },
        });
      });
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };

  createProject = (data) => {
    // console.log("project createProject", data);

    this.projectService
      .addProject(
        { ...data, contacts: [data.contact] },
        data.template,
        this.context.languageFranchise,
        this.context.franchise
      )
      .then((response) => {
        // console.log("response add project", response);
        // const _projects = [...this.state.projects];
        // _projects.push({
        //   ...response.data,
        //   ...response.data.infoProject,
        //   startDate: response.data.infoProject.dateStart
        // });
        // this.setState({
        //   projects: _projects,
        //   alertAPI: true,
        //   alert: {
        //     open: true,
        //     title: this.context.translation.alerts.titles.success,
        //     status: "success",
        //     text: this.context.translation.templates.projects.alerts
        //       .create_project.success.text,
        //     submit: this.context.translation.templates.projects.alerts
        //       .create_project.success.submit,
        //     onSubmit: this.handleOnCloseAlert
        //   }
        // });
      })
      .catch((err) => {
        // console.log("error createProject", err);
        this.setState({
          alertAPI: true,
          alert: {
            open: true,
            title: this.context.translation.alerts.titles.warning,
            status: "warning",
            text: this.context.translation.templates.projects.alerts
              .create_project.warning.text,
            submit:
              this.context.translation.templates.projects.alerts.create_project
                .warning.submit,
            onSubmit: this.handleOnCloseAlert,
          },
        });
      });
  };

  render() {
    if (this.language === null) {
      this.language = this.context.language.value;
    }
    if (this.language !== this.context.language.value) {
      this.language = this.context.language.value;
    }
    let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    return (
      <Paper elevation={0} style={{ background: "transparent" }}>
        {alert}
        <ProjectsList
          projects={this.state.projects}
          contacts={this.state.contacts}
          opportunities={this.state.opportunities}
          createProject={this.createProject}
          templates={this.state.templates}
          translation={
            this.context.translation.templates
              ? this.context.translation.templates.projects
              : null
          }
          currency={this.context.currency}
        />
      </Paper>
    );
  }
}

export default ProjectsContainer;
