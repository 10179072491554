import { createTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const theme = createTheme({
  themeName: "Sportmadness Theme",
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    type: "dark",
    primary: {
      main: "#77ffb5",
      contrastText: "#0D1B2A",
    },
    danger: red[500],
    pending: {
      main: "#ffe082",
    },
    secondary: {
      //	main: '#0D1B2A',
      main: "#E95A58",
      contrastText: "#fff",
    },
    status: {
      danger: "#E95A58",
    },
    error: {
      main: "#E95A58",
    },
    canvasColor: "#616161",
    disabledColor: "#33ff99",
    accent1Color: "#465775",
  },
  overrides: {
    MUIDataTableToolbar: {
      root: {
        backgroundColor: " #b7b6b682",
      },
    },
  },
});

export default theme;
