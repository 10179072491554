import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";

const WebStaffListTemplate = (translation) => {
  return {
    title: translation.title,
    buttons: {
      download_shift_report: translation.buttons.download_shift_report,
    },
    datatables: {
      staff: {
        columns: [
          {
            label: translation.datatables.staff.columns.id,
            name: "_id",
            options: {
              display: false,
              filter: false,
            },
          },
          {
            name: "name",
            label: translation.datatables.staff.columns.name,
          },
          {
            name: "email",
            label: translation.datatables.staff.columns.email,
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: false,
              customBodyRender: (value, tableMeta, updateValue) => (
                <Link
                  to={`/staff/${tableMeta.rowData[0]}`}
                  style={{ color: "#fafafa" }}
                >
                  <VisibilityIcon />
                </Link>
              ),
            },
          },
        ],
        options: {
          selectableRows: false,
          download: false,
          print: false,
        },
      },
    },
  };
};

export { WebStaffListTemplate };
