import Axios from "core/axios";

class AcademyServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_ACADEMY;
    this.service = new Axios(this.api_url);
  }

  getCourses = language => this.service.get("/courses?lang=" + language);

  getCourse = (id, language) =>
    this.service.get(`/courses/${id}?lang=${language}`);
}

export default AcademyServices;
