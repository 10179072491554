const googleTranslate = require("google-translate")(
  "AIzaSyBcd04enatkHi0ModNWlt0hSzMOgv_dzTk"
);

const Airtable = require("airtable");
const base = new Airtable({
  apiKey:
    "patujgtVH6a7ORHa0.c308ae691dd88d49371cdb418645e963e53b3da9ec22f2b851e58597b2da9c9c",
}).base("appQ3abDRfi9wpJnw");

const translate = (string, source, targets) =>
  targets.map((target) => {
    return new Promise((resolve, reject) => {
      googleTranslate.translate(string, source, target, (err, translation) => {
        console.log(translation);
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve({ [target]: translation.translatedText });
        }
      });
    });
  });

const getRecords = (table, query, view) => {
  return new Promise((resolve, reject) => {
    base(table)
      .select({
        filterByFormula: query || "",
        view: view || "All",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            // console.log(record.get('key'));
          });
          resolve(records);
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            reject(err);
            console.error(err);
            return;
          }
        }
      );
  });
};

const reduceObj = (arrObj) =>
  arrObj.reduce((result, currentObject) => {
    for (let key in currentObject) {
      if (currentObject.hasOwnProperty(key)) {
        result[key] = currentObject[key];
      }
    }
    return result;
  }, {});

const translateRecords = (languages, table) =>
  getRecords(table, "({translated} = 0)", "Pending Translation").then(
    (data) => {
      //obtenemos solo los registros que no han sido traducidos, columna "translated=0"
      const updatedData = [];
      console.log(data.length);
      data.forEach((item) => {
        //cada uno de los registros que necesitamos traducir
        Promise.all(translate(item.fields.es, "es", languages)).then((tr) => {
          const translations = reduceObj(tr); //transformamos  el objeto de traducciones para pode
          const fields = {};
          for (let [key, value] of Object.entries(translations)) {
            fields[key] = value;
          }
          updatedData.push({ id: item.id, fields });
        });
      });

      let updatedRecords = 0;
      let slicedData = [];
      //actualizamos los registros de airtable por intervalos para no sobrepasar el límite de la API de Airtable
      const interval = setInterval(() => {
        slicedData = updatedData.slice(updatedRecords, updatedRecords + 10);
        if (updatedRecords < data.length) {
          base(table).update(slicedData, (err, records) => {
            if (err) {
              console.error(err);
              return;
            }
            records.forEach(function (record) {
              console.log(record.get("key"));
            });
          });
          updatedRecords += 10;
        } else clearInterval(interval);
      }, 1500);
    }
  );

//idiomas a los que queremos traducir
const languages = ["en", "eu", "ca", "pt", "it", "fr", "de"];
// const languages = ["fr"];

translateRecords(languages, "translation");
