import { templates } from "./templates";

class Language {
  constructor(country) {
    this.translation =
      require(`assets/languages/i18n/translations/${localStorage.getItem(
        "sportmadness-manager-lang"
      )}`).translation;
    this.locale = country;

    // console.log(localStorage.getItem('sportmadness-manager-lang'));
    // console.log("me inicializo");
    // console.log("coutnry", country);
    // console.log("this.locale language", this.locale);
  }

  getLanguage = () => {
    // console.log("tr locale", this.translation.locale);
    return {
      locale: this.translation.locale,
      sideDrawer: [
        {
          primary: {
            name: this.translation.sideDrawer.contacts,
            url: "/contacts",
          },
        },
        {
          primary: {
            name: this.translation.sideDrawer.billing,
            url: "/invoices",
          },
        },
        {
          primary: {
            name: this.translation.sideDrawer.settings,
            url: "/billing-settings",
          },
        },
      ],
      templates: templates(this.translation),
      alerts: {
        titles: {
          success: "Success",
          warning: "Warning",
          error: "Error",
        },
      },
    };
  };
}

export { Language };
