import React from "react";
import { Chip } from "react-library-sm";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import { Link } from "react-router-dom";
import moment from "moment";
import { options } from "assets/mui-datatables/options-default";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const DashboardProjectTemplate = translation => {
  return {
    cards: {
      open_projects: {
        title: translation.dashboards.projects.cards.open_projects.title
      },
      total_budget: {
        title: translation.dashboards.projects.cards.total_budget.title
      }
    },
    datatables: {
      tasks: {
        title: translation.dashboards.projects.datatables.tasks.title,
        textLabels: translation.dashboards.projects.datatables.tasks.textLabels,
        columns: [
          {
            label: translation.dashboards.projects.datatables.tasks.columns.id,
            name: "_id",
            options: { display: false, filter: false }
          },
          {
            label:
              translation.dashboards.projects.datatables.tasks.columns
                .projectId,
            name: "projectId",
            options: { display: false, filter: false }
          },
          {
            label:
              translation.dashboards.projects.datatables.tasks.columns.name,
            name: "name",
            options: { filter: false }
          },
          {
            label:
              translation.dashboards.projects.datatables.tasks.columns.date,
            name: "date",
            options: {
              filter: true,
              filterType: "custom",
              customFilterListRender: v => {
                const values = v.map(_v =>
                  moment(_v).format("DD-MM-YYYY HH:mm")
                );
                if (v[0] && v[1]) {
                  return `${translation.dashboards.projects.datatables.tasks.date.from} ${values[0]}, ${translation.dashboards.projects.datatables.tasks.date.to} ${values[1]}`;
                } else if (values[0]) {
                  return `${translation.dashboards.projects.datatables.tasks.date.from} ${values[0]}`;
                } else if (values[1]) {
                  return `${translation.dashboards.projects.datatables.tasks.date.to} ${values[1]}`;
                }
                return false;
              },
              filterOptions: {
                names: [],
                logic(date, filters) {
                  const momFilt = filters.map(filt =>
                    moment(filt, "DD-MM-YYYY HH:mm")
                  );
                  const momDate = moment(date, "DD-MM-YYYY HH:mm");
                  let show = true;

                  if (filters[0] && filters[1]) {
                    show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                  } else if (filters[0]) {
                    show = momDate.isSameOrAfter(momFilt[0]);
                  } else if (filters[1]) {
                    show = momDate.isSameOrBefore(momFilt[1]);
                  }
                  return !show;
                },
                display: (filterList, onChange, index, column) => {
                  // return <RangeDatePicker
                  //   time
                  //   translation={translation.projects.single.datatables.tasks.date ? translation.projects.single.datatables.tasks.date: null}
                  //   values={filterList}
                  //   onChange={onChange}
                  //   index={index}
                  //   column={column}
                  // />
                  return [
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.dashboards.projects.datatables.tasks.date
                            .from
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][0] || null}
                        onChange={event => {
                          filterList[index][0] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                        style={{ marginRight: "5%" }}
                      />
                      <KeyboardDateTimePicker
                        ampm={false}
                        clearable
                        label={
                          translation.dashboards.projects.datatables.tasks.date
                            .to
                        }
                        format="DD/MM/YYY HH:mm"
                        value={filterList[index][1] || null}
                        onChange={event => {
                          filterList[index][1] = moment(
                            event,
                            "DD/MM/YYY HH:mm"
                          );
                          onChange(filterList[index], index, column);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ];
                }
              },
              customBodyRender: (value, tableMeta, updateValue) =>
                moment(tableMeta.rowData[3]).format("DD/MM/YYYY HH:mm")
            }
          },
          {
            label:
              translation.dashboards.projects.datatables.tasks.columns
                .projectName,
            name: "projectName",
            options: {
              filter: false
            }
          },
          {
            label:
              translation.dashboards.projects.datatables.tasks.columns.status,
            name: "status",
            options: {
              filter: true,
              filterType: "checkbox",
              customFilterListRender: v =>
                `${translation.dashboards.projects.datatables.tasks.columns.status} ${v}`,
              filterOptions: {
                names: Object.values(
                  translation.dashboards.projects.datatables.tasks.status
                ),
                logic(status, filterVal) {
                  const show =
                    (filterVal.indexOf(
                      translation.dashboards.projects.datatables.tasks.status
                        .pending
                    ) >= 0 &&
                      status === "pending") ||
                    (filterVal.indexOf(
                      translation.dashboards.projects.datatables.tasks.status
                        .doing
                    ) >= 0 &&
                      status === "doing") ||
                    (filterVal.indexOf(
                      translation.dashboards.projects.datatables.tasks.status
                        .done
                    ) >= 0 &&
                      status === "done");
                  return !show;
                }
              },
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Chip
                    label={
                      translation.dashboards.projects.datatables.tasks.status[
                        tableMeta.rowData[5]
                      ]
                    }
                    style={
                      tableMeta.rowData[5] === "done"
                        ? {
                            background: "#77ffb5",
                            color: "#616161"
                          }
                        : tableMeta.rowData[5] === "doing"
                        ? {
                            background: "#ffe082",
                            color: "#616161"
                          }
                        : {
                            background: "#E95A58",
                            color: "#ffffff"
                          }
                    }
                  />
                );
              }
            }
          },
          {
            label: "",
            name: "options",
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              empty: true,
              label: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Link
                    to={`/projects/${tableMeta.rowData[1]}/view`}
                    style={{ color: "#fff" }}
                  >
                    <RemoveRedEye />
                  </Link>
                );
              }
            }
          }
        ],
        options: options
      }
    }
  };
};

export { DashboardProjectTemplate };
