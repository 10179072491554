import React, { Component } from "react";
import { WebStaffSingle } from "react-library-sm";
import moment from "moment";
import { Grid } from "@material-ui/core";
import UserContext from "providers/UserProvider";
import StaffServices from "services/franchise-staff/staff.services";
import { s3GetObject } from "core/S3";

class ManagerWebStaffSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
    this.staffService = new StaffServices();
  }

  static contextType = UserContext;

  componentDidMount() {
    this.staffService
      .getStaffSingle(null, this.props.match.params.staffId)
      .then((response) => {
        const responseStaffData = response.data;
        this.setState({
          data: {
            staff: this.createStaffView(responseStaffData),
            originalShifts: response.data.shifts,
            shifts: this.createShiftView(response.data.shifts),
            handleEditStaff: this.handleEditStaff,
            handleViewCertificate: this.handleViewCertificate,
          },
        });
        // this.staffService.getShiftList().then((responseShifts) => {
        //   const responseShiftsData = responseShifts.data.filter(
        //     (shift) => shift.staffId === this.props.match.params.staffId
        //   );
        //   this.setState({
        //     data: {
        //       staff: this.createStaffView(responseStaffData),
        //       originalShifts: responseShiftsData,
        //       shifts: this.createShiftView(responseShiftsData),
        //     },
        //   });
        // });
      });
  }

  handleEditStaff = (data) => {
    this.staffService
      .putSingleStaff(
        this.context.franchise,
        this.props.match.params.staffId,
        data
      )
      .then((response) => {
        // console.log(response);
      });
    // console.log("data edit staff", data);
  };

  createStaffView = (data) => {
    return { ...data };
  };

  handleViewCertificate = (key) => {
    return s3GetObject(key).then((response) => {
      // console.log("id ", response);
      return response;
    });
  };

  createShiftView = (data) => {
    return data
      .map((dataRecount) => ({
        _id: dataRecount._id,
        day: moment(dataRecount.start.date).format("DD/MM/YYYY"),
        comments: dataRecount.comments,
        hourStart: dataRecount.start.date
          ? moment(dataRecount.start.date).format("HH:mm")
          : "",
        hourEnd: dataRecount.end.date
          ? moment(dataRecount.end.date).format("HH:mm")
          : "",
        duration:
          dataRecount.start.date && dataRecount.end.date
            ? moment
                .duration(
                  moment(dataRecount.end.date).diff(
                    moment(dataRecount.start.date)
                  )
                )
                .asHours()
                .toFixed(2) + " horas"
            : "",
        checkIn: {
          date: moment(dataRecount.start.date).format("DD/MM/YYYY HH:mm"),
          coords: {
            latitude: dataRecount.start.location.coordinates[0],
            longitude: dataRecount.start.location.coordinates[1],
          },
        },
        checkOut: {
          date: moment(dataRecount.end.date).format("DD/MM/YYYY HH:mm"),
          coords: {
            latitude: dataRecount.end.location.coordinates[0],
            longitude: dataRecount.end.location.coordinates[1],
          },
        },
        handleSubmit: this.handleSubmitComments,
      }))
      .sort((a, b) => moment(a.day).diff(moment(b.day)));
  };

  handleSubmitComments = (id, values) => {
    const data = this.state.data;
    const shiftToSend = data.originalShifts.find((shift) => shift._id === id);
    // console.log(shiftToSend, id);
    shiftToSend.comments = { ...shiftToSend.comments };
    shiftToSend.comments.franchise = values.comments;

    this.staffService.putShiftComment(shiftToSend._id, {
      comments: shiftToSend.comments,
      start: shiftToSend.start,
      end: shiftToSend.end,
    });
    data.shifts = data.shifts.map((shift) => {
      if (shift._id === id) {
        return {
          ...shift,
          comments: { ...shift.comments, franchise: values.comments },
        };
      } else {
        return shift;
      }
    });
    this.setState({ data: data });
  };

  render() {
    return (
      <Grid container>
        <WebStaffSingle
          role="manager"
          mapboxApiAccessToken="pk.eyJ1Ijoic3BvcnRtYWRuZXNzIiwiYSI6ImNrOXV2cXZ0NjA1bXgzZmw2N2F0Y2VzemQifQ.oJZLfHp4A9AAaaKiozncxg"
          data={this.state.data}
          auth_token={localStorage.getItem("rocket-chat-auth_token")}
          chatServer={process.env.REACT_APP_ROCKET_CHAT_SERVER}
          translation={this.context.translation.templates.web.staff.single}
        />
      </Grid>
    );
  }
}
export { ManagerWebStaffSingle };
