import Axios from "core/axiosCampus";
import axios from "axios";
class RocketChatServices {
  constructor() {
    this.service = new Axios(process.env.REACT_APP_API_CHAT);
  }

  login = (user) => {
    // console.log("login rocket service", user);
    return this.service.post("/rocket_chat_login", user);
  };

  setNewPwd = async (values) => {
    // return this.service.post("/rocket_chat_login", user);
    try {
      const login = await axios.post(
        `${process.env.REACT_APP_ROCKET_CHAT_SERVER}/api/v1/login`,
        {
          username: process.env.REACT_APP_ROCKET_CHAT_ADMIN,
          password: process.env.REACT_APP_ROCKET_CHAT_ADMIN_PWD,
        }
      );

      // console.log("login setnew pd service", login.data);
      if (login.data.status === "success") {
        const user = await axios.get(
          `${
            process.env.REACT_APP_ROCKET_CHAT_SERVER
          }/api/v1/users.info?username=${values.email.replace("@", ".")}`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Auth-Token": login.data.data.authToken,
              "X-User-Id": login.data.data.userId,
            },
          }
        );
        // console.log("user", user.data);
        if (user.data.success) {
          return axios.post(
            `${process.env.REACT_APP_ROCKET_CHAT_SERVER}/api/v1/users.update`,
            {
              userId: user.data.user._id,
              data: { password: values.password },
            },
            {
              headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": login.data.data.authToken,
                "X-User-Id": login.data.data.userId,
              },
            }
          );
        }
      }
    } catch (e) {
      // console.log("err setNewPwd RocketService", e);
    }
  };

  getChannels = ({ serviceId, onlyDirectMessages }) => {
    return this.service.get(
      `/channels?service=Campus&serviceId=${serviceId}&onlyDirectMessages=${onlyDirectMessages}`
    );
  };
}

export default RocketChatServices;
