import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { WebStaffList } from "react-library-sm";
import moment from "moment";
import UserContext from "providers/UserProvider";
import StaffServices from "services/franchise-staff/staff.services";

class ManagerWebStaffList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
    this.staffService = new StaffServices();
  }

  static contextType = UserContext;

  componentDidMount() {
    this.staffService.getStaffList().then((response) => {
      this.setState({
        data: {
          handleDownloadShiftReport: this.handleDownloadShiftReport,
          staff: response.data,
        },
      });
    });
  }

  handleDownloadShiftReport = () => {
    return this.staffService.getShiftList().then((response) => {
      return Promise.resolve(
        this.createDataShiftRecount(response.data, this.state.data.staff)
      );
    });
  };

  createDataShiftRecount = (data, staff) => {
    const finalData = data
      .map((dataRecount) => ({
        day: moment(dataRecount.start.date).format("DD/MM/YYYY"),
        hourStart: dataRecount.start.date
          ? moment(dataRecount.start.date).format("HH:mm")
          : "",
        hourEnd: dataRecount.end.date
          ? moment(dataRecount.end.date).format("HH:mm")
          : "",
        duration:
          dataRecount.start.date && dataRecount.end.date
            ? moment
                .duration(
                  moment(dataRecount.end.date).diff(
                    moment(dataRecount.start.date)
                  )
                )
                .asHours()
                .toFixed(2) + " horas"
            : "",
        employee: staff.find(
          (staffEmployee) => staffEmployee._id === dataRecount.staffId
        ).name,
      }))
      .sort((a, b) => moment(a.day).diff(moment(b.day)));

    return {
      reports: finalData,
      dateStart: finalData[0].day,
      dateEnd: finalData[finalData.length - 1].day,
    };
  };
  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <WebStaffList
          role="manager"
          data={this.state.data}
          translation={this.context.translation.templates.web.staff.list}
        />
      </Grid>
    );
  }
}
export { ManagerWebStaffList };
